import type {
  BinaryFilter,
  ResultSet,
  SerializedResult,
  UnaryFilter,
} from "@cubejs-client/core";
import moment from "moment";
import type { ISerieData } from "../../../../../components/chart/domain";
import { getSelectedPalette } from "../../../../../components/palette/utils/paletteData";
import type { IOrg } from "../../../../../interfaces/org";
import type { ChartOption } from "../../../../chart-options/ChartOptions";
import type { IAnalysisType, ILagoonQuery } from "../../domain";

export const CUSTOM_DIMENSION_ORDERING_SUFFIX = "__sort";

// Used to hold together many SerieData
export interface DatasetItem {
  // Value on the X axis
  x: string;
  // One entry per SerieData
  // The key is following the format `xxxx.current` or `xxxx.previous`
  // This is to help reconcilating series that are linked together through a time comparaison
  [key: string]: string | number;
}

export const cleanSerializedResultSetFromDimensionCustomSorting = (
  resultSet: SerializedResult
): SerializedResult => {
  // Helper function to filter out keys ending with the custom suffix
  const cleanKeys = (obj: { [key: string]: any }) => {
    return Object.fromEntries(
      Object.entries(obj).filter(
        ([key]) => !key.endsWith(CUSTOM_DIMENSION_ORDERING_SUFFIX)
      )
    );
  };

  // Helper function to clean the "order" array
  const cleanOrder = (order: { id: string; desc: boolean }[] | undefined) => {
    return order?.map(({ id, ...rest }) => ({
      ...rest,
      id: id.split(CUSTOM_DIMENSION_ORDERING_SUFFIX)[0],
    }));
  };

  // Helper function to clean dimensions
  const cleanDimensions = (dimensions: string[] | undefined) => {
    return (
      dimensions?.filter(
        (dim) => !dim.endsWith(CUSTOM_DIMENSION_ORDERING_SUFFIX)
      ) ?? []
    );
  };

  // Clean the result set
  return {
    ...resultSet,
    loadResponse: {
      ...resultSet.loadResponse,
      pivotQuery: {
        ...resultSet.loadResponse.pivotQuery,
        dimensions: cleanDimensions(
          resultSet.loadResponse.pivotQuery.dimensions
        ),
        order: Array.isArray(resultSet.loadResponse.pivotQuery.order)
          ? cleanOrder(resultSet.loadResponse.pivotQuery.order as any)
          : resultSet.loadResponse.pivotQuery.order,
      },
      results: resultSet.loadResponse.results.map((result) => ({
        ...result,
        annotation: {
          ...result.annotation,
          dimensions: cleanKeys(result.annotation.dimensions),
        },
        data: result.data.map(cleanKeys),
        query: {
          ...result.query,
          order: Array.isArray(result.query.order)
            ? cleanOrder(result.query.order as any)
            : result.query.order,
          dimensions: cleanDimensions(result.query.dimensions),
        },
      })),
    },
  } as SerializedResult;
};

// Some measures are inserted in the lagoon query only for sorting purpose
// This is cleaning them from the resultSet before consumption by the charts
// This keep those measures hidden from the charts
export const cleanSerializedResultSetFromMeasureOnlyUsedForSorting = (
  resultSet: SerializedResult,
  measuresOnlyForOrdering: string[]
): SerializedResult => {
  // Helper function to filter out keys ending with the custom suffix
  const cleanKeys = (obj: { [key: string]: any }) => {
    return Object.fromEntries(
      Object.entries(obj).filter(
        ([key]) => !measuresOnlyForOrdering.includes(key)
      )
    );
  };

  // Helper function to clean dimensions
  const cleanMeasures = (measures: string[] | undefined) => {
    return (
      measures?.filter((met) => !measuresOnlyForOrdering.includes(met)) ?? []
    );
  };

  // Clean the result set
  return {
    ...resultSet,
    loadResponse: {
      ...resultSet.loadResponse,
      results: resultSet.loadResponse.results.map((result) => ({
        ...result,
        annotation: {
          ...result.annotation,
          dimensions: cleanKeys(result.annotation.dimensions),
        },
        data: result.data.map(cleanKeys),
        query: {
          ...result.query,
          measures: cleanMeasures(result.query.measures),
        },
      })),
    },
  };
};

export const canDrill = (
  resultSet: ResultSet,
  serie: ISerieData,
  xKey: string,
  analysisType: IAnalysisType,
  lagoonQuery: ILagoonQuery
) => {
  const getPivot = (r: ResultSet) => {
    const pivot = r.normalizePivotConfig();
    const { x, y } = pivot;
    const formattedPivot = {
      x: x.filter((v) => v !== "compareDateRange" && v !== "measures"),
      y: y.filter((v) => v !== "compareDateRange"),
    };

    // const pivotingValue = serie.dimensions.filter(a => (pivotConfig || []).includes(a.key));

    let xValues: string[] = [];
    let yValues: string[] = [];

    (serie.dimensions || []).map((dim) => {
      if (x.includes(dim.key)) {
        xValues.push(dim.value);
      }
      if (y.includes(dim.key)) {
        yValues.push(dim.value);
      }
    });

    let formattedXKey = xKey
      .split(",")
      .filter((r) => r !== "previous" && r !== "current");

    if (
      analysisType === "TIME" &&
      serie.previous &&
      lagoonQuery.selectedGranularity
    ) {
      // in case of time series and comparison we need to subtract the comparison granularity to get the proper date
      formattedPivot.x.forEach((fp, i) => {
        const [cubeName, dimension, granularity] = fp.split(".");
        if (granularity) {
          // we found the index for the time we can down tune it
          formattedXKey = formattedXKey.map((_, index) => {
            if (index === i) {
              return moment(formattedXKey[i])
                .subtract(1, lagoonQuery.selectedGranularity)
                .format("YYYY-MM-DDTHH:mm:ss.SSS");
            }
            return _;
          });
        }
      });
    }

    const removeWierdValues = (v: string) => {
      if (v === "∅") {
        return null;
      } else if (v === "[Empty string]") {
        return "";
      }
      return v;
    };

    const p = {
      xValues: [...xValues, ...formattedXKey].map(removeWierdValues),
      yValues: [...yValues, serie.metric.key].map(removeWierdValues),
    };

    return {
      drills: p,
      pivot: formattedPivot,
    };
  };

  // we need to decompose queries for date comparison as drill is not supported
  if ((resultSet as any).queryType === "compareDateRangeQuery") {
    const queries = (resultSet as any).decompose();

    if (serie.previous) {
      const { drills, pivot } = getPivot(queries[1]);
      return queries[1].drillDown(drills, pivot);
    } else {
      const { drills, pivot } = getPivot(queries[0]);
      // doing so because cube adds a compareDateRange filter to compare query
      const q = queries[0].drillDown(drills, pivot);
      return {
        ...q,
        filters: (q.filters || []).filter((a) => {
          return (
            (a as BinaryFilter | UnaryFilter).member !== "compareDateRange"
          );
        }),
      };
    }
  }

  const { drills, pivot } = getPivot(resultSet);
  return resultSet.drillDown(drills, pivot);
};

export const formatChartOptions = (org: IOrg, chartOptions?: ChartOption) => {
  if (!chartOptions) {
    return undefined;
  }
  const { palette, series } = chartOptions;
  const copiedChartOptions: ChartOption = { ...chartOptions };

  if (palette) {
    copiedChartOptions.palette = getSelectedPalette(org, palette);
  }

  if (series) {
    copiedChartOptions.series = Object.keys(series)
      .filter((f) => !!f && !!series[f])
      .reduce((acc, v) => {
        return {
          ...acc,
          [v]: {
            ...series[v],
            palette: series[v].palette
              ? getSelectedPalette(org, series[v].palette)
              : undefined,
          },
        };
      }, {});
  }

  return copiedChartOptions;
};
