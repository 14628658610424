import type {
  CubejsApi,
  LoadMethodOptions,
  Query,
  ResultSet,
  SqlQuery,
} from "@cubejs-client/core";
import cubejs from "@cubejs-client/core";
import cubejsNext from "@cubejs-client/core-next";
import * as Sentry from "@sentry/react";
import { v4 as uuidv4 } from "uuid";
import { getBrowserTimezone } from "../utils/cubejsUtils";
import { retryPromise } from "../utils/promiseUtils";
import type { ApiOptions } from "./ApiService";
import ApiService from "./ApiService";
import {
  authenticatedRequest,
  getAccessToken,
  getAuthorizationType,
} from "./authService";
import HttpTransport from "./LagoonTransport";

export interface WlyResultSet<T> extends ResultSet<T> {
  id: string;
  duration: number;
}

export type LagoonObjectType = "VIEW" | "EXPLORATION" | "OBJECT";
export enum LagoonCallOrigin {
  WHALY_APP = "WHALY_APP",
  PUSH = "PUSH",
}

export const lagoonServiceLoad = (
  orgId: string,
  query: Query | Query[],
  objectType: LagoonObjectType,
  objectId: string,
  explorationId: string | undefined,
  origin: LagoonCallOrigin,
  reportId?: string,
  options?: LoadMethodOptions | undefined,
  useLagoonNext?: boolean,
  timezone?: string
): Promise<WlyResultSet<any>> => {
  return authenticatedRequest().then(() =>
    load(
      orgId,
      query,
      objectType,
      objectId,
      explorationId,
      origin,
      reportId,
      options,
      useLagoonNext,
      timezone
    )
  );
};

export const lagoonServiceSQL = (
  orgId: string,
  query: Query | Query[],
  objectType: LagoonObjectType,
  objectId: string,
  explorationId: string | undefined,
  origin: LagoonCallOrigin,
  reportId?: string,
  options?: LoadMethodOptions | undefined,
  useLagoonNext?: boolean,
  timezone?: string
): Promise<SqlQuery> => {
  return authenticatedRequest().then(() =>
    sql(
      orgId,
      query,
      objectType,
      objectId,
      explorationId,
      origin,
      reportId,
      options,
      useLagoonNext,
      timezone
    )
  );
};

const getCubeClient = (
  useLagoonNext: boolean,
  orgId: string,
  objectType: string,
  objectId: string,
  baseRequestId: string,
  origin: LagoonCallOrigin,
  explorationId?: string,
  reportId?: string
) => {
  const headers = {
    "x-whaly-org-id": orgId,
    "x-whaly-object-type": objectType,
    "x-whaly-object-id": objectId,
    "x-whaly-origin": origin,
    ...(explorationId ? { "x-whaly-exploration-id": explorationId } : {}),
    ...(reportId ? { "x-whaly-report-id": reportId } : {}),
  };

  const token = getAccessToken();
  const type = getAuthorizationType();

  const authorization = `${type} ${token}`;

  if (useLagoonNext) {
    const url = `${window.WHALY_CONSTANTS.LAGOON_NEXT_URL}/cubejs-api/v1`;
    return cubejsNext(authorization, {
      apiUrl: url,
      transport: new HttpTransport({
        apiUrl: url,
        headers,
        authorization,
        baseRequestId,
      } as any),
      // We consider that the API is the same than the "current" version
    }) as unknown as CubejsApi;
  } else {
    const url = `${window.WHALY_CONSTANTS.LAGOON_URL}/cubejs-api/v1`;
    return cubejs(authorization, {
      apiUrl: url,
      transport: new HttpTransport({
        apiUrl: url,
        headers,
        authorization,
        baseRequestId,
      } as any),
    });
  }
};

const load = (
  orgId: string,
  query: Query | Query[],
  objectType: LagoonObjectType,
  objectId: string,
  explorationId: string | undefined,
  origin: LagoonCallOrigin,
  reportId?: string,
  options?: LoadMethodOptions | undefined,
  useLagoonNext?: boolean,
  timezone?: string
): Promise<WlyResultSet<any>> => {
  const startTs = Date.now();
  const baseRequestId = uuidv4();

  return retryPromise(
    () =>
      getCubeClient(
        !!useLagoonNext,
        orgId,
        objectType,
        objectId,
        baseRequestId,
        origin,
        explorationId,
        reportId
      ).load(
        Array.isArray(query)
          ? query.map((q) => ({
              ...q,
              timezone: timezone ?? getBrowserTimezone(),
            }))
          : {
              ...query,
              timezone: timezone ?? getBrowserTimezone(),
            },
        {
          ...options,
        } as any
      ),
    { retries: 3, retryIntervalMs: 200 }
  )
    .then((r) => {
      const endTs = Date.now();
      const duration = endTs - startTs;
      // enhance resultSet with data we need for introspection
      (r as any).id = baseRequestId;
      (r as any).duration = duration;
      return r as WlyResultSet<any>;
    })
    .catch((err) => {
      console.error(err);
      Sentry.captureException(err, (scope) => {
        scope.setTag("service", "lagoon");
        scope.setTag("requestId", baseRequestId);
        return scope;
      });
      let errMessage = err;
      if (typeof err === "string") {
        errMessage = errMessage + " requestId: " + baseRequestId;
      } else if (err instanceof Error) {
        errMessage.message =
          errMessage.message + " requestId: " + baseRequestId;
      }
      throw new Error(errMessage);
    });
};

const sql = (
  orgId: string,
  query: Query | Query[],
  objectType: LagoonObjectType,
  objectId: string,
  explorationId: string | undefined,
  origin: LagoonCallOrigin,
  reportId?: string,
  options?: LoadMethodOptions | undefined,
  useLagoonNext?: boolean,
  timezone?: string
): Promise<SqlQuery> => {
  const baseRequestId = uuidv4();

  return getCubeClient(
    !!useLagoonNext,
    orgId,
    objectType,
    objectId,
    baseRequestId,
    origin,
    explorationId,
    reportId
  )
    .sql(
      {
        ...query,
        timezone: timezone ?? getBrowserTimezone(),
      },
      {
        ...options,
      }
    )
    .catch((err) => {
      console.error(err);
      throw new Error(err);
    });
};

export interface IQueryTraceResult<T = any> {
  isFromServingLayer: boolean;
  isFromCache: boolean;
  events: IQueryTraceData<T>[];
}

export interface IQueryTraceData<T = any> {
  evtName: string;
  offset: number;
  requestId: string;
  span: string;
  ts: number;
  data: T;
}

export const traceQuery = (
  queryId: string,
  useLagoonNext: boolean
): Promise<IQueryTraceResult> => {
  const opts: ApiOptions = {};
  if (useLagoonNext) {
    opts.lagoonNextUrl = true;
  } else {
    opts.lagoonUrl = true;
  }
  return ApiService.getRequest(`/trace/${queryId}`, undefined, undefined, {
    authenticated: false,
    ...opts,
  }).then((r) => {
    const { data, isFromCache, isFromServingLayer } = r as {
      status: "ok";
      isFromServingLayer: boolean;
      isFromCache: boolean;
      data: IQueryTraceData[];
    };

    return {
      isFromCache,
      isFromServingLayer,
      events: data,
    };
  });
};
