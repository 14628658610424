import { gql } from "@apollo/client";
import { USER_FRAGMENT } from "../../../fragments/user";
import type {
  IExploration,
  IExplorationSection,
} from "../../../interfaces/explorations";
import type { IFileUpload } from "../../../interfaces/fileUpload";
import type { IJobExecution } from "../../../interfaces/jobExecutions";
import type { IModelFolder } from "../../../interfaces/modelFolder";
import type { IObject, IObjectLayoutType } from "../../../interfaces/object";
import type { IRadar } from "../../../interfaces/radar";
import type { IDataset, ISource } from "../../../interfaces/sources";
import type { IWorksheet } from "../../../interfaces/worksheet";
import StorageService from "../../../services/StorageService";
import { EXTRACT_SCHEDULE_FRAGMENT } from "../../../store/dataStores/workspace/WorkspaceDatastoreDomain";
import type { FetchedDestination } from "../workbench/domain";

export const MODEL_FOLDER_FRAGMENT = `
  fragment ModelFolderQuery on ModelFolder {
    id
    name
    image
    updatedAt
    parent {
      id
    }
  }
`;

export const WORKSHEET_FRAGMENT = `
${USER_FRAGMENT}

fragment WorksheetQuery on Worksheet {
  id
  name
  query
  deleted
  createdAt
  createdBy {
    ...UpdatedBy
  }
}
`;

export const RADAR_LIST_FRAGMENT = gql`
  fragment RadarListFragment on Radar {
    id
    name
    isDeleted
    updatedAt
  }
`;

export const UPLOADER_LIST_FRAGMENT = gql`
  fragment FileUploadListFragment on FileUploadJob {
    id
    name
    storage {
      id
    }
  }
`;

export const UPLOADER_COMPLETE_FRAGMENT = gql`
  fragment FileUploadCompleteFragment on FileUploadJob {
    id
    slug
    name
    description
    isEnabled
    lastRun {
      id
      createdAt
    }
    storage {
      id
    }
    template
    computeType
    computeConfig
    transformerDockerUrl
    transformerDockerVars
    uploaderDockerUrl
    uploaderDockerVars
  }
`;

export const RADAR_COMPLETE_FRAGMENT = gql`
  fragment RadarCompleteFragment on Radar {
    id
    name
    isDeleted
    uniqueKeys
    topicKey
    recordIdKey
    eventKeys
    warehouseDatabaseId
    warehouseSchemaId
    warehouseTableId
    warehouseDatabaseQuoting
    warehouseSchemaQuoting
    warehouseTableQuoting
    model {
      id
    }
    object {
      id
    }
    emailLayout {
      id
    }
    schedule {
      id
      period
      atHour
      dailySendEvery
      weeklyOnDay
      monthlyDayOfMonthPreset
      monthlyDayOfMonthNumber
      timezone
    }
  }
`;

export const OBJECT_FRAGMENT = `
${USER_FRAGMENT}
${EXTRACT_SCHEDULE_FRAGMENT}
fragment ObjectQuery on Object {
  id
  name
  slug
  color
  icon
  isExtractEnabled
  extractSchedule {
    ...ExtractSchedule
  }
  model {
    id
  }
  defaultLayout {
    id
  }
  table {
    view {
      dataset {
        cacheStrategy
        isPersistedAs
        warehouseSize {
          sizeBytes
          rowCount
        }
      }
    }
    activeExtract {
      status
      sizeBytes
      rowCount
    }
  }
  isDeleted
  createdAt
  createdBy {
    ...UpdatedBy
  }
}
`;

export interface IWorkbenchData {
  allDatasets: IDataset[];
  allSources: ISource[];
  allExplorations: IExploration[];
  allExplorationSections: IExplorationSection[];
  destinationJobExecutions: IJobExecution[];
  allModelFolders: IModelFolder[];
  Destination?: FetchedDestination;
  allWorksheets: IWorksheet[];
  allObjects: IObject[];
  connectors: ISource[];
  allObjectLayouts: Array<IObjectLayoutFragment>;
  allRadars: IRadar[];
  allFileUploadJobs: IFileUpload[];
}

export interface IObjectLayoutFragment {
  id: string;
  name: string;
  type: IObjectLayoutType;
  object: {
    id: string;
  };
  createdAt: string;
  updatedAt: string;
}

const buildCacheKey = (userId: string, orgId: string, warehouseId: string) =>
  `_wly_usr_${userId}_org_${orgId}_wrhs_${warehouseId}`;

export const getValueFromCache = (
  userId: string,
  orgId: string,
  warehouseId: string
) => {
  const defaultValue = StorageService.getItem(
    buildCacheKey(userId, orgId, warehouseId)
  );
  try {
    if (defaultValue) {
      const value: Partial<IWorkbenchData> = JSON.parse(defaultValue);
      return value;
    }
  } catch (err) {
    console.error(err);
  }
  return null;
};

export const storeValueFromCache = (
  userId: string,
  orgId: string,
  warehouseId: string,
  data: Partial<IWorkbenchData>
) => {
  try {
    StorageService.setItem({
      [buildCacheKey(userId, orgId, warehouseId)]: JSON.stringify(data),
    });
  } catch (err) {
    console.log(err);
  }
};

export const OBJECT_LAYOUT_FRAGMENT = `
fragment ObjectLayoutFragment on ObjectLayout {
  id
  name
  type
  object {
    id
  }
  createdAt
  updatedAt
}
`;
