import { Typography } from "antd";
import type { TextProps } from "antd/lib/typography/Text";
import { escapeRegExp } from "lodash";

export const Highlight = ({
  text,
  highlight,
  ...props
}: {
  text: string;
  highlight: string;
} & TextProps) => {
  if (!highlight.trim())
    return <Typography.Text {...props}>{text}</Typography.Text>;

  const regex = new RegExp(`(${escapeRegExp(highlight)})`, "gi");
  const parts = text.split(regex);

  return (
    <Typography.Text {...props}>
      {parts
        .filter((part) => part)
        .map((part, i) =>
          regex.test(part) ? (
            <Typography.Text mark key={i}>
              {part}
            </Typography.Text>
          ) : (
            <span key={i}>{part}</span>
          )
        )}
    </Typography.Text>
  );
};
