import { inject, observer } from "mobx-react";
import { useState } from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import type { InjectedAntUtilsProps } from "../../../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../../../components/ant-utils/withAntUtils";
import { compose } from "../../../../../components/compose/WlyCompose";
import { Folder } from "../../../../../components/folders/view/Folder";
import type { IReportFolder } from "../../../../../interfaces/folder";
import { routeDescriptor } from "../../../../../routes/routes";
import GraphQLService from "../../../../../services/graphql/GraphQLService";
import type { WorkspaceDatatoreProps } from "../../../../../store/dataStores/workspace/workspaceDatastore";
import {
  CREATE_REPORT_FOLDER,
  MOVE_REPORT,
  REORDER_REPORT_FOLDERS,
  UPDATE_REPORT_FOLDER,
} from "../../../../../store/dataStores/workspace/WorkspaceDatastoreDomain";
import type { InjectedOrgProps } from "../../../../orgs/WithOrg";
import WithOrg from "../../../../orgs/WithOrg";

type ReorderFolderChange = {
  id: string;
  order?: number;
  parent?: { connect: { id: string } } | { disconnect: { id: string } };
};

interface IFolderMenuInnerProps {
  personalFolder?: IReportFolder;
  folders: IReportFolder[];
  shouldRerender: () => void;
  isDragging: boolean;
  updateFolders: (r: IReportFolder[]) => void;
}

type Props = IFolderMenuInnerProps &
  RouteComponentProps<{}> &
  InjectedAntUtilsProps &
  InjectedOrgProps &
  WorkspaceDatatoreProps;

function FolderMenuInner(props: Props) {
  const {
    history,
    match: { params },
    folders,
    antUtils,
    shouldRerender,
    isDragging,
    updateFolders,
    org,
    personalFolder,
    workspaceDatastore: { refresh: refreshWorkspace },
  } = props;

  const [loadingCount, setLoadingCount] = useState(0);

  const reorderReportFolders = (data: Array<ReorderFolderChange>) => {
    setLoadingCount((prev) => prev + 1);

    // updateFolders(
    //   folders.map((f) => {
    //     const found = data.find((d) => d.id === f.id);
    //     if (found) {
    //       return {
    //         ...f,
    //         order: found.order ?? f.order,
    //       };
    //     }
    //     return f;
    //   })
    // );

    return GraphQLService(REORDER_REPORT_FOLDERS, {
      data,
    })
      .then((data) => refreshWorkspace().then(() => data))
      .finally(() => {
        setLoadingCount((prev) => prev - 1);
      });
  };

  const updateReportFolder = (data: Array<{ id: string; data: any }>) => {
    setLoadingCount((prev) => prev + 1);

    // updateFolders(
    //   folders.map((f) => {
    //     const found = data.find((d) => d.id === f.id);
    //     if (found) {
    //       return {
    //         ...f,
    //         name: found.data.name ? found.data.name : f.name,
    //         description: found.data.description
    //           ? found.data.description
    //           : f.description,
    //         cover: found.data.cover ? found.data.cover : f.cover,
    //         image: found.data.image ? found.data.image : f.image,
    //         color: found.data.color ? found.data.color : f.color,
    //       };
    //     }
    //     return f;
    //   })
    // );

    return GraphQLService(UPDATE_REPORT_FOLDER, {
      updateReportfolders: data,
    })
      .then((data) => refreshWorkspace().then(() => data))
      .finally(() => {
        setLoadingCount((prev) => prev - 1);
      });
  };

  const onDropReport = (droppedReport, targetFolder) => {
    setLoadingCount((prev) => prev + 1);
    const m = antUtils.message.loading({ content: "Moving report..." });

    // updateFolders(
    //   folders
    //     .map((folder) => {
    //       return getFolderWithoutDuplicateReport({
    //         ...folder,
    //         reports: folder.reports.filter(({ id }) => id !== droppedReport.id),
    //       });
    //     })
    //     .map((folder) => {
    //       return getFolderWithoutDuplicateReport({
    //         ...folder,
    //         reports:
    //           folder.id === targetFolder.id
    //             ? [...folder.reports, droppedReport]
    //             : folder.reports,
    //       });
    //     })
    // );

    return GraphQLService(MOVE_REPORT, {
      id: droppedReport.id,
      data: {
        folder: {
          connect: {
            id: targetFolder.id,
          },
        },
      },
    })
      .then(() => {
        m();
        antUtils.message.success(
          `Report ${droppedReport.name} moved to folder ${targetFolder.name}`
        );
        shouldRerender();
        return refreshWorkspace();
      })
      .finally(() => {
        setLoadingCount((prev) => prev - 1);
      });
  };

  return (
    <Folder
      setSelectedSlug={(s, e) => {
        const route = routeDescriptor["folders"].renderRoute({
          ...params,
          folderSlug: s,
        });
        if (e.ctrlKey || e.metaKey) {
          window.open(route, "_blank");
        } else {
          history.push(route);
        }
      }}
      loading={loadingCount > 0}
      folders={folders}
      onShare={async () => {}}
      hideTitle={true}
      isDragging={isDragging}
      onDropReport={onDropReport}
      personalFolder={personalFolder}
      onChange={(folderChanges) => {
        return reorderReportFolders(
          Object.entries(folderChanges).map(([key, { order, parent }]) => {
            const folderChange = { id: key, order };

            if (parent?.operation === "CONNECT") {
              return {
                ...folderChange,
                parent: { connect: { id: parent.id } },
              };
            } else if (parent?.operation === "DISCONNECT") {
              return {
                ...folderChange,
                parent: { disconnect: { id: parent.id } },
              };
            } else {
              return { ...folderChange, parent: undefined };
            }
          })
        );
      }}
      onDelete={(idsToDelete) => {
        return updateReportFolder(
          idsToDelete.map((v) => {
            return {
              id: v,
              data: {
                deleted: true,
              },
            };
          })
        ).then((r) => {
          updateFolders(
            folders.filter(
              (f) => !r.updateReportFolders.map((a) => a.id).includes(f.id)
            )
          );
          setTimeout(() => {
            props.history.push(
              routeDescriptor["folders"].renderRoute({
                ...props.match.params,
                folderSlug: folders[0].slug,
              })
            );
          }, 100);
          return r;
        });
      }}
      onEdit={(v) => {
        return updateReportFolder([
          {
            id: v.id,
            data: {
              name: v.name,
              image: v.image,
              color: v.color,
            },
          },
        ]).then((r) => {
          updateFolders(
            folders.map((f) => {
              if (r.updateReportFolders.map((i) => i.id).includes(f.id)) {
                return r.updateReportFolders.find((i) => i.id === f.id);
              }
              return f;
            })
          );
          return r;
        });
      }}
      onCreate={(v) => {
        setLoadingCount((prev) => prev + 1);

        return GraphQLService(CREATE_REPORT_FOLDER, {
          orgId: org.id,
          name: v.name,
          image: v.image,
          parent: v.parentId
            ? {
                connect: {
                  id: v.parentId,
                },
              }
            : undefined,
          order: v.order,
        })
          .then((r) => {
            updateFolders([...folders, r.createReportFolder]);
            setTimeout(() => {
              props.history.push(
                routeDescriptor["folders"].renderRoute({
                  ...props.match.params,
                  folderSlug: r.createReportFolder.slug,
                })
              );
            }, 100);

            return r.createReportFolder as IReportFolder;
          })
          .finally(() => {
            setLoadingCount((prev) => prev - 1);
          });
      }}
    />
  );
}

export default compose<Props, IFolderMenuInnerProps>(
  withRouter,
  withAntUtils,
  WithOrg,
  inject("workspaceDatastore"),
  observer
)(FolderMenuInner);
