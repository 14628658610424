import type { IUserRoleType } from "../interfaces/user";

export type MenuIcon = "workspace" | "workbench" | "settings";

export type ViewName =
  | "organisations"
  | "publicLinkReport"
  | "privateLinkReport"
  | "sharingLinkReport"
  | "publicEmbeddedReport"
  | "featureCrossSellPage"
  | "login"
  | "portalLogin"
  | "callback"
  | "portalCallback"
  | "redirect"
  | "home"
  | "report"
  | "reportEdit"
  | "reportExploration"
  | "reportTileExplorationEdit"
  | "reportTileExplorationCreate"
  | "reportTileEdit"
  | "reportFilterEdit"
  | "sourceConnection"
  | "sourceConnectionStep2"
  | "warehouseConnection"
  | "warehouseConnectionStep2"
  | "actionConnection"
  | "actionConnectionStep2"
  | "objectStorageConnection"
  | "objectStorageConnectionStep2"
  | "workbench"
  | "settingsContentSharedReports"
  | "settingsContentUnusedExplorations"
  | "settingsActionHistory"
  | "settingsActionManagement"
  | "settingsBasic"
  | "settingsAccessManagement"
  | "settingsServiceAccountManagement"
  | "settingsPartnerPortalDetails"
  | "settingsPartnerPortals"
  | "settingsGroupManagement"
  | "settingsWarehouse"
  | "settingsWarehouseDetail"
  | "settingsSourceUsage"
  | "settingsPlatformUsage"
  | "settingsTheme"
  | "settingsAuditLog"
  | "settingsUserAttribute"
  | "settingsRealm"
  | "settingsUser"
  | "settingsEmailTemplate"
  | "settingsNotificationTopics"
  | "settingsNotificationJobs"
  | "settingsObjectStorage"
  | "object"
  | "org-picker"
  | "org-status"
  | "privateRecord"
  | "explores"
  | "explore"
  | "folders"
  | "object-record"
  | "object-record-embed"
  | "object-record-public-shared"
  | "object-record-centered"
  | "object-record-document"
  | "object-record-document-centered"
  | "object-list"
  | "rootCause"
  | "fileUploads";

export enum RouteDescriptorComponent {
  ExplorationModal = "ExplorationModal",
  ObjectPageModal = "ObjectPageModal",
  OrgPickerModal = "OrgPickerModal",
  RealmSettingsModal = "RealmSettingsModal",
  RecordModal = "RecordModal",
  RootCauseAnalysisPage = "RootCauseAnalysisPage",
  StatusPageModal = "StatusPageModal",
  UserSettingsModal = "UserSettingsModal",
  V2Layout = "V2Layout",
  ReportV2Page = "ReportV2Page",
  ObjectEmbedView = "ObjectEmbedView",
  ReportPublicView = "ReportPublicView",
  ActionConnectionPage = "ActionConnectionPage",
  SourceConnectionPage = "SourceConnectionPage",
  WarehouseConnectionPage = "WarehouseConnectionPage",
  ObjectStorageConnectionPage = "ObjectStorageConnectionPage",
  FeatureCrossSellPage = "FeatureCrossSellPage",
  Callback = "Callback",
  Login = "Login",
  PortalCallback = "PortalCallback",
  PortalLogin = "PortalLogin",
  Org = "Org",
  RedirectPage = "RedirectPage",
  ReportEmbedPage = "ReportEmbedPage",
  ReportSharingLink = "ReportSharingLink",
  ActionManagementPage = "ActionManagementPage",
  ActionHistoryPage = "ActionHistoryPage",
  AuditLogPage = "AuditLogPage",
  BasicSettingPage = "BasicSettingPage",
  EmailTemplate = "EmailTemplate",
  NotificationJobsPage = "NotificationJobsPage",
  NotificationTopicsPage = "NotificationTopicsPage",
  PartnerPortalDetailPage = "PartnerPortalDetailPage",
  PartnerPortalPage = "PartnerPortalPage",
  SettingsPlatformUsage = "SettingsPlatformUsage",
  ServiceAccountManagementPage = "ServiceAccountManagementPage",
  SharedReportsPage = "SharedReportsPage",
  SettingsSourceUsage = "SettingsSourceUsage",
  ThemeSettingPage = "ThemeSettingPage",
  UnusedExplorationPage = "UnusedExplorationPage",
  UserAttributesPage = "UserAttributesPage",
  UserGroupManagementPage = "UserGroupManagementPage",
  AccessManagementPage = "AccessManagementPage",
  WarehouseSettingsPage = "WarehouseSettingsPage",
  ObjectStorageSettingsPage = "ObjectStorageSettingsPage",
  WarehouseListPage = "WarehouseListPage",
  ObjectPageContainer = "ObjectPageContainer",
  RecordMjml = "RecordMjml",
  RecordView = "RecordView",
  RecordViewEmbed = "RecordViewEmbed",
  RecordViewPublicEmbed = "RecordViewPublicEmbed",
  WorkbenchPageWrapper = "WorkbenchPageWrapper",
  FolderPage = "FolderPage",
  HomePage = "HomePage",
  FileUploadModal = "FileUploadModal",
}

export type RouteDescriptor = {
  [key in ViewName]: RouteDefinition;
};

interface BaseRouteDefinition {
  layout: Layout;
  path: string;
  component: RouteDescriptorComponent;
  embedModal?: RouteDescriptorComponent;
  title: String;
  public?: PublicType;
  renderRoute: <T, G>(params: T, query?: G) => string;
  props?: { [key: string]: any };
  menuIcon?: MenuIcon;
  restrictAccess?: IUserRoleType;
  removeInheritance?: boolean; // this is usefull when the parent component injects
  // some props but you don't need them on specific pages
  // it is mainly used for report views in the workspace
}

interface SubPrivateRouteDefinition extends PrivateRouteDefinition {
  subComponent: RouteDescriptorComponent;
  subProps?: { [key: string]: any };
}

interface PublicRouteDefinition extends BaseRouteDefinition {
  public: PublicType;
}

type PublicType = "SHARED" | "PUBLIC" | "SHARING_LINK";

interface PrivateRouteDefinition extends BaseRouteDefinition {
  menuIcon: MenuIcon;
}

export type RouteDefinition =
  | PrivateRouteDefinition
  | PublicRouteDefinition
  | SubPrivateRouteDefinition;

export type Layout = "main" | "external" | "none";

export function generateRoutesFromDefinition(
  descriptor: RouteDescriptor
): RouteDefinition[] {
  return Object.keys(descriptor).map((key) => descriptor[key as ViewName]);
}
