import { Select } from "antd";
import type { CSSProperties } from "react";
import type { ITimezone } from "react-timezone-select";
import { useTimezoneSelect } from "react-timezone-select";

type TimezoneSelectProps = {
  customOptions?: { value: string; label: string }[];
  value?: ITimezone;
  onChange?: (timezone: string) => void;
  style?: CSSProperties;
};
export const TimezoneSelect = ({
  customOptions,
  value,
  onChange,
  style,
}: TimezoneSelectProps) => {
  const { options } = useTimezoneSelect({
    labelStyle: "abbrev",
  });

  return (
    <Select
      value={value}
      options={[...(customOptions ?? []), ...options]}
      onChange={(value: string) => {
        if (value && onChange) {
          onChange(value);
        }
      }}
      showSearch
      optionFilterProp="label"
      style={style}
    />
  );
};
