import { gql } from "@apollo/client";
import type { DeepPartial } from "../../../../../helpers/typescriptHelpers";
import {
  IComputeType,
  type IFileUpload,
} from "../../../../../interfaces/fileUpload";
import graphQlService from "../../../../../services/graphql/GraphQLService";

export interface CreateFileUploaderJobFunction {
  (options: { name: string }, orgId: string): Promise<string>;
}
export interface UpdateFileUploaderJobFunction {
  (id: string, data: DeepPartial<IFileUpload>): Promise<void>;
}
export const createFileUploader: CreateFileUploaderJobFunction = async (
  opt,
  orgId
) => {
  const t = await graphQlService<{ createFileUploadJob: { id: string } }>(
    gql`
      mutation createFileUploadJob($data: FileUploadJobCreateInput) {
        createFileUploadJob(data: $data) {
          id
        }
      }
    `,
    {
      data: {
        name: opt.name,
        description: "",
        isEnabled: false,
        computeType: IComputeType.GOOGLE,
        computeConfig: "{}",
        transformerDockerVars: "{}",
        transformerDockerUrl: "",
        uploaderDockerVars: "{}",
        uploaderDockerUrl: "",
        org: {
          connect: {
            id: orgId,
          },
        },
      },
    }
  );
  return t.createFileUploadJob.id;
};

export const updateFileUploader: UpdateFileUploaderJobFunction = async (
  id,
  data
) => {
  await graphQlService<{ updateFileUploadJob: { id: string } }>(
    gql`
      mutation updateFileUploadJob($id: ID!, $data: FileUploadJobUpdateInput) {
        updateFileUploadJob(id: $id, data: $data) {
          id
        }
      }
    `,
    {
      id: id,
      data: {
        name: data.name,
        description: data.description,
        isEnabled: data.isEnabled,
        storage: data.storage
          ? {
              connect: {
                id: data.storage?.id,
              },
            }
          : undefined,
        computeType: data.computeType,
        computeConfig: data.computeConfig,
        transformerDockerUrl: data.transformerDockerUrl,
        transformerDockerVars: data.transformerDockerVars,
        uploaderDockerVars: data.uploaderDockerVars,
        uploaderDockerUrl: data.uploaderDockerUrl,
        isDeleted: data.isDeleted,
      },
    }
  );
};
