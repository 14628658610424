import { Modal } from "antd";
import { inject, observer } from "mobx-react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import { compose } from "../../components/compose/WlyCompose";
import Feednack from "../../components/layout/feedback/feedback";
import type { WorkspaceDatatoreProps } from "../../store/dataStores/workspace/workspaceDatastore";
import type { ILagoonQuery } from "../exploration/single/domain";
import type { InjectedOrgProps } from "../orgs/WithOrg";
import WithOrg from "../orgs/WithOrg";
import "./FileUploaderModal.scss";
import FileUploaderDetail from "./components/FileUploaderDetail";
import FileUploaderListSelection from "./components/FileUploaderListSelection";

interface IExplorationModalProps {
  open: boolean;
  onClose: () => void;
  isEmbed?: boolean;
  initialQuery?: ILagoonQuery;
}

type Props = WorkspaceDatatoreProps &
  IExplorationModalProps &
  RouteComponentProps<{ fileUploadSlug: string }> &
  InjectedOrgProps;

function ExplorationModal(props: Props) {
  const {
    open,
    workspaceDatastore: { data },
    onClose,
    match: {
      params: { fileUploadSlug },
    },
  } = props;

  if (data.status !== "success") {
    return (
      <Feednack>
        <div>Please load datastore first</div>
      </Feednack>
    );
  }

  const renderInner = () => {
    if (fileUploadSlug) {
      const currentFileUpload = data.data.allFileUploadJobs.find(
        (ex) => ex.slug === fileUploadSlug
      );
      return (
        <FileUploaderDetail
          onClose={onClose}
          currentFileUpload={currentFileUpload}
        />
      );
    } else {
      return (
        <FileUploaderListSelection
          fileUploadJobs={data.data.allFileUploadJobs}
          onClose={onClose}
        />
      );
    }
  };

  return (
    <Modal
      maskClosable={false}
      destroyOnClose={true}
      closable={false}
      width={"50%"}
      centered
      className="file-uploader-modal"
      styles={{
        body: {
          height: "100%",
          padding: 0,
          maxHeight: "100%",
          overflow: "hidden",
        },
      }}
      footer={null}
      open={open}
    >
      {renderInner()}
    </Modal>
  );
}

export default compose<Props, IExplorationModalProps>(
  inject("workspaceDatastore"),
  observer,
  WithOrg,
  withRouter
)(ExplorationModal);
