import { FileTextOutlined } from "@ant-design/icons";
import { Button, Dropdown, Space, Typography } from "antd";
import type { ColumnProps } from "antd/lib/table";
import moment from "moment";
import React from "react";
import { WlyCard } from "../../../../../../../components/cards/WlyCard";
import CardTable from "../../../../../../../components/table/CardTable";
import {
  FileUploadRunSyncStatus,
  type IFileUpload,
  type IFileUploadRun,
} from "../../../../../../../interfaces/fileUpload";
import type { IJobExecution } from "../../../../../../../interfaces/jobExecutions";
import ExecutionLogsDrawer from "../../../../../../job-execution/ExecutionLogsDrawer";
import { ExecutionStatusTag } from "../../../../../../job-execution/status/ExecutionStatusTag";
import { useGetFileUploadJobExecutionsDetails } from "../../api/useGetFileUploaderJobExecutionDetails";

export interface IFileUploaderOverviewViewerProps {
  fileUploadJob: IFileUpload;
}

export function FileUploaderOverviewViewer(
  props: IFileUploaderOverviewViewerProps
) {
  const { fileUploadJob } = props;

  const [selectedJobExecution, setSelectedJobExecution] =
    React.useState<IJobExecution | null>(null);
  const { data, pending, loading, error } =
    useGetFileUploadJobExecutionsDetails({
      variables: { fileUploadId: fileUploadJob.id },
    });

  const generateColumns = (): ColumnProps<IFileUploadRun>[] => {
    const getEndTime = (s: IFileUploadRun) => {
      if (s.uploadJobExecution?.updatedAt) {
        return s.uploadJobExecution?.updatedAt;
      } else if (s.uploadJobExecution?.createdAt) {
        return s.uploadJobExecution?.createdAt;
      } else if (s.transformJobExecution?.updatedAt) {
        return s.transformJobExecution?.updatedAt;
      } else if (s.uploadJobExecution?.createdAt) {
        return s.uploadJobExecution?.createdAt;
      } else if (s.updatedAt) {
        return s.updatedAt;
      }
    };

    return [
      {
        title: "Transformer Status",
        key: "transformer",
        render: (v, s) => {
          return (
            <div>
              <ExecutionStatusTag execution={s.transformJobExecution} />
            </div>
          );
        },
      },
      {
        title: "Uploader Status",
        key: "uploader",
        render: (v, s) => {
          return (
            <div>
              <ExecutionStatusTag execution={s.uploadJobExecution} />
            </div>
          );
        },
      },
      {
        title: "Start Date",
        key: "status",
        render: (v, s) => {
          return <div>{moment(s.createdAt).fromNow()}</div>;
        },
      },
      {
        title: "Duration",
        key: "duration",
        render: (v, s) => {
          return (
            <div>
              {s.syncStatus === FileUploadRunSyncStatus.idle
                ? moment
                    .duration(moment(getEndTime(s)).diff(s.createdAt))
                    .humanize()
                : "---"}
            </div>
          );
        },
      },
      {
        title: "Logs",
        key: "logs",
        render: (v, s) => {
          return (
            <div>
              <Dropdown
                trigger={["click"]}
                placement="bottomRight"
                menu={{
                  items: [
                    {
                      key: "transformer",
                      label: "Transformer log",
                      disabled: !s.transformJobExecution,
                      onClick: () =>
                        setSelectedJobExecution(s.transformJobExecution),
                    },
                    {
                      key: "uploader",
                      label: "Uploader log",
                      disabled: !s.uploadJobExecution,
                      onClick: () =>
                        setSelectedJobExecution(s.uploadJobExecution),
                    },
                  ],
                }}
              >
                <Button shape="circle" icon={<FileTextOutlined />} />
              </Dropdown>
            </div>
          );
        },
      },
    ];
  };

  const renderExecutionCard = () => {
    if (error) {
      return (
        <WlyCard>
          <Typography.Text type="danger">
            There was an error fetching invokations
          </Typography.Text>
        </WlyCard>
      );
    }
    return (
      <>
        <CardTable<IFileUploadRun>
          cardTitle={<div>Invocations</div>}
          actionButtons={<div />}
          rowKey="id"
          loading={loading || pending}
          dataSource={data?.allFileUploadJobRuns ?? []}
          columns={generateColumns()}
          pagination={{
            size: "small",
            style: { display: "none" },
            defaultPageSize: 1000,
          }}
        />
      </>
    );
  };

  return (
    <div style={{ maxWidth: 800, width: "100%", margin: "24px auto" }}>
      <Space style={{ width: "100%" }} direction="vertical" size={24}>
        <WlyCard>
          <div>
            <Typography.Title level={4}>{fileUploadJob.name}</Typography.Title>
          </div>
          {fileUploadJob.description && (
            <div>
              <Typography.Text type="secondary">
                {fileUploadJob.description}
              </Typography.Text>
            </div>
          )}
          {fileUploadJob.lastRun ? (
            <div>
              <Typography.Text>Last invokation was done</Typography.Text>{" "}
              <Typography.Text strong>
                {moment(fileUploadJob.lastRun.createdAt).fromNow()}
              </Typography.Text>
            </div>
          ) : (
            <div>
              <Typography.Text>
                This file uploader has never been invoked
              </Typography.Text>
            </div>
          )}
        </WlyCard>
        {renderExecutionCard()}
        <ExecutionLogsDrawer
          onClose={() => setSelectedJobExecution(null)}
          selectedJobExecution={selectedJobExecution!}
          visible={!!selectedJobExecution}
        />
      </Space>
    </div>
  );
}
