import {
  CopyOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { Button, Flex, Popover } from "antd";
import classNames from "classnames";
import { useCallback, useMemo, useRef, useState } from "react";
import { useHoverDirty } from "react-use";
import type { InjectedAntUtilsProps } from "../../../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../../../components/ant-utils/withAntUtils";
import { compose } from "../../../../../components/compose/WlyCompose";
import type { DataType } from "../../../../../interfaces/transformations";
import FieldRenderer from "../../../../spreadsheet/renderer/FieldRenderer";

import type { ICellRendererParams, IRowNode } from "ag-grid-community";
import { createPortal } from "react-dom";
import "./CustomCellRenderer.scss";

const ANTD_POPOVER_PADDING = 24;

interface CustomCellRendererProps {
  value: any;
  domain: DataType;
}
export const CustomCellRenderer = compose(withAntUtils)(
  ({
    value,
    domain,
    antUtils,
    api,
    eGridCell: cellElement,
    node: rowNode,
  }: CustomCellRendererProps & ICellRendererParams & InjectedAntUtilsProps) => {
    const cellElementRef = useRef<HTMLElement>(cellElement);
    const [viewMore, setViewMore] = useState(false);
    const isHovering = useHoverDirty(cellElementRef);

    return (
      <div className="dataset-viewer-custom-cell">
        {(isHovering || viewMore) && (
          <CellContentMenu
            value={value}
            domain={domain}
            antUtils={antUtils}
            cellElement={cellElement}
            rowNode={rowNode}
            viewMore={viewMore}
            setViewMore={(open) => setViewMore(open)}
            api={api}
          />
        )}
        <FieldRenderer content={value} type={domain} />
      </div>
    );
  }
);

type CellContentMenuProps = {
  cellElement: HTMLElement;
  rowNode: IRowNode<any>;
  viewMore: boolean;
  setViewMore: (open: boolean) => void;
} & Partial<ICellRendererParams> &
  CustomCellRendererProps &
  InjectedAntUtilsProps;

const CellContentMenu = ({
  value,
  domain,
  antUtils,
  cellElement,
  rowNode,
  viewMore,
  setViewMore,
  api,
}: CellContentMenuProps) => {
  const handleCopyToClipboard = useCallback(() => {
    try {
      api?.deselectAll();
      navigator.clipboard.writeText(value);
      antUtils.message.success("Value copied to clipboard");
    } catch (error) {
      console.warn(error);
    }
  }, [antUtils.message, api, value]);

  const handleOpenChange = useCallback(
    (open: boolean) => {
      setViewMore(open);
      !open && rowNode.setSelected(false);
    },
    [rowNode, setViewMore]
  );

  const cellElementWidth = useMemo(
    () => cellElement.getBoundingClientRect().width,
    [cellElement]
  );
  const cellElementHeight = useMemo(
    () => cellElement.getBoundingClientRect().height,
    [cellElement]
  );
  const cellElementPaddingLeft = useMemo(
    () => parseInt(getComputedStyle(cellElement).paddingLeft),
    [cellElement]
  );
  const popoverWidth = useMemo(
    () => cellElementWidth - cellElementPaddingLeft - ANTD_POPOVER_PADDING,
    [cellElementPaddingLeft, cellElementWidth]
  );

  return (
    <Flex
      gap="2px"
      className={classNames("cell-hover-menu", viewMore && "active")}
    >
      <Button
        type="default"
        size="small"
        icon={viewMore ? <EyeInvisibleOutlined /> : <EyeOutlined />}
        onClick={() => handleOpenChange(!viewMore)}
      />

      <Button
        type="default"
        size="small"
        icon={<CopyOutlined />}
        onClick={handleCopyToClipboard}
      />

      {viewMore &&
        createPortal(
          <Popover
            content={
              <div
                style={{
                  minWidth: `${1 * popoverWidth}px`,
                  maxWidth: `${1.5 * popoverWidth}px`,
                  maxHeight: `${4.5 * cellElementHeight}px`,
                  overflow: "auto",
                }}
              >
                <FieldRenderer
                  content={value}
                  type={domain}
                  checkJson
                  withPreWrap
                />
              </div>
            }
            trigger="click"
            open={viewMore}
            onOpenChange={handleOpenChange}
            arrow={false}
            placement="bottomLeft"
            getPopupContainer={() =>
              cellElement.closest(".ag-center-cols-container") ?? document.body
            }
            autoAdjustOverflow={false}
            align={{ offset: [0, -cellElementHeight + -4] }}
          />,
          cellElement
        )}
    </Flex>
  );
};
