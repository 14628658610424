import { FullscreenExitOutlined, FullscreenOutlined } from "@ant-design/icons";
import { Badge, Flex } from "antd";
import { inject, observer } from "mobx-react";
import moment from "moment";
import { Component } from "react";
import { compose } from "../../../../components/compose/WlyCompose";
import Error from "../../../../components/error/Error";
import { default as Loading } from "../../../../components/layout/feedback/loading";
import UserAvatar from "../../../../components/user/avatar/UserAvatar";
import type { AsyncData } from "../../../../helpers/typescriptHelpers";
import type { IDestination } from "../../../../interfaces/destinations";
import type { IRunResult } from "../../../../interfaces/jobExecutions";
import type {
  IDataset,
  IDatasetType,
  ISource,
} from "../../../../interfaces/sources";
import type { Transformation } from "../../../../interfaces/transformations";
import type { WorkbenchUIStoreProps } from "../../../../store/workbenchUIStore";
import workbenchUIStore from "../../../../store/workbenchUIStore";
import type { InjectedOrgProps } from "../../../orgs/WithOrg";
import WithOrg from "../../../orgs/WithOrg";
import type { ISpreadsheetLoading } from "../../../spreadsheet/SpreadsheetCore";
import type {
  ColumnInformations,
  TableTabItem,
} from "../../../spreadsheet/domain";
import * as Toolbar from "../../../spreadsheet/toolbar/Toolbar";
import { DatasetViewer } from "../dataset-viewer/DatasetViewer";
import type {
  FetchedDestination,
  IActiveObjectDatasetUrlState,
  IActiveObjectFlowDatasetUIState,
  IActiveObjectSQLConfigUIState,
  IDatasetLineageGraph,
  IDatasetUpdate,
  ModifyQuery,
  TabData,
  TableData,
} from "../domain";
import type { DatasetSavedData } from "../selector/tables/models/AddModelModal";
import "./Dataset.scss";
import FlowConfiguration from "./tabs/flow/FlowConfiguration";
import GeneralInformation from "./tabs/general/GeneralInformation";
import LineageRenderer from "./tabs/lineage/LineageRenderer";
import SQLConfiguration from "./tabs/sql/SQLConfiguration";

interface IDatasetProps {
  tabData: TabData;
  routeRender: (params: object, query?: object) => string;
  onCreateRelationship?: (createPayload: any) => Promise<void>;
  onUpdateRelationship?: (id: string, updatePayload: any) => Promise<void>;
  onDeleteRelationship?: (id: string) => Promise<void>;
  onRenameView?: (viewId: string, name: string) => Promise<void>;
  onDeleteView?: (viewId: string) => Promise<any>;
  onCreateView?: (name: string) => Promise<void>;
  onUpdateDataset?: IDatasetUpdate;
  onDeleteDataset?: () => Promise<void>;
  datasets: IDataset[];
  mashupData: TableData;
  onRefresh?: (activeKey: string, activeView: string) => void;
  scollToColumnIndex?: number;
  onDrillUpdate?: (viewId: string, drills: string[]) => Promise<any>;
  tables: TableTabItem[];
  spreadsheetLoading: ISpreadsheetLoading;
  onModifyTransformation?: ModifyQuery;
  activeTableKey: string;
  activeViewKey: string;
  spreadsheetErrorMessage?: string;
  datasetQueryCursor: string;
  viewQueryCursor: AsyncData<string>;
  transformations: AsyncData<Transformation[]>;
  setStaleQuery?: (q: string) => void;
  staleQuery?: string;
  staleHead?: string;
  setStaleHead?: (h: string) => void;
  onClose?: () => void;
  isStale: boolean;
  sources: Array<ISource>;
  onShowFlowMigration?: () => void;
  showFullscreen?: boolean;
  onFullscreenChange?: (isFullscreen: boolean) => void;
  onCreateModel: (dataset: DatasetSavedData) => Promise<any>;
  runResults: IRunResult[];
  destination: FetchedDestination;
  datasetLineageGraph: IDatasetLineageGraph;
  onRefreshDatasets: (id?: string[]) => Promise<void>;
  onRefreshExplorations: (id?: string[]) => Promise<void>;
  onExploreClick?: (activeKey: string, activeView: string) => void;
  currentWarehouse: IDestination;
}

interface IState {
  selectedTab: DatasetTab | undefined;
  isFullscreen: boolean;
}

type Props = IDatasetProps & WorkbenchUIStoreProps & InjectedOrgProps;

type DatasetTab = "information" | "configuration" | "data" | "lineage";

const CAN_RENDER_CONFIGURATION: Array<IDatasetType> = ["SQL", "FLOW"];

class Dataset extends Component<Props, IState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedTab: undefined,
      isFullscreen: false,
    };
  }

  getCurrentTab = (): DatasetTab => {
    const {
      workbenchUIStore: { getActiveObject },
    } = this.props;
    const activeObject = getActiveObject();

    switch ((activeObject?.urlState as IActiveObjectDatasetUrlState)?.tab) {
      case "information":
        return "information";
      case "configuration":
        if (
          CAN_RENDER_CONFIGURATION.includes(this.props.tabData.type) &&
          this.props.tabData.dataset.managedBy === "WHALY"
        ) {
          return "configuration";
        }
        return "information";
      case "data":
        return "data";
      case "lineage":
        return "lineage";
      default:
        return this.props.tabData.type === "SQL" ? "configuration" : "data";
    }
  };

  setCurrentTab = (tab: DatasetTab): void => {
    const {
      workbenchUIStore: { getActiveObject, setActiveObjectUrlParams },
    } = this.props;
    const activeObject = getActiveObject();
    this.setState({ selectedTab: tab });
    activeObject &&
      setActiveObjectUrlParams(activeObject, {
        ...activeObject?.urlState,
        tab: tab,
      });
  };

  componentDidMount() {
    const tab = this.getCurrentTab();
    if (this.state.selectedTab !== tab) {
      this.setCurrentTab(tab);
    }
  }

  componentDidUpdate(prevProps: Props) {
    const tab = this.getCurrentTab();
    if (this.state.selectedTab !== tab) {
      this.setCurrentTab(tab);
    }

    const currentStore =
      this.props.tabData.data[this.props.activeViewKey]?.store;
    const prevPropsStore =
      prevProps.tabData.data[this.props.activeViewKey]?.store;

    if (
      currentStore?.raw?.status === "initial" &&
      prevPropsStore?.raw?.status === "success" &&
      this.props.onRefresh
    ) {
      this.props.onRefresh(this.props.activeTableKey, this.props.activeViewKey);
    }
  }

  handleTabClick = (tab: DatasetTab) => {
    const { getActiveObject, setActiveObjectUrlParams } =
      this.props.workbenchUIStore;
    const activeObject = getActiveObject();

    activeObject &&
      setActiveObjectUrlParams(activeObject, {
        ...activeObject.urlState,
        tab,
      });
  };

  public render() {
    const {
      tabData,
      datasets,
      onCreateRelationship,
      onDeleteRelationship,
      onUpdateRelationship,
      onDeleteDataset,
      onUpdateDataset,
      mashupData,
      onDrillUpdate,
      onRefresh,
      tables,
      spreadsheetLoading,
      onModifyTransformation,
      activeTableKey,
      activeViewKey,
      transformations,
      datasetQueryCursor,
      setStaleQuery,
      staleQuery,
      staleHead,
      setStaleHead,
      isStale,
      onClose,
      sources,
      workbenchUIStore: { getActiveObject, getActiveObjectUrlParams },
      destination,
      datasetLineageGraph,
      onRefreshDatasets,
      onRefreshExplorations,
      currentWarehouse,
    } = this.props;

    const activeObject = getActiveObject();
    const urlParam = activeObject
      ? getActiveObjectUrlParams(activeObject)
      : undefined;
    const selectedTab = (urlParam as IActiveObjectDatasetUrlState)?.tab
      ? (urlParam as IActiveObjectDatasetUrlState)?.tab
      : "data";

    const activeTable = tables.find((t) => t.key === activeTableKey);
    const activeView = activeTable?.views.find((v) => v.key === activeViewKey);

    const onMigrationDone = async (
      nextDatasetId: string,
      datasetIds: string[],
      explorationIds: string[]
    ) => {
      await Promise.all([
        onRefreshDatasets(datasetIds),
        onRefreshExplorations(explorationIds),
      ]);

      const activeObject = workbenchUIStore.getActiveObject();
      activeObject && workbenchUIStore.removeActiveObject(activeObject);
      workbenchUIStore.pushActiveObject({
        type: "dataset",
        value: nextDatasetId,
      });
    };

    const renderTabContent = () => {
      const { setActiveObjectUIState, getActiveObject } =
        this.props.workbenchUIStore;

      switch (selectedTab) {
        case "information":
          return (
            <GeneralInformation
              tabs={tables}
              currentTab={tabData}
              currentWarehouse={currentWarehouse}
              datasets={datasets}
              currentTransformations={transformations}
              onCreateRelationship={onCreateRelationship}
              onDeleteRelationship={onDeleteRelationship}
              onUpdateRelationship={onUpdateRelationship}
              onUpdateDataset={onUpdateDataset}
              onDeleteDataset={onDeleteDataset}
              sources={sources}
              activeViewKey={activeViewKey}
              destination={destination}
              runResults={tabData.runResults}
            />
          );
        case "data":
          const columnsInfos = tabData.runResults.reduce<ColumnInformations>(
            (acc, v) => {
              if (v.type === "test" && v.columnName) {
                return {
                  ...acc,
                  [v.columnName]: {
                    ...acc[v.columnName],
                    tests: [
                      ...(acc[v.columnName]?.tests || []),
                      { name: v.operationName, status: v.status },
                    ],
                  },
                };
              }
              return acc;
            },
            {}
          );

          return (
            <DatasetViewer
              loading={spreadsheetLoading}
              tableData={mashupData}
              currentWarehouse={currentWarehouse}
              datasets={datasets}
              datasetQueryCursor={datasetQueryCursor}
              columnInfos={columnsInfos}
              activeTable={activeTable}
              activeView={activeView}
              activeTableKey={activeTableKey}
              activeViewKey={activeViewKey}
              onRefresh={
                onRefresh && (() => onRefresh(activeTableKey, activeViewKey))
              }
              onDrillUpdate={
                onDrillUpdate &&
                ((drills: string[]) => onDrillUpdate(activeViewKey, drills))
              }
              onMigrationDone={onMigrationDone}
              onCreateModel={this.props.onCreateModel}
            />
          );
        case "configuration":
          if (tabData.type === "FLOW") {
            if (transformations.status === "initial") {
              onRefresh?.(tabData.dataset.id, activeViewKey);
              return <Loading />;
            } else if (transformations.status === "loading") {
              return <Loading />;
            }
            if (transformations.status === "error") {
              return <Error>{transformations.error?.message}</Error>;
            }
            return (
              <FlowConfiguration
                query={transformations.data}
                staleQuery={staleQuery}
                datasets={datasets}
                setStaleQuery={setStaleQuery}
                onUpdateDataset={onUpdateDataset}
                onModifyTransformation={onModifyTransformation}
                staleHead={staleHead}
                isStale={isStale}
                currentWarehouse={currentWarehouse}
                setStaleHead={setStaleHead}
                dataset={tabData.dataset}
                onRefreshDatasets={onRefreshDatasets}
                onRefreshExplorations={onRefreshExplorations}
                initialState={
                  activeObject?.uiState
                    ? (activeObject.uiState as IActiveObjectFlowDatasetUIState)
                    : undefined
                }
                saveUIState={(state: IActiveObjectFlowDatasetUIState) => {
                  const activeObject = getActiveObject();
                  if (!activeObject) return;
                  const fullstate: IActiveObjectFlowDatasetUIState =
                    activeObject.uiState
                      ? {
                          ...activeObject.uiState,
                          ...state,
                        }
                      : {
                          ...state,
                        };
                  setActiveObjectUIState(activeObject, fullstate);
                }}
              />
            );
          } else {
            return (
              <SQLConfiguration
                uniqueId={`dataset-${tabData.dataset.id}`}
                key={tabData.dataset.id} // remonts when dataset changes
                currentDataset={tabData.dataset}
                query={tabData.sql}
                currentWarehouse={currentWarehouse}
                onUpdateDataset={
                  tabData.dataset.managedBy !== "DBT_CLOUD"
                    ? onUpdateDataset
                    : undefined
                }
                datasets={datasets}
                activeTableKey={activeTableKey}
                activeViewKey={activeViewKey}
                saveUIState={(state: IActiveObjectSQLConfigUIState) => {
                  const activeObject = getActiveObject();
                  if (!activeObject) return;
                  const fullstate: IActiveObjectSQLConfigUIState =
                    activeObject.uiState
                      ? {
                          ...activeObject.uiState,
                          ...state,
                        }
                      : {
                          ...state,
                        };
                  setActiveObjectUIState(activeObject, fullstate);
                }}
                initialState={
                  activeObject.uiState as IActiveObjectSQLConfigUIState
                }
                setStaleQuery={setStaleQuery}
                staleQuery={staleQuery}
                onRefreshDatasets={onRefreshDatasets}
                onRefreshExplorations={onRefreshExplorations}
              />
            );
          }
        case "lineage":
          return (
            <LineageRenderer
              datasetLineageGraph={datasetLineageGraph}
              currentTab={tabData}
              tableTabItems={tables}
              destination={destination}
            />
          );
      }
    };

    return (
      <div className="dataset">
        <div className="dataset-toolbar">
          <div style={{ flex: 1 }}>
            <Toolbar.Toolbar style={{ borderTop: "none" }} onClose={onClose}>
              <Toolbar.Item
                active={selectedTab === "data"}
                disabled={false}
                color="pink"
                onClick={() => this.handleTabClick("data")}
              >
                Preview
              </Toolbar.Item>

              {CAN_RENDER_CONFIGURATION.includes(tabData.type) &&
                tabData.dataset.managedBy === "WHALY" && (
                  <Toolbar.Item
                    disabled={false}
                    active={selectedTab === "configuration"}
                    color="yellow"
                    onClick={() => this.handleTabClick("configuration")}
                  >
                    <Badge dot={isStale} offset={[5, -5]}>
                      Configuration
                    </Badge>
                  </Toolbar.Item>
                )}

              <Toolbar.Item
                active={selectedTab === "information"}
                disabled={false}
                color="green"
                onClick={() => this.handleTabClick("information")}
              >
                <Badge
                  dot={
                    tabData.runResults.filter((t) => t.status !== "success")
                      .length > 0
                  }
                  status="warning"
                  offset={[4, 0]}
                  size="small"
                >
                  Information
                </Badge>
              </Toolbar.Item>

              <Toolbar.Item
                active={selectedTab === "lineage"}
                disabled={false}
                color="violet"
                onClick={() => this.handleTabClick("lineage")}
              >
                Lineage
              </Toolbar.Item>

              <div style={{ flexGrow: 1 }} />

              {activeView && (
                <Flex align="center" gap="small">
                  <b>Last updated :</b>
                  <span>{moment(activeView.updatedAt).fromNow()}</span>
                  <UserAvatar
                    size="small"
                    user={activeView.updatedBy}
                    tooltip
                  />
                </Flex>
              )}

              {this.props.showFullscreen && (
                <Toolbar.Item
                  onClick={() =>
                    this.setState(
                      { isFullscreen: !this.state.isFullscreen },
                      () => {
                        this.props.onFullscreenChange?.(
                          this.state.isFullscreen
                        );
                      }
                    )
                  }
                >
                  {this.state.isFullscreen ? (
                    <FullscreenExitOutlined />
                  ) : (
                    <FullscreenOutlined />
                  )}
                </Toolbar.Item>
              )}

              <div style={{ width: 12 }} />
            </Toolbar.Toolbar>
          </div>
        </div>
        {renderTabContent()}
      </div>
    );
  }
}

export default compose<Props, IDatasetProps>(WithOrg)(
  inject("workbenchUIStore")(observer(Dataset))
);
