import type { ProgressProps } from "antd";
import { Flex, Progress, Skeleton, Tag, Typography } from "antd";
import numeral from "numeral";
import type { IOrg } from "../../../../interfaces/org";
import type { DataType } from "../../../../interfaces/transformations";
import type { ColumnInfoTest } from "../../../spreadsheet/domain";
import FieldRenderer from "../../../spreadsheet/renderer/FieldRenderer";
import { TestRenderer } from "../../../spreadsheet/tests/TestRenderer";
import { useDistinctValuesCount } from "./hooks/useDistinctValuesCount";
import { useNullsCount } from "./hooks/useNullsCount";
import { useTop10Values } from "./hooks/useTop10Values";

type ColumnDetailsProps = {
  org: IOrg;
  field: string;
  description?: string;
  domain: DataType;
  type: string;
  tests?: ColumnInfoTest[];
  viewId: string;
  recordCount?: number;
};
export const ColumnDetails = ({
  org,
  field,
  description,
  domain,
  type,
  tests,
  viewId,
  recordCount,
}: ColumnDetailsProps) => {
  return (
    <Flex vertical gap="middle">
      {description && (
        <Flex vertical>
          <b>Description</b>
          <Typography.Text>{description}</Typography.Text>
        </Flex>
      )}

      <Flex vertical>
        <b>Type</b>
        <Typography.Text>{type}</Typography.Text>
      </Flex>

      {tests && tests.length && (
        <Flex vertical>
          <b>Tests</b>
          <Flex wrap="wrap" gap="small">
            {tests.map((t, i) => {
              return <TestRenderer key={i} test={t} />;
            })}
          </Flex>
        </Flex>
      )}

      <Flex vertical>
        <b>% of nulls</b>
        <NullsCountDisplay
          org={org}
          viewId={viewId}
          field={field}
          recordCount={recordCount}
        />
      </Flex>

      <Flex vertical>
        <b>Number of distinct values</b>
        <DistinctValuesCountDisplay
          org={org}
          viewId={viewId}
          field={field}
          recordCount={recordCount}
        />
      </Flex>

      <Flex vertical>
        <b>Top 10 values</b>
        <Top10ValuesDisplay
          org={org}
          viewId={viewId}
          field={field}
          domain={domain}
        />
      </Flex>
    </Flex>
  );
};

type NullsCountDisplayProps = Pick<
  ColumnDetailsProps,
  "org" | "viewId" | "field" | "recordCount"
>;
const NullsCountDisplay = ({
  org,
  viewId,
  field,
  recordCount,
}: NullsCountDisplayProps) => {
  const { nullsCount, loading, error } = useNullsCount(org, viewId, field);

  if (loading) {
    return (
      <Skeleton.Input
        style={{ width: 150, borderRadius: 20, height: 20 }}
        active
        size="small"
      />
    );
  }
  if (error) {
    return <Typography.Text type="danger">{error.message}</Typography.Text>;
  }
  if (typeof nullsCount !== "number" || typeof recordCount !== "number") {
    return null;
  }

  const percent = (nullsCount / recordCount) * 100;

  let status: ProgressProps["status"] = "normal";
  if (percent < 20) status = "success";
  if (percent > 80) status = "exception";

  return (
    <Progress
      percent={percent}
      format={(p) => `${numeral(p).format("0[.]00")}%`}
      status={status}
    />
  );
};

type DistinctValuesCountDisplayProps = Pick<
  ColumnDetailsProps,
  "org" | "viewId" | "field" | "recordCount"
>;
const DistinctValuesCountDisplay = ({
  org,
  viewId,
  field,
  recordCount,
}: DistinctValuesCountDisplayProps) => {
  const { distinctValuesCount, loading, error } = useDistinctValuesCount(
    org,
    viewId,
    field
  );

  if (loading) {
    return (
      <Skeleton.Input
        style={{ width: 150, borderRadius: 20, height: 20 }}
        active
        size="small"
      />
    );
  }
  if (error) {
    return <Typography.Text type="danger">{error.message}</Typography.Text>;
  }
  if (
    typeof distinctValuesCount !== "number" ||
    typeof recordCount !== "number"
  ) {
    return null;
  }

  return (
    <Typography.Text>
      {numeral(distinctValuesCount).format("0,0[.]00")}{" "}
      {distinctValuesCount === recordCount && (
        <i>Each row has a unique value</i>
      )}
    </Typography.Text>
  );
};

type Top10ValuesDisplayProps = Pick<
  ColumnDetailsProps,
  "org" | "viewId" | "field" | "domain"
>;
const Top10ValuesDisplay = ({
  org,
  viewId,
  field,
  domain,
}: Top10ValuesDisplayProps) => {
  const { top10Values, loading, error } = useTop10Values(org, viewId, field);

  if (loading) {
    return (
      <Flex gap="small" wrap="wrap">
        {Array.from({ length: 10 }).map((_, i) => (
          <Skeleton.Input
            key={`${field}-top10skeleton-${i}`}
            size="small"
            style={{ width: 50, borderRadius: 20, height: 20 }}
            active
          />
        ))}
      </Flex>
    );
  }
  if (error) {
    return <Typography.Text type="danger">{error.message}</Typography.Text>;
  }
  if (!top10Values) {
    return null;
  }

  const dimensionKey = `View_${viewId}.${field}`;

  return (
    <Flex gap="small" wrap="wrap">
      {top10Values.map((value, i) => {
        return (
          <Tag key={`${field}-top10value-${i}`} style={{ margin: 0 }}>
            <FieldRenderer content={value[dimensionKey]} type={domain} />
          </Tag>
        );
      })}
    </Flex>
  );
};
