import { DiffEditor } from "@monaco-editor/react";
import { Button, Flex, Modal } from "antd";

export type DiffFieldType = "unknown" | "query" | "sql";
type DiffModalProps = {
  previousValue: string | undefined;
  newValue: string | undefined;
  fieldType: DiffFieldType;
  visible?: boolean;
  onCancel?: () => void;
  onOk?: () => void;
  additionalButtons?: React.ReactNode;
};

export function DiffModal({
  previousValue,
  newValue,
  fieldType,
  visible,
  onCancel,
  onOk,
  additionalButtons,
}: DiffModalProps) {
  const renderSQLDiff = () => (
    <div
      style={{
        height: "calc(var(--doc-height) - 300px)",
      }}
    >
      <DiffEditor
        language="sql"
        original={previousValue}
        modified={newValue}
        options={{
          automaticLayout: true,
          readOnly: true,
        }}
      />
    </div>
  );

  const renderQueryDiff = () => {
    let original = previousValue
      ? JSON.stringify(JSON.parse(previousValue), null, 2)
      : previousValue;
    let modified = newValue
      ? JSON.stringify(JSON.parse(newValue), null, 2)
      : newValue;
    return (
      <div
        style={{
          height: "calc(var(--doc-height) - 300px)",
        }}
      >
        <DiffEditor
          language="json"
          original={original}
          modified={modified}
          options={{
            automaticLayout: true,
            readOnly: true,
          }}
        />
      </div>
    );
  };

  const renderDefaultDiff = () => {
    if (previousValue && newValue) {
      return (
        <div>
          <div>
            <b>From:</b>
          </div>
          <div>{previousValue}</div>
          <br />
          <br />
          <div>
            <b>To:</b>
          </div>
          <div>{newValue}</div>
        </div>
      );
    }
    if (!previousValue && newValue) {
      return (
        <div>
          <div>Added:</div>
          <div>{newValue}</div>
        </div>
      );
    }
    if (previousValue && !newValue) {
      return (
        <div>
          <div>Deleted:</div>
          <div>{newValue}</div>
        </div>
      );
    }
  };

  return (
    <Modal
      open={visible}
      width={"90%"}
      destroyOnClose={true}
      onCancel={onCancel}
      onOk={onOk}
      closable={false}
      className="history-value-renderer"
      footer={
        <Flex gap="small" justify="end">
          {additionalButtons}
          <Button type="primary" onClick={onCancel}>
            Close
          </Button>
        </Flex>
      }
    >
      {fieldType === "sql" && renderSQLDiff()}
      {fieldType === "query" && renderQueryDiff()}
      {fieldType === "unknown" && renderDefaultDiff()}
    </Modal>
  );
}
