import type { IAction, IActionMeta } from "../../interfaces/actions";
import type {
  IBaseCatalogItemOption,
  ICatalogItemMeta,
} from "../../interfaces/catalog";
import type {
  IDestination,
  IDestinationMeta,
} from "../../interfaces/destinations";
import type { IObjectStorageMeta } from "../../interfaces/objectStorage";
import type {
  ISource,
  ISourceMeta,
  ISourceMetaExecutor,
} from "../../interfaces/sources";
import {
  TARGET_DATABASE_FORM_FIELD_NAME,
  TARGET_SCHEMA_FORM_FIELD_NAME,
} from "../../interfaces/sources";
import GraphQLService from "../../services/graphql/GraphQLService";
import type {
  ItemValueInput,
  RawInput,
} from "./connection/steps/step1/ItemConnectionStep1";
import type { ItemValueUpdateInput } from "./connection/steps/step2/ItemConnectionStep2";

export const generateItemValueFromInput = (
  itemMeta: ICatalogItemMeta,
  v: { [key: string]: any },
  orgId: string
): ItemValueInput[] => {
  // TODO: find a way to remove this cast
  const sourceInputs: ItemValueInput[] = (
    itemMeta.options as IBaseCatalogItemOption[]
  ).map((k) => {
    const val = v[k.key] ? v[k.key] : k.defaultValue ? k.defaultValue : "";
    return {
      value: val as string,
      option: {
        connect: {
          id: k.id,
        },
      },
      org: {
        connect: {
          id: orgId,
        },
      },
    };
  });
  return sourceInputs;
};

export const createSourceAndRedirect = (
  name: string,
  orgId: string,
  warehouseId: string,
  itemMeta: ISourceMeta,
  inputs: ItemValueInput[],
  history: any,
  redirect: (source: ISource, sourceMetaType: ISourceMetaExecutor) => string,
  rawInputs: RawInput
) =>
  GraphQLService(
    `
mutation createSource(
  $name: String!,
  $sourceMetaId: ID!,
  $orgId: ID!,
  $sourceValuesInput: [SourceValueCreateInput]!,
  $targetSchema: String,
  $targetDatabase: String,
  $warehouseId: ID!
) {
  createSource(data: {
    name: $name,
    status: authentication,
    targetSchema: $targetSchema,
    targetDatabase: $targetDatabase,
    sourceMeta: {
      connect: {
        id: $sourceMetaId
      }
    },
    warehouse: {
      connect: {
        id: $warehouseId
      }
    }
    values: {
      create: $sourceValuesInput
    },
    org: {
      connect: {
        id: $orgId
      }
    }
  }) {
    id
    slug
    name
    values {
      id
      value
    }
  }
}
`,
    {
      name: name,
      orgId: orgId,
      sourceMetaId: itemMeta.id,
      sourceValuesInput: inputs,
      targetSchema: rawInputs[TARGET_SCHEMA_FORM_FIELD_NAME],
      targetDatabase: rawInputs[TARGET_DATABASE_FORM_FIELD_NAME],
      warehouseId: warehouseId,
    }
  ).then(async (s) => {
    return generateRedirect(itemMeta, s.createSource, history, redirect);
  });

export const createDestinationAndRedirect = (
  name: string,
  orgId: string,
  itemMeta: IDestinationMeta,
  inputs: ItemValueInput[],
  redirect: (destination: IDestination) => Promise<any>
): Promise<void> => {
  return GraphQLService(
    `
  mutation createDestination(
    $name: String!,
    $itemMetaId: ID!,
    $orgId: ID!,
    $itemValuesInput: [DestinationValueCreateInput]!,
    $targetDatabase: String
  ) {
    createDestination(data: {
      name: $name,
      targetDatabase: $targetDatabase,
      destinationMeta: {
        connect: {
          id: $itemMetaId
        }
      },
      values: {
        create: $itemValuesInput
      },
      org: {
        connect: {
          id: $orgId
        }
      }
    }) {
      id
      slug
      name
      values {
        id
        value
      }
    }
  }
  `,
    {
      name: name,
      orgId: orgId,
      itemMetaId: itemMeta.id,
      itemValuesInput: inputs,
      targetDatabase: itemMeta.defaultTargetDatabase,
    },
    "createDestination"
  ).then(async (s) => {
    return redirect(s);
  });
};

export const createActionAndRedirect = (
  name: string,
  orgId: string,
  itemMeta: IActionMeta,
  inputs: ItemValueInput[],
  redirect: (action: IAction) => Promise<any>
) =>
  GraphQLService(
    `
  mutation createAction(
    $name: String!,
    $itemMetaId: String!,
    $orgId: ID!,
    $itemValuesInput: [ActionValueCreateInput]!,
  ) {
    createAction(data: {
      name: $name,
      actionMeta: $itemMetaId,
      values: {
        create: $itemValuesInput
      },
      org: {
        connect: {
          id: $orgId
        }
      }
    }) {
      id
      slug
      name
      values {
        id
        value
      }
    }
  }
  `,
    {
      name: name,
      orgId: orgId,
      itemMetaId: itemMeta.id,
      itemValuesInput: inputs,
    },
    "createAction"
  ).then(async (s) => {
    return redirect(s);
  });

export const createObjectStorageAndRedirect = (
  name: string,
  orgId: string,
  itemMeta: IObjectStorageMeta,
  inputs: ItemValueInput[],
  redirect: (action: IAction) => Promise<any>
) =>
  GraphQLService(
    `
    mutation createObjectStorage(
      $name: String!,
      $itemMetaId: ID!,
      $orgId: ID!,
      $itemValuesInput: [ObjectStorageValueCreateInput]!,
    ) {
      createObjectStorage(data: {
        name: $name,
        objectStorageMeta: {
          connect: {
            id: $itemMetaId
          }
        },
        values: {
          create: $itemValuesInput
        },
        org: {
          connect: {
            id: $orgId
          }
        }
      }) {
        id
        slug
        name
        values {
          id
          value
        }
      }
    }
    `,
    {
      name: name,
      orgId: orgId,
      itemMetaId: itemMeta.id,
      itemValuesInput: inputs,
    },
    "createObjectStorage"
  ).then(async (s) => {
    return redirect(s);
  });

const generateRedirect = async (
  itemMeta: ISourceMeta,
  item: ISource,
  history: any,
  redirect: (source: ISource, sourceMetaType: ISourceMetaExecutor) => string
) => {
  if (itemMeta.executor === "FIVETRAN") {
    // We use the Fivetran Connect Card when we don't have our own auth (e.g. we don't have any Oauth app ourselves).
    if (itemMeta.auth === false) {
      // We can't get the `fivetranConnectCardUrl` field directly in the source creation
      // response due to some timing issue between Core Services and Fivetran API
      // So we ask it afterwards
      const connectCardUrlResponse = await GraphQLService(
        `query GetConnectCardUrl($sourceId: ID!) {
          Source(where: { id: $sourceId }) {
            fivetranConnectCardUrl
          }
        }`,
        { sourceId: item.id }
      );

      const WHALY_CONSTANTS = window.WHALY_CONSTANTS || {};
      const FIVETRAN_CONNECT_CARD_LANDING_URL = `${
        (WHALY_CONSTANTS as any).FIVETRAN_CONNECT_CARD_LANDING_URL
      }`;

      // This state is needed so that our external Oauth gateway
      // can redirect on the proper frontend route by decrypting it
      const state = {
        sourceId: item.id,
        redirect: `${window.location.origin}${redirect(
          item,
          itemMeta.executor
        )}`,
      };

      const rawState = Buffer.from(JSON.stringify(state)).toString("base64");

      // This is the URL that we give to Fivetran system.
      // It point on our Oauth gateway
      const redirectUrl = `${FIVETRAN_CONNECT_CARD_LANDING_URL}?state=${rawState}`;

      // We redirect to the Fivetran Connect Card URL
      window.location.href = `${
        connectCardUrlResponse.Source.fivetranConnectCardUrl
      }&redirect_uri=${encodeURIComponent(redirectUrl)}`;
    } else {
      //TODO: Implement "Bring your own token"
      throw new Error(
        `SourceMeta with executor=FIVETRAN and auth=true (aka. "Bring your own token" Fivetran integration) is not supported yet.)`
      );
    }
  } else if (itemMeta.executor === "SINGER" || itemMeta.executor === "SHORE") {
    if (itemMeta.oAuthConfig && itemMeta.oAuthConfig.authorizationUrl) {
      const state = btoa(
        JSON.stringify({
          sourceId: item.id,
          uuid: "",
          redirect: `${window.location.origin}${redirect(
            item,
            itemMeta.executor
          )}`,
        })
      );
      window.location.href = `${itemMeta.oAuthConfig.authorizationUrl}&state=${state}`;
    } else {
      history.push(redirect(item, itemMeta.executor));
    }
  }
};

export const sourceStep2 = (
  itemId: string,
  itemValueUpdateInput: ItemValueUpdateInput[]
): Promise<{
  updateSourceValues: Array<{ id: string }>;
  updateSource: { id: string; slug: string };
}> =>
  GraphQLService(
    `
mutation updateSourceValues(
  $itemValueUpdateInput: [SourceValuesUpdateInput]
  $itemId: ID!
  $status: SourceStatusType
) {
  updateSourceValues(data: $itemValueUpdateInput) {id}
  updateSource(id: $itemId, data: {status: $status}) {id slug}
}
`,
    {
      itemValueUpdateInput: itemValueUpdateInput,
      itemId: itemId,
      status: "discovery",
    }
  );

export const destinationStep2 = async (
  itemId: string,
  itemValueUpdateInput: ItemValueUpdateInput[],
  dataLoading: boolean
): Promise<string> => {
  const destinationResult = await GraphQLService<{
    slug: string;
    destinationMeta: IDestinationMeta;
    values: { id: string; option: { id: string } }[];
  }>(
    `
    query GetDestinationMetaOfDestination($destinationId: ID!) {
      Destination(where: { id: $destinationId }) {
        slug
        destinationMeta {
          defaultTargetDatabase
          options {
            id
            key
          }
        }
        values {
          id
          option {
            id
          }
        }
      }
    }
`,
    {
      destinationId: itemId,
    },
    "Destination"
  );

  const destinationMeta = destinationResult.destinationMeta;
  const values = destinationResult.values;

  const getTargetDatabase = (): string => {
    if (
      destinationMeta.defaultTargetDatabase.startsWith(`{{`) &&
      destinationMeta.defaultTargetDatabase.endsWith(`}}`)
    ) {
      const macro = destinationMeta.defaultTargetDatabase
        .replace("{{", "")
        .replace("}}", "");

      const splitted = macro.split(".");

      if (splitted.length === 2 && splitted[0] === "option") {
        const optionKey = splitted[1];
        const option = destinationMeta.options.find(
          (option) => option.key === optionKey
        );
        const optionId = option.id;

        const matchingValue = values.find((value) => {
          return value.option.id === optionId;
        });
        const matchingValueId = matchingValue.id;
        const matchingInput = itemValueUpdateInput.find((input) => {
          return input.id === matchingValueId;
        });

        return matchingInput.data.value as string;
      }
    }
    return destinationMeta.defaultTargetDatabase;
  };

  const targetDatabase = getTargetDatabase();
  await GraphQLService(
    `
    mutation UpdateDestinationTargetDatabase($destinationId: ID!, $targetDatabase: String!) {
      updateDestination(
        id: $destinationId
        data: { targetDatabase: $targetDatabase, isBiEnabled: true, ${
          dataLoading ? `isDataLoadingEnabled: true` : ""
        } }
      ) {
        id
      }
    }
`,
    {
      destinationId: itemId,
      targetDatabase,
    }
  );

  await GraphQLService(
    `
mutation updateDestinationValues(
  $itemValueUpdateInput: [DestinationValuesUpdateInput]
) {
  updateDestinationValues(data: $itemValueUpdateInput) {id}
}
`,
    {
      itemValueUpdateInput: itemValueUpdateInput,
      itemId: itemId,
    }
  );
  return destinationResult.slug;
};

export const actionStep2 = (
  itemId: string,
  itemValueUpdateInput: ItemValueUpdateInput[]
): Promise<{ updateActionValues: Array<{ id: string }> }> =>
  GraphQLService(
    `
mutation updateActionValues(
  $itemValueUpdateInput: [ActionValuesUpdateInput]
) {
  updateActionValues(data: $itemValueUpdateInput) {id}
}
`,
    {
      itemValueUpdateInput: itemValueUpdateInput,
      itemId: itemId,
    }
  );

export const objectStorageStep2 = (
  itemId: string,
  itemValueUpdateInput: ItemValueUpdateInput[]
): Promise<{
  updateObjectStorageValues: Array<{ id: string }>;
}> =>
  GraphQLService(
    `
  mutation updateObjectStorageValues(
    $itemValueUpdateInput: [ObjectStorageValuesUpdateInput]
  ) {
    updateObjectStorageValues(data: $itemValueUpdateInput) {id}
  }
  `,
    {
      itemValueUpdateInput: itemValueUpdateInput,
      itemId: itemId,
    }
  );
