import { LoadingOutlined, UploadOutlined } from "@ant-design/icons";
import type { UploadProps } from "antd";
import { Upload } from "antd";
import * as React from "react";
import type { InjectedAntUtilsProps } from "../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../components/ant-utils/withAntUtils";
import { compose } from "../../../components/compose/WlyCompose";
import type { AsyncData } from "../../../helpers/typescriptHelpers";

interface ICustomFileUploaderProps {
  onUpload: (fileName: string, formData: FormData) => Promise<void>;
  disabled?: boolean;
}

const { Dragger } = Upload;

type Props = ICustomFileUploaderProps & InjectedAntUtilsProps;

function CustomFileUploader(props: Props) {
  const {
    antUtils: { message },
    onUpload,
    disabled,
  } = props;

  const [uploading, setUploading] = React.useState<
    AsyncData<{ filePath: string }>
  >({
    status: "initial",
  });

  React.useEffect(() => {
    if (uploading.status === "success") {
      setTimeout(() => {
        setUploading({ status: "initial" });
      }, 3000);
    }
  }, [uploading.status]);

  const uploadProps: UploadProps = {
    name: "file",
    disabled: uploading.status === "loading" || disabled,
    showUploadList: false,
    accept: "*/*",
    customRequest: (options) => {
      const formData = new FormData();
      formData.append("file", options.file);
      onUpload((options.file as File).name, formData)
        .then((r) => {
          options?.onSuccess?.(r);
        })
        .catch((err) => {
          options?.onError?.(err);
        });
    },
    onChange(info) {
      const { status } = info.file;
      if (status === "uploading") {
        setUploading({ status: "loading" });
      }
      if (status === "done" && info.file?.response) {
        setUploading({
          status: "success",
          data: { filePath: info.file?.response },
        });
      } else if (status === "error") {
        setUploading({ status: "initial" });
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    beforeUpload: async (file) => {
      setUploading({ status: "loading" });
    },
  };

  const renderInnerModal = () => {
    if (uploading.status === "success") {
      return (
        <div
          style={{
            height: 200,
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          Success
        </div>
      );
    }
    if (uploading.status === "loading") {
      return (
        <Dragger {...uploadProps}>
          <p className="ant-upload-drag-icon">
            <LoadingOutlined />
          </p>
          <p className="ant-upload-text">Uploading...</p>
        </Dragger>
      );
    }

    return (
      <Dragger {...uploadProps}>
        <p className="ant-upload-drag-icon">
          <UploadOutlined />
        </p>
        <p className="ant-upload-text">Drag your file here</p>
        {/* <p className="ant-upload-hint">
          Accepts XXXX files. In case of doubts you can download a template
          there.
        </p> */}
      </Dragger>
    );
  };

  return <>{renderInnerModal()}</>;
}

export default compose<Props, ICustomFileUploaderProps>(withAntUtils)(
  CustomFileUploader
);
