import { inject, observer } from "mobx-react";
import * as React from "react";
import GenericCatalog from "../../../components/catalog/list/GenericCatalog";
import { GenericCatalogLoader } from "../../../components/catalog/loader/GenericCatalogLoader";
import { compose } from "../../../components/compose/WlyCompose";
import Error from "../../../components/layout/feedback/error";
import { type IObjectStorageMeta } from "../../../interfaces/objectStorage";
import type { BreadcrumbStoreProps } from "../../../store/breadCrumbStore";
import GraphQl from "../../graphql/graphql";
import type { InjectedOrgProps } from "../../orgs/WithOrg";
import withOrg from "../../orgs/WithOrg";

interface IObjectStorageCatalogPageProps {
  onClose: () => void;
}

const GQL = `
query {
  allObjectStorageMetas {
    id
    slug
    publicInfo {
      label
      name
      logo
      tagline
      website
      description
    }
  }
}
`;

interface GQLQueryResult {
  allObjectStorageMetas: IObjectStorageMeta[];
}

type Props = IObjectStorageCatalogPageProps &
  InjectedOrgProps &
  BreadcrumbStoreProps;

class ObjectStorageCatalogPage extends React.Component<Props> {
  public componentDidMount() {
    const { breadcrumbStore } = this.props;
    breadcrumbStore.setBreadcrumb([{ label: "Object Storage Catalog" }]);
  }

  public render() {
    const { onClose } = this.props;

    const title = "Object Storage Catalog";

    return (
      <GraphQl<GQLQueryResult> query={GQL} variables={{}}>
        {(gql) => {
          if (gql.status === "initial" || gql.status === "loading") {
            return <GenericCatalogLoader title={title} onClose={onClose} />;
          }
          if (gql.status === "error") {
            return <Error />;
          }
          return (
            <GenericCatalog
              title={title}
              items={gql.data.allObjectStorageMetas}
              alreadyPurchasedItems={[]}
              destinationViewName={"objectStorageConnection"}
              overrideButtonName={"Setup"}
              onClose={onClose}
            />
          );
        }}
      </GraphQl>
    );
  }
}

export default compose<Props, IObjectStorageCatalogPageProps>(withOrg)(
  inject("breadcrumbStore")(observer(ObjectStorageCatalogPage))
);
