import { SearchOutlined } from "@ant-design/icons";
import type { InputRef } from "antd";
import { Flex, Input, Tag } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useDebounce } from "react-use";
import { Highlight } from "../../../../../components/typography/Highlight";
import type { SchemaResult } from "../../../../../interfaces/transformations";
import { DomainTag } from "../DomainTag";

import "./RowDetails.scss";

type RowDetailsProps = {
  rowData: Record<string, any>;
  schema: SchemaResult;
  primaryKeys?: string[];
};
export const RowDetails = ({
  rowData,
  schema,
  primaryKeys,
}: RowDetailsProps) => {
  const [searchInputRef, setSearchInputRef] = useState<InputRef | null>(null);
  const [search, setSearch] = useState("");
  const [debouncedSearch, setDebouncedSearch] = useState("");

  useEffect(() => {
    searchInputRef?.focus();
  }, [searchInputRef]);

  useDebounce(() => setDebouncedSearch(search), 300, [search]);

  const filteredRowData = useMemo(
    () =>
      Object.entries(rowData)
        .map(([field, value]) => {
          const valueToString = !!value?.toString ? value.toString() : value;
          const filterUsableValue =
            typeof valueToString === "string"
              ? valueToString.toLowerCase()
              : "";
          const filterUsableField = field.toLowerCase();
          const filterUsableSearch = debouncedSearch.toLowerCase();

          return {
            field,
            value,
            filterUsableField,
            filterUsableValue,
            filterUsableSearch,
          };
        })
        .filter(
          ({ filterUsableField, filterUsableValue, filterUsableSearch }) => {
            return (
              filterUsableField.includes(filterUsableSearch) ||
              filterUsableValue.includes(filterUsableSearch)
            );
          }
        )
        .map(({ field, value }) => {
          const valueToString = !!value?.toString ? value.toString() : value;
          return {
            key: field,
            field: <Highlight text={field ?? ""} highlight={debouncedSearch} />,
            value: (
              <Highlight
                text={valueToString ?? "null"}
                highlight={debouncedSearch}
                italic={!valueToString}
                type={valueToString ? undefined : "secondary"}
              />
            ),
          };
        }),
    [rowData, debouncedSearch]
  );

  return (
    <Flex vertical className="dataset-row-details">
      <div className="search-input-container">
        <Input
          ref={(ref) => ref && !searchInputRef && setSearchInputRef(ref)}
          placeholder="Search"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          suffix={<SearchOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
        />
      </div>

      <Flex vertical gap="small" className="rows">
        {filteredRowData.map(({ key, field, value }) => {
          const domain = schema[key]?.domain;
          return (
            <Flex vertical key={key}>
              <Flex gap="small" align="baseline">
                {domain && <DomainTag domain={domain} />}
                <b>{field}</b>
                {primaryKeys?.includes(key) && (
                  <Tag color="gold">PRIMARY KEY</Tag>
                )}
              </Flex>
              <span style={{ whiteSpace: "pre-wrap" }}>{value}</span>
            </Flex>
          );
        })}
      </Flex>
    </Flex>
  );
};
