import type {
  BinaryFilter,
  DateRange,
  Filter,
  Query,
  SqlQuery,
  TimeDimension,
  TimeDimensionGranularity,
  UnaryFilter,
} from "@cubejs-client/core";
import { ResultSet } from "@cubejs-client/core";
import { uniq } from "lodash";
import moment from "moment";
import type { ChartType } from "../../../../components/chart/domain";
import type { IComparisonPeriod } from "../../../../components/measures/comparison-selector/ComparisonSelector";
import type { MeasureItemSortValue } from "../../../../components/measures/measure-item/MeasureItem";
import type {
  IQueryTraceResult,
  LagoonCallOrigin,
  WlyResultSet,
} from "../../../../services/LagoonService";
import {
  lagoonServiceLoad,
  lagoonServiceSQL,
  traceQuery,
} from "../../../../services/LagoonService";
import { getComparisonDate } from "../../../../utils/cubejsUtils";
import { format } from "../../../../utils/periodUtils";
import type { IWlyDatePickerInputValue } from "../../../reports/view/filters/date-filter/WlyDatePicker";
import { convertWlyDatePickerValueToMoment } from "../../../reports/view/filters/date-filter/WlyDatePicker";
import type { SimpleDateRange } from "../../../reports/view/filters/domain";
import type {
  FilterOperator,
  IAnalysisType,
  ILagoonQueryExtra,
} from "../domain";
import {
  cleanSerializedResultSetFromDimensionCustomSorting,
  cleanSerializedResultSetFromMeasureOnlyUsedForSorting,
  CUSTOM_DIMENSION_ORDERING_SUFFIX,
} from "./chart/domain";

type QueryDataProperties = {
  orgId: string;
  objectId: string;
  objectType: "VIEW" | "EXPLORATION";
  analysisType: IAnalysisType;
  chartType: ChartType;
  measures: string[];
  dimensions: string[];
  filters: (UnaryFilter | BinaryFilter)[];
  filterOperator: FilterOperator;
  dateRange: IWlyDatePickerInputValue;
  orderBy: Array<[string, MeasureItemSortValue]>;
  limit: number;
  showOther?: boolean;
  showOtherDimensionLimit?: number;
  origin: LagoonCallOrigin;
  comparisonPeriod?: IComparisonPeriod;
  timeDimension?: string;
  selectedGranularity?: TimeDimensionGranularity;
  pivotConfig?: string[];
  extra?: ILagoonQueryExtra;
  metricFilters?: (UnaryFilter | BinaryFilter)[];
  metricFilterOperator?: FilterOperator;
  reportId?: string;
  timezone?: string;
  useLagoonNext?: boolean;
};

type QueryDataResults = {
  resultSet: WlyResultSet<any>;
  additionalQueryResultSet?: WlyResultSet<any>;
  getSQL: () => Promise<SqlQuery>;
  getTrace: () => Promise<IQueryTraceResult>;
};

enum AdditionalQueryType {
  sparkLine,
  tableGrandTotal,
  pivotedTableGrandTotal,
  retention,
  waterfallTimeserie,
  noop,
}

export const queryData = async ({
  orgId,
  objectId,
  objectType,
  analysisType,
  chartType,
  measures,
  dimensions,
  filters,
  filterOperator,
  dateRange,
  orderBy,
  limit,
  showOther,
  showOtherDimensionLimit,
  origin,
  comparisonPeriod,
  timeDimension,
  selectedGranularity,
  pivotConfig,
  extra,
  metricFilters,
  metricFilterOperator,
  reportId,
  timezone,
  useLagoonNext,
}: QueryDataProperties): Promise<QueryDataResults> => {
  const dates = dateRange
    ? convertWlyDatePickerValueToMoment(dateRange)
    : undefined;

  // when sorting a dimension, we sort on a special dimension whose name is (dimname + __sort)
  // this special dimension contains custom sorting logic that can be overriden by the end-user when configuring the dimension
  const sortMapping: { [key: string]: string } = {};

  const getSpecialDimensionSortingName = (dimName: string) => {
    const dimSortName = dimName + CUSTOM_DIMENSION_ORDERING_SUFFIX;
    sortMapping[dimName] = dimSortName;
    return dimSortName;
  };

  const formattedOrderedBy: Array<[string, MeasureItemSortValue]> = orderBy.map(
    ([key, direction]) => {
      if (dimensions.includes(key)) {
        return [getSpecialDimensionSortingName(key), direction];
      }
      return [key, direction];
    }
  );

  const getSecondaryQueryType = (): AdditionalQueryType => {
    if (analysisType === "METRIC" && timeDimension) {
      return AdditionalQueryType.sparkLine;
    } else if (chartType === "waterfall-timeserie") {
      return AdditionalQueryType.waterfallTimeserie;
    } else if (chartType === "retention") {
      return AdditionalQueryType.retention;
    } else if (chartType === "table" && !pivotConfig?.length) {
      return AdditionalQueryType.tableGrandTotal;
    } else if (chartType === "table" && pivotConfig?.length) {
      return AdditionalQueryType.pivotedTableGrandTotal;
    } else {
      return AdditionalQueryType.noop;
    }
  };

  const computeSparklineGranularity = () => {
    if (!dates) return "month";

    const [startDate, endDate] = dates;
    const duration = moment.duration(endDate.diff(startDate));

    if (duration.asDays() <= 30) return "day";
    else if (duration.asWeeks() <= 52) return "week";
    else if (duration.asMonths() <= 36) return "month";
    else return "year";
  };

  const getDateRange = ([startDate, endDate]: SimpleDateRange): DateRange => {
    return [startDate.format(format), endDate.format(format)];
  };

  const getCompareDateRange = (): DateRange[] | undefined => {
    if (dates && comparisonPeriod) {
      const currentDateRange = getDateRange(dates);
      const previousDateRange: DateRange = [
        getComparisonDate(dates, comparisonPeriod)[0].format(format),
        getComparisonDate(dates, comparisonPeriod)[1].format(format),
      ];

      return [currentDateRange, previousDateRange];
    }
  };

  const getTimeDimensions = (
    granularity: TimeDimensionGranularity | undefined
  ): TimeDimension[] | undefined => {
    if (timeDimension) {
      return [
        {
          dimension: timeDimension,
          granularity,
          ...(comparisonPeriod
            ? { compareDateRange: getCompareDateRange() }
            : { dateRange: dates ? getDateRange(dates) : undefined }),
        },
      ];
    }
  };

  const getQueryFilters = (
    filterOperator: FilterOperator,
    metricFilterOperator: FilterOperator | undefined,
    filters: (UnaryFilter | BinaryFilter)[],
    metricFilters: (UnaryFilter | BinaryFilter)[] | undefined,
    additionalFilters?: (UnaryFilter | BinaryFilter)[] | undefined
  ) =>
    [
      ...(additionalFilters ? [{ and: [{ and: additionalFilters }] }] : []),
      {
        and: [
          {
            [filterOperator]: filters,
          },
        ],
      },
      {
        and: [
          {
            [metricFilterOperator ?? "and"]: metricFilters ?? [],
          },
        ],
      },
    ] as Filter[];

  const queryFilters = getQueryFilters(
    filterOperator,
    metricFilterOperator,
    filters,
    metricFilters
  );

  const loadService = (query: Query | Query[]) =>
    lagoonServiceLoad(
      orgId,
      query,
      objectType,
      objectId,
      objectType === "EXPLORATION" ? objectId : undefined,
      origin,
      reportId,
      undefined,
      useLagoonNext,
      timezone
    );

  /**
   * This query is used to calculate an additional aggregation
   * For table viz, it will calculate a grouped aggregate to get a total
   * For metrics viz with timeseries, it will calculate a result breakdown by time to get a sparkline
   * @returns
   */
  const generateAdditionalResultPromise = (): Promise<
    WlyResultSet<any> | undefined
  > => {
    switch (getSecondaryQueryType()) {
      case AdditionalQueryType.waterfallTimeserie:
        return loadService({
          measures,
          dimensions,
          timeDimensions: selectedGranularity
            ? getTimeDimensions(selectedGranularity)
            : [],
          limit,
          filters: queryFilters,
          order: formattedOrderedBy.length ? formattedOrderedBy : undefined,
        });
      case AdditionalQueryType.retention:
        return loadService({
          measures: [measures[0]],
          dimensions,
          limit,
          filters: queryFilters,
          timeDimensions: getTimeDimensions(undefined),
          order: formattedOrderedBy.length ? formattedOrderedBy : undefined,
        });
      case AdditionalQueryType.sparkLine:
        return loadService({
          measures,
          dimensions: [],
          timeDimensions: getTimeDimensions(computeSparklineGranularity()),
          limit,
          filters: queryFilters,
          order: formattedOrderedBy.length ? formattedOrderedBy : undefined,
        });
      case AdditionalQueryType.tableGrandTotal:
        return loadService({
          measures,
          dimensions: [],
          timeDimensions: getTimeDimensions(undefined),
          limit,
          filters: queryFilters,
          order: formattedOrderedBy.length ? formattedOrderedBy : undefined,
        });
      case AdditionalQueryType.pivotedTableGrandTotal:
        return loadService({
          measures,
          dimensions: pivotConfig ?? [],
          timeDimensions: getTimeDimensions(undefined),
          limit,
          filters: queryFilters,
          order: formattedOrderedBy.length ? formattedOrderedBy : undefined,
        });
      default:
        return Promise.resolve(undefined);
    }
  };

  const additionalSortDimensions = dimensions.flatMap((d) => {
    const sortDim = sortMapping[d];
    if (sortDim) return [sortDim];
    return [];
  });

  let dimensionsToQuery = uniq([...dimensions, ...additionalSortDimensions]);

  // Some measures can be used only in 'orderBy' clause but not in the 'measures' array
  // Use case: If you want to sort a chart by a metric but you want the metric to be not visible in the chart
  // But we still need to pass them technically to cubeJS as "measures" to generate the proper SQL query
  // Otherwise they are dropped
  // In a post processing step, they will be "cleaned" from the resultSet before chart rendering
  const additionalSortMeasures = orderBy
    .filter(([key]) => !dimensions.includes(key)) // Keep only non dimension, e.g. metric
    .filter(([key]) => !measures.includes(key)) // Keep only the metrics that are not already in the query
    .map(([key]) => {
      return key;
    });

  let measuresToQuery = measures.concat(additionalSortMeasures);

  if (chartType === "waterfall-timeserie") {
    dimensionsToQuery = [];
    measuresToQuery = measures;
  }

  if (chartType === "retention") {
    dimensionsToQuery = [dimensions[0]];
    measuresToQuery = [measures[1]];
  }

  if (extra) {
    const dimensionsToAdd = Object.keys(extra).reduce(
      (acc, currentValue) => [
        ...acc,
        ...(extra[currentValue].dimensions?.length > 0
          ? extra[currentValue].dimensions
          : []),
      ],
      []
    );
    dimensionsToQuery = uniq([...dimensions, ...dimensionsToAdd]);

    const measuresToAdd = Object.keys(extra).reduce(
      (acc, currentValue) => [
        ...acc,
        ...(extra[currentValue].metrics?.length > 0
          ? extra[currentValue].metrics
          : []),
      ],
      []
    );
    measuresToQuery = uniq([...measures, ...measuresToAdd]);
  }

  const mainQuery: Query = {
    measures: measuresToQuery,
    dimensions: dimensionsToQuery,
    timeDimensions: getTimeDimensions(selectedGranularity),
    limit,
    filters: queryFilters,
    order: formattedOrderedBy.length ? formattedOrderedBy : undefined,
  };

  const clean = (q: WlyResultSet<any>): WlyResultSet<any> => {
    const withoutSortingDims =
      cleanSerializedResultSetFromDimensionCustomSorting(q.serialize());
    const withoutSrtOnlyMeasures =
      cleanSerializedResultSetFromMeasureOnlyUsedForSorting(
        withoutSortingDims,
        additionalSortMeasures
      );
    const r = ResultSet.deserialize(withoutSrtOnlyMeasures);
    (r as any).id = q.id;
    (r as any).duration = q.duration;
    return r as WlyResultSet<any>;
  };

  const generateResultPromise = (query: Query) => loadService(query);
  const generateGetSQLPromise = (query: Query) => {
    return lagoonServiceSQL(
      orgId,
      query,
      objectType,
      objectId,
      objectType === "EXPLORATION" ? objectId : undefined,
      origin,
      reportId,
      undefined,
      useLagoonNext
    );
  };

  if (showOther && showOtherDimensionLimit) {
    const firstResultSet = await generateResultPromise({
      measures: measuresToQuery,
      dimensions: dimensionsToQuery,
      timeDimensions: getTimeDimensions(undefined),
      limit: showOtherDimensionLimit,
      filters: queryFilters,
      order: formattedOrderedBy.length ? formattedOrderedBy : undefined,
    });
    const firstResultData: object[] = (clean(firstResultSet) as any)
      .loadResponses[0].data;
    const dimensionValuesToFilter = firstResultData.map<string>(
      (d) => d[dimensions[0]]
    );

    const secondResultSetPromise = generateResultPromise({
      measures: measuresToQuery,
      dimensions: dimensionsToQuery,
      timeDimensions: getTimeDimensions(selectedGranularity),
      limit,
      filters: getQueryFilters(
        filterOperator,
        metricFilterOperator,
        [
          {
            member: dimensions[0],
            operator: "equals",
            values: dimensionValuesToFilter,
          },
        ],
        metricFilters
      ),
      order: formattedOrderedBy.length ? formattedOrderedBy : undefined,
    });

    const thirdResultSetPromise = generateResultPromise({
      measures: measuresToQuery,
      dimensions: undefined,
      timeDimensions: getTimeDimensions(selectedGranularity),
      limit: undefined,
      filters: getQueryFilters(
        filterOperator,
        metricFilterOperator,
        filters,
        metricFilters,
        dimensionValuesToFilter.map((value) => ({
          member: dimensions[0],
          operator: "notEquals",
          values: [value],
        }))
      ),
      order: formattedOrderedBy.length ? formattedOrderedBy : undefined,
    });

    const [secondResultSet, thirdResultSet, additionalResultSet] =
      await Promise.all([
        secondResultSetPromise,
        thirdResultSetPromise,
        generateAdditionalResultPromise(),
      ]);

    const insertOtherLabel = (data: object) => ({
      ...data,
      [dimensions[0]]: "Other",
    });

    const mergeDataWithOrderedInsert = (
      first: object[],
      second: object[],
      dimensionLimit: number
    ) => {
      return first.flatMap((d, i) => {
        if (i % dimensionLimit === 0) {
          return [second[i / dimensionLimit], d];
        } else {
          return [d];
        }
      });
    };

    const mergeResultSets = (
      first: WlyResultSet<any>,
      second: WlyResultSet<any>,
      needOrderedInsert: boolean = false
    ) => {
      const firstLoadResponses = (clean(first) as any).loadResponses;
      const firstLoadResponse = (clean(first) as any).loadResponse;
      const secondLoadResponses = (clean(second) as any).loadResponses;
      const secondLoadResponse = (clean(second) as any).loadResponse;

      return clean(
        Object.assign(first, {
          loadResponses: firstLoadResponses.map((r, i) => ({
            ...r,
            data: needOrderedInsert
              ? mergeDataWithOrderedInsert(
                  r.data,
                  secondLoadResponses[i].data.map(insertOtherLabel),
                  showOtherDimensionLimit
                )
              : [
                  ...r.data,
                  ...secondLoadResponses[i].data.map(insertOtherLabel),
                ],
          })),
          loadResponse: {
            ...firstLoadResponse,
            results: firstLoadResponse.results.map((r, i) => ({
              ...r,
              data: needOrderedInsert
                ? mergeDataWithOrderedInsert(
                    r.data,
                    secondLoadResponse.results[i].data.map(insertOtherLabel),
                    showOtherDimensionLimit
                  )
                : [
                    ...r.data,
                    ...secondLoadResponse.results[i].data.map(insertOtherLabel),
                  ],
            })),
          },
        }) as WlyResultSet<any>
      );

      // return clean(
      //   Object.assign(resultSet, {
      //     loadResponses: [{ ...resultLoadResponses, data: newResultEntries }],
      //     loadResponse: {
      //       ...resultLoadResponse,
      //       results: [{ ...resultLoadResponses, data: newResultEntries }],
      //     },
      //   }) as WlyResultSet<any>
      // );
    };

    const finalResult = mergeResultSets(
      secondResultSet,
      thirdResultSet,
      chartType === "table" && !!selectedGranularity
    );

    return {
      resultSet: finalResult,
      additionalQueryResultSet: additionalResultSet,
      getSQL: () => generateGetSQLPromise(mainQuery),
      getTrace: () => traceQuery(finalResult.id, !!useLagoonNext),
    };
  }

  const [resultSetResponse, additionalQueryResponse] = await Promise.allSettled(
    [generateResultPromise(mainQuery), generateAdditionalResultPromise()]
  );

  // TODO: Return the values of SQL even when the resultSet is in error
  // This need to change the return type of this function
  if (resultSetResponse.status === "rejected") {
    throw new Error(resultSetResponse.reason);
  }

  if (additionalQueryResponse.status === "rejected") {
    throw new Error(additionalQueryResponse.reason);
  }

  return {
    resultSet: clean(resultSetResponse.value) as any,
    additionalQueryResultSet: additionalQueryResponse.value
      ? clean(additionalQueryResponse.value)
      : undefined,
    getSQL: () => generateGetSQLPromise(mainQuery),
    getTrace: () => traceQuery(resultSetResponse.value.id, !!useLagoonNext),
  };
};
