import { gql } from "@apollo/client";
import {
  useGQLQuery,
  type QueryOptions,
} from "../../../../../../components/hooks/query/useGQLQuery";
import type { IFileUpload } from "../../../../../../interfaces/fileUpload";
import { UPLOADER_COMPLETE_FRAGMENT } from "../../../../single/domain";

type QueryData = {
  FileUploadJob: IFileUpload;
};

type QueryVariables = {
  id: string;
};

const FileUploadJobDetailsQuery = gql`
  ${UPLOADER_COMPLETE_FRAGMENT}

  query GetFileUploadJobDetails($id: ID!) {
    FileUploadJob(where: { id: $id }) {
      ...FileUploadCompleteFragment
    }
  }
`;

export const useGetFileUploaderDetails = (
  options: QueryOptions<QueryData, QueryVariables>
) => {
  return useGQLQuery<QueryData, QueryVariables>(
    FileUploadJobDetailsQuery,
    options
  );
};
