import { GROUP_INFO_FRAGMENT } from "../../../fragments/group";
import type { IOrg, IPartnerPortal } from "../../../interfaces/org";
import type { IUser } from "../../../interfaces/user";

export const UPDATE_USER = `
  mutation updateUser(
    $userRoleId: ID!
    $userId: ID!
    $locale: String
    $userRoleUpdateInput: UserRoleUpdateInput
    $attributesToUpdate: [UserAttributesUpdateInput]!
    $attributesToCreate: [UserAttributesCreateInput]!
    $subscriptionsToDelete: [UserTopicSubscriptionsUpdateInput]!
  ) {
    updateUser(id: $userId, data: { locale: $locale}) {
      id
    }
    updateUserRole(id: $userRoleId, data: $userRoleUpdateInput) {
      id
    }
    updateUserAttributes(data: $attributesToUpdate) {
      id
    }
    createUserAttributes(data: $attributesToCreate) {
      id
    }
    updateUserTopicSubscriptions(data: $subscriptionsToDelete) {
      id
    }
  }
`;

const USER_TOPIC_SUBSCRIBTION_FRAGMENT = `
  fragment UserTopicSubscription on UserTopicSubscription {
    id
    topic {
      id
      name
      topicGroup {
        id
        name
      }
    }
    workflow {
      id
      name
    }
    isDeleted
    org {
      id
    }
    createdAt
  }
`;

const USER_WITH_GROUP_AND_NOTIFICATION_SUBSCRIPTION_INFO_FRAGMENT = `
  ${GROUP_INFO_FRAGMENT}
  ${USER_TOPIC_SUBSCRIBTION_FRAGMENT}

  fragment UserInfo on User {
    id
    firstName
    lastName
    avatarColor
    gravatar
    email
    isPublicGuestUser
    locale
    isAdmin
    type
    portal {
      id
      name
    }
    signUpToken
    topicSubscriptions(where: { isDeleted: false, org: { id: $orgId } }) {
      ...UserTopicSubscription
    }
    groups(where: { isDeleted: false, org: { id: $orgId } }) {
      id
      group {
        ...GroupInfo
      }
    }
    attributes(where: { deleted: false, org: { id: $orgId } }) {
      id
      value
      userAttributeMeta {
        id
      }
    }
    realm {
      realm {
        id
      }
    }
  }
`;

export const GET_USER_ROLE_USER = `
${USER_WITH_GROUP_AND_NOTIFICATION_SUBSCRIPTION_INFO_FRAGMENT}

query getOrgUsers(
    $orgId: ID!,
    $userRoleWhereInput: UserRoleWhereInput,
    $skip: Int,
    $first: Int
    ) {
  allUserRoles(where: $userRoleWhereInput, skip: $skip, first: $first, sortBy: [createdAt_DESC]) {
    id
    role
    user {
      ...UserInfo
    }
  }
}
`;

export const GET_USER_ROLE_USER_WITH_NOTIFICATION_INFO = `
  ${USER_WITH_GROUP_AND_NOTIFICATION_SUBSCRIPTION_INFO_FRAGMENT}

  query getOrgUsers(
    $orgId: ID!
    $userRoleWhereInput: UserRoleWhereInput
    $skip: Int
    $first: Int
  ) {
    allUserRoles(
      where: $userRoleWhereInput
      skip: $skip
      first: $first
      sortBy: [createdAt_DESC]
    ) {
      id
      role
      v2HomeConfig
      hasFileUploadPermission
      user {
        ...UserInfo
      }
    }
    allTopics(
      where: {
        isDeleted: false
        org: { id: $orgId }
        topicGroup: { isDeleted: false }
      }
    ) {
      id
      name
      topicGroup {
        id
        name
        workflowSelections(where: { isDeleted: false }) {
          id
          workflow {
            id
            name
          }
        }
      }
    }
    allNotificationWorkflows(where: { isDeleted: false, org: { id: $orgId } }) {
      id
      name
    }
  }
`;

export const generateUserWhereInput = (
  type: "STANDARD" | "PORTAL",
  org: IOrg,
  currentUser: IUser,
  portal?: IPartnerPortal
) => {
  let where: any = {
    isDeleted_not: true,
    org: {
      id: org.id,
    },
    user: {
      type: type ? type : "STANDARD",
      portal: portal
        ? {
            id: portal.id,
          }
        : undefined,
    },
  };

  if (!currentUser.isAdmin) {
    where.user = { ...where.user, isPublicGuestUser: false };
  }
  return where;
};
