import type { IJobExecution } from "./jobExecutions";
import type { IObjectStorage } from "./objectStorage";
import type { IUser } from "./user";

export enum IComputeType {
  "GOOGLE" = "GOOGLE",
}

export interface IFileUpload {
  id: string;
  slug: string;
  name: string;
  description: string;
  isEnabled: boolean;
  storage: IObjectStorage;
  lastRun?: IFileUploadRun;
  runs: IFileUploadRun[];
  template?: string;
  computeType: IComputeType;
  computeConfig: string;
  transformerDockerUrl: string;
  transformerDockerVars: string;
  uploaderDockerUrl: string;
  uploaderDockerVars: string;
  isDeleted: boolean;
  createdAt: string;
  updatedAt: string;
  createdBy: IUser;
  updatedBy: IUser;
}

export interface IFileUploadRun {
  id: string;
  identifier: string;
  filePath: string;
  syncStatus: FileUploadRunSyncStatus;
  transformJobExecution: IJobExecution;
  uploadJobExecution: IJobExecution;
  createdAt: string;
  updatedAt: string;
  createdBy: IUser;
  updatedBy: IUser;
}

export enum FileUploadRunSyncStatus {
  queued = "queued",
  idle = "idle",
  syncing = "syncing",
}
