import { LayoutOutlined } from "@ant-design/icons";
import { inject, observer } from "mobx-react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import { compose } from "../../../../components/compose/WlyCompose";
import { Emoji } from "../../../../components/form/emoji-picker/Emoji";
import WlyGlobalSearch from "../../../../components/global-search/WlyGlobalSearch";
import { WlyDynamicIcon } from "../../../../components/icons/dynamic-renderer/WlyDynamicIconRenderer";
import { IconHolder } from "../../../../components/icons/holder/IconHolder";
import { ReportIcon } from "../../../../components/report/ReportIcon";
import type {
  IExploration,
  IExplorationSection,
} from "../../../../interfaces/explorations";
import type { IReportFolder } from "../../../../interfaces/folder";
import type { IObject } from "../../../../interfaces/object";
import { IOrgFeatureType } from "../../../../interfaces/org";
import { IUserRoleType } from "../../../../interfaces/user";
import { routeDescriptor } from "../../../../routes/routes";
import type { WorkspaceUIStoreProps } from "../../../../store/workspaceUIStore";
import type { InjectedOrgProps } from "../../../orgs/WithOrg";
import WithOrg from "../../../orgs/WithOrg";
import { hasRoleAccessBoolean } from "../../../user-settings/HasRoleAccess";

interface IWorkspaceSearchModalProps {
  allExplorations: IExploration[];
  personalFolder?: IReportFolder;
  folders: IReportFolder[];
  allExplorationSections: IExplorationSection[];
  objects: IObject[];
}

type Props = IWorkspaceSearchModalProps &
  WorkspaceUIStoreProps &
  RouteComponentProps<{}> &
  InjectedOrgProps;

function WorkspaceSearchModal(props: Props) {
  const {
    workspaceUIStore,
    history,
    match,
    org,
    orgFeatures,
    user,
    personalFolder,
    folders,
    objects,
    allExplorations,
    allExplorationSections,
  } = props;

  return (
    <WlyGlobalSearch
      placeholder="Search the workspace..."
      context="WORKSPACE"
      open={workspaceUIStore.globalSearchOpen}
      setOpen={(open) => workspaceUIStore.setGlobalSearchOpen(open)}
      items={[
        ...(personalFolder && personalFolder[0]
          ? personalFolder[0].reports
          : []
        ).map((r) => {
          return {
            key: `report-${r.id}`,
            icon: <ReportIcon type={r.type} />,
            label: r.name,
            value: `report-${r.id}`,
            category: "Report",
            details: "Personnal folder",
            onSelect: () =>
              history.push(
                routeDescriptor.report.renderRoute({
                  ...match.params,
                  reportSlug: r.slug,
                })
              ),
          };
        }),
        ...folders.flatMap((f) => {
          return f.reports.map((r) => {
            return {
              key: `report-${r.id}`,
              icon: <ReportIcon type={r.type} />,
              label: r.name,
              value: `report-${r.id}`,
              category: "Report",
              details: f.name,
              onSelect: () =>
                history.push(
                  routeDescriptor.report.renderRoute({
                    ...match.params,
                    reportSlug: r.slug,
                  })
                ),
            };
          });
        }),
        ...(hasRoleAccessBoolean(IUserRoleType.EDITOR, user, org.id)
          ? allExplorations
          : []
        ).map((e) => {
          const section = allExplorationSections?.find(
            (s) => s.id === e.section?.id
          );
          return {
            key: `explo-${e.id}`,
            icon: section?.image ? (
              <Emoji size={18} emoji={section.image} />
            ) : (
              <Emoji size={18} emoji={":hammer_and_wrench:"} />
            ),
            label: e.name,
            value: `explo-${e.id}`,
            category: "Exploration",
            details: section ? section.name : "Work in progress",
            onSelect: () =>
              history.push(
                routeDescriptor["explore"].renderRoute({
                  ...match.params,
                  exploreSlug: e.slug,
                })
              ),
          };
        }),
        ...(orgFeatures.includes(IOrgFeatureType.OBJECTS) ? objects : []).map(
          (object) => {
            return {
              key: `object-${object.id}`,
              icon: (
                <IconHolder
                  icon={
                    object.icon ? (
                      <WlyDynamicIcon name={object.icon} />
                    ) : (
                      <LayoutOutlined color="#F9F8F8" />
                    )
                  }
                  size={20}
                  background={object.color ?? "#F1BD6C"}
                  color={"#F9F8F8"}
                />
              ),
              label: object.name,
              value: `object-${object.id}`,
              category: "List",
              onSelect: () =>
                history.push(
                  routeDescriptor["object"].renderRoute({
                    ...match.params,
                    objectSlug: object.slug,
                  })
                ),
            };
          }
        ),
      ]}
    />
  );
}

export default compose<Props, IWorkspaceSearchModalProps>(
  withRouter,
  WithOrg,
  inject("workspaceUIStore"),
  observer
)(WorkspaceSearchModal);
