import { InfoCircleFilled, PlusOutlined } from "@ant-design/icons";
import { Button, Dropdown, Space, Tooltip } from "antd";
import { inject, observer } from "mobx-react";
import dbt from "../../../../assets/dbt.webp";
import whaly from "../../../../assets/icon.svg";
import { compose } from "../../../../components/compose/WlyCompose";
import type { DeepPartial } from "../../../../helpers/typescriptHelpers";
import type { IDestination } from "../../../../interfaces/destinations";
import type {
  IExploration,
  IExplorationSection,
} from "../../../../interfaces/explorations";
import type { IFileUpload } from "../../../../interfaces/fileUpload";
import type { IJobExecution } from "../../../../interfaces/jobExecutions";
import { JobType } from "../../../../interfaces/jobExecutions";
import type { IModelFolder } from "../../../../interfaces/modelFolder";
import type { IObject } from "../../../../interfaces/object";
import type { IRadar } from "../../../../interfaces/radar";
import type { ISource } from "../../../../interfaces/sources";
import type { IWorksheet } from "../../../../interfaces/worksheet";
import type {
  ISelectorType,
  WorkbenchUIStoreProps,
} from "../../../../store/workbenchUIStore";
import type { TableTabItem } from "../../../spreadsheet/domain";
import type { IObjectLayoutFragment } from "../../single/domain";
import { DatasetPopover } from "../dataset/DatasetPopover";
import type { FetchedDestination, IActiveObject, ObjectStore } from "../domain";
import { WorkbenchCollapse } from "./collapse/WorkbenchCollapse";
import type {
  IOnDeleteConnector,
  IOnUpdateConnector,
} from "./connectors/ConnectorsTable";
import ConnectorsTable from "./connectors/ConnectorsTable";
import ExplorationTable from "./explorations/ExplorationTable";
import FileUploaderTable from "./file-uploader/FileUploaderTable";
import type { UpdateFileUploaderJobFunction } from "./file-uploader/domain";
import JobTable from "./jobs/JobTable";
import type { UpdateObjectLayoutFunction } from "./object-layouts/domain";
import ObjectTable from "./object/ObjectTable";
import type { UpdateObjectFunction } from "./object/domain";
import RadarTable from "./radar/RadarTable";
import type { UpdateRadarFunction } from "./radar/domain";
import { TableItem } from "./tables/TableItem";
import type { DatasetSavedData } from "./tables/models/AddModelModal";
import ModelTable from "./tables/models/ModelTable";
import SourceTable from "./tables/sources/SourceTable";
import WorksheetTable from "./worksheet/WorksheetTable";

interface ISelectorRendererProps {
  selected: ISelectorType;
  currentWarehouse: IDestination;
  // props used for workbench tables listing
  tables: Array<TableTabItem>;
  modelFolders: Array<IModelFolder>;
  sources: Array<ISource>;
  connectors: Array<ISource>;
  activeObject?: IActiveObject;
  onCreateDataset?: (dataset: DatasetSavedData) => Promise<any>;
  onDeleteDataset?: (id: string) => Promise<any>;
  onCreateSource?: () => void;
  onDeleteSource?: (sourceId: string) => Promise<void>;
  onUpdateSource?: (
    sourceId: string,
    data: DeepPartial<ISource>
  ) => Promise<void>;

  // props used for job listing
  destinationJobExecutions: IJobExecution[];

  onRefreshJobExecutions: () => Promise<void>;
  cancelJobExecutionRun: (exec: IJobExecution) => Promise<void>;
  destination: FetchedDestination;

  // props used for execution listing;
  explorations: IExploration[];
  explorationSections: IExplorationSection[];
  onExplorationUpdate: (ids: string[]) => Promise<void>;
  onSectionUpdate: (ids?: string[]) => Promise<void>;

  // props used for worksheets
  worksheets: IWorksheet[];
  onCreateWorksheet: () => Promise<string>;
  onUpdateWorksheet: (id: string, data: Partial<IWorksheet>) => Promise<void>;

  // props used for connectors
  onDeleteConnector: IOnDeleteConnector;
  onUpdateConnector: IOnUpdateConnector;

  // props used for objects
  objects: IObject[];
  onUpdateObject: UpdateObjectFunction;
  objectStore: ObjectStore;
  createObjectLayout: (object: IObjectLayoutFragment) => string;
  onUpdateObjectLayout: UpdateObjectLayoutFunction;

  // props used for radars
  radars: Array<IRadar>;
  onUpdateRadar: UpdateRadarFunction;

  // props used for fileUploadJobs
  fileUploadJobs: IFileUpload[];
  onUpdateFileUploader: UpdateFileUploaderJobFunction;

  // common
  onActiveObjectChange: (activeObject: IActiveObject) => void;
  onDeleteModelFolder: (id: string) => Promise<any>;
  onRefreshModelFolderTree: () => Promise<any>;
}

type Props = ISelectorRendererProps & WorkbenchUIStoreProps;

function SelectorRenderer(props: Props) {
  const {
    tables,
    sources,
    connectors,
    onCreateDataset,
    onCreateSource,
    onDeleteSource,
    onUpdateSource,
    onDeleteDataset,
    activeObject,
    onActiveObjectChange,
    destinationJobExecutions,
    onRefreshJobExecutions,
    cancelJobExecutionRun,
    destination,
    explorationSections,
    explorations,
    onExplorationUpdate,
    onSectionUpdate,
    modelFolders,
    onDeleteModelFolder,
    onRefreshModelFolderTree,
    worksheets,
    onCreateWorksheet,
    onUpdateWorksheet,
    onDeleteConnector,
    onUpdateConnector,
    objects,
    onUpdateObject,
    workbenchUIStore,
    currentWarehouse,
    objectStore,
    onUpdateObjectLayout,
    createObjectLayout,
    radars,
    onUpdateRadar,
    fileUploadJobs,
    onUpdateFileUploader,
  } = props;

  const renderLine = (
    key: string,
    nameComponent: React.ReactNode,
    menuComponent?: React.ReactNode,
    rawId?: string,
    draggable?: boolean,
    tabData?: TableTabItem
  ) => {
    const { destination } = props;

    return (
      <TableItem
        id={key}
        nameComponent={nameComponent}
        menuComponent={menuComponent}
        draggable={draggable}
        generateDragItem={() => ({
          id: rawId,
        })}
        wrap={(children, isDragging) => (
          <DatasetPopover
            tabData={tabData}
            destination={destination}
            placement="right"
            isDragging={isDragging}
          >
            {children}
          </DatasetPopover>
        )}
      />
    );
  };

  const renderOrigin = (
    managedBy: "WHALY" | "DBT_CLOUD" | "WAREHOUSE",
    overrideSourceLogo?: string
  ) => {
    if (overrideSourceLogo) {
      return (
        <img
          className="workbench-item-name-inner-text-managed-by"
          alt={"managed by"}
          src={overrideSourceLogo}
        />
      );
    }
    if (destination.isDbtCloudSyncEnabled === true) {
      switch (managedBy) {
        case "DBT_CLOUD":
          return (
            <img
              className="workbench-item-name-inner-text-managed-by"
              alt={"managed by"}
              src={dbt}
            />
          );
        case "WHALY":
          return (
            <img
              className="workbench-item-name-inner-text-managed-by"
              alt={"managed by"}
              src={whaly}
            />
          );
      }
    }
  };

  const panels = [
    {
      key: 1,
      hidden: props.selected !== "exploration",
      label: (
        <Space>
          Explorations
          <Tooltip
            title="Explorations act as your semantic layer. They are preconfigured dimensions and metrics on different tables with joins between them."
            placement="right"
          >
            <InfoCircleFilled style={{ color: "#B3B5B6" }} />
          </Tooltip>
        </Space>
      ),
      children: (
        <ExplorationTable
          activeObject={activeObject}
          onActiveObjectChange={onActiveObjectChange}
          explorations={explorations}
          explorationSections={explorationSections}
          onExplorationUpdate={onExplorationUpdate}
          onSectionUpdate={onSectionUpdate}
        />
      ),
      extra: (
        <Dropdown
          trigger={["click"]}
          menu={{
            items: [
              {
                key: 1,
                onClick: () => {
                  workbenchUIStore.setExplorationSectionCreationOpen(true);
                },
                label: "New section",
              },
              {
                key: 2,
                onClick: () => workbenchUIStore.setExplorationEditionOpen(true),
                label: "New exploration",
              },
            ],
          }}
          placement={"bottom"}
          arrow
        >
          <Button
            type="text"
            shape="circle"
            size="small"
            icon={<PlusOutlined />}
          />
        </Dropdown>
      ),
    },
    {
      key: 2,
      hidden: props.selected !== "data",
      label: (
        <Space>
          Models
          <Tooltip
            title="A Model is a dataset that has been generated by a user. It can either be a SQL Model or a Flow Model depending on your capabilities to write SQL queries. Models can reference each other."
            placement="right"
          >
            <InfoCircleFilled style={{ color: "#B3B5B6" }} />
          </Tooltip>
        </Space>
      ),
      children: (
        <ModelTable
          onRefreshModelFolderTree={onRefreshModelFolderTree}
          onDeleteModelFolder={onDeleteModelFolder}
          onCreateDataset={onCreateDataset}
          modelFolders={modelFolders}
          currentWarehouse={currentWarehouse}
          tables={tables.filter(
            (t) => !!t.isModel && t.hideFromInterface === false
          )}
          activeKey={
            activeObject && activeObject.type === "dataset"
              ? activeObject.value
              : undefined
          }
          renderLine={renderLine}
          renderOrigin={renderOrigin}
          onActiveModelChange={(prev, next) => {
            onActiveObjectChange({
              value: next,
              type: "dataset",
            });
          }}
          onDeleteModel={onDeleteDataset}
        />
      ),
      extra: (
        <Dropdown
          trigger={["click"]}
          menu={{
            items: [
              {
                key: 1,
                onClick: () => {
                  workbenchUIStore.setModelFolderEditionOpen(true);
                },
                label: "New folder",
              },
              {
                key: 2,
                onClick: () => {
                  workbenchUIStore.setDatasetEditionOpen(true);
                },
                label: "New model",
              },
            ],
          }}
          placement={"bottom"}
          arrow
        >
          <Button
            type="text"
            shape="circle"
            size="small"
            icon={<PlusOutlined />}
          />
        </Dropdown>
      ),
    },
    {
      key: 3,
      hidden: props.selected !== "data",
      label: (
        <Space>
          Sources
          <Tooltip
            title="A Source is any tables that has been made available from your warehouse to query in Whaly. The data is hosted in your warehouse, we only copy the table location."
            placement="right"
          >
            <InfoCircleFilled style={{ color: "#B3B5B6" }} />
          </Tooltip>
        </Space>
      ),
      children: (
        <SourceTable
          tables={tables.filter((t) => !t.isModel)}
          sources={sources}
          activeKey={
            activeObject && activeObject.type === "dataset"
              ? activeObject.value
              : undefined
          }
          onCreateDataset={onCreateDataset}
          onDeleteSource={onDeleteSource}
          onUpdateSource={onUpdateSource}
          renderLine={renderLine}
          renderOrigin={renderOrigin}
          currentWarehouse={currentWarehouse}
          onActiveTableChange={(prev, next) => {
            onActiveObjectChange({
              value: next,
              type: "dataset",
            });
          }}
          onDeleteDataset={onDeleteDataset}
        />
      ),
      extra: (
        <Button
          type="text"
          shape="circle"
          size="small"
          onClick={onCreateSource}
          icon={<PlusOutlined />}
        />
      ),
    },
    {
      key: 4,
      hidden: props.selected !== "worksheet",
      label: (
        <Space>
          Worksheets
          <Tooltip
            title="Worksheet allow you to iterate quickly on models before saving them or to keep valuable queries that you need to reuse often."
            placement="right"
          >
            <InfoCircleFilled style={{ color: "#B3B5B6" }} />
          </Tooltip>
        </Space>
      ),
      children: (
        <WorksheetTable
          worksheets={worksheets}
          activeObject={activeObject}
          onActiveObjectChange={onActiveObjectChange}
          onUpdateWorksheet={onUpdateWorksheet}
          currentWarehouse={currentWarehouse}
        />
      ),
      extra: (
        <Button
          onClick={async () => {
            const id = await onCreateWorksheet();
            const ao = {
              type: "worksheet",
              value: id,
            } as IActiveObject;
            workbenchUIStore.pushActiveObject(ao);
            workbenchUIStore.setActiveObjectFocused(ao);
          }}
          type="text"
          shape="circle"
          size="small"
          icon={<PlusOutlined />}
        />
      ),
    },
    {
      key: 5,
      hidden: props.selected !== "connector",
      label: (
        <Space>
          Connectors
          <Tooltip title="" placement="right">
            <InfoCircleFilled style={{ color: "#B3B5B6" }} />
          </Tooltip>
        </Space>
      ),
      children: (
        <ConnectorsTable
          connectors={connectors}
          activeObject={activeObject}
          onActiveObjectChange={onActiveObjectChange}
          onDeleteConnector={onDeleteConnector}
          currentWarehouse={currentWarehouse}
          onUpdateConnector={onUpdateConnector}
        />
      ),
      extra: (
        <Button
          onClick={() => {
            workbenchUIStore.setConnectorCatalogOpen(true);
          }}
          type="text"
          shape="circle"
          size="small"
          icon={<PlusOutlined />}
        />
      ),
    },
    {
      key: 6,
      hidden: props.selected !== "dbtCloudSync",
      label: "dbt Cloud syncs",
      children: (
        <JobTable
          jobExecutions={destinationJobExecutions}
          activeObject={activeObject}
          jobType={JobType.DBT_CLOUD_SYNC}
          onActiveObjectChange={onActiveObjectChange}
          onRefreshJobExecutions={onRefreshJobExecutions}
          cancelJobExecutionRun={cancelJobExecutionRun}
          destinationDbtCloudSyncStatus={destination.dbtCloudSyncStatus}
          destinationPersistenceEngineSyncStatus={
            destination.persistenceEngineSyncStatus
          }
          currentWarehouse={currentWarehouse}
        />
      ),
      extra: (
        <Button
          onClick={() => {
            workbenchUIStore.setDbtCloudSyncExecutionEditionOpen(true);
          }}
          type="text"
          shape="circle"
          size="small"
          icon={<PlusOutlined />}
        />
      ),
    },
    {
      key: 7,
      hidden: props.selected !== "persistenceEngine",
      label: "Persistence Runs",
      children: (
        <JobTable
          jobExecutions={destinationJobExecutions}
          activeObject={activeObject}
          jobType={JobType.PERSISTENCE_ENGINE_RUN}
          onActiveObjectChange={onActiveObjectChange}
          onRefreshJobExecutions={onRefreshJobExecutions}
          cancelJobExecutionRun={cancelJobExecutionRun}
          destinationDbtCloudSyncStatus={destination.dbtCloudSyncStatus}
          destinationPersistenceEngineSyncStatus={
            destination.persistenceEngineSyncStatus
          }
          currentWarehouse={currentWarehouse}
        />
      ),
      extra: (
        <Button
          onClick={() => {
            workbenchUIStore.setPersistenceEngineExecutionEditionOpen(true);
          }}
          type="text"
          shape="circle"
          size="small"
          icon={<PlusOutlined />}
        />
      ),
    },
    {
      key: 8,
      hidden: props.selected !== "object",
      label: (
        <Space>
          Objects
          <Tooltip
            title="Object help your user search, segment and drill through individual records."
            placement="right"
          >
            <InfoCircleFilled style={{ color: "#B3B5B6" }} />
          </Tooltip>
        </Space>
      ),
      children: (
        <ObjectTable
          objects={objects}
          activeObject={activeObject}
          onActiveObjectChange={onActiveObjectChange}
          onUpdateObject={onUpdateObject}
        />
      ),
      extra: (
        <Button
          onClick={() => workbenchUIStore.setObjectAdditionOpen(true)}
          type="text"
          shape="circle"
          size="small"
          icon={<PlusOutlined />}
        />
      ),
    },
    {
      key: 10,
      hidden: props.selected !== "radar",
      label: (
        <Space>
          Radars
          <Tooltip
            title="A radar periodically extracts signals from a model to create notifications on an object"
            placement="right"
          >
            <InfoCircleFilled style={{ color: "#B3B5B6" }} />
          </Tooltip>
        </Space>
      ),
      children: (
        <RadarTable
          radars={radars}
          activeObject={activeObject}
          onActiveObjectChange={onActiveObjectChange}
          onUpdateRadar={onUpdateRadar}
        />
      ),
      extra: (
        <Button
          onClick={() => {
            workbenchUIStore.setRadarAdditionOpen(true);
          }}
          type="text"
          shape="circle"
          size="small"
          icon={<PlusOutlined />}
        />
      ),
    },
    {
      key: 11,
      hidden: props.selected !== "uploader",
      label: (
        <Space>
          File Upload
          <Tooltip
            title="Expose a UI element to end users to allow them to upload file that will trigger a job."
            placement="right"
          >
            <InfoCircleFilled style={{ color: "#B3B5B6" }} />
          </Tooltip>
        </Space>
      ),
      children: (
        <FileUploaderTable
          fileUploaders={fileUploadJobs ?? []}
          activeObject={activeObject}
          onActiveObjectChange={onActiveObjectChange}
          currentWarehouse={currentWarehouse}
          onUpdateFileUploader={onUpdateFileUploader}
        />
      ),
      extra: (
        <Button
          onClick={() => {
            workbenchUIStore.setFileUploadAdditionOpen(true);
          }}
          type="text"
          shape="circle"
          size="small"
          icon={<PlusOutlined />}
        />
      ),
    },
  ];

  if (!props.selected) {
    // not returning anything when menu is collapsed
    // prevents react-resizable-panels to throw an error
    return <></>;
  } else {
    // key prevents react-resizable-panels to throw an error
    // when resizing the container vertically or horizontally
    return <WorkbenchCollapse key={props.selected} items={panels} />;
  }
}

export default compose<Props, ISelectorRendererProps>()(
  inject("workbenchUIStore")(observer(SelectorRenderer))
);
