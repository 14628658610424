import { SearchOutlined } from "@ant-design/icons";
import type { IHeaderParams } from "ag-grid-community";
import { Button, Flex } from "antd";
import type {
  DataType,
  SchemaItem,
} from "../../../../../interfaces/transformations";
import { DomainTag } from "../DomainTag";

import "./DatasetViewerHeaderCell.scss";

interface DatasetViewerHeaderCellProps extends IHeaderParams {
  isPrimaryKey: boolean;
  domain: DataType;
  type: SchemaItem["type"];
  onClick?: () => void;
}
export const DatasetViewerHeaderCell = ({
  isPrimaryKey,
  domain,
  type,
  onClick,
  displayName,
}: DatasetViewerHeaderCellProps) => {
  return (
    <div
      className="ag-cell-label-container dataset-viewer-header-cell"
      role="presentation"
    >
      {onClick && (
        <Button
          className="ag-header-icon ag-header-cell-menu-button"
          type="text"
          size="small"
          icon={<SearchOutlined style={{ fontSize: 12 }} />}
          onClick={() => onClick()}
        />
      )}

      <div
        data-ref="eLabel"
        className="ag-header-cell-label"
        role="presentation"
      >
        <Flex vertical gap="small" style={{ overflow: "hidden" }}>
          <Flex gap="small">
            <DomainTag domain={domain} />

            <span
              data-ref="eText"
              className="ag-header-cell-text"
              role="columnheader"
            >
              {displayName}
            </span>
          </Flex>

          <span className="column-type">{type}</span>
        </Flex>
      </div>

      {isPrimaryKey && <span className="primary-key-indicator" />}
    </div>
  );
};
