import { useLagoonQuery } from "../../../../../components/hooks/query/useLagoonQuery";
import type { IOrg } from "../../../../../interfaces/org";

export const useTop10Values = (org: IOrg, objectId: string, field: string) => {
  const measureKey = `View_${objectId}._wly_count`;
  const dimensionKey = `View_${objectId}.${field}`;
  const { data, loading, error } = useLagoonQuery(
    {
      measures: [measureKey],
      dimensions: [dimensionKey],
      order: [[measureKey, "desc"]],
      limit: 10,
      filters: [{ and: [{ member: dimensionKey, operator: "set" }] }],
    },
    { org, objectType: "VIEW", objectId }
  );

  return {
    top10Values: data,
    loading,
    error,
  };
};
