import { inject, observer } from "mobx-react";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import type { InjectedAntUtilsProps } from "../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../components/ant-utils/withAntUtils";
import { compose } from "../../../components/compose/WlyCompose";
import type { IObjectStorageMeta } from "../../../interfaces/objectStorage";
import { routeDescriptor } from "../../../routes/routes";
import { testObjectStorage } from "../../../services/FileService";
import type { UserStoreProps } from "../../../store/userStore";
import type { InjectedOrgProps } from "../../orgs/WithOrg";
import WithOrg from "../../orgs/WithOrg";
import ItemConnectionPage from "../connection/ItemConnectionPage";
import type { ItemValueInput } from "../connection/steps/step1/ItemConnectionStep1";
import { createObjectStorageAndRedirect, objectStorageStep2 } from "../domain";

interface IObjectStorageConnectionPageProps {}

type Props = IObjectStorageConnectionPageProps &
  RouteComponentProps<{ organizationSlug: string; itemSlug: string }> &
  UserStoreProps &
  InjectedOrgProps &
  InjectedAntUtilsProps;

class ObjectStorageConnectionPage extends React.Component<Props> {
  public render() {
    const {
      history,
      match: { params },
      antUtils,
    } = this.props;

    const redirectUri = routeDescriptor.settingsObjectStorage.renderRoute({
      ...params,
    });

    return (
      <ItemConnectionPage
        allItemsMetaQuery={`
        query getObjectStorageMeta($slug: String!) {
          allItemMetas: allObjectStorageMetas(where: { slug: $slug }) {
            id
            slug
            publicInfo {
              name
              label
              tagline
              website
              logo
              description
            }
            options {
              id
              slug
              key
              type
              options
              description
              label
              editable
              defaultValue
              encrypt
              hidden
            }
          }
        }
        `}
        remainingItemValuesQuery={`
        query getRemainingObjectStorageValues(
          $itemId: ID!
        ) {
            allItemValue: allObjectStorageValues(where:{objectStorage: {id: $itemId}, option: { hidden_not: true}}) {
              id
              value
              option {
                id
                slug
                key
                type
                options
                label
                editable
                defaultValue
                encrypt
                order
                hidden
                required
                description
              }
            }
        }
        `}
        step1CreationPromise={async (
          name: string,
          orgId: string,
          itemMeta: IObjectStorageMeta,
          inputs: ItemValueInput[]
        ) => {
          try {
            await createObjectStorageAndRedirect(
              name,
              orgId,
              itemMeta,
              inputs,
              (s) => {
                const {
                  match: { params },
                  history,
                } = this.props;
                history.push(
                  routeDescriptor.objectStorageConnectionStep2.renderRoute({
                    ...params,
                    itemId: s.id,
                  })
                );
                return Promise.resolve();
              }
            );
          } catch (err) {
            console.error(err);
          }
        }}
        inputValidationPromise={(v) => {
          return Promise.resolve();
        }}
        step2CreationPromise={async (
          itemId,
          itemValueUpdateInput,
          rawValues,
          catalogValues
        ) => {
          try {
            // todo add object storage validation
            const values = itemValueUpdateInput.reduce((acc, v) => {
              const opt = catalogValues.find((c) => c.id === v.id);
              if (opt) {
                return {
                  ...acc,
                  [opt.option.key]: v.data.value,
                };
              }
              return acc;
            }, {});
            const data = await testObjectStorage(itemId, values);

            if (data.status === "error") {
              throw new Error(data.error);
            }

            await objectStorageStep2(itemId, itemValueUpdateInput);
            antUtils.message.success(
              `Object Storage has been successfully setup!`,
              4
            );
            history.push(redirectUri);
          } catch (err) {
            console.error(err, this.props.match.params);
            antUtils.message.error(err.message);
          }
        }}
        redirectUri={redirectUri}
      />
    );
  }
}

export default compose<Props, IObjectStorageConnectionPageProps>(
  withRouter,
  WithOrg,
  withAntUtils
)(inject("userStore")(observer(ObjectStorageConnectionPage)));
