import { generatePath } from "react-router";
import type { ActionType } from "../containers/reports/view/domain";
import { IUserRoleType } from "../interfaces/user";
import type { RouteDescriptor, ViewName } from "./domain";
import {
  RouteDescriptorComponent,
  generateRoutesFromDefinition,
} from "./domain";

const buildPath: <T>(
  viewName: ViewName
) => <T, G>(params: T, query?: G) => string = <T, G>(viewName: ViewName) => {
  return (params?: T, query?: G) => {
    if (query) {
      const queryString = Object.keys(query)
        .filter((key) => !!query[key])
        .map((key) => key + "=" + (query as any)[key])
        .join("&");
      return `${generatePath(
        routeDescriptor[viewName].path,
        params as any
      )}?${queryString}`;
    }
    return generatePath(routeDescriptor[viewName].path, params as any);
  };
};

export const routeDescriptor: RouteDescriptor = {
  // Legacy route used for "Public Link"
  // Should be deprecated to redirect on the Sharing Link route once the Public Link -> Sharing Link migration is over
  publicLinkReport: {
    layout: "none",
    path: "/:organizationSlug/public/report/:publicToken",
    component: RouteDescriptorComponent.ReportPublicView,
    title: "Public Report",
    renderRoute: buildPath<{ publicToken: string }>("publicLinkReport"),
    public: "SHARED",
    props: {
      fetchOnlyPublicReport: true,
      isBeingPushed: false,
    },
  },
  // Used to take screenshots through reef
  privateLinkReport: {
    layout: "none",
    path: "/:organizationSlug/private/report/:privateToken/:workflowId?",
    component: RouteDescriptorComponent.ReportPublicView,
    title: "Public Report",
    renderRoute: buildPath<{ privateToken: string }>("privateLinkReport"),
    public: "SHARED",
    props: {
      isBeingPushed: true,
      hideLayout: true,
      hideFilters: true,
      showTitle: true,
    },
  },
  // Used to render the sharing links that people are generating from
  sharingLinkReport: {
    layout: "none",
    path: "/:organizationSlug/shared/report/:publicToken/:sharingToken",
    component: RouteDescriptorComponent.ReportSharingLink,
    title: "Public Report",
    renderRoute: buildPath<{ publicToken: string; sharingToken: string }>(
      "sharingLinkReport"
    ),
    public: "SHARING_LINK",
    props: {},
  },
  // Used for Embed API feature where end user programmatically embed a report into their product
  publicEmbeddedReport: {
    layout: "none",
    path: "/:organizationSlug/embed/report/:embedToken",
    component: RouteDescriptorComponent.ReportEmbedPage,
    title: "Public Report",
    renderRoute: buildPath<{ reportToken: string }>("publicEmbeddedReport"),
    public: "SHARING_LINK",
    props: {},
  },
  featureCrossSellPage: {
    layout: "none",
    path: "/features/discover/:featureSlug",
    component: RouteDescriptorComponent.FeatureCrossSellPage,
    title: "Discover",
    menuIcon: "settings",
    renderRoute: buildPath<{ featureSlug: string }>("featureCrossSellPage"),
  },
  login: {
    layout: "none",
    path: "/login",
    component: RouteDescriptorComponent.Login,
    title: "Login",
    public: "PUBLIC",
    renderRoute: buildPath<{}>("login"),
  },
  portalLogin: {
    layout: "none",
    path: "/portal-login",
    component: RouteDescriptorComponent.PortalLogin,
    title: "Portal Login",
    public: "PUBLIC",
    renderRoute: buildPath<{}>("portalLogin"),
  },
  callback: {
    layout: "none",
    path: "/callback",
    component: RouteDescriptorComponent.Callback,
    title: "Login",
    public: "PUBLIC",
    renderRoute: buildPath<{}>("callback"),
  },
  portalCallback: {
    layout: "none",
    path: "/portal-callback",
    component: RouteDescriptorComponent.PortalCallback,
    title: "Login",
    public: "PUBLIC",
    renderRoute: buildPath<{}>("portalCallback"),
  },
  redirect: {
    layout: "none",
    path: "/:organizationSlug/redirect",
    component: RouteDescriptorComponent.V2Layout,
    subComponent: RouteDescriptorComponent.RedirectPage,
    title: "Redirect",
    removeInheritance: true,
    menuIcon: "workspace",
    renderRoute: buildPath<{ organizationSlug: string }>("redirect"),
  },
  home: {
    layout: "none",
    path: "/:organizationSlug",
    component: RouteDescriptorComponent.V2Layout,
    subComponent: RouteDescriptorComponent.HomePage,
    removeInheritance: true,
    title: "Home",
    menuIcon: "workspace",
    renderRoute: buildPath<{ organizationSlug: string }>("home"),
  },
  report: {
    layout: "none",
    path: "/:organizationSlug/reports/:reportSlug",
    component: RouteDescriptorComponent.V2Layout,
    subComponent: RouteDescriptorComponent.ReportV2Page,
    removeInheritance: true,
    title: "Dashboard",
    menuIcon: "workspace",
    renderRoute: buildPath<{ organizationSlug: string; reportSlug: string }>(
      "report"
    ),
  },
  reportEdit: {
    layout: "none",
    path: "/:organizationSlug/reports/:reportSlug/edit",
    component: RouteDescriptorComponent.V2Layout,
    subComponent: RouteDescriptorComponent.ReportV2Page,
    removeInheritance: true,
    title: "Report Edition",
    menuIcon: "workspace",
    props: {
      editing: true,
    },
    renderRoute: buildPath<{ organizationSlug: string; reportSlug: string }>(
      "reportEdit"
    ),
  },
  reportExploration: {
    layout: "none",
    path: "/:organizationSlug/reports/:reportSlug/tiles/:tileSlug",
    component: RouteDescriptorComponent.V2Layout,
    subComponent: RouteDescriptorComponent.ReportV2Page,
    title: "Report",
    removeInheritance: true,
    menuIcon: "workspace",
    renderRoute: buildPath<{
      organizationSlug: string;
      reportSlug: string;
      tileSlug: string;
    }>("reportExploration"),
    props: {
      actionType: "EXPLORE" as ActionType,
    },
  },
  reportTileExplorationEdit: {
    layout: "none",
    path: "/:organizationSlug/reports/:reportSlug/edit/tiles/:tileSlug/edit",
    component: RouteDescriptorComponent.V2Layout,
    subComponent: RouteDescriptorComponent.ReportV2Page,
    removeInheritance: true,
    title: "Report Edition",
    menuIcon: "workspace",
    props: {
      editing: true,
      actionType: "EDIT_TILE" as ActionType,
    },
    renderRoute: buildPath<{
      organizationSlug: string;
      reportSlug: string;
      tileSlug: string;
    }>("reportTileExplorationEdit"),
  },
  reportTileExplorationCreate: {
    layout: "none",
    path: "/:organizationSlug/reports/:reportSlug/edit/tiles/create/:explorationSlug",
    component: RouteDescriptorComponent.V2Layout,
    subComponent: RouteDescriptorComponent.ReportV2Page,
    removeInheritance: true,
    title: "Report Edition",
    menuIcon: "workspace",
    props: {
      editing: true,
      actionType: "CREATE_TILE" as ActionType,
    },
    renderRoute: buildPath<{ organizationSlug: string; reportSlug: string }>(
      "reportTileExplorationCreate"
    ),
  },
  reportTileEdit: {
    layout: "none",
    path: "/:organizationSlug/reports/:reportSlug/edit/tiles/:tileSlug?",
    title: "Report Edition",
    menuIcon: "workspace",
    removeInheritance: true,
    component: RouteDescriptorComponent.V2Layout,
    subComponent: RouteDescriptorComponent.ReportV2Page,
    props: {
      editing: true,
    },
    renderRoute: buildPath<{
      organizationSlug: string;
      reportSlug: string;
      tileSlug: string;
    }>("reportTileEdit"),
  },
  reportFilterEdit: {
    layout: "none",
    path: "/:organizationSlug/reports/:reportSlug/edit/filters/:filterSlug",
    title: "Report Edition",
    menuIcon: "workspace",
    removeInheritance: true,
    component: RouteDescriptorComponent.V2Layout,
    subComponent: RouteDescriptorComponent.ReportV2Page,
    props: {
      editing: true,
    },
    renderRoute: buildPath<{
      organizationSlug: string;
      reportSlug: string;
      filterSlug: string;
    }>("reportFilterEdit"),
  },
  sourceConnection: {
    layout: "external",
    path: "/:organizationSlug/catalog/:warehouseSlug/sources/:itemSlug/connection",
    component: RouteDescriptorComponent.SourceConnectionPage,
    title: "Sources",
    menuIcon: "workspace",
    renderRoute: buildPath<{
      organizationSlug: string;
      warehouseSlug: string;
      itemSlug: string;
    }>("sourceConnection"),
    props: {},
  },
  sourceConnectionStep2: {
    layout: "external",
    path: "/:organizationSlug/catalog/:warehouseSlug/sources/:itemSlug/connection/:itemId",
    component: RouteDescriptorComponent.SourceConnectionPage,
    title: "Sources",
    menuIcon: "workspace",
    renderRoute: buildPath<{
      organizationSlug: string;
      warehouseSlug: string;
      itemSlug: string;
      sourceId: string;
    }>("sourceConnectionStep2"),
    props: {},
  },
  warehouseConnection: {
    layout: "external",
    path: "/:organizationSlug/catalog/warehouse/:itemSlug/connection",
    component: RouteDescriptorComponent.WarehouseConnectionPage,
    title: "Warehouse",
    menuIcon: "settings",
    renderRoute: buildPath<{ organizationSlug: string; itemSlug: string }>(
      "warehouseConnection"
    ),
    props: {},
  },
  warehouseConnectionStep2: {
    layout: "external",
    path: "/:organizationSlug/catalog/warehouse/:itemSlug/connection/:itemId/:connectionType?",
    component: RouteDescriptorComponent.WarehouseConnectionPage,
    title: "Sources",
    menuIcon: "settings",
    renderRoute: buildPath<{
      organizationSlug: string;
      itemSlug: string;
      itemId: string;
    }>("warehouseConnectionStep2"),
    props: {},
  },
  actionConnection: {
    layout: "external",
    path: "/:organizationSlug/catalog/action/:itemSlug/connection",
    component: RouteDescriptorComponent.ActionConnectionPage,
    title: "Action",
    menuIcon: "settings",
    renderRoute: buildPath<{ organizationSlug: string; itemSlug: string }>(
      "actionConnection"
    ),
    props: {},
  },
  actionConnectionStep2: {
    layout: "external",
    path: "/:organizationSlug/catalog/action/:itemSlug/connection/:itemId",
    component: RouteDescriptorComponent.ActionConnectionPage,
    title: "Action",
    menuIcon: "settings",
    renderRoute: buildPath<{
      organizationSlug: string;
      itemSlug: string;
      itemId: string;
    }>("actionConnectionStep2"),
    props: {},
  },
  objectStorageConnection: {
    layout: "external",
    path: "/:organizationSlug/catalog/object-storage/:itemSlug/connection",
    component: RouteDescriptorComponent.ObjectStorageConnectionPage,
    title: "Object Storage",
    menuIcon: "settings",
    renderRoute: buildPath<{ organizationSlug: string; itemSlug: string }>(
      "objectStorageConnection"
    ),
    props: {},
  },
  objectStorageConnectionStep2: {
    layout: "external",
    path: "/:organizationSlug/catalog/object-storage/:itemSlug/connection/:itemId/:connectionType?",
    component: RouteDescriptorComponent.ObjectStorageConnectionPage,
    title: "Object Storage",
    menuIcon: "settings",
    renderRoute: buildPath<{
      organizationSlug: string;
      itemSlug: string;
      itemId: string;
    }>("objectStorageConnectionStep2"),
    props: {},
  },
  workbench: {
    layout: "none",
    path: "/:organizationSlug/workbench/:warehouseSlug?/:tableSlug?/:dependencySlug?",
    component: RouteDescriptorComponent.V2Layout,
    subComponent: RouteDescriptorComponent.WorkbenchPageWrapper,
    title: "Workbench",
    menuIcon: "workbench",
    renderRoute: buildPath<{
      organizationSlug: string;
      tableSlug?: string;
      dependencySlug?: string;
    }>("workbench"),
    restrictAccess: IUserRoleType.BUILDER,
    props: {},
  },
  settingsContentSharedReports: {
    layout: "none",
    path: "/:organizationSlug/settings/shared-reports",
    component: RouteDescriptorComponent.V2Layout,
    props: {
      menu: "SETTINGS",
    },
    subComponent: RouteDescriptorComponent.SharedReportsPage,
    subProps: {},
    title: "Settings - Shared Reports",
    menuIcon: "settings",
    renderRoute: buildPath<{ organizationSlug: string }>(
      "settingsContentSharedReports"
    ),
    restrictAccess: IUserRoleType.ADMIN_BUILDER,
  },
  settingsContentUnusedExplorations: {
    layout: "none",
    path: "/:organizationSlug/settings/unused-explorations",
    component: RouteDescriptorComponent.V2Layout,
    props: {
      menu: "SETTINGS",
    },
    subComponent: RouteDescriptorComponent.UnusedExplorationPage,
    subProps: {},
    title: "Settings - Unused Explorations",
    menuIcon: "settings",
    renderRoute: buildPath<{ organizationSlug: string }>(
      "settingsContentUnusedExplorations"
    ),
    restrictAccess: IUserRoleType.ADMIN_BUILDER,
  },
  settingsActionHistory: {
    layout: "none",
    path: "/:organizationSlug/settings/action-history",
    component: RouteDescriptorComponent.V2Layout,
    props: {
      menu: "SETTINGS",
    },
    subComponent: RouteDescriptorComponent.ActionHistoryPage,
    subProps: {},
    title: "Settings - Action History",
    menuIcon: "settings",
    renderRoute: buildPath<{ organizationSlug: string }>(
      "settingsActionHistory"
    ),
    restrictAccess: IUserRoleType.ADMIN_BUILDER,
  },
  settingsActionManagement: {
    layout: "none",
    path: "/:organizationSlug/settings/action-management",
    component: RouteDescriptorComponent.V2Layout,
    props: {
      menu: "SETTINGS",
    },
    subComponent: RouteDescriptorComponent.ActionManagementPage,
    subProps: {},
    title: "Settings - Action Management",
    menuIcon: "settings",
    renderRoute: buildPath<{ organizationSlug: string }>(
      "settingsActionManagement"
    ),
    restrictAccess: IUserRoleType.ADMIN_BUILDER,
  },
  settingsBasic: {
    layout: "none",
    path: "/:organizationSlug/settings/basic",
    component: RouteDescriptorComponent.V2Layout,
    props: {
      menu: "SETTINGS",
    },
    subComponent: RouteDescriptorComponent.BasicSettingPage,
    subProps: {},
    removeInheritance: true,
    title: "Settings - Basic Settings",
    menuIcon: "settings",
    renderRoute: buildPath<{ organizationSlug: string }>("settingsBasic"),
    restrictAccess: IUserRoleType.ADMIN_BUILDER,
  },
  settingsAccessManagement: {
    layout: "none",
    path: "/:organizationSlug/settings/access-management",
    component: RouteDescriptorComponent.V2Layout,
    props: {
      menu: "SETTINGS",
    },
    subComponent: RouteDescriptorComponent.AccessManagementPage,
    subProps: {},
    title: "Settings - Users",
    menuIcon: "settings",
    renderRoute: buildPath<{ organizationSlug: string }>(
      "settingsAccessManagement"
    ),
    restrictAccess: IUserRoleType.ADMIN_BUILDER,
  },
  settingsServiceAccountManagement: {
    layout: "none",
    path: "/:organizationSlug/settings/service-accounts",
    component: RouteDescriptorComponent.V2Layout,
    props: {
      menu: "SETTINGS",
    },
    subComponent: RouteDescriptorComponent.ServiceAccountManagementPage,
    subProps: {},
    title: "Settings - Service Accounts",
    menuIcon: "settings",
    renderRoute: buildPath<{ organizationSlug: string }>(
      "settingsServiceAccountManagement"
    ),
    restrictAccess: IUserRoleType.ADMIN_BUILDER,
  },
  settingsPartnerPortalDetails: {
    layout: "none",
    path: "/:organizationSlug/settings/partner-portals/:partnerPortalSlug/details",
    component: RouteDescriptorComponent.V2Layout,
    props: {
      menu: "SETTINGS",
    },
    subComponent: RouteDescriptorComponent.PartnerPortalDetailPage,
    subProps: {},
    title: "Settings - Partner Users",
    menuIcon: "settings",
    renderRoute: buildPath<{
      organizationSlug: string;
      partnerPortalSlug: string;
    }>("settingsPartnerPortalDetails"),
    restrictAccess: IUserRoleType.ADMIN_BUILDER,
  },
  settingsPartnerPortals: {
    layout: "none",
    path: "/:organizationSlug/settings/partner-portals",
    component: RouteDescriptorComponent.V2Layout,
    props: {
      menu: "SETTINGS",
    },
    subComponent: RouteDescriptorComponent.PartnerPortalPage,
    subProps: {},
    removeInheritance: true,
    title: "Settings - Partner Portals",
    menuIcon: "settings",
    renderRoute: buildPath<{ organizationSlug: string }>(
      "settingsPartnerPortals"
    ),
    restrictAccess: IUserRoleType.ADMIN_BUILDER,
  },
  settingsGroupManagement: {
    layout: "none",
    path: "/:organizationSlug/settings/user-groups",
    component: RouteDescriptorComponent.V2Layout,
    props: {
      menu: "SETTINGS",
    },
    subComponent: RouteDescriptorComponent.UserGroupManagementPage,
    subProps: {},
    title: "Settings - User Groups",
    menuIcon: "settings",
    renderRoute: buildPath<{ organizationSlug: string }>(
      "settingsGroupManagement"
    ),
    restrictAccess: IUserRoleType.ADMIN_BUILDER,
  },
  settingsWarehouse: {
    layout: "none",
    path: "/:organizationSlug/settings/warehouses",
    component: RouteDescriptorComponent.V2Layout,
    props: {
      menu: "SETTINGS",
    },
    subComponent: RouteDescriptorComponent.WarehouseListPage,
    subProps: {},
    title: "Settings - Warehouse",
    menuIcon: "settings",
    renderRoute: buildPath<{ organizationSlug: string }>("settingsWarehouse"),
    restrictAccess: IUserRoleType.ADMIN_BUILDER,
  },
  settingsObjectStorage: {
    layout: "none",
    path: "/:organizationSlug/settings/object-storages",
    component: RouteDescriptorComponent.V2Layout,
    props: {
      menu: "SETTINGS",
    },
    subComponent: RouteDescriptorComponent.ObjectStorageSettingsPage,
    subProps: {},
    title: "Settings - Object Storages",
    menuIcon: "settings",
    renderRoute: buildPath<{ organizationSlug: string }>(
      "settingsObjectStorage"
    ),
    restrictAccess: IUserRoleType.ADMIN_BUILDER,
  },
  settingsWarehouseDetail: {
    layout: "none",
    path: "/:organizationSlug/settings/warehouses/:warehouseSlug",
    component: RouteDescriptorComponent.V2Layout,
    props: {
      menu: "SETTINGS",
    },
    subComponent: RouteDescriptorComponent.WarehouseSettingsPage,
    subProps: {},
    title: "Settings - Warehouse",
    menuIcon: "settings",
    renderRoute: buildPath<{ organizationSlug: string }>(
      "settingsWarehouseDetail"
    ),
    restrictAccess: IUserRoleType.ADMIN_BUILDER,
  },
  settingsSourceUsage: {
    layout: "none",
    path: "/:organizationSlug/settings/source-usage",
    component: RouteDescriptorComponent.V2Layout,
    props: {
      menu: "SETTINGS",
    },
    subComponent: RouteDescriptorComponent.SettingsSourceUsage,
    subProps: {},
    title: "Settings - Source Usage",
    menuIcon: "settings",
    renderRoute: buildPath<{}>("settingsSourceUsage"),
    restrictAccess: IUserRoleType.ADMIN_BUILDER,
  },
  settingsPlatformUsage: {
    layout: "none",
    path: "/:organizationSlug/settings/platform-usage",
    component: RouteDescriptorComponent.V2Layout,
    props: {
      menu: "SETTINGS",
    },
    subComponent: RouteDescriptorComponent.SettingsPlatformUsage,
    subProps: {},
    title: "Settings - Platform Usage",
    menuIcon: "settings",
    renderRoute: buildPath<{}>("settingsPlatformUsage"),
    restrictAccess: IUserRoleType.ADMIN_BUILDER,
  },
  settingsTheme: {
    layout: "none",
    path: "/:organizationSlug/settings/theme",
    component: RouteDescriptorComponent.V2Layout,
    props: {
      menu: "SETTINGS",
    },
    subComponent: RouteDescriptorComponent.ThemeSettingPage,
    subProps: {},
    title: "Settings - Theme Settings",
    menuIcon: "settings",
    renderRoute: buildPath<{ organizationSlug: string }>("settingsTheme"),
    restrictAccess: IUserRoleType.ADMIN_BUILDER,
  },
  settingsAuditLog: {
    layout: "none",
    path: "/:organizationSlug/settings/audit-log",
    component: RouteDescriptorComponent.V2Layout,
    props: {
      menu: "SETTINGS",
    },
    subComponent: RouteDescriptorComponent.AuditLogPage,
    subProps: {},
    title: "Settings - Audit Log",
    menuIcon: "settings",
    renderRoute: buildPath<{}>("settingsAuditLog"),
    restrictAccess: IUserRoleType.ADMIN_BUILDER,
  },
  settingsUserAttribute: {
    layout: "none",
    path: "/:organizationSlug/settings/user-attributes",
    component: RouteDescriptorComponent.V2Layout,
    props: {
      menu: "SETTINGS",
    },
    subComponent: RouteDescriptorComponent.UserAttributesPage,
    subProps: {},
    title: "Settings - User Attributes",
    menuIcon: "settings",
    renderRoute: buildPath<{}>("settingsUserAttribute"),
    restrictAccess: IUserRoleType.ADMIN_BUILDER,
  },
  settingsRealm: {
    layout: "none",
    path: "/:organizationSlug/settings/realm",
    subComponent: RouteDescriptorComponent.HomePage,
    component: RouteDescriptorComponent.V2Layout,
    embedModal: RouteDescriptorComponent.RealmSettingsModal,
    title: "Settings - Team",
    menuIcon: "settings",
    renderRoute: buildPath<{}>("settingsRealm"),
    restrictAccess: IUserRoleType.ADMIN_BUILDER,
  },
  settingsUser: {
    layout: "none",
    path: "/:organizationSlug/settings/user",
    subComponent: RouteDescriptorComponent.HomePage,
    component: RouteDescriptorComponent.V2Layout,
    embedModal: RouteDescriptorComponent.UserSettingsModal,
    title: "Settings - User",
    menuIcon: "settings",
    renderRoute: buildPath<{ organizationSlug: string }>("settingsUser"),
  },
  organisations: {
    layout: "external",
    path: "/",
    component: RouteDescriptorComponent.Org,
    title: "Organizations",
    menuIcon: "workspace",
    renderRoute: buildPath<{}>("organisations"),
  },
  explores: {
    layout: "none",
    path: "/:organizationSlug/explores",
    component: RouteDescriptorComponent.V2Layout,
    subComponent: RouteDescriptorComponent.HomePage,
    removeInheritance: true,
    embedModal: RouteDescriptorComponent.ExplorationModal,
    title: "Sales Dashboard",
    menuIcon: "workspace",
    renderRoute: buildPath<{ organizationSlug: string }>("explores"),
  },
  explore: {
    layout: "none",
    path: "/:organizationSlug/explores/:exploreSlug",
    component: RouteDescriptorComponent.V2Layout,
    subComponent: RouteDescriptorComponent.HomePage,
    removeInheritance: true,
    title: "Sales Dashboard",
    menuIcon: "workspace",
    renderRoute: buildPath<{ organizationSlug: string; exploreSlug: string }>(
      "explore"
    ),
  },
  folders: {
    layout: "none",
    path: "/:organizationSlug/folders/:folderSlug",
    component: RouteDescriptorComponent.V2Layout,
    subComponent: RouteDescriptorComponent.FolderPage,
    removeInheritance: true,
    title: "Sales Dashboard",
    menuIcon: "workspace",
    props: {},
    renderRoute: buildPath<{ organizationSlug: string; folderSlug: string }>(
      "folders"
    ),
  },
  privateRecord: {
    layout: "none",
    path: "/:organizationSlug/private/objects/:objectId/layouts/:layoutId/records/:recordId",
    component: RouteDescriptorComponent.RecordMjml,
    title: "Public Report",
    renderRoute: buildPath<{
      objectId: string;
      layoutId: string;
      recordId: string;
    }>("privateRecord"),
    public: "SHARED",
  },
  settingsEmailTemplate: {
    layout: "none",
    path: "/:organizationSlug/settings/email-template",
    component: RouteDescriptorComponent.V2Layout,
    props: {
      menu: "SETTINGS",
    },
    subComponent: RouteDescriptorComponent.EmailTemplate,
    subProps: {},
    title: "Settings - Email Template",
    menuIcon: "settings",
    renderRoute: buildPath<{ organizationSlug: string }>(
      "settingsEmailTemplate"
    ),
    restrictAccess: IUserRoleType.ADMIN_BUILDER,
  },
  settingsNotificationTopics: {
    layout: "none",
    path: "/:organizationSlug/settings/notification-topics",
    component: RouteDescriptorComponent.V2Layout,
    props: {
      menu: "SETTINGS",
    },
    subComponent: RouteDescriptorComponent.NotificationTopicsPage,
    subProps: {},
    title: "Settings - Audit Log",
    menuIcon: "settings",
    renderRoute: buildPath<{}>("settingsNotificationTopics"),
    restrictAccess: IUserRoleType.ADMIN_BUILDER,
  },
  settingsNotificationJobs: {
    layout: "none",
    path: "/:organizationSlug/settings/notification-jobs",
    component: RouteDescriptorComponent.V2Layout,
    props: {
      menu: "SETTINGS",
    },
    subComponent: RouteDescriptorComponent.NotificationJobsPage,
    subProps: {},
    title: "Settings - Audit Log",
    menuIcon: "settings",
    renderRoute: buildPath<{ organizationSlug: string }>(
      "settingsNotificationJobs"
    ),
    restrictAccess: IUserRoleType.ADMIN_BUILDER,
  },
  "object-list": {
    layout: "none",
    path: "/:organizationSlug/objects",
    component: RouteDescriptorComponent.V2Layout,
    subComponent: RouteDescriptorComponent.HomePage,
    embedModal: RouteDescriptorComponent.ObjectPageModal,
    removeInheritance: true,
    title: "Sales Dashboard",
    menuIcon: "workspace",
    renderRoute: buildPath<{ organizationSlug: string; objectSlug: string }>(
      "object-list"
    ),
  },
  object: {
    layout: "none",
    path: "/:organizationSlug/object/:objectSlug",
    component: RouteDescriptorComponent.V2Layout,
    subComponent: RouteDescriptorComponent.ObjectPageContainer,
    removeInheritance: true,
    title: "Sales Dashboard",
    menuIcon: "workspace",
    renderRoute: buildPath<{ organizationSlug: string; objectSlug: string }>(
      "object"
    ),
  },
  "object-record": {
    layout: "none",
    path: "/:organizationSlug/object/:objectSlug/:recordId/:layoutId?",
    component: RouteDescriptorComponent.V2Layout,
    subComponent: RouteDescriptorComponent.RecordView,
    removeInheritance: true,
    title: "Sales Dashboard",
    menuIcon: "workspace",
    renderRoute: buildPath<{
      organizationSlug: string;
      objectSlug: string;
      recordId: string;
    }>("object-record"),
  },
  "object-record-embed": {
    layout: "none",
    path: "/:organizationSlug/object-embed/:objectSlug/:recordId/:layoutId?",
    component: RouteDescriptorComponent.RecordViewEmbed,
    subComponent: null as any,
    title: "Object record",
    menuIcon: "settings",
    renderRoute: buildPath<{
      organizationSlug: string;
      objectSlug: string;
      layoutId: string;
      recordId: string;
    }>("object-record-embed"),
  },
  "object-record-public-shared": {
    layout: "none",
    path: "/:organizationSlug/object-public-shared/:objectSlug/:recordId/:layoutId?",
    component: RouteDescriptorComponent.RecordViewPublicEmbed,
    subComponent: null as any,
    title: "Object record",
    menuIcon: "settings",
    renderRoute: buildPath<{
      organizationSlug: string;
      objectSlug: string;
      layoutId: string;
      recordId: string;
    }>("object-record-public-shared"),
    props: {
      shared: true,
    },
    public: "SHARING_LINK",
  },
  "object-record-centered": {
    layout: "none",
    path: "/:organizationSlug/object-centered/:objectSlug/:recordId/:layoutId?",
    component: RouteDescriptorComponent.V2Layout,
    subComponent: RouteDescriptorComponent.HomePage,
    embedModal: RouteDescriptorComponent.RecordModal,
    removeInheritance: true,
    title: "Sales Dashboard",
    menuIcon: "workspace",
    renderRoute: buildPath<{ organizationSlug: string; objectSlug: string }>(
      "object-record-centered"
    ),
  },
  "object-record-document": {
    layout: "none",
    path: "/:organizationSlug/object/:objectSlug/:recordId/documents/:documentId",
    component: RouteDescriptorComponent.V2Layout,
    subComponent: RouteDescriptorComponent.RecordView,
    removeInheritance: true,
    title: "Sales Dashboard",
    menuIcon: "workspace",
    renderRoute: buildPath<{
      organizationSlug: string;
      objectSlug: string;
      recordId: string;
    }>("object-record-document"),
    subProps: {
      display: "DOCUMENT",
    },
  },
  "object-record-document-centered": {
    layout: "none",
    path: "/:organizationSlug/object-centered/:objectSlug/:recordId/documents/:documentId",
    component: RouteDescriptorComponent.V2Layout,
    subComponent: RouteDescriptorComponent.HomePage,
    embedModal: RouteDescriptorComponent.RecordModal,
    removeInheritance: true,
    title: "Sales Dashboard",
    menuIcon: "workspace",
    renderRoute: buildPath<{ organizationSlug: string; objectSlug: string }>(
      "object-record-document-centered"
    ),
    props: {
      display: "DOCUMENT",
    },
  },
  rootCause: {
    layout: "none",
    path: "/:organizationSlug/rootCause",
    component: RouteDescriptorComponent.V2Layout,
    subComponent: RouteDescriptorComponent.HomePage,
    embedModal: RouteDescriptorComponent.RootCauseAnalysisPage,
    removeInheritance: true,
    title: "Root cause",
    menuIcon: "workspace",
    renderRoute: buildPath<{
      organizationSlug: string;
      explorationSlug: string;
    }>("rootCause"),
  },
  "org-picker": {
    layout: "none",
    path: "/:organizationSlug/orgs",
    component: RouteDescriptorComponent.V2Layout,
    subComponent: RouteDescriptorComponent.HomePage,
    embedModal: RouteDescriptorComponent.OrgPickerModal,
    removeInheritance: true,
    title: "Org Picker",
    menuIcon: "workspace",
    renderRoute: buildPath<{ organizationSlug: string }>("org-picker"),
  },
  "org-status": {
    layout: "none",
    path: "/:organizationSlug/status",
    component: RouteDescriptorComponent.V2Layout,
    subComponent: RouteDescriptorComponent.HomePage,
    embedModal: RouteDescriptorComponent.StatusPageModal,
    removeInheritance: true,
    title: "Status Page",
    menuIcon: "workspace",
    renderRoute: buildPath<{ organizationSlug: string }>("org-status"),
  },
  fileUploads: {
    layout: "none",
    path: "/:organizationSlug/file-uploaders/:fileUploadSlug?",
    component: RouteDescriptorComponent.V2Layout,
    subComponent: RouteDescriptorComponent.HomePage,
    removeInheritance: true,
    embedModal: RouteDescriptorComponent.FileUploadModal,
    title: "Upload a file",
    menuIcon: "workspace",
    renderRoute: buildPath<{ organizationSlug: string }>("fileUploads"),
  },
};

export default generateRoutesFromDefinition(routeDescriptor);
