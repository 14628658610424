import { useDrag } from "react-dnd";
import type { IReport } from "../../../interfaces/reports";

import { Typography } from "antd";
import { Link } from "react-router-dom";
import type { InjectedOrgProps } from "../../../containers/orgs/WithOrg";
import WithOrg from "../../../containers/orgs/WithOrg";
import { routeDescriptor } from "../../../routes/routes";
import { compose } from "../../compose/WlyCompose";
import { ReportIcon } from "../../report/ReportIcon";
import "./FolderItem.scss";

type FolderReportProps = {
  report: IReport;
};
type Props = InjectedOrgProps & FolderReportProps;
const Comp = ({ report, org }: Props) => {
  const [{ opacity }, dragRef] = useDrag(
    () => ({
      type: "REPORT",
      item: report,
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.3 : 1,
      }),
      options: {
        dropEffect: "move",
      },
    }),
    []
  );

  return (
    <div ref={dragRef} className="folder-item v2" style={{ opacity }}>
      <Link
        to={routeDescriptor.report.renderRoute({
          organizationSlug: org.slug,
          reportSlug: report.slug,
        })}
        className="folder-item-name-container"
      >
        <div className="folder-item-name-image">
          <ReportIcon type={report.type} />
        </div>

        <div className="folder-item-name-title">
          <Typography.Text>{report.name}</Typography.Text>
        </div>
      </Link>
    </div>
  );
};

export const FolderReport = compose<Props, FolderReportProps>(WithOrg)(Comp);
