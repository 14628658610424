import authStore from "../store/authStore";
import StorageService from "./StorageService";

export const encodePageLocation = (page: string): string => {
  return `whaly-request-${btoa(page)}`;
};

export const decodePageLocation = (hash: string): string => {
  return atob(hash.replace("whaly-request-", ""));
};

export const hasNeverBeenAuthenticated = () => {
  return !window.authentication;
};

const isAuthenticated = () => {
  return window.authentication && window.authentication.expires_at > Date.now();
};

export const isPartnerPortal = () => {
  const domain = window.location.hostname;
  return (
    domain.includes(".portals") || !!window.WHALY_CONSTANTS.OVERRIDE_PORTAL_URL
  );
};

export const getAccessToken = (): string | undefined => {
  const overrideToken = StorageService.getItem("_wly_override_token");

  return overrideToken ?? window.authentication?.access_token;
};

export const getAuthorizationType = () => {
  const overrideToken = StorageService.getItem("_wly_override_token");
  const { token_type } = window.authentication ?? {};

  if (overrideToken) {
    return "Bearer";
  }
  if (token_type === "bearer") {
    return "Bearer";
  }
  if (token_type === "portal-jwt") {
    return "PortalJwt";
  }
};

export const authenticatedRequest = (): Promise<void> => {
  return isAuthenticated() ? Promise.resolve() : authStore.refreshSession();
};
