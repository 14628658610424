import { WarningFilled } from "@ant-design/icons";
import type { DiagramEngine } from "@projectstorm/react-diagrams";
import { PortModelAlignment, PortWidget } from "@projectstorm/react-diagrams";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import { compose } from "../../../../../../../components/compose/WlyCompose";
import { SourceImageRenderer } from "../../../../../../../components/sources/SourceImageRenderer";
import { DatasetPopover } from "../../../DatasetPopover";
import type { DatasetNodeModel } from "./DatasetNodeModel";
import "./DatasetNodeWidget.scss";

interface DatasetNodeWidgetProps {
  node: DatasetNodeModel;
  engine: DiagramEngine;
  size?: number;
}

export const HEIGHT = 52;
export const WIDTH = 188; // GRID IS 68 px so this should be a multiple of 68 - PORT SIZE
const PORT_SIZE = 16;

type Props = DatasetNodeWidgetProps & RouteComponentProps<{}>;

class DatasetNodeWidget extends React.Component<Props> {
  render() {
    const { node } = this.props;

    const inLinks = Object.keys(node.getInPort().getLinks());
    const outLinks = Object.keys(node.getOutPort().getLinks());
    const hasWarning =
      node.tableTabItem.runResults
        .filter((t) => t.status !== "success")
        .filter((error) => error.type !== "configuration").length > 0;

    const hasUpstreamErrors = node.tableTabItem.hasUpstreamError;

    return (
      <DatasetPopover
        tabData={node.tableTabItem}
        destination={node.destination}
        placement="top"
      >
        <div
          className={`dataset-node-widget-wrapper ${
            node.isFeatured ? `selected` : ""
          }`}
          style={{ height: HEIGHT + PORT_SIZE, width: WIDTH + PORT_SIZE }}
        >
          {inLinks.length > 0 ? (
            <PortWidget
              style={{
                top: HEIGHT / 2,
                left: 0,
                position: "absolute",
              }}
              port={this.props.node.getPort(PortModelAlignment.LEFT)}
              engine={this.props.engine}
            >
              <div className="dataset-port-widget" />
            </PortWidget>
          ) : null}

          <div
            className="dataset-node-widget-core"
            style={{ height: HEIGHT, width: WIDTH }}
          >
            {hasWarning && (
              <div className="data-node-widget-warning">
                <WarningFilled />
              </div>
            )}
            {!hasWarning && hasUpstreamErrors && (
              <div className="data-node-widget-warning" />
            )}
            <div className="dataset-node-widget-core-inner">
              <div className="dataset-node-widget-core-logo">
                <SourceImageRenderer
                  size={24}
                  isModel={node.tableTabItem?.dataset.isModel}
                  alt={node.tableTabItem?.dataset.name}
                  img={
                    node.tableTabItem?.dataset.source?.sourceMeta?.publicInfo
                      ?.logo
                  }
                />
              </div>
              <div className="dataset-node-widget-core-content">
                <div className="dataset-node-widget-core-content-title">
                  {node.tableTabItem?.dataset.name}
                </div>
                <div className="dataset-node-widget-core-content-subtitle">
                  {node.tableTabItem?.dataset.source?.name}
                </div>
              </div>
            </div>
          </div>
          {outLinks.length ? (
            <PortWidget
              style={{
                top: HEIGHT / 2,
                right: 0,
                position: "absolute",
              }}
              port={this.props.node.getPort(PortModelAlignment.RIGHT)}
              engine={this.props.engine}
            >
              <div className="dataset-port-widget" />
            </PortWidget>
          ) : null}
        </div>
      </DatasetPopover>
    );
  }
}

export default compose<Props, DatasetNodeWidgetProps>(withRouter)(
  DatasetNodeWidget
);
