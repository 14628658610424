import {
  CompassOutlined,
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  InfoCircleFilled,
  MoreOutlined,
  ThunderboltTwoTone,
} from "@ant-design/icons";
import type { ResultSet, SqlQuery } from "@cubejs-client/core";
import type { MenuProps } from "antd";
import { Button, Dropdown, Popover, Tooltip } from "antd";
import type { Remote } from "comlink";
import cuid from "cuid";
import moment from "moment";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import type { InjectedAntUtilsProps } from "../../../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../../../components/ant-utils/withAntUtils";
import {
  ChartDefinition,
  type ChartType,
} from "../../../../../components/chart/domain";
import { compose } from "../../../../../components/compose/WlyCompose";
import type {
  AvailableDimension,
  AvailableMetric,
} from "../../../../../components/measures/filter-item/FilterItem";
import { rationalizeFilters } from "../../../../../components/measures/filter-item/domain";
import type { EditTooltipItem } from "../../../../../components/report/tooltip/EditTooltip";
import { EditTooltip } from "../../../../../components/report/tooltip/EditTooltip";
import { SafeText } from "../../../../../components/safe-text/SafeText";
import UserAvatar from "../../../../../components/user/avatar/UserAvatar";
import { encodeLagoonQueryForURL } from "../../../../../helpers/queryStringHelpers";
import type {
  AsyncCachedData,
  AsyncData,
} from "../../../../../helpers/typescriptHelpers";
import type { IExploration } from "../../../../../interfaces/explorations";
import type { IFilter, ITile } from "../../../../../interfaces/reports";
import { IUserRoleType } from "../../../../../interfaces/user";
import { routeDescriptor } from "../../../../../routes/routes";
import type {
  IQueryTraceResult,
  WlyResultSet,
} from "../../../../../services/LagoonService";
import { applyFiltersToLagoonQuery } from "../../../../../services/tileService";
import { downloadCsv } from "../../../../../utils/csvDownload";
import type { CubeJSPivotWorker } from "../../../../../worker/main.worker";
import type { ILagoonQuery } from "../../../../exploration/single/domain";
import type { IAsyncMeta } from "../../../../exploration/single/visualization/chart/Chart";
import { STALE_DATA_MESSAGE } from "../../../../exploration/single/visualization/chart/Chart";
import ChartErrorHandler from "../../../../exploration/single/visualization/chart/ChartErrorHandler";
import ChartSizer from "../../../../exploration/single/visualization/chart/ChartSizer";
import ChartWarnings from "../../../../exploration/single/visualization/chart/ChartWarnings";
import type { InjectedOrgProps } from "../../../../orgs/WithOrg";
import WithOrg from "../../../../orgs/WithOrg";
import HasRoleAccess, {
  hasRoleAccessBoolean,
} from "../../../../user-settings/HasRoleAccess";
import type { FilterMapStore } from "../../domain";
import "./ChartCard.scss";
import { ChartCardSummary, showSummary } from "./ChartCardSummary";

interface IChartCardProps {
  tile: ITile;
  reportId: string;
  isEmbedded: boolean;
  data: Store;
  onRefresh?: (id: string, overrideQuery?: ILagoonQuery) => void;
  onDelete?: (id: string) => Promise<any>;
  onEdit?: (id: string) => void;
  onRename?: (id: string, name: string) => void;
  setRender?: (meta: IAsyncMeta) => void;
  onDuplicate?: (tile: ITile) => Promise<any>;
  onOpenConsole?: (c?: string) => void;
  disableDrills?: boolean;
  editing: boolean;
  selected: boolean;
  renderOutsideViewport?: boolean;
  externalWorker: Remote<CubeJSPivotWorker>;
  filterStore: FilterMapStore;
  reportFilters: IFilter[];
}

export interface Store {
  query: ILagoonQuery;
  overrideQuery?: ILagoonQuery;
  explorationId: string;
  availableMetrics: Array<AvailableMetric>;
  availableDimensions: Array<AvailableDimension>;
  data: AsyncCachedData<WlyResultSet<any>>;
  additionalResults: AsyncData<WlyResultSet<any | undefined>>;
  hasUpstreamErrors: boolean;
  foundExploration?: IExploration;
  chartType: ChartType;
  meta?: IAsyncMeta;
  sql?: SqlQuery;
  raw: ITile;
  trace?: AsyncData<IQueryTraceResult>;
  getSQL?: () => Promise<SqlQuery>;
  getTrace?: () => Promise<IQueryTraceResult>;
  updatedAt: string;
}

type Props = IChartCardProps &
  InjectedAntUtilsProps &
  RouteComponentProps<{}> &
  InjectedOrgProps;

type IState = {
  warnings: string[];
  traceModalOpen: boolean;
};

class ChartCard extends React.Component<Props, IState> {
  id: string = cuid();
  headerId: string = cuid();

  constructor(props: Props) {
    super(props);
    this.state = {
      warnings: [],
      traceModalOpen: false,
    };
  }

  componentDidMount() {
    if (this.props.data.hasUpstreamErrors) {
      this.setState({
        warnings: [...this.state.warnings, STALE_DATA_MESSAGE],
      });
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState): void {
    if (
      !this.props.data.hasUpstreamErrors &&
      this.state.warnings.find((w) => w === STALE_DATA_MESSAGE)
    ) {
      this.setState({
        warnings: this.state.warnings.filter((w) => w !== STALE_DATA_MESSAGE),
      });
    }
  }

  onDownloadData = (
    orgId: string,
    reportId: string,
    data: AsyncCachedData<ResultSet<any>>,
    tile: ITile
  ) => {
    if (
      typeof ChartDefinition[this.props.tile.chartType].downloadCSV ===
      "function"
    ) {
      downloadCsv(
        orgId,
        reportId,
        {
          type: "ChartData",
          chartType: this.props.tile.chartType,
          options: {
            query: this.props.data.query,
            resultSet: this.props.data.data,
            additionalResultSet: this.props.data.additionalResults,
          },
        },
        tile.name ? tile.name : "export"
      )();
    } else {
      downloadCsv(
        orgId,
        reportId,
        { type: "ResultSet", data },
        tile.name ? tile.name : "export"
      )();
    }
  };

  onWarningsChange = (warnings) => {
    this.setState({ warnings: warnings });
  };

  public render() {
    const {
      data,
      tile,
      onDelete,
      onEdit,
      onDuplicate,
      history,
      match: { params },
      onOpenConsole,
      reportId,
      antUtils,
      editing,
      selected,
      user,
      org,
      renderOutsideViewport,
      externalWorker,
      filterStore,
      reportFilters,
      userFeatures,
      onRefresh,
    } = this.props;

    const {
      data: asyncData,
      query,
      overrideQuery,
      availableDimensions,
      availableMetrics,
      additionalResults,
    } = data;

    const onDeleteClick = () => {
      if (onDelete) {
        return antUtils.modal.confirm({
          title: "Do you want to delete this tile?",
          content:
            "You won't be able to go back once it is done, are you sure that is what you want ?",
          okText: "Delete",
          okButtonProps: {
            danger: true,
          },
          cancelText: "Cancel",
          onOk: () => {
            return onDelete(tile.id);
          },
          onCancel: () => {
            return;
          },
        });
      }
    };

    const menuItems: MenuProps["items"] = [
      ...(!onEdit &&
      hasRoleAccessBoolean(IUserRoleType.REPORT_DOWNLOADER, user, org.id)
        ? [
            {
              key: 2,
              onClick: () =>
                this.onDownloadData(org.id, reportId, asyncData, tile),
              label: "Export CSV",
            },
          ]
        : []),
      ...(onOpenConsole &&
      hasRoleAccessBoolean(IUserRoleType.BUILDER, user, org.id)
        ? [
            {
              key: 5,
              onClick: () => onOpenConsole(tile.id),
              label: "View in console",
            },
          ]
        : []),
    ];

    const items: EditTooltipItem[] = [];

    if (onEdit) {
      items.push({
        key: "edit",
        name: "Edit",
        props: {
          icon: <EditOutlined />,
          onClick: (e) => {
            onEdit(tile.id);
            e.stopPropagation();
          },
        },
      });
    }

    if (onDuplicate) {
      items.push({
        key: "duplicate",
        name: "Duplicate",
        props: {
          icon: <CopyOutlined />,
          onClick: () => onDuplicate(tile),
        },
      });
    }

    if (onDelete) {
      items.push({
        key: "delete",
        name: "Delete",
        props: {
          icon: <DeleteOutlined />,
          onClick: onDeleteClick,
          danger: true,
        },
      });
    }

    const renderPopover = () => {
      let hasPopover = false;
      let showDetails = false;
      if (!editing && tile.description) {
        hasPopover = true;
      }
      if (editing) {
        hasPopover = true;
        showDetails = true;
      }
      if (this.props.isEmbedded) {
        hasPopover = false;
        showDetails = false;
      }
      if (!hasPopover) {
        return undefined;
      } else {
        return (
          <Popover
            overlayClassName="chart-info-popover"
            title={tile.name}
            content={
              <>
                <div className="chart-info-popover-inner-item">
                  <SafeText>{tile.description ?? "No description..."}</SafeText>
                </div>
                {showDetails && (
                  <>
                    <div className="chart-info-popover-inner-item">
                      <UserAvatar tooltip user={tile.updatedBy} size={23} />{" "}
                      {`updated ${moment(tile.updatedAt).fromNow()}`}
                    </div>
                  </>
                )}
              </>
            }
          >
            <InfoCircleFilled
              size={20}
              className="tile-chart-header-title-info"
            />
          </Popover>
        );
      }
    };

    let isFromCache = false;
    let isFromServingLayer = false;
    if (data.trace?.status === "success") {
      const traceData = data.trace;
      isFromCache = traceData.data.isFromCache;
      isFromServingLayer = traceData.data.isFromServingLayer;
    }

    const shouldDisplayPicker = showSummary(query.chartOptions?.summary);

    return (
      <div className="tile-chart-wrap">
        {items.length > 0 && editing && selected ? (
          <EditTooltip
            items={items}
            additionalStyle={{
              position: "absolute",
              top: -42,
              right: -2,
            }}
          />
        ) : null}
        <div className={`tile-chart hideable`} id={this.id}>
          <div className="tile-chart-header" id={this.headerId}>
            {this.state.warnings.length > 0 && !this.props.isEmbedded ? (
              <ChartWarnings
                warnings={this.state.warnings}
                placement="topRight"
              />
            ) : null}

            <div className="tile-chart-header-title">
              <div className="tile-chart-header-title-name">{tile.name}</div>
              {renderPopover()}
            </div>

            {!editing && (
              <HasRoleAccess accessLevel={IUserRoleType.REPORT_DOWNLOADER}>
                <div className="tile-chart-header-actions">
                  {isFromCache || isFromServingLayer ? (
                    <Tooltip
                      title={() => {
                        if (isFromServingLayer && !isFromCache) {
                          return "Accelerated by Serving Layer";
                        } else if (isFromCache && !isFromServingLayer) {
                          return "Accelerated by Whaly cache";
                        } else {
                          return "Accelerated by Whaly cache and Serving Layer";
                        }
                      }}
                    >
                      <ThunderboltTwoTone twoToneColor="#FFCC00" />
                    </Tooltip>
                  ) : undefined}
                  <Button.Group>
                    <HasRoleAccess accessLevel={IUserRoleType.VIEWER}>
                      <Button
                        onClick={(e) => {
                          const newQuery = Object.assign({}, data.query);

                          const { dateRange, filters } =
                            applyFiltersToLagoonQuery(
                              newQuery,
                              reportFilters,
                              filterStore,
                              tile.id
                            );
                          // we have to do this for some reason :(
                          newQuery.dateRange = JSON.stringify(dateRange) as any;
                          newQuery.filterOperator = "and";
                          newQuery.filters = rationalizeFilters(filters) as any;
                          newQuery.chartType = tile.chartType;

                          history.push(
                            routeDescriptor["explore"].renderRoute(
                              {
                                ...params,
                                exploreSlug: tile.exploration.slug,
                              },
                              {
                                // should include filters informations
                                query: encodeLagoonQueryForURL(newQuery),
                                loadQuery: true,
                              }
                            )
                          );
                          e.stopPropagation();
                        }}
                        icon={<CompassOutlined />}
                        size="small"
                      >
                        Explore
                      </Button>
                    </HasRoleAccess>
                    <Dropdown
                      getPopupContainer={() => {
                        const el = document.getElementById(this.id);
                        if (el) {
                          return el;
                        }
                        return document.body;
                      }}
                      trigger={["click"]}
                      menu={{
                        items: menuItems,
                      }}
                      placement="bottomRight"
                    >
                      <Button size="small" icon={<MoreOutlined />} />
                    </Dropdown>
                  </Button.Group>
                </div>
              </HasRoleAccess>
            )}
          </div>
          {shouldDisplayPicker && (
            <ChartCardSummary
              availableDimensions={availableDimensions}
              availableMetrics={availableMetrics}
              overrideQuery={overrideQuery}
              initialQuery={query}
              onRefresh={onRefresh ? (q) => onRefresh(tile.id, q) : undefined}
              editing={editing}
            />
          )}
          <div
            className={`tile-chart-content ${
              shouldDisplayPicker ? "sub-header-visible" : ""
            }`}
          >
            {tile.type === "chart" ? (
              <ChartErrorHandler>
                <ChartSizer
                  analysisType={tile.analysisType}
                  currentChartType={tile.chartType}
                  data={asyncData}
                  scale={tile.analysisType === "TIME" ? "time" : "ordinal"}
                  lagoonQuery={overrideQuery ? overrideQuery : query}
                  availableDimensions={availableDimensions}
                  availableMetrics={availableMetrics}
                  exploration={data.foundExploration}
                  setRender={this.props.setRender}
                  onWarningsChange={this.onWarningsChange}
                  additionalResults={additionalResults}
                  reportId={reportId}
                  disableDrills={this.props.disableDrills}
                  renderOutsideViewport={renderOutsideViewport}
                  externalWorker={externalWorker}
                />
              </ChartErrorHandler>
            ) : (
              <div>{tile.content}</div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default compose<Props, IChartCardProps>(
  WithOrg,
  withRouter,
  withAntUtils
)(ChartCard);
