import moment from "moment";
import { GROUP_INFO_FRAGMENT } from "../fragments/group";
import type { ConnectedUserResult } from "../interfaces/auth";
import type { IUserRoleType } from "../interfaces/user";
import GraphQLService from "./graphql/GraphQLService";

export const getConnectedUser = (): Promise<ConnectedUserResult> => {
  return GraphQLService(
    `
    ${GROUP_INFO_FRAGMENT}
    # Write your query or mutation here
    query me {
      meWithSessionInfo {
        me {
          id
          firstName
          lastName
          email
          gravatar
          avatarColor
          isAdmin
          createdAt
          isPublicGuestUser
          locale
          lastLoginDate
          featureGrants(where: { isDeleted: false }) {
            feature {
              apiName
            }
          }
          type
          portal {
            id
            slug
            name
            logo
            primaryColor
            defaultHomeCover
          }
          attributes(where: { deleted_not: true }) {
            id
            value
            userAttributeMeta {
              id
            }
          }
          realm {
            type
            realm {
              id
              identityProviderType
              emailDomain
            }
          }
          roles(first: 1000, where: { isDeleted: false, org: { isDeleted: false } }) {
            id
            homeCover
            role
            v2HomeConfig
            hasFileUploadPermission
            org {
              groups(where: { isDeleted_not: true }) {
                ...GroupInfo
              }
              userAttributeMetas(where: { deleted_not: true }) {
                id
                technicalName
                label
                type
                options
                defaultValue
                allowMultipleValues
              }
              id
              isDeleted
              name
              status
              freeTrialEndDate
              accountType
              paymentLink
              logo
              slug
              emailTemplate
              realm {
                id
                identityProviderType
                emailDomain
              }
              slackCredentials {
                isActive
              }
              defaultPaletteCollection
              paletteCollections(where: { deleted_not: true }) {
                id
                name
                slug
                sequential
                diverging
                categorical
              }
              destinations(where: { isDeleted_not: true }) {
                id
                isBiEnabled
                isDataLoadingEnabled
                slug
                name
                targetDatabase
                persistenceEngineDefaultTargetDatabase
                persistenceEngineDefaultTargetSchema
                destinationMeta {
                  slug
                  isWhalyManaged
                  targetName
                  warehouseType
                  publicInfo {
                    logo
                  }
                }
              }
              featureGrants(where: { isDeleted: false }) {
                freeTrialStartedAt
                freeTrialEndAt
                feature {
                  apiName
                }
              }
            }
          }
        }
        session {
          isSudoSession
        }
      }
    }
  `,
    {},
    "meWithSessionInfo"
  );
};

const CREATE_USER_ROLE_GQL = `
mutation AssignUserRole(
  $userId: ID!,
  $orgId: ID!,
  $role: UserRoleRoleType
  ) {
  assignUserRole(
    orgId: $orgId,
    userId: $userId,
    role: $role
  )
}
`;

export const assignUserRole = (
  userId: string,
  orgId: string,
  role: IUserRoleType
): Promise<void> => {
  return GraphQLService(CREATE_USER_ROLE_GQL, {
    userId,
    orgId,
    role,
  });
};

export const registerUserLogin = (
  userId: string,
  lastKnownLoginDate?: string
) => {
  const now = moment().format();
  return GraphQLService(
    `mutation registerUserLogin($userId: ID!, $lastLoginDate: String!, $firstLoginDate: String) {
      updateUser(id: $userId, data: {
        firstLoginDate: $firstLoginDate,
        lastLoginDate: $lastLoginDate
      }) {
        id
      }
    }`,
    {
      userId,
      lastLoginDate: now,
      firstLoginDate: !lastKnownLoginDate ? now : undefined,
    }
  );
};

export const registerReportView = (orgId: string, reportId: string) => {
  return GraphQLService(
    `
  mutation registerReportView($reportId: ID!, $orgId: ID!) {
    createReportUserView(data: {
      report: {
        connect: {
          id: $reportId
        }
      },
      org: {
        connect: {
          id: $orgId
        }
      },
    }) {
      createdAt
    }  
  }
  `,
    {
      orgId,
      reportId,
    }
  );
};
