import {
  FilterOutlined,
  MoreOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import type { BinaryFilter, UnaryFilter } from "@cubejs-client/core";
import { Button, Dropdown, Space, Typography } from "antd";
import type { Moment } from "moment";
import React, { useId } from "react";
import { useInView } from "react-intersection-observer";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import type { InjectedAntUtilsProps } from "../../../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../../../components/ant-utils/withAntUtils";
import { compose } from "../../../../../components/compose/WlyCompose";
import { FromNow } from "../../../../../components/moment/FromNow";
import type { IExploration } from "../../../../../interfaces/explorations";
import type { IFilter, IReport } from "../../../../../interfaces/reports";
import { IUserRoleType } from "../../../../../interfaces/user";
import { routeDescriptor } from "../../../../../routes/routes";
import GraphQLService from "../../../../../services/graphql/GraphQLService";
import type { InjectedOrgProps } from "../../../../orgs/WithOrg";
import WithOrg from "../../../../orgs/WithOrg";
import HasRoleAccess from "../../../../user-settings/HasRoleAccess";
import { BORDER_COLOR } from "../../../../v2-demo/container/layout/domain";
import type { FilterMapStore, IFilterStore } from "../../domain";
import { UPDATE_FILTER, convertStringToFilterType } from "../../domain";
import { convertWlyDatePickerValueToMoment } from "../../filters/date-filter/WlyDatePicker";
import { DraggableFielFilter } from "../../filters/field-filter/DraggableFieldFilter";
import FieldFilter from "../../filters/field-filter/FieldFilter";
import ScheduleListDrawer from "../../schedule/ScheduleListDrawer";
import ShareDrawer from "../../share/ShareDrawer";
import "./DashboardFilters.scss";
import { renderEmbedModal } from "./domain";

interface IDashboardFiltersProps {
  editing?: boolean;
  explorations: Array<IExploration>;
  filterStore: FilterMapStore;
  report: IReport;
  shouldUpdateQueries: boolean;
  setSelected?: (id: string) => void;
  refreshReport?: () => any;
  reloadReport: (reportSlug: string, orgId) => Promise<IReport>;
  saving: (saving: boolean) => void;
  setFilterStoreValue: (id: string, data: IFilterStore) => void;
  setShouldUpdateQuery: (v: boolean) => void;
  setReport: (report: IReport) => void;
  fetched: Moment;
  forceCacheRefresh: (force: boolean) => any;
  hideFilters?: boolean;
  onOpenConsole?: () => void;
  isEmbedded?: boolean;
}

type Props = IDashboardFiltersProps &
  InjectedAntUtilsProps &
  InjectedOrgProps &
  RouteComponentProps<{
    filterSlug?: string;
    organizationSlug: string;
    reportSlug: string;
  }>;

function DashboardFilters(props: Props) {
  const {
    editing,
    explorations,
    filterStore,
    org,
    report,
    shouldUpdateQueries,
    refreshReport,
    reloadReport,
    saving,
    setSelected,
    isEmbedded,
    setFilterStoreValue,
    setShouldUpdateQuery,
    history,
    match: { params },
    setReport,
    fetched,
    forceCacheRefresh,
    hideFilters,
    antUtils,
    onOpenConsole,
  } = props;

  const id = useId();
  const { ref, inView } = useInView({
    initialInView: true,
  });
  const [dragging, setDragging] = React.useState<string | undefined>();
  const [scheduleListDrawerOpened, setScheduleListDrawerOpened] =
    React.useState<{ opened: boolean }>({
      opened: false,
    });
  const [shareDrawerOpened, setShareDrawerOpened] =
    React.useState<boolean>(false);

  const flatExplorations = explorations.flatMap((ex) =>
    ex.tables.flatMap((t) =>
      t.dimensions.map((d) => ({
        ...d,
        explorationId: ex.id,
        generatedId: `${t.cubeName}.${d.cubeName}`,
      }))
    )
  );
  const [showSubFiltersInView, setShowSubFiltersInView] =
    React.useState<boolean>(false);

  React.useEffect(() => {
    setShowSubFiltersInView(false);
  }, [inView]);

  const getControlledFilterValue = (filter: IFilter) => {
    if (!filter.controlledBy || filter.controlledBy?.length === 0) return [];
    const filterQueryValues: Array<BinaryFilter | UnaryFilter> = [];
    filter.controlledBy.forEach((cb) => {
      if (!cb.dimensionToUpdate) return null;
      if (!filterStore[cb.parent?.id]) return null;
      const filterValue = filterStore[cb.parent.id].value;
      const hasValue =
        filterValue &&
        filterValue.length > 0 &&
        filterValue[0] !== "WHALY_NO_FILTER";
      if (!hasValue) return null;
      if (filterStore[cb.parent.id].type === "TIME") {
        const value = convertWlyDatePickerValueToMoment(
          convertStringToFilterType(filterValue[0], "TIME")
        );
        // TIME filters can be undefined when set to all time
        // we don't apply additional filters in that case
        if (value) {
          filterQueryValues.push({
            member: cb.dimensionToUpdate,
            operator: "inDateRange",
            values: value as any,
          });
        }
      } else {
        filterQueryValues.push({
          member: cb.dimensionToUpdate,
          operator: "equals",
          values: filterStore[cb.parent.id].value,
        });
      }
    });
    return filterQueryValues;
  };
  const showEmbedModal = () => antUtils.modal.info(renderEmbedModal(report));

  // we don't show the sticky filters for embeded or in edition
  const canShowStickyNote = !editing && !isEmbedded;

  const renderActions = () => {
    return (
      <>
        {shouldUpdateQueries ? (
          <Button
            className="pulse-button"
            type={"primary"}
            onClick={() => {
              setShouldUpdateQuery(false);
              refreshReport?.();
            }}
          >
            Update
          </Button>
        ) : (
          <Dropdown
            trigger={["click"]}
            placement={"bottomRight"}
            arrow={true}
            menu={{
              items: [
                {
                  key: "refresh",
                  label: "Refresh",
                  onClick: () => forceCacheRefresh(false),
                },
                {
                  key: "clear-and-refresh",
                  label: "Clear cache & refresh",
                  onClick: () => forceCacheRefresh(true),
                },
                {
                  type: "divider",
                },
                fetched && {
                  key: "helper",
                  label: (
                    <Typography.Text type="secondary" style={{ fontSize: 12 }}>
                      Last refreshed:
                      <br />{" "}
                      <FromNow
                        style={{ fontSize: "0.9em", marginRight: 6 }}
                        date={fetched}
                      />
                    </Typography.Text>
                  ),
                  style: {
                    pointerEvents: "none",
                  },
                },
              ],
            }}
          >
            <Button shape="circle" icon={<ReloadOutlined />} />
          </Dropdown>
        )}
        <HasRoleAccess accessLevel={IUserRoleType.EDITOR}>
          {!isEmbedded && (
            <Dropdown
              trigger={["click"]}
              placement={"bottomRight"}
              arrow={true}
              menu={{
                items: [
                  {
                    key: "subscribe",
                    label: "Schedule delivery",
                    onClick: () =>
                      setScheduleListDrawerOpened({
                        opened: true,
                      }),
                  },
                  {
                    key: "sharing",
                    onClick: () => setShareDrawerOpened(true),
                    label: "Sharing Links",
                  },
                  {
                    key: "embeds",
                    onClick: () => showEmbedModal(),
                    label: "Embed code",
                  },
                  {
                    type: "divider",
                  },
                  {
                    key: 3,
                    onClick: () => onOpenConsole?.(),
                    label: "Open console",
                  },
                ],
              }}
            >
              <Button shape="circle" icon={<MoreOutlined />} />
            </Dropdown>
          )}
        </HasRoleAccess>
      </>
    );
  };

  const hasFilters = report.filters.length > 0 && !hideFilters;
  const numberOfDisplayedFilters = report.filters.filter(
    (f) => !f.hidden
  ).length;

  const renderFilters = () => {
    return hasFilters ? (
      <Space wrap align="end">
        {[...report.filters]
          .sort((a, b) => {
            const aOrder = a.order ? a.order : 0;
            const bOrder = b.order ? b.order : 0;

            return aOrder - bOrder;
          })
          .filter((f) => {
            return editing ? true : !f.hidden;
          })
          .map((f, i, s) => {
            const onRemoveFilter = () => {
              return GraphQLService(UPDATE_FILTER, {
                id: f.id,
                data: {
                  deleted: true,
                },
                reportId: report.id,
              })
                .then((r) => {
                  return reloadReport(report.slug, org.id);
                })
                .then(() => {
                  saving(false);
                })
                .catch((err) => {
                  saving(false);
                });
            };

            let defaultValue: string[] | undefined = undefined;
            if (f.requireValue) {
              defaultValue = f.defaultValue;
            } else if (f.componentType === "SELECT") {
              defaultValue = ["WHALY_NO_FILTER"];
            }

            let hidden: "FULL" | "VISIBLE" | "NONE" = "NONE";
            if (f.hidden && editing) {
              hidden = "VISIBLE";
            } else if (f.hidden) {
              hidden = "FULL";
            }

            const isSelected = params.filterSlug
              ? params.filterSlug === f.slug
              : false;

            if (editing) {
              return (
                <DraggableFielFilter
                  key={f.id}
                  filterType={f.type}
                  value={filterStore[f.id].value}
                  defaultValue={defaultValue}
                  filterName={f.name}
                  labelDimension={f.labelDimension}
                  filters={s}
                  onDrop={(f) => {
                    setReport({
                      ...report,
                      filters: f,
                    });
                  }}
                  onDrag={(id, d) => {
                    if (!dragging && d) {
                      setDragging(id);
                    } else if (dragging && dragging === id && !d) {
                      setDragging(undefined);
                    }
                  }}
                  valueDimension={f.valueDimension}
                  editing={!!editing}
                  hidden={hidden}
                  controlledBy={f.controlledBy}
                  additionalQueryFilters={getControlledFilterValue(f)}
                  autocompleteLimit={f.filterLimit}
                  tilesToUpdate={f.tilesToUpdate}
                  filterId={f.id}
                  onEdit={() => {
                    setSelected?.(f.id);
                    history.push(
                      routeDescriptor["reportFilterEdit"].renderRoute({
                        ...props.match.params,
                        filterSlug: f.slug,
                      })
                    );
                  }}
                  onRemove={onRemoveFilter}
                  componentType={f.componentType}
                  requireValue={f.requireValue}
                  selected={isSelected}
                  objectId={
                    flatExplorations.find(
                      (e) => e.generatedId === f.valueDimension
                    )?.explorationId || ""
                  }
                  objectType={"EXPLORATION"}
                  onChange={(v) => {
                    if (v !== undefined) {
                      setFilterStoreValue(f.id, {
                        value: v,
                        dimension: f.valueDimension,
                        type: f.type,
                        hidden: !!f.hidden,
                      });
                      refreshReport?.();
                    }
                  }}
                />
              );
            }

            return (
              <FieldFilter
                key={f.id}
                filterType={f.type}
                value={filterStore[f.id].value}
                defaultValue={defaultValue}
                filterName={f.name}
                labelDimension={f.labelDimension}
                valueDimension={f.valueDimension}
                editing={!!editing}
                hidden={hidden}
                controlledBy={f.controlledBy}
                additionalQueryFilters={getControlledFilterValue(f)}
                autocompleteLimit={f.filterLimit}
                tilesToUpdate={f.tilesToUpdate}
                onEdit={() => {
                  setSelected?.(f.id);
                  history.push(
                    routeDescriptor["reportFilterEdit"].renderRoute({
                      ...props.match.params,
                      filterSlug: f.slug,
                    })
                  );
                }}
                onRemove={onRemoveFilter}
                componentType={f.componentType}
                requireValue={f.requireValue}
                selected={isSelected}
                objectId={
                  flatExplorations.find(
                    (e) => e.generatedId === f.valueDimension
                  )?.explorationId || ""
                }
                objectType={"EXPLORATION"}
                onChange={(v) => {
                  if (v !== undefined) {
                    setFilterStoreValue(f.id, {
                      value: v,
                      dimension: f.valueDimension,
                      type: f.type,
                      hidden: !!f.hidden,
                    });
                    refreshReport?.();
                  }
                }}
              />
            );
          })}
      </Space>
    ) : undefined;
  };

  return (
    <>
      {canShowStickyNote && (
        <>
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              pointerEvents: inView ? "none" : undefined,
              opacity: inView ? 0 : 1,
              backgroundColor: "#FFF",
              zIndex: 2,
            }}
          >
            <div
              style={{
                padding: `12px 24px`,
                borderBottom: `1px solid ${BORDER_COLOR}`,
                display: "flex",
                height: 48,
                alignItems: "center",
              }}
            >
              <div style={{ flex: 1 }}>
                <Typography.Text strong>{report.name}</Typography.Text>
              </div>
              <div style={{ flex: `0 auto` }}>
                {" "}
                <Space direction="horizontal">
                  {hasFilters && numberOfDisplayedFilters > 0 && (
                    <Button
                      icon={<FilterOutlined />}
                      shape="round"
                      onClick={() => setShowSubFiltersInView((s) => !s)}
                    >
                      {showSubFiltersInView
                        ? "Hide filters"
                        : `Show ${numberOfDisplayedFilters} filters`}
                    </Button>
                  )}
                  {renderActions()}
                </Space>
              </div>
            </div>
            {showSubFiltersInView ? (
              <div
                style={{
                  padding: `12px 24px`,
                  borderBottom: `1px solid ${BORDER_COLOR}`,
                }}
              >
                {renderFilters()}
              </div>
            ) : undefined}
          </div>
        </>
      )}
      <div
        id={id}
        ref={ref}
        className={`dashboard-filters ${dragging ? "zone" : ""}`}
      >
        <div className="filters-content">
          <div className="filters-content-filter">{renderFilters()}</div>
          <div className="filters-content-actions">
            <Space direction="horizontal">{renderActions()}</Space>

            <ScheduleListDrawer
              report={props.report}
              visible={scheduleListDrawerOpened.opened}
              onClose={() =>
                setScheduleListDrawerOpened({
                  opened: false,
                })
              }
            />
            <ShareDrawer
              reportId={props.report.id}
              visible={shareDrawerOpened}
              setVisible={setShareDrawerOpened}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default compose<Props, IDashboardFiltersProps>(
  WithOrg,
  withRouter,
  withAntUtils
)(DashboardFilters);
