import type { DeepPartial } from "../../../../helpers/typescriptHelpers";
import type { IDestination } from "../../../../interfaces/destinations";
import type { IExploration } from "../../../../interfaces/explorations";
import type { IFileUpload } from "../../../../interfaces/fileUpload";
import type { IJobExecution } from "../../../../interfaces/jobExecutions";
import type { IObject } from "../../../../interfaces/object";
import type { IRadar } from "../../../../interfaces/radar";
import type { IDataset, ISource } from "../../../../interfaces/sources";
import type { SchemaResult } from "../../../../interfaces/transformations";
import type { TableTabItem } from "../../../spreadsheet/domain";
import type { IObjectLayoutFragment } from "../../single/domain";
import { reverseRelationshipType } from "../dataset/tabs/general/relationship/RelationshipLineRenderer";
import type {
  ExplorationStore,
  FetchedDestination,
  IActiveObject,
  IDatasetLineageGraph,
  ModifyQuery,
  ObjectStore,
  TabData,
  UpdateDestination,
  WorksheetStore,
} from "../domain";
import type { UpdateFileUploaderJobFunction } from "../selector/file-uploader/domain";
import type { UpdateObjectLayoutFunction } from "../selector/object-layouts/domain";
import type { UpdateObjectFunction } from "../selector/object/domain";
import type { UpdateRadarFunction } from "../selector/radar/domain";
import type { DatasetSavedData } from "../selector/tables/models/AddModelModal";
import ConnectorViewerWrapper from "./connector/ConnectorViewerWrapper";
import DataViewer from "./data/DataViewer";
import EmptyViewer from "./empty/EmptyViewer";
import ExplorationViewer from "./exploration/ExplorationViewer";
import FileUploaderViewer from "./file-uploader/viewer/FileUploaderViewer";
import JobViewer from "./job/JobViewer";
import { ObjectLayoutViewer } from "./object-layout/ObjectLayoutViewer";
import ObjectViewer from "./object/ObjectViewer";
import RadarViewer from "./radar/RadarViewer";
import SettingsViewer from "./settings/SettingsViewer";
import type { UpdateWorksheetData } from "./worksheet/WorksheetViewer";
import WorksheetViewer from "./worksheet/WorksheetViewer";

interface IViewerRendererProps {
  activeObject?: IActiveObject;
  updateActiveObject: (newActiveObject: IActiveObject) => void;
  dataStore: {
    [tableKey: string]: TabData;
  };
  updateExploration: (
    explorationId: string,
    payload: DeepPartial<IExploration>
  ) => Promise<void>;
  runDataExtract: (explorationId: string) => Promise<void>;
  datasets: IDataset[];
  objects: IObject[];
  destination: FetchedDestination;
  sources: ISource[];
  onCreateView?: (datasetId: string, viewName: string) => Promise<any>;
  onRenameView?: (viewId: string, viewName: string) => Promise<any>;
  onDeleteView?: (viewId: string, nextViewSlug: string) => Promise<any>;
  onCreateRelationship?: (relationshipQuery: any) => Promise<any>;
  onUpdateRelationship?: (id: string, relationshipQuery: any) => Promise<any>;
  onDeleteRelationship?: (id: string) => Promise<any>;
  onCreateDataset?: (datasetData: DatasetSavedData) => Promise<any>;
  onDeleteDataset?: (datasetId: string) => Promise<any>;
  onUpdateDataset?: (
    datasetId: string,
    a: {
      name?: string;
      description?: string;
      sql?: string;
      primaryKey?: string;
    }
  ) => Promise<any>;
  onDestinationUpdate?: UpdateDestination;
  onUpdateDrills?: (viewId: string, drills: string[]) => Promise<any>;
  onCreateSource?: (sourceName: string, sourceImage: string) => Promise<any>;
  onUpdateSource?: (
    sourceId: string,
    data: DeepPartial<ISource>
  ) => Promise<void>;
  routeRender: (params: object, query?: object) => string;
  mainTabs: TableTabItem[];
  fetchData: (
    warehouseId: string,
    activeDatasetId: string,
    activeViewId: string,
    increment?: boolean,
    fullReload?: boolean
  ) => Promise<void>;
  resetDatasetCache: (datasetId: string) => void;
  onModifyTransformation: (
    activeDataset: string,
    activeView: string
  ) => ModifyQuery;
  onFullscreenChange: (isFullscreen: boolean) => void;

  executionStore: {
    [identifier: string]: IJobExecution;
  };

  datasetLineageGraph: IDatasetLineageGraph;

  explorationStore: ExplorationStore;
  fetchExploration: (explorationId: string) => Promise<void>;
  fetchObject: (objectId: string) => Promise<void>;
  fetchObjectLayout: (objectId: string, layoutId: string) => Promise<void>;
  onUpdateObjectLayout: UpdateObjectLayoutFunction;
  createObjectLayout: (object: IObjectLayoutFragment) => string;
  fetchTableSchema: (
    tablesSchemas: Array<{
      datasetId: string;
      viewId: string;
      blendedDatasetId?: string;
    }>
  ) => Promise<SchemaResult[]>;
  onRefreshDatasets: (id?: string[]) => Promise<void>;
  onRefreshExplorations: (id?: string[]) => Promise<void>;

  worksheetStore: WorksheetStore;
  updateWorksheetData: UpdateWorksheetData;
  objectStore: ObjectStore;
  onUpdateObject: UpdateObjectFunction;
  currentWarehouse: IDestination;

  radars: IRadar[];
  onUpdateRadar: UpdateRadarFunction;

  fileUploaders: IFileUpload[];
  onUpdateFileUploader: UpdateFileUploaderJobFunction;
}

export function ViewerRenderer(props: IViewerRendererProps) {
  const {
    activeObject,
    updateActiveObject,
    dataStore,
    datasets,
    sources,
    objects,
    onCreateView,
    onRenameView,
    onDeleteView,
    onCreateRelationship,
    onUpdateRelationship,
    onDeleteRelationship,
    onCreateDataset,
    onDeleteDataset,
    onUpdateDataset,
    onUpdateDrills,
    onCreateSource,
    onUpdateSource,
    routeRender,
    mainTabs,
    fetchData,
    resetDatasetCache,
    onModifyTransformation,
    onFullscreenChange,
    executionStore,
    destination,
    datasetLineageGraph,
    explorationStore,
    fetchExploration,
    fetchTableSchema,
    updateExploration,
    runDataExtract,
    onRefreshDatasets,
    onRefreshExplorations,
    onDestinationUpdate,
    worksheetStore,
    updateWorksheetData,
    objectStore,
    onUpdateObject,
    currentWarehouse,
    fetchObject,
    fetchObjectLayout,
    onUpdateObjectLayout,
    createObjectLayout,
    radars,
    onUpdateRadar,
    fileUploaders,
    onUpdateFileUploader,
  } = props;

  if (!activeObject) {
    return <EmptyViewer />;
  }

  switch (activeObject.type) {
    case "dataset":
      return (
        <DataViewer
          activeObject={activeObject}
          updateActiveObject={updateActiveObject}
          store={dataStore}
          currentWarehouse={currentWarehouse}
          datasets={datasets}
          sources={sources}
          onCreateView={onCreateView}
          onRenameView={onRenameView}
          onDeleteView={onDeleteView}
          onCreateRelationship={onCreateRelationship}
          onUpdateRelationship={onUpdateRelationship}
          onDeleteRelationship={onDeleteRelationship}
          onCreateDataset={onCreateDataset}
          onDeleteDataset={onDeleteDataset}
          onUpdateDataset={onUpdateDataset}
          onUpdateDrills={onUpdateDrills}
          onCreateSource={onCreateSource}
          onUpdateSource={onUpdateSource}
          routeRender={routeRender}
          mainTabs={mainTabs}
          fetchData={fetchData}
          resetDatasetCache={resetDatasetCache}
          onModifyTransformation={onModifyTransformation}
          onFullscreenChange={onFullscreenChange}
          destination={destination}
          datasetLineageGraph={datasetLineageGraph}
          onRefreshDatasets={onRefreshDatasets}
          onRefreshExplorations={onRefreshExplorations}
        />
      );
    case "job":
      return (
        <JobViewer
          executionStore={executionStore}
          activeObject={activeObject}
        />
      );
    case "exploration":
      return (
        <ExplorationViewer
          activeObject={activeObject}
          explorationStore={explorationStore}
          fetchExploration={fetchExploration}
          dataStore={dataStore}
          fetchTableSchema={fetchTableSchema}
          currentWarehouse={currentWarehouse}
          allDatasets={datasets}
          updateExploration={updateExploration}
          runDataExtract={runDataExtract}
          allIncomingDatasetRelationships={datasets
            .flatMap((d) => [
              ...d.incomingRelationships,
              ...d.outgoingRelationships.map((r) => ({
                id: r.id,
                type: reverseRelationshipType(r.type),
                to: r.from,
                from: r.to,
                right: r.left,
                left: r.right,
                editable: r.editable,
              })),
            ])
            .filter((v, i, s) => s.map((a) => a.id).indexOf(v.id) === i)}
        />
      );
    case "worksheet":
      return (
        <WorksheetViewer
          key={activeObject?.value}
          activeObject={activeObject}
          datasets={datasets}
          worksheetStore={worksheetStore}
          updateWorksheetData={updateWorksheetData}
          onCreateDataset={onCreateDataset}
          currentWarehouse={currentWarehouse}
        />
      );
    case "object":
      return (
        <ObjectViewer
          key={activeObject?.value}
          fetchTableSchema={fetchTableSchema}
          activeObject={activeObject}
          dataStore={dataStore}
          currentWarehouse={currentWarehouse}
          objectStore={objectStore}
          fetchModelData={fetchData}
          fetchObject={fetchObject}
          onUpdateObject={onUpdateObject}
          onUpdateObjectLayout={onUpdateObjectLayout}
          createObjectLayout={createObjectLayout}
          allDatasets={datasets}
          allObjects={objects}
          allIncomingDatasetRelationships={datasets
            .flatMap((d) => [
              ...d.incomingRelationships,
              ...d.outgoingRelationships.map((r) => ({
                id: r.id,
                type: reverseRelationshipType(r.type),
                to: r.from,
                from: r.to,
                right: r.left,
                left: r.right,
                editable: r.editable,
              })),
            ])
            .filter((v, i, s) => s.map((a) => a.id).indexOf(v.id) === i)}
        />
      );
    case "object-layout":
      return (
        <ObjectLayoutViewer
          key={activeObject?.value}
          activeObject={activeObject}
          objectStore={objectStore}
          fetchObject={fetchObject}
          fetchObjectLayout={fetchObjectLayout}
          onUpdateObjectLayout={onUpdateObjectLayout}
          updateActiveObject={updateActiveObject}
          createObjectLayout={createObjectLayout}
          allDatasets={datasets}
        />
      );
    case "radar":
      const radarUpdateKey = radars.find(
        (r) => r.id === activeObject.value
      )?.updatedAt;
      return (
        <RadarViewer
          currentWarehouse={currentWarehouse}
          key={activeObject?.value}
          updateKey={radarUpdateKey}
          activeObject={activeObject}
          objectStore={objectStore}
          datasets={datasets}
          onUpdateRadar={onUpdateRadar}
        />
      );
    case "connector":
      return (
        <ConnectorViewerWrapper
          key={activeObject?.value}
          activeObject={activeObject}
          currentWarehouse={currentWarehouse}
        />
      );
    case "settings":
      return (
        <SettingsViewer
          activeObject={activeObject}
          destination={destination}
          onDestinationUpdate={onDestinationUpdate}
          currentWarehouse={currentWarehouse}
        />
      );
    case "uploader":
      const fileUploadJobUpdateKey = fileUploaders.find(
        (r) => r.id === activeObject.value
      )?.updatedAt;
      return (
        <FileUploaderViewer
          key={activeObject?.value}
          updateKey={fileUploadJobUpdateKey}
          activeObject={activeObject}
          currentWarehouse={currentWarehouse}
          onUpdateFileUploader={onUpdateFileUploader}
        />
      );
    default:
      return <div>unsupported</div>;
  }
}
