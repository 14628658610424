import { FullscreenOutlined } from "@ant-design/icons";
import type { SqlQuery } from "@cubejs-client/core";
import { Space, Typography } from "antd";
import type { Remote } from "comlink";
import { releaseProxy, wrap } from "comlink";
import { isEqual } from "lodash";
import { inject, observer } from "mobx-react";
import type { Moment } from "moment";
import moment from "moment";
import * as React from "react";
import { flushSync } from "react-dom";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import { compose } from "../../../components/compose/WlyCompose";
import type {
  AvailableDimension,
  AvailableMetric,
} from "../../../components/measures/filter-item/FilterItem";
import type { DeepPartial } from "../../../helpers/typescriptHelpers";
import type {
  IExploration,
  IExplorationVersion,
} from "../../../interfaces/explorations";
import type { IReport, ITile } from "../../../interfaces/reports";
import { IUserFeatureType } from "../../../interfaces/user";
import { track } from "../../../services/AnalyticsService";
import type { IQueryTraceResult } from "../../../services/LagoonService";
import { LagoonCallOrigin } from "../../../services/LagoonService";
import GraphQLService from "../../../services/graphql/GraphQLService";
import {
  applyFiltersToLagoonQuery,
  applyTimezoneToLagoonQuery,
  parseTileContent,
} from "../../../services/tileService";
import type { UserStoreProps } from "../../../store/userStore";
import type { CubeJSPivotWorker } from "../../../worker/main.worker";
import type { ILagoonQuery } from "../../exploration/single/domain";
import { DEFAULT_ROW_LIMIT } from "../../exploration/single/visualization/query-builder/domain";
import { queryData } from "../../exploration/single/visualization/queryData";
import type { InjectedOrgProps } from "../../orgs/WithOrg";
import WithOrg from "../../orgs/WithOrg";
import type { Store } from "./chart/card/ChartCard";
import { ReportConsole } from "./console/ReportConsole";
import DashboardWrapper from "./content/dashboard/DashboardWrapper";
import QuestionWrapper from "./content/question/QuestionWrapper";
import type {
  ActionType,
  DataMapStore,
  FilterMapStore,
  IFilterStore,
} from "./domain";
import { INITIAL_GQL_QUERY, updateCSS } from "./domain";
import { windowWidth } from "./grid/utils";

interface IReportContentProps {
  saving: (saving: boolean) => void;
  isSaving: boolean;
  isEmbedded: boolean;
  isPublic: boolean;
  isBeingPushed: boolean;
  isSharingLink: boolean;
  report: IReport;
  editing?: boolean;
  hideLayout?: boolean;
  hideFilters?: boolean;
  showTitle?: boolean;
  actionType?: ActionType;
  explorations: IExploration[];
  embededWorkbench?: boolean;
  disableDrills?: boolean;
  isDisplayedInWorkspace?: boolean;
}

type Props = IReportContentProps &
  RouteComponentProps<
    { tileSlug?: string; explorationSlug?: string },
    {},
    { scrollToBottom?: boolean }
  > &
  InjectedOrgProps &
  UserStoreProps;

interface IState {
  fetched?: Moment;
  hasLoaded: boolean;
  report: IReport;
  explorations: IExploration[];
  shouldUpdateQueries: boolean;
  data: DataMapStore;
  filters: FilterMapStore;
  windowWidth: number;
  isConsoleOpened: boolean | string;
}

const MAX_CONCURRENCY = 12;

class ReportContent extends React.Component<Props, IState> {
  mounted: boolean;
  originalWorker: Worker;
  worker: Remote<CubeJSPivotWorker>;

  constructor(props: Props) {
    super(props);
    this.originalWorker = new Worker(
      new URL("../../../worker/main.worker", import.meta.url),
      {
        name: "report-worker",
        type: "module",
      }
    );
    this.worker = wrap<CubeJSPivotWorker>(this.originalWorker);
    window.reportHasLoaded = false;
    this.mounted = true;

    const { report, explorations } = props;

    this.state = {
      report,
      explorations,
      hasLoaded: false,
      shouldUpdateQueries: false,
      windowWidth: windowWidth(),
      isConsoleOpened: false,
      data: report.tiles
        .filter((c) => c.type === "chart")
        .reduce<DataMapStore>((acc, c) => {
          const foundExploration = explorations.find(
            (ex) => ex.id === c.exploration.id
          );

          const store: Store = {
            query: parseTileContent(c.content),
            chartType: c.chartType,
            data: { status: "loading" },
            additionalResults: { status: "loading" },
            trace: { status: "loading" },
            hasUpstreamErrors: !!foundExploration?.hasUpstreamErrors,
            foundExploration: foundExploration,
            explorationId: c.exploration.id,
            availableMetrics: this.getAvailableMetrics(c, explorations),
            availableDimensions: this.getAvailableDimension(c, explorations),
            raw: c,
            meta: {
              status: "initial",
            },
            updatedAt: Date.now().toString(),
          };

          return {
            ...acc,
            [c.id]: store,
          };
        }, {}),

      filters: report.filters.reduce<{ [key: string]: IFilterStore }>(
        (a, f) => {
          return {
            ...a,
            [f.id]: {
              type: f.type,
              dimension: f.valueDimension,
              value: Array.isArray(f.defaultValue)
                ? f.defaultValue
                : [f.defaultValue],
              hidden: !!f.hidden,
            },
          };
        },
        {}
      ),
    };
  }

  // based on https://github.com/rxaviers/async-pool
  asyncPool = async function* (concurrency, iterable, iteratorFn) {
    const executing = new Set<Promise<any>>();
    async function consume() {
      const [promise, value] = await Promise.race(executing);
      executing.delete(promise);
      return value;
    }
    for (const item of iterable) {
      // Wrap iteratorFn() in an async fn to ensure we get a promise.
      // Then expose such promise, so it's possible to later reference and
      // remove it from the executing pool.
      if (!this.mounted) return;
      const promise = (async () => await iteratorFn(item, iterable))().then(
        (value) => [promise, value]
      );
      executing.add(promise);
      if (executing.size >= concurrency) {
        yield await consume();
      }
    }

    while (executing.size) {
      yield await consume();
    }
  };

  fetchDataInBatch = async (keys: string[], firstRun: boolean) => {
    const { report } = this.props;
    const { data } = this.state;

    const dataIterator = (k) =>
      this.fetchData(
        k,
        data[k].explorationId,
        data[k].overrideQuery ?? data[k].query
      );
    const consoleIterator = this.fetchConsoleData;

    const sortedKeys = keys.sort((a, b) => {
      const aTile = report.tiles.find((t) => t.id === a);
      const bTile = report.tiles.find((t) => t.id === b);

      if (!aTile || !bTile) {
        return 0;
      }
      if (!isNaN(aTile.top) && !isNaN(bTile.top)) {
        return aTile.top - bTile.top;
      }
      if (!isNaN(aTile.left) && !isNaN(bTile.left)) {
        return aTile.left - bTile.left;
      }
      return 0;
    });

    // we set all the tiles to loading
    if (!firstRun) {
      flushSync(() => {
        this.setState((prevState) => {
          const data = keys.reduce<DataMapStore>((acc, key) => {
            const store: Store = {
              ...prevState.data[key],
              data: {
                status: "loading",
                cache:
                  prevState.data[key].data.status === "success"
                    ? (prevState.data[key].data as any).data
                    : undefined,
              },
              additionalResults: {
                status: "loading",
              },
              trace: {
                status: "loading",
              },
              updatedAt: Date.now().toString(),
            };

            return {
              ...acc,
              [key]: store,
            };
          }, {});

          return { data: { ...prevState.data, ...data } };
        });
      });
    }

    // we fetch the chart data and update their states
    for await (const i of this.asyncPool(
      MAX_CONCURRENCY,
      sortedKeys,
      dataIterator
    )) {
      // do nothing with promise results
      // as it's already taken care of in fetchData
    }

    // we fetch the console data and store it in the results
    // we all is retrieved we update the state
    const results: Array<{ [key: string]: Partial<Store> }>[] = [];

    for await (const i of this.asyncPool(
      MAX_CONCURRENCY,
      sortedKeys,
      consoleIterator
    )) {
      results.push(i);
    }
    const partialStores = results.reduce<{ [key: string]: Partial<Store> }>(
      (acc, c) => {
        const key = Object.keys(c)[0];
        return {
          ...acc,
          [key]: {
            ...c[key],
          },
        };
      },
      {}
    );
    flushSync(() => {
      this.setState((prevState) => {
        const data = Object.keys(prevState.data ?? {}).reduce((acc, c) => {
          return {
            ...acc,
            [c]: {
              ...prevState.data[c],
              ...partialStores[c],
              updatedAt: Date.now().toString(),
            },
          };
        }, {});
        return { data: { ...prevState.data, ...data } };
      });
    });
  };

  componentDidMount() {
    const exec = async () => {
      await this.fetchDataInBatch(Object.keys(this.state.data), true);
    };
    exec();

    const buildContext = () => {
      if (this.props.isEmbedded) {
        return "EMBEDDED";
      }
      if (this.props.isBeingPushed) {
        return "PUSH";
      }
      if (this.props.isPublic) {
        return "PUBLIC";
      }
      if (this.props.isSharingLink) {
        return "SHARING_LINK";
      }
      return "IN_APP";
    };

    track("Report Viewed", {
      id: this.props.report.id,
      type: this.props.report.type,
      context: buildContext(),
    });

    this.props.userStore.registerViewedReport(
      this.props.org.id,
      this.props.report.id
    );

    window.addEventListener("resize", this.setWindowWidth);
  }

  setWindowWidth = () => {
    const width = windowWidth();
    if (width !== this.state.windowWidth) {
      this.setState({
        windowWidth: width,
      });
    }
  };

  componentDidUpdate(prevProps: Props, prevState: IState) {
    if (!this.state.hasLoaded) {
      const stillRunning: boolean[] = [];
      Object.keys(this.state.data).forEach((k) => {
        if (
          this.state.data[k].data.status === "initial" ||
          this.state.data[k].data.status === "loading"
        ) {
          stillRunning.push(true);
        } else if (this.state.data[k].data.status === "success") {
          if (
            this.state.data[k].meta &&
            ["initial", "loading"].includes(this.state.data[k].meta?.status)
          ) {
            stillRunning.push(true);
          } else {
            stillRunning.push(false);
          }
        } else if (this.state.data[k].data.status === "error") {
          stillRunning.push(false);
        }
      });

      if (!stillRunning.includes(true) && !this.state.hasLoaded) {
        flushSync(() => {
          this.setState(
            {
              hasLoaded: true,
            },
            () => {
              window.dataMapStore = this.state.data;
              window.reportHasLoaded = true;
            }
          );
        });
      }
    }

    if (
      !isEqual(prevState.report?.id, this.state.report?.id) ||
      !isEqual(
        prevState.report?.reportOptions,
        this.state.report?.reportOptions
      )
    ) {
      updateCSS(this.state.report?.reportOptions);
    }
  }

  componentWillUnmount(): void {
    this.worker[releaseProxy]();
    this.originalWorker.terminate();
    this.mounted = false;
    window.removeEventListener("resize", this.setWindowWidth);
    flushSync(() => {
      this.setState({});
    });
  }

  getAvailableDimension = (tile: ITile, explorations: IExploration[]) => {
    const currentExploration = explorations.find(
      (e) => e.id === tile.exploration.id
    );
    if (currentExploration) {
      const tableEnhancedDimensions: Array<AvailableDimension> =
        currentExploration.tables.flatMap((t) => {
          return t.dimensions.map((d) => ({
            key: `${t.cubeName}.${d.cubeName}`,
            description: d.description,
            label: d.name,
            tableName: t.name,
            type: d.type,
            domain: undefined as any,
          }));
        }) as Array<AvailableDimension>;
      return tableEnhancedDimensions;
    }
    return [];
  };

  getAvailableMetrics = (tile: ITile, explorations: IExploration[]) => {
    const currentExploration = explorations.find(
      (e) => e.id === tile.exploration.id
    );
    if (currentExploration) {
      const enhancedMetrics =
        currentExploration.tables.flatMap<AvailableMetric>((t) => {
          return t.metrics.map((d) => {
            return {
              key: `${t.cubeName}.${d.cubeName}`,
              label: d.name,
              description: d.description,
              formatter: {
                suffix: d.suffix,
                prefix: d.prefix,
                format: d.format,
                customFormatting: d.overrideFormatting,
              },
            };
          });
        });
      return enhancedMetrics;
    }
    return [];
  };

  reloadReport = async (slug: string, orgId: string) => {
    const { allReports } = await GraphQLService<{ allReports: IReport[] }>(
      INITIAL_GQL_QUERY,
      {
        slug,
        orgId,
      }
    );

    if (allReports.length !== 1) throw new Error("NOT_FOUND");

    const report = allReports[0];
    const { explorations, data, filters } = this.state;

    flushSync(() => {
      this.setState({
        report,
        data: report.tiles
          .filter(({ type }) => type === "chart")
          .reduce<DataMapStore>((acc, current) => {
            const { id, exploration, content, chartType } = current;
            const foundExploration = explorations.find(
              ({ id }) => id === exploration.id
            );

            const store: Store = {
              query: parseTileContent(content),
              chartType,
              explorationId: exploration.id,
              hasUpstreamErrors: !!foundExploration?.hasUpstreamErrors,
              foundExploration,
              data: data[id]?.data ?? { status: "initial" },
              additionalResults: data[id]?.additionalResults ?? {
                status: "initial",
              },
              availableMetrics: this.getAvailableMetrics(current, explorations),
              availableDimensions: this.getAvailableDimension(
                current,
                explorations
              ),
              raw: current,
              updatedAt: Date.now().toString(),
            };

            return {
              ...acc,
              [id]: store,
            };
          }, {}),
        filters: report.filters.reduce<FilterMapStore>((acc, current) => {
          const { id, valueDimension, type, hidden, defaultValue } = current;

          return {
            ...acc,
            [id]: {
              value: filters[id]?.value ?? defaultValue,
              dimension: valueDimension,
              type,
              hidden,
            },
          };
        }, {}),
      });
    });
  };

  refreshReport = () => {
    flushSync(() => {
      this.setState(
        {
          shouldUpdateQueries: false,
          data: Object.keys(this.state.data).reduce<{ [key: string]: Store }>(
            (a, v) => {
              return {
                ...a,
                [v]: {
                  ...this.state.data[v],
                  data: { status: "loading" },
                  additionalResults: { status: "loading" },
                },
              };
            },
            {}
          ),
        },
        () => {
          this.fetchDataInBatch(Object.keys(this.state.data), false);
        }
      );
    });
  };

  onOpenConsole = (c) => {
    flushSync(() => {
      this.setState({ isConsoleOpened: c ? c : true });
    });
  };

  onCloseConsole = () => {
    flushSync(() => {
      this.setState({ isConsoleOpened: false });
    });
  };

  setExplorations = (e) => {
    flushSync(() => {
      this.setState({ explorations: e });
    });
  };

  setReport = (r) => {
    flushSync(() => {
      this.setState({ report: r });
    });
  };

  setFilterStoreValue = (id, data) => {
    flushSync(() => {
      this.setState((prevState) => ({
        filters: {
          ...prevState.filters,
          [id]: {
            ...prevState.filters[id],
            ...data,
          },
        },
      }));
    });
  };

  setDataStoreValue = (id: string, data: Partial<Store>) => {
    flushSync(() => {
      this.setState((prevState) => ({
        data: {
          ...prevState.data,
          [id]: {
            ...prevState.data[id],
            ...data,
            updatedAt: Date.now().toString(),
          },
        },
      }));
    });
  };

  forceCacheRefresh = async (forceRefresh: boolean) => {
    flushSync(() => {
      this.setState(
        {
          shouldUpdateQueries: false,
          data: Object.keys(this.state.data).reduce<{ [key: string]: Store }>(
            (a, v) => {
              return {
                ...a,
                [v]: {
                  ...this.state.data[v],
                  data: { status: "loading" },
                },
              };
            },
            {}
          ),
        },
        () => {
          if (forceRefresh) {
            const explorationVersions: IExplorationVersion[] =
              this.state.report.tiles
                .filter((t) => t.type === "chart")
                .map((t) => {
                  return t.exploration.version;
                })
                .filter((v) => typeof v?.id === "string");

            GraphQLService(
              `
                  mutation refreshExplorationKeys($data: [ExplorationVersionsUpdateInput]!) {
                    updateExplorationVersions(data: $data) {
                      id
                    }
                  }
                  `,
              {
                data: explorationVersions.map((v) => {
                  return {
                    id: v.id,
                    data: {
                      value: Math.round(Date.now() / 1000),
                    },
                  };
                }),
              }
            ).then(() => {
              this.fetchDataInBatch(Object.keys(this.state.data), false);
            });
          } else {
            this.fetchDataInBatch(Object.keys(this.state.data), false);
          }
        }
      );
    });
  };

  fetchData = async (
    key: string,
    explorationId: string,
    baseQuery: ILagoonQuery
  ) => {
    try {
      const { report, filters, explorations } = this.state;
      const {
        userFeatures,
        isBeingPushed,
        org,
        report: propsReport,
      } = this.props;
      const tile = report.tiles.find((t) => t.id === key);
      const shouldUseLagoonNext = userFeatures.includes(
        IUserFeatureType.TMP_USE_LAGOON_NEXT
      );

      if (!tile) {
        throw new Error("No tile found");
      }

      let query = applyTimezoneToLagoonQuery(
        baseQuery,
        report.timezoneOverride
      );
      query = applyFiltersToLagoonQuery(
        query,
        report.filters,
        filters,
        tile.id
      );

      const { resultSet, additionalQueryResultSet, getSQL, getTrace } =
        await queryData({
          orgId: org.id,
          objectId: explorationId,
          objectType: "EXPLORATION",
          analysisType: query.analysisType,
          chartType: tile.chartType,
          measures: query.selectedMeasures,
          dimensions: query.selectedDimensions,
          filters: query.filters,
          filterOperator: "and",
          dateRange: query.dateRange,
          orderBy: query.orderBy ?? [],
          limit: query.limit ?? DEFAULT_ROW_LIMIT,
          showOther: query.showOther ?? false,
          showOtherDimensionLimit: query.showOtherDimensionLimit ?? 5,
          origin: isBeingPushed
            ? LagoonCallOrigin.PUSH
            : LagoonCallOrigin.WHALY_APP,
          comparisonPeriod: query.comparison,
          timeDimension: query.selectedTime,
          selectedGranularity: query.selectedGranularity,
          pivotConfig: query.pivotDimensions,
          extra: query.extra,
          metricFilters: query.metricFilters,
          metricFilterOperator: query.metricFilterOperator,
          reportId: propsReport.id,
          timezone: query.timezone,
          useLagoonNext: shouldUseLagoonNext,
        });

      const tableEnhancedDimensions = this.getAvailableDimension(
        tile,
        explorations
      );
      const enhancedMetrics = this.getAvailableMetrics(tile, explorations);

      flushSync(() => {
        this.setState((prevState) => ({
          fetched:
            (prevState.fetched && moment().isAfter(prevState.fetched)) ||
            !prevState.fetched
              ? moment()
              : prevState.fetched,
          data: {
            ...prevState.data,
            [key]: {
              ...prevState.data[key],
              availableDimensions: tableEnhancedDimensions,
              availableMetrics: enhancedMetrics,
              additionalResults: {
                status: "success",
                data: additionalQueryResultSet,
              },
              data: {
                status: "success",
                data: resultSet,
              },
              trace: {
                status: "loading",
              },
              getTrace,
              getSQL,
              updatedAt: Date.now().toString(),
            } as Store,
          },
        }));
      });
    } catch (err) {
      console.error("lagoon error - ", err);
      flushSync(() => {
        this.setState((prevState) => ({
          fetched: moment(),
          data: {
            ...prevState.data,
            [key]: {
              ...prevState.data[key],
              data: {
                status: "error",
                error: err as any,
              },
              additionalResults: {
                status: "error",
                error: err as any,
              },
              updatedAt: Date.now().toString(),
            },
          },
        }));
      });
    }
  };

  fetchConsoleData = async (
    key: string
  ): Promise<DeepPartial<DataMapStore>> => {
    try {
      const tile = this.state.report.tiles.find((t) => t.id === key);
      if (!tile) {
        throw new Error("no tile found");
      }

      const results = await Promise.all([
        typeof this.state.data[key]?.getSQL === "function"
          ? this.state.data[key].getSQL()
          : new Promise((_, reject) => reject("not found")),
        typeof this.state.data[key]?.getTrace === "function"
          ? this.state.data[key].getTrace()
          : new Promise((_, reject) => reject("not found")),
      ]);

      return {
        [key]: {
          sql: results[0] as SqlQuery | undefined,
          trace: {
            status: "success",
            data: results[1] as IQueryTraceResult,
          },
        },
      };
    } catch (err) {
      console.error("lagoon error - ", err);
      return {
        [key]: {
          trace: {
            status: "error",
            error: err,
          },
        },
      };
    }
  };

  public onTileRefresh = (tileId: string, overrideQuery?: ILagoonQuery) => {
    if (overrideQuery) {
      this.setDataStoreValue(tileId, {
        overrideQuery: overrideQuery,
      });
    }
    this.fetchDataInBatch([tileId], false);
  };

  public render() {
    const { editing } = this.props;
    const { report, explorations, windowWidth, isConsoleOpened, data } =
      this.state;

    return (
      <div className="report-container">
        {windowWidth < 1177 && editing && report.type === "DASHBOARD" ? (
          <div className="browser-too-small">
            <div className="block-box">
              <Space direction="vertical">
                <FullscreenOutlined style={{ color: "red", fontSize: 36 }} />
                <Typography.Title
                  style={{ margin: 0, color: "white" }}
                  level={3}
                >
                  Your browser is too small.
                </Typography.Title>
                <Typography.Text
                  style={{ margin: 0, color: "rgb(197, 197, 197)" }}
                >
                  Resize your browser to be more than 1177px wide to get back
                  into edit mode.
                </Typography.Text>
              </Space>
            </div>
          </div>
        ) : null}
        {report.type === "DASHBOARD" ? (
          <DashboardWrapper
            isEmbedded={this.props.isEmbedded}
            isSharingLink={this.props.isSharingLink}
            disableNavigationItems={
              this.props.isPublic || this.props.isSharingLink
            }
            hideLayout={this.props.hideLayout}
            editing={editing}
            report={this.state.report}
            fetched={this.state.fetched}
            explorations={explorations}
            refreshReport={this.refreshReport}
            forceCacheRefresh={this.forceCacheRefresh}
            onOpenConsole={this.onOpenConsole}
            isDisplayedInWorkspace={!!this.props.isDisplayedInWorkspace}
            actionType={this.props.actionType}
            reloadReport={this.reloadReport}
            saving={this.props.saving}
            showTitle={this.props.showTitle}
            setExplorations={this.setExplorations}
            setReport={this.setReport}
            filterStore={this.state.filters}
            setFilterStoreValue={this.setFilterStoreValue}
            disableDrills={this.props.disableDrills}
            dataStore={this.state.data}
            setDataStoreValue={this.setDataStoreValue}
            hideFilters={this.props.hideFilters}
            embededWorkbench={this.props.embededWorkbench}
            isBeingPushed={this.props.isBeingPushed}
            externalWorker={this.worker}
            onRefresh={this.onTileRefresh}
          />
        ) : (
          <QuestionWrapper
            hideLayout={this.props.hideLayout}
            editing={editing}
            report={this.state.report}
            refreshReport={this.refreshReport}
            fetched={this.state.fetched}
            forceCacheRefresh={this.forceCacheRefresh}
            explorations={explorations}
            setExplorations={this.setExplorations}
            dataStore={this.state.data}
            reloadReport={this.reloadReport}
            saving={this.props.saving}
            setReport={this.setReport}
            actionType={this.props.actionType}
            disableDrills={this.props.disableDrills}
            onOpenConsole={this.onOpenConsole}
            isDisplayedInWorkspace={this.props.isDisplayedInWorkspace}
            setDataStoreValue={this.setDataStoreValue}
            embededWorkbench={this.props.embededWorkbench}
          />
        )}
        <ReportConsole
          visible={!!isConsoleOpened}
          onClose={this.onCloseConsole}
          data={data}
          initialSelectedItem={
            typeof this.state.isConsoleOpened === "string"
              ? this.state.isConsoleOpened
              : undefined
          }
        />
      </div>
    );
  }
}

export default compose<Props, IReportContentProps>(
  withRouter,
  WithOrg
)(inject("userStore")(observer(ReportContent)));
