import { MenuOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { Modal, Tooltip, Transfer, Typography } from "antd";
import { union } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Emoji } from "../../../components/form/emoji-picker/Emoji";
import Loading from "../../../components/layout/feedback/loading";
import type { SchemaResult } from "../../../interfaces/transformations";
import TypeRenderer from "../renderer/TypeRenderer";
import "./DrillsModal.scss";

const { Text } = Typography;

interface IDrillsModalProps {
  visible: boolean;
  onClose: () => void;
  schemaLoading: boolean;
  drills: string[];
  primaryKeys: string[];
  schema: SchemaResult;
  onUpdate: (drills: string[]) => Promise<any>;
}

interface RecordType {
  key: string;
  disabled: boolean;
}

export default function DrillsModal({
  visible,
  onClose,
  schemaLoading,
  drills,
  primaryKeys,
  schema,
  onUpdate,
}: IDrillsModalProps) {
  const dataSource: RecordType[] = union(drills, Object.keys(schema)).map(
    (key) => ({
      key,
      disabled: primaryKeys.includes(key) && !!schema[key],
    })
  );

  const [targetKeys, setTargetKeys] = useState<string[]>(
    union(drills, primaryKeys)
  );
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const resetState = useCallback(() => {
    setTargetKeys(union(drills, primaryKeys));
    setSelectedKeys([]);
  }, [drills, primaryKeys]);

  useEffect(() => resetState(), [drills, primaryKeys, resetState]);

  const filterOption = (inputValue: string, option: RecordType) =>
    option.key.indexOf(inputValue) > -1;

  const onSelectChange = (
    sourceSelectedKeys: string[],
    targetSelectedKeys: string[]
  ) => setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);

  const moveRow = async (dragIndex: number, hoverIndex: number) => {
    const clonedList = targetKeys;
    const el = clonedList.splice(dragIndex, 1)[0];
    clonedList.splice(hoverIndex, 0, el);
    setTargetKeys(clonedList);
  };

  return (
    <Modal
      title="Configure your drill down"
      className="drill-modal"
      open={visible}
      width={850}
      onCancel={() => {
        setLoading(false);
        resetState();
        onClose();
      }}
      cancelButtonProps={{ disabled: loading }}
      closable={!loading}
      maskClosable={!loading}
      confirmLoading={loading}
      onOk={() => {
        setLoading(true);
        onUpdate(targetKeys).then(() => {
          setLoading(false);
          onClose();
        });
      }}
      destroyOnClose
    >
      <div>
        <Text>
          Select the columns you want to display in your drill down. It is
          recommended to <b>not select more than 8 columns</b> to make sure the
          drill stays readable.
        </Text>
        {schemaLoading ? (
          <div style={{ padding: "56px 0" }}>
            <Loading />
          </div>
        ) : (
          <DndProvider backend={HTML5Backend}>
            <Transfer
              dataSource={dataSource}
              titles={["Available columns", "Drill columns"]}
              showSearch
              filterOption={filterOption}
              targetKeys={targetKeys}
              selectedKeys={selectedKeys}
              onChange={(keys) => setTargetKeys(keys)}
              onSelectChange={onSelectChange}
              render={(item) => (
                <DraggableItem
                  index={targetKeys.findIndex((key) => key === item.key)}
                  label={item.key}
                  primaryKeys={primaryKeys}
                  schema={schema}
                  moveRow={moveRow}
                />
              )}
              style={{ marginTop: 20 }}
              listStyle={{
                width: 300,
                height: 345,
                flex: 1,
              }}
            />
          </DndProvider>
        )}
      </div>
    </Modal>
  );
}

type DraggableItemProps = {
  index: number;
  label: string;
  primaryKeys: string[];
  schema: SchemaResult;
  moveRow: (indexOne: number, indexTo: number) => void;
};
const DraggableItem = ({
  index,
  label,
  primaryKeys,
  schema,
  moveRow,
}: DraggableItemProps) => {
  const type = "DraggableItem";
  const ref = useRef<HTMLDivElement>(null);

  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: (monitor: any) => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName:
          dragIndex < index ? ` drop-over-downward` : ` drop-over-upward`,
      };
    },
    drop: (item: { index: number }) => {
      moveRow(item.index, index);
    },
  });

  const [, drag, preview] = useDrag({
    type,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  preview(drop(ref));

  return (
    <div
      ref={ref}
      key={label}
      className={`item-wrapper ${isOver ? dropClassName : ""}`}
    >
      <span
        className={`label ${primaryKeys.includes(label) ? "primary-key" : ""}`}
      >
        {schema[label] ? (
          <TypeRenderer
            domain={schema[label].domain}
            formula={schema[label].type === "FORMULA"}
          />
        ) : (
          <div style={{ height: 14, display: "inline-block", marginRight: 3 }}>
            <Emoji emoji={":warning:"} size={14} />
          </div>
        )}{" "}
        {label}{" "}
        {primaryKeys.includes(label) ? (
          <span style={{ color: "gray", paddingLeft: 5 }}>
            <Tooltip title="Primary keys should always be included in drills">
              <QuestionCircleOutlined />
            </Tooltip>
          </span>
        ) : undefined}
        {!schema[label] ? (
          <span style={{ color: "gray", paddingLeft: 5 }}>
            <Tooltip title="This column does not exist anymore. You should probably remove it from the drill.">
              <QuestionCircleOutlined />
            </Tooltip>
          </span>
        ) : undefined}
      </span>
      {index !== -1 && (
        <span className="drag-icon" ref={drag}>
          <MenuOutlined />
        </span>
      )}
    </div>
  );
};
