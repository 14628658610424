import { ExclamationCircleOutlined, MoreOutlined } from "@ant-design/icons";
import type { MenuProps } from "antd";
import { Button, Drawer, Dropdown, Space, Tag, message } from "antd";
import type { ColumnProps } from "antd/lib/table";
import * as React from "react";
import type { IUserRole, IUserRoleWithGroups } from "../../../interfaces/user";
import { IUserRoleType } from "../../../interfaces/user";

import { inject, observer } from "mobx-react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import type { InjectedAntUtilsProps } from "../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../components/ant-utils/withAntUtils";
import { compose } from "../../../components/compose/WlyCompose";
import CardTable from "../../../components/table/CardTable";
import { UserRenderer } from "../../../components/user/UserRenderer";
import type { IUserGroup } from "../../../interfaces/group";
import type { INotificationWorkflow } from "../../../interfaces/notification";
import type { IOrg, IPartnerPortal } from "../../../interfaces/org";
import { IUserRealmType } from "../../../interfaces/realm";
import type { ITopic } from "../../../interfaces/topic";
import GraphQLService from "../../../services/graphql/GraphQLService";
import type { UserStoreProps } from "../../../store/userStore";
import type { InjectedOrgProps } from "../../orgs/WithOrg";
import WithOrg from "../../orgs/WithOrg";
import { hasRoleAccessBoolean } from "../../user-settings/HasRoleAccess";
import "./AccessManagement.scss";
import EditUserForm from "./EditUserForm";
import AccessManagementForm from "./NewUserForm";
import BulkUpload from "./bulk/BulkUpload";
import { UPDATE_USER } from "./domain";

interface IAccessManagementTableProps {
  users: IUserRoleWithGroups[];
  currentOrg: IOrg;
  reRender: () => void;
  portal?: IPartnerPortal;
  topics: ITopic[];
  workflows: INotificationWorkflow[];
}

interface IState {
  open: boolean;
  bulkOpen: boolean;
  selectedUserRole?: IUserRoleWithGroups;
}

type Props = IAccessManagementTableProps &
  RouteComponentProps<{ organizationSlug: string }> &
  InjectedAntUtilsProps &
  InjectedOrgProps &
  UserStoreProps;

class AccessManagementTable extends React.Component<Props, IState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      open: false,
      bulkOpen: false,
    };
  }

  public showDrawer = () => {
    this.setState({ open: true });
  };

  public hideDrawer = () => {
    this.setState({ open: false });
  };

  public onSubmit = () => {
    const { reRender } = this.props;
    reRender();
  };

  public deleteUserRole = (userRole: IUserRole) => {
    const { antUtils } = this.props;
    return antUtils.modal.confirm({
      title: `Are your sure you want to delete this user?`,
      icon: <ExclamationCircleOutlined />,
      content: `By deleting this user, ${userRole.user.email} will not be able to access your organization anymore.`,
      okText: "Delete",
      okButtonProps: {
        danger: true,
      },
      cancelText: "Cancel",
      onOk: async () => {
        try {
          await GraphQLService(
            `
          mutation DeleteUserRole(
            $userRoleId: ID!,
          ) {
            updateUserRole(id: $userRoleId, data: {isDeleted: true}) {
              id
            }
          }`,
            { userRoleId: userRole.id }
          );
          this.props.reRender();
          antUtils.message.success("User successfully deleted");
        } catch (err) {
          antUtils.message.error("An unexpected error happened, please retry");
        }
      },
      onCancel() {
        return null;
      },
    });
  };

  public deleteUserGroup = async (userGroup: IUserGroup) => {
    try {
      await GraphQLService(
        `
          mutation DeleteUserGroup(
            $userGroupId: ID!,
          ) {
            updateUserGroup(id: $userGroupId, data: {isDeleted: true}) {
              id
            }
          }`,
        { userGroupId: userGroup.id }
      );
    } catch (err) {
      message.error("An unexpected error happened, please retry");
    }
  };

  public createUserSubscription = async (options: {
    userId: string;
    orgId: string;
    topicId: string;
    workflowId: string;
  }) => {
    try {
      await GraphQLService(
        `
          mutation CreateUserTopicSubscription(
            $userId: ID!
            $topicId: ID!
            $workflowId: ID!
            $orgId: ID!
          ) {
            createUserTopicSubscription(
              data: {
                user: { connect: { id: $userId } }
                topic: { connect: { id: $topicId } }
                workflow: { connect: { id: $workflowId } }
                org: { connect: { id: $orgId } }
              }
            ) {
              id
            }
          }
        `,
        options
      );
    } catch (err) {
      message.error("An unexpected error happened, please retry");
    }
  };

  public createUserGroup = async (
    userId: string,
    groupId: string,
    orgId: string
  ) => {
    try {
      await GraphQLService(
        `
      mutation CreateUserGroup(
        $userId: ID!,
        $groupId: ID!,
        $orgId: ID!
      ) {
        createUserGroupsWithACL(data: [
        {
        data: {
          user: {
            connect: {
              id: $userId
            }
          }
          group: {
            connect: {
              id: $groupId
            }
          }
          org: {
            connect: {
              id: $orgId
            }
          }
      }
      }])
      }`,
        { userId, groupId, orgId }
      );
    } catch (err) {
      message.error("An unexpected error happened, please retry");
    }
  };

  createSudoRequest = async (targetUserId: string) => {
    const { portal } = this.props;
    const sudoRequestCreateResult = await GraphQLService<{
      createSudoRequestWithACL: {
        token: string;
      };
    }>(
      `
      mutation CreateSudoRequest($targetUserId:ID!) {
        createSudoRequestWithACL(targetUserId: $targetUserId) {
          token
        }
      }
    `,
      {
        targetUserId,
      }
    );

    if (sudoRequestCreateResult.createSudoRequestWithACL.token) {
      if (portal) {
        const urlToRedirectTo = `${window.WHALY_CONSTANTS.PARTNER_PORTAL_TEMPLATE_URL.replace(
          "{PORTAL_SLUG}",
          portal.slug
        )}/auth/api/sudo/${
          sudoRequestCreateResult.createSudoRequestWithACL.token
        }`;
        window.location.replace(urlToRedirectTo);
      } else {
        const urlToRedirectTo = `${window.WHALY_CONSTANTS.LOGIN_APP_URL}/sudo/${sudoRequestCreateResult.createSudoRequestWithACL.token}`;
        window.location.replace(urlToRedirectTo);
      }
    }
  };

  public copyInviteLink = (invite: IUserRole) => {
    const { antUtils, portal } = this.props;
    if (!invite?.user?.signUpToken) {
      return;
    }
    if (portal) {
      navigator.clipboard.writeText(
        `${window.WHALY_CONSTANTS.PARTNER_PORTAL_TEMPLATE_URL.replace(
          "{PORTAL_SLUG}",
          portal.slug
        )}/auth/public/signup?email=${encodeURIComponent(
          invite?.user.email
        )}&token=${encodeURIComponent(invite?.user?.signUpToken)}`
      );
    } else {
      navigator.clipboard.writeText(
        `${
          window.WHALY_CONSTANTS.APP_URL
        }/auth/signup?email=${encodeURIComponent(
          invite?.user.email
        )}&token=${encodeURIComponent(invite?.user?.signUpToken)}`
      );
    }

    antUtils.message.info("Invite link copied to your clipboard");
  };

  public sendInviteEmail = async (userId: string) => {
    const { antUtils, portal, org } = this.props;

    const l = antUtils.message.loading("Sending email...", 0);
    try {
      l();
      const d = await GraphQLService(
        `
      mutation sendInviteEmail($orgId: ID!, $userId: ID!) {
        sendInviteEmail(orgId: $orgId, userId: $userId)
      }
      `,
        {
          userId,
          orgId: org.id,
        }
      );
      if (!d) {
        throw new Error("Coulnd't send email");
      }
      antUtils.message.success("Sucessfully sent invite email");
    } catch (err) {
      console.error(err);
      l();
      antUtils.message.error("Error while sending your email invite");
    }
  };

  public renderRole = (role: IUserRoleType) => {
    switch (role) {
      case IUserRoleType.BUILDER:
        return <div>Builder</div>;
      case IUserRoleType.ADMIN_BUILDER:
        return <div>Admin builder</div>;
      case IUserRoleType.EDITOR:
        return <div>Editor</div>;
      case IUserRoleType.REPORT_DOWNLOADER:
        return <div>Report Downloader</div>;
      case IUserRoleType.REPORT_VIEWER:
        return <div>Report Viewer</div>;
      case IUserRoleType.VIEWER:
        return <div>Viewer</div>;
    }
  };

  public renderLicense = (role: IUserRoleType, isWhalyUser: boolean) => {
    if (isWhalyUser) {
      // we give our user for free
      return <div>Free</div>;
    }
    switch (role) {
      case IUserRoleType.BUILDER:
        return <div>Builder</div>;
      case IUserRoleType.ADMIN_BUILDER:
        return <div>Builder</div>;
      case IUserRoleType.EDITOR:
        return <div>Editor</div>;
      case IUserRoleType.REPORT_VIEWER:
        return <div>Viewer</div>;
      case IUserRoleType.REPORT_DOWNLOADER:
        return <div>Viewer</div>;
      case IUserRoleType.VIEWER:
        return <div>Viewer</div>;
    }
  };

  public generateColumns = (
    connectedUserRoleType: IUserRoleType,
    portal?: IPartnerPortal
  ): ColumnProps<IUserRoleWithGroups>[] => {
    const { user: connectedUser } = this.props;

    return [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        render: (v, s) => {
          return <UserRenderer user={(s as IUserRole)?.user} />;
        },
      },
      {
        title: "Tags",
        key: "role",
        render: (v, s) => (
          <div className="user-tags">
            {(s as IUserRole)?.user?.isPublicGuestUser ? (
              <Tag color="green">Public user</Tag>
            ) : null}
            {(s as IUserRole)?.user?.isAdmin ? (
              <Tag color="magenta">Whaly support team</Tag>
            ) : null}
          </div>
        ),
      },
      {
        title: "Role",
        key: "role",
        render: (v, s) => {
          const role = s.role;
          return this.renderRole(role);
        },
      },
      {
        title: "License",
        key: "license",
        render: (v, record) => {
          const role = record.role;
          return this.renderLicense(
            role,
            (record as IUserRole)?.user?.isAdmin ||
              (record as IUserRole)?.user?.isPublicGuestUser
          );
        },
      },
      ...(connectedUserRoleType === IUserRoleType.ADMIN_BUILDER
        ? [
            {
              title: "Actions",
              key: "Actions",
              align: "right" as const,
              render: (v, record) => {
                // Management of Invitations
                const userRole = record as IUserRoleWithGroups;
                const selectedUser = userRole.user;
                if (selectedUser.isPublicGuestUser) return null;

                const selectedUserRealmCanBeRead =
                  selectedUser.realm?.realm?.id;
                const selectedUserIsNotConnectedUser =
                  selectedUser.id !== connectedUser.id;
                const selectedAndConnectedUserAreFromSameRealm =
                  connectedUser.realm.realm.id ===
                  selectedUser.realm?.realm?.id;
                const connectedUserIsRealmAdmin =
                  connectedUser.realm.type === IUserRealmType.ADMIN;

                const menu: MenuProps = {
                  items: [],
                };

                // Edit/impersonate of User is possible only if:
                // the selectedUser (e.g. to be edited) is not a Whaly Admin
                // OR
                // the connectedUser is a Whaly admin himself
                if (!selectedUser.isAdmin || connectedUser.isAdmin) {
                  // Impersonate is possible only when:
                  // - connectedUser and selectedUser are sharing the same realm
                  // OR
                  // - connectedUser is a Whaly Admin
                  // Note: If the selected user is from another realm, we will have null as the Id as we can't read it

                  if (
                    (selectedUserRealmCanBeRead &&
                      selectedAndConnectedUserAreFromSameRealm &&
                      selectedUserIsNotConnectedUser &&
                      connectedUserIsRealmAdmin) ||
                    connectedUser.isAdmin
                  ) {
                    (menu.items || []).push({
                      key: 1,
                      onClick: () => {
                        this.createSudoRequest(selectedUser.id);
                      },
                      label: "Impersonate",
                    });
                  }
                }

                if (record.user?.signUpToken) {
                  menu.items!.push({
                    key: `edit-invite-${record.id}`,
                    onClick: () => this.copyInviteLink(record),
                    label: "Copy invite link",
                  });

                  menu.items!.push({
                    key: `edit-send-invite-${record.id}`,
                    onClick: () => this.sendInviteEmail(record.user.id),
                    label: "Send invite email",
                  });

                  menu.items!.push({
                    key: `revoke-invite-${record.id}`,
                    onClick: () => this.deleteUserRole(record),
                    danger: true,
                    label: "Revoke",
                  });

                  return (
                    <Space>
                      <Button
                        size="small"
                        onClick={() =>
                          this.setState({ selectedUserRole: userRole })
                        }
                      >
                        {portal ? "Edit Partner" : "Edit User"}
                      </Button>
                      <Dropdown
                        trigger={["click"]}
                        placement="bottomRight"
                        menu={menu}
                      >
                        <Button
                          type="text"
                          size="small"
                          shape="circle"
                          icon={<MoreOutlined />}
                        />
                      </Dropdown>
                    </Space>
                  );
                } else {
                  (menu.items || []).push({
                    key: 3,
                    onClick: () => this.deleteUserRole(userRole),
                    danger: true,
                    label: "Revoke access",
                  });

                  return (
                    <Space>
                      <Button
                        size="small"
                        onClick={() =>
                          this.setState({ selectedUserRole: userRole })
                        }
                      >
                        {portal ? "Edit Partner" : "Edit User"}
                      </Button>
                      <Dropdown
                        trigger={["click"]}
                        placement="bottomRight"
                        menu={menu}
                      >
                        <Button
                          icon={<MoreOutlined />}
                          type="text"
                          size="small"
                          shape="circle"
                        />
                      </Dropdown>
                    </Space>
                  );
                }
              },
            },
          ]
        : []),
    ];
  };

  public render() {
    const {
      users,
      currentOrg,
      user: connectedUser,
      org,
      role: connectedUserRole,
      userStore: { getUser },
      portal,
      reRender,
      topics,
      workflows,
    } = this.props;
    return (
      <>
        <CardTable<IUserRole>
          cardTitle={<div className="title">Users</div>}
          actionButtons={
            hasRoleAccessBoolean(
              IUserRoleType.ADMIN_BUILDER,
              connectedUser,
              org.id
            ) ? (
              <Space>
                <Button onClick={this.showDrawer} type="primary">
                  {portal ? "Invite Partner" : "Invite Team Member"}
                </Button>
                {portal ? (
                  <Dropdown
                    menu={{
                      items: [
                        {
                          key: "bulk",
                          label: "Bulk update users",
                          onClick: () => this.setState({ bulkOpen: true }),
                        },
                      ],
                    }}
                    placement="bottomRight"
                    trigger={["click"]}
                  >
                    <Button shape="circle" icon={<MoreOutlined />} />
                  </Dropdown>
                ) : undefined}
              </Space>
            ) : undefined
          }
          rowKey="id"
          dataSource={users}
          columns={this.generateColumns(connectedUserRole.role, portal)}
          pagination={{
            size: "small",
            style: { display: "none" },
            defaultPageSize: 1000,
          }}
        />
        <Drawer
          width={"35%"}
          open={!!this.state.selectedUserRole}
          closable={true}
          styles={{ body: { padding: "0 0 80px 0" } }}
          keyboard={false}
          maskClosable={false}
          destroyOnClose={true}
          onClose={() => this.setState({ selectedUserRole: undefined })}
          title={portal ? "Edit partner" : "Edit User"}
        >
          <EditUserForm
            onSubmit={async (
              newUserRoleValue,
              groupIdsToBeAdded,
              groupsToBeDeleted,
              attributesToUpdate,
              attributesToCreate,
              subscriptionsToCreate,
              subscriptionsToDelete
            ) => {
              await GraphQLService(UPDATE_USER, {
                userId: newUserRoleValue.user.id,
                locale: newUserRoleValue.user.locale,
                userRoleId: newUserRoleValue.id,
                userRoleUpdateInput: {
                  role: newUserRoleValue.role,
                  v2HomeConfig: newUserRoleValue.v2HomeConfig,
                  hasFileUploadPermission:
                    newUserRoleValue.hasFileUploadPermission,
                },
                attributesToUpdate,
                attributesToCreate,
                subscriptionsToDelete: subscriptionsToDelete.map((id) => ({
                  id: id,
                  data: { isDeleted: true },
                })),
              });
              if (subscriptionsToCreate.length > 0) {
                const promises = subscriptionsToCreate.map((s) => {
                  return this.createUserSubscription({
                    userId: newUserRoleValue.user.id,
                    orgId: org.id,
                    topicId: s.topicId,
                    workflowId: s.workflowId,
                  });
                });
                await Promise.all(promises);
              }
              if (groupIdsToBeAdded.length > 0) {
                const promises = groupIdsToBeAdded.map((groupId) => {
                  return this.createUserGroup(
                    newUserRoleValue.user.id,
                    groupId,
                    org.id
                  );
                });
                await Promise.all(promises);
              }
              if (groupsToBeDeleted.length > 0) {
                const promises = groupsToBeDeleted.map((userGroup) => {
                  return this.deleteUserGroup(userGroup);
                });
                await Promise.all(promises);
              }
              // we refresh the current user if we modify any associated values
              if (connectedUserRole.id === newUserRoleValue.id) {
                await getUser();
              }
              this.onSubmit();
            }}
            onDrawerClose={() => this.setState({ selectedUserRole: undefined })}
            initialData={this.state.selectedUserRole}
            topics={topics}
            workflows={workflows}
          />
        </Drawer>
        <Drawer
          width={"35%"}
          open={this.state.open}
          closable={false}
          styles={{ body: { padding: "0 0 80px 0" } }}
          keyboard={false}
          maskClosable={false}
          destroyOnClose={true}
        >
          <AccessManagementForm
            org={currentOrg}
            onSubmit={this.onSubmit}
            onDrawerClose={this.hideDrawer}
            portal={portal}
          />
        </Drawer>
        <BulkUpload
          open={this.state.bulkOpen}
          portal={portal}
          onClose={() => this.setState({ bulkOpen: false })}
          onDone={reRender}
        />
      </>
    );
  }
}

export default compose<Props, IAccessManagementTableProps>(
  inject("userStore"),
  observer,
  withRouter,
  WithOrg,
  withAntUtils
)(AccessManagementTable);
