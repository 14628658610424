import { useLagoonQuery } from "../../../../../components/hooks/query/useLagoonQuery";
import type { IOrg } from "../../../../../interfaces/org";

export const useDistinctValuesCount = (
  org: IOrg,
  objectId: string,
  field: string
) => {
  const measureKey = `View_${objectId}.${field}_distinct`;
  const dimensionKey = `View_${objectId}.${field}`;
  const { data, loading, error } = useLagoonQuery(
    {
      measures: [measureKey],
      filters: [{ and: [{ member: dimensionKey, operator: "set" }] }],
    },
    { org, objectType: "VIEW", objectId }
  );

  return {
    distinctValuesCount: data ? (data[0][measureKey] as number) : undefined,
    loading,
    error,
  };
};
