import { Button, Select } from "antd";
import type { BaseSelectRef } from "rc-select";
import type { ReactNode } from "react";
import { useRef, useState } from "react";
import "./MeasurePicker.scss";

interface IMeasurePickerProps {
  availableMeasures: Array<{
    key: string;
    label: string;
  }>;
  selectedMeasureKeys: Array<string>;
  onMeasureChange: (measure: string) => void;
  block?: boolean;
  max?: number;
  disabled?: boolean;
  children?: ReactNode;
}

const MeasurePicker = ({
  availableMeasures,
  selectedMeasureKeys,
  onMeasureChange,
  children,
  max,
  disabled,
}: IMeasurePickerProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const selectRef = useRef<BaseSelectRef>(null);

  const hasAvailableMeasures = availableMeasures?.length > 0;
  const hasExceedMax = !!max && selectedMeasureKeys.length >= max;

  const isDisabled = disabled || !hasAvailableMeasures || hasExceedMax;

  return (
    <div className="measure-picker">
      <Button
        block
        size={"small"}
        type={"dashed"}
        disabled={isDisabled}
        className="measure-picker-button"
        style={{
          display: open ? "none" : "initial",
        }}
      >
        {children}
      </Button>
      <Select
        ref={selectRef}
        className="measure-picker-select"
        onDropdownVisibleChange={setOpen}
        size={"small"}
        showSearch
        disabled={isDisabled}
        optionFilterProp={"label"}
        options={availableMeasures.map((am) => {
          return {
            label: am.label,
            value: am.key,
          };
        })}
        onChange={(measure) => {
          onMeasureChange(measure);
          selectRef.current?.blur?.();
        }}
      />
    </div>
  );
};

export default MeasurePicker;
