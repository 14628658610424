import type { AvailableMetric } from "../../../../components/measures/filter-item/FilterItem";
import type {
  IObject,
  IObjectPresetFilter,
  IObjectProperty,
  IObjectPropertyActivitiesFormatterConfig,
  IObjectPropertyScoreFormatterConfig,
} from "../../../../interfaces/object";
import type { IMetric } from "../../../../interfaces/table";
import type { AvailableProperty } from "./viewer/domain";
import { parseObjectPropertyFormatter } from "./viewer/domain";

export interface AvailableMetricColumn extends AvailableMetric {
  sortAndFilterKey: string;
}

const convertMetricToAvailableMetric = (
  cubeName: string,
  m: IMetric
): AvailableMetricColumn => {
  return {
    key: `${cubeName}.${m.cubeName}`,
    label: m.name,
    description: m.description,
    hierarchyPath: m.hierarchyPath,
    sortAndFilterKey: `${cubeName}.${m.cubeName}_sort`,
    formatter: {
      format: m.format,
      prefix: m.prefix,
      suffix: m.suffix,
      customFormatting: m.overrideFormatting,
    },
  };
};

export const convertPropertyToAvailableProperties = (
  cubeName: string,
  object: IObject,
  property: IObjectProperty
): AvailableProperty => {
  const key = `${cubeName}.${property.cubeName}`;
  let sortAndFilterKey = `${cubeName}.${property.cubeName}`;
  let propertyObject = object;
  let type: AvailableProperty["type"] = "standard";
  if (property.propertyType === "foreignKey") {
    sortAndFilterKey = `${cubeName}.${property.cubeName}_name`;
    propertyObject = property.foreignKey!;
    type = "foreignKey";
  }
  if (property.userPropertyMapping) {
    type = "userKey";
    sortAndFilterKey = `${cubeName}.${property.cubeName}_raw`;
  }
  if (property.sortingAndFilteringColumn) {
    sortAndFilterKey = `${cubeName}.${property.cubeName}_sort`;
  }
  if (property.formatter === "activities") {
    const config = parseObjectPropertyFormatter(
      "activities",
      property.formatterConfig || ""
    ) as IObjectPropertyActivitiesFormatterConfig;
    if (config?.config?.sortingKey) {
      sortAndFilterKey = config?.config?.sortingKey;
    }
  }
  if (property.formatter === "score") {
    const config = parseObjectPropertyFormatter(
      "score",
      property.formatterConfig || ""
    ) as IObjectPropertyScoreFormatterConfig;
    if (config?.config?.sortingKey) {
      sortAndFilterKey = config?.config?.sortingKey;
    }
  }
  return {
    key: key,
    sortAndFilterKey: sortAndFilterKey,
    label: property.label,
    description: property.description,
    type: type,
    domain: property.columnDomain,
    object: propertyObject,
    formatter: property.formatter,
    formatterConfig: property.formatterConfig,
    hierarchyPath: property.hierarchyPath,
  };
};

const convertPropertiesToAvailableProperties = (
  cubeName: string,
  object: IObject,
  properties: IObjectProperty[],
  includeId?: boolean
): AvailableColumn[] => {
  return [
    {
      type: "property",
      data: {
        key: `${cubeName}.label`,
        sortAndFilterKey: `${cubeName}.name`,
        label: "Label",
        type: "primaryKey",
        domain: "STRING",
        object: object,
      },
    },
    ...(includeId
      ? [
          {
            type: "property",
            identifier: "id",
            data: {
              key: `${cubeName}.id`,
              sortAndFilterKey: `${cubeName}.id`,
              label: "ID",
              type: "primaryKey",
              domain: "STRING",
              object: object,
            },
          } as AvailableColumn,
        ]
      : []),
    ...properties.map<AvailableColumn>((p) => ({
      type: "property",
      identifier: p.id,
      data: convertPropertyToAvailableProperties(cubeName, object, p),
    })),
  ];
};

export const getObjectColumns = (
  object: IObject,
  includeId?: boolean
): Array<AvailableColumn> => {
  return [
    ...convertPropertiesToAvailableProperties(
      object.table.cubeName,
      object,
      object.properties,
      includeId
    ),
    ...object.table.metrics.map<AvailableColumn>((m) => ({
      type: "metric",
      data: convertMetricToAvailableMetric(object.table.cubeName, m),
    })),
  ];
};

export const isAvailableProperty = (
  availableColumn: AvailableColumn
): availableColumn is {
  type: "property";
  data: AvailableProperty;
} => availableColumn.type === "property";

export const isAvailableMetric = (
  availableColumn: AvailableColumn
): availableColumn is {
  type: "metric";
  data: AvailableMetricColumn;
} => availableColumn.type === "metric";

export type AvailableColumn =
  | {
      type: "metric";
      data: AvailableMetricColumn;
    }
  | {
      type: "property";
      data: AvailableProperty;
    };

enum ColumnType {
  "LabelColumn" = "LabelColumn",
  "TagColumn" = "TagColumn",
  "DateColumn" = "DateColumn",
  "ProgressColumn" = "ProgressColumn",
  "UserInfo" = "UserInfo",
}

const getColumnType = (measure: AvailableColumn): ColumnType | null => {
  if (measure.type !== "metric") {
    if (
      measure.data.key.endsWith(".label") ||
      measure.data.type === "foreignKey"
    ) {
      return ColumnType.LabelColumn;
    } else if (measure.data.formatter === "pills") {
      return ColumnType.TagColumn;
    } else if (measure.data.domain === "TIME") {
      return ColumnType.DateColumn;
    } else if (measure.data.type === "userKey") {
      return ColumnType.UserInfo;
    }
  }
  if (measure.type === "metric") {
    if (measure.data.formatter.format === "PERCENT") {
      return ColumnType.ProgressColumn;
    }
  }
  return null;
};

export const getObjectPresetFilters = (
  object: IObject
): Array<IObjectPresetFilter> => {
  return (object.presetFilters ?? []).map((preset) => ({
    ...preset,
    value: JSON.parse(preset.value as unknown as string),
  }));
};
