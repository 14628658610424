import { action, makeObservable, observable } from "mobx";
import type {
  IActiveObject,
  IActiveObjectType,
  IActiveObjectUIState,
} from "../containers/workbench/workbench/domain";
import type { ModelFolderEditInitialData } from "../containers/workbench/workbench/model/folder/ModelFolderEdition";
import type { DatasetInitialData } from "../containers/workbench/workbench/selector/tables/models/AddModelModal";

export type ISelectorType =
  | "persistenceEngine"
  | "dbtCloudSync"
  | "data"
  | "exploration"
  | "worksheet"
  | "connector"
  | "object"
  | "object_layout"
  | "radar"
  | "uploader"
  | null
  | undefined;

export const encodeUrlParam = (activeObject: IActiveObject) => {
  return btoa(
    JSON.stringify({
      type: activeObject.type,
      value: activeObject.value,
      urlState: activeObject.urlState,
    })
  );
};

const decodeUrlParam = (param: string) => {
  try {
    return JSON.parse(atob(param));
  } catch (err) {
    console.error(err);
    return null;
  }
};

class WorkbenchUIStore {
  activeObjects: IActiveObject[] = [];
  sourceEditionOpen: boolean = false;
  globalSearchOpen: boolean = false;
  objectAdditionOpen: boolean = false;
  radarAdditionOpen: boolean = false;
  modelFolderEditionOpen: boolean | ModelFolderEditInitialData = false;
  datasetEditionOpen: boolean | DatasetInitialData = false;
  explorationEditionOpen: boolean = false;
  connectorCatalogOpen: boolean = false;
  explorationSectionCreationOpen: boolean = false;
  warehouseAdditionOpen: boolean = false;
  selector: ISelectorType = "exploration";
  lastActiveSelector: ISelectorType = "exploration";
  onRouteParamUpdate: (left?: string, right?: string) => void | undefined;
  showcasedNodeId: string | undefined | null;
  dbtCloudSyncExecutionEditionOpen: boolean = false;
  persistenceEngineExecutionEditionOpen: boolean = false;
  fileUploadAdditionOpen: boolean = false;

  destroy = () => {
    this.activeObjects = [];
    this.sourceEditionOpen = false;
    this.globalSearchOpen = false;
    this.objectAdditionOpen = false;
    this.radarAdditionOpen = false;
    this.modelFolderEditionOpen = false;
    this.datasetEditionOpen = false;
    this.dbtCloudSyncExecutionEditionOpen = false;
    this.persistenceEngineExecutionEditionOpen = false;
    this.explorationEditionOpen = false;
    this.connectorCatalogOpen = false;
    this.explorationSectionCreationOpen = false;
    this.selector = "exploration";
    this.lastActiveSelector = "exploration";
    this.showcasedNodeId = undefined;
    this.warehouseAdditionOpen = false;
    this.fileUploadAdditionOpen = false;
  };

  setOnRouteParamUpdate = (
    onRouteParamUpdate: (left?: string, right?: string) => void
  ) => {
    this.onRouteParamUpdate = onRouteParamUpdate;
  };

  constructor() {
    makeObservable(this, {
      activeObjects: observable,
      sourceEditionOpen: observable,
      globalSearchOpen: observable,
      objectAdditionOpen: observable,
      radarAdditionOpen: observable,
      modelFolderEditionOpen: observable,
      datasetEditionOpen: observable,
      explorationEditionOpen: observable,
      connectorCatalogOpen: observable,
      explorationSectionCreationOpen: observable,
      selector: observable,
      lastActiveSelector: observable,
      onRouteParamUpdate: observable,
      showcasedNodeId: observable,
      dbtCloudSyncExecutionEditionOpen: observable,
      persistenceEngineExecutionEditionOpen: observable,
      warehouseAdditionOpen: observable,
      fileUploadAdditionOpen: observable,
      destroy: action,
      setOnRouteParamUpdate: action,
      setIsShowcased: action,
      setDbtCloudSyncExecutionEditionOpen: action,
      setPersistenceEngineExecutionEditionOpen: action,
      setExplorationEditionOpen: action,
      setConnectorCatalogOpen: action,
      setExplorationSectionCreationOpen: action,
      setSelector: action,
      toggleSelector: action,
      setGlobalSearchOpen: action,
      setSourceEditionOpen: action,
      setObjectAdditionOpen: action,
      setRadarAdditionOpen: action,
      setFileUploadAdditionOpen: action,
      setModelFolderEditionOpen: action,
      setDatasetEditionOpen: action,
      initActiveObjects: action,
      reorderActiveObjects: action,
      pushActiveObject: action,
      getActiveObjects: action,
      updateActiveObject: action,
      removeActiveObject: action,
      getActiveObject: action,
      setActiveObjectFocused: action,
      setActiveObjectStale: action,
      getActiveObjectStaleState: action,
      setActiveObjectUrlParams: action,
      getActiveObjectUrlParams: action,
      getActiveObjectUIState: action,
      setActiveObjectUIState: action,
      setWarehouseAdditionOpen: action,
      swapActiveObjectId: action,
    });
  }

  setFileUploadAdditionOpen(open: boolean) {
    this.fileUploadAdditionOpen = open
  }

  setWarehouseAdditionOpen(open: boolean) {
    this.warehouseAdditionOpen = open;
  }

  setIsShowcased(nodeId?: string | null) {
    this.showcasedNodeId = nodeId;
  }

  setDbtCloudSyncExecutionEditionOpen(open: boolean) {
    this.dbtCloudSyncExecutionEditionOpen = open;
  }

  setPersistenceEngineExecutionEditionOpen(open: boolean) {
    this.persistenceEngineExecutionEditionOpen = open;
  }

  setExplorationEditionOpen(open: boolean) {
    this.explorationEditionOpen = open;
  }

  setConnectorCatalogOpen(open: boolean) {
    this.connectorCatalogOpen = open;
  }

  setExplorationSectionCreationOpen(open: boolean) {
    this.explorationSectionCreationOpen = open;
  }

  setSelector = (selector: ISelectorType) => {
    this.selector = selector;
    if (this.selector) {
      this.lastActiveSelector = selector;
    }
  };

  toggleSelector = () => {
    if (this.selector === null) {
      this.setSelector(this.lastActiveSelector);
    } else {
      this.setSelector(null);
    }
  };

  setGlobalSearchOpen = (open: boolean) => {
    this.globalSearchOpen = open;
  };

  setSourceEditionOpen = (open: boolean) => {
    this.sourceEditionOpen = open;
  };

  setObjectAdditionOpen = (open: boolean) => {
    this.objectAdditionOpen = open;
  };

  setRadarAdditionOpen = (open: boolean) => {
    this.radarAdditionOpen = open;
  };

  setModelFolderEditionOpen = (open: boolean | ModelFolderEditInitialData) => {
    this.modelFolderEditionOpen = open;
  };

  setDatasetEditionOpen = (open: boolean | DatasetInitialData) => {
    this.datasetEditionOpen = open;
  };

  initActiveObjects = (left?: string, right?: string) => {
    const parsedLeft = left ? decodeUrlParam(left) : null;
    if (parsedLeft) {
      this.pushActiveObject(parsedLeft);
      this.setActiveObjectFocused(parsedLeft);
    }
  };

  reorderActiveObjects = (ao: IActiveObject[]) => {
    this.activeObjects = [...ao];
  };

  swapActiveObjectId = (
    type: IActiveObjectType,
    prevId: string,
    nextId: string
  ) => {
    this.activeObjects = [
      ...this.activeObjects.map((ao) => {
        if (ao.type === type && ao.value === prevId) {
          return {
            ...ao,
            value: nextId,
          };
        }
        return ao;
      }),
    ];
    this.updateRouter();
  };

  pushActiveObject = (newActiveObject: IActiveObject) => {
    const alreadyInState = this.activeObjects.find(
      (ao) =>
        ao.type === newActiveObject.type && ao.value === newActiveObject.value
    );
    if (!alreadyInState) {
      this.activeObjects = [...this.activeObjects, newActiveObject];
      this.setActiveObjectFocused(newActiveObject);
    } else {
      this.setActiveObjectFocused(newActiveObject);
    }
  };

  getActiveObjects = () => this.activeObjects;

  updateActiveObject = (updateActiveObject: IActiveObject) => {
    this.activeObjects = this.activeObjects.map((ao) => {
      if (
        ao.type === updateActiveObject.type &&
        ao.value === updateActiveObject.value
      ) {
        return updateActiveObject;
      }
      return ao;
    });
  };

  removeActiveObject = (removeActiveObject: IActiveObject) => {
    const newObjects = this.activeObjects.flatMap((ao) => {
      if (
        ao.type === removeActiveObject.type &&
        ao.value === removeActiveObject.value
      ) {
        return [];
      }
      return [ao];
    });
    this.activeObjects = newObjects;
    if (newObjects.length > 0) {
      this.setActiveObjectFocused(newObjects[newObjects.length - 1]);
    } else {
      this.updateRouter();
    }
  };

  getActiveObject = (): IActiveObject | undefined => {
    return this.activeObjects.find((ao) => !!ao.focused);
  };

  setActiveObjectFocused = (activeObject: IActiveObject) => {
    this.activeObjects = this.activeObjects.map((ao) => {
      if (ao.type === activeObject.type && ao.value === activeObject.value) {
        return {
          ...ao,
          focused: true,
        };
      }
      return {
        ...ao,
        focused: false,
      };
    });

    this.updateRouter();
  };

  private updateRouter = () => {
    if (this.onRouteParamUpdate) {
      const activeObject = this.getActiveObject();
      const encodedUrlParam = activeObject
        ? encodeUrlParam(activeObject)
        : null;
      this.onRouteParamUpdate(encodedUrlParam || undefined);
    }
  };

  setActiveObjectStale = (
    activeObject: IActiveObject,
    state?: any,
    stale?: boolean
  ) => {
    this.activeObjects = this.activeObjects.map((ao) => {
      if (ao.type === activeObject.type && ao.value === activeObject.value) {
        return {
          ...ao,
          stale: stale === undefined ? true : stale,
          staleState: state === undefined ? null : state,
        };
      }
      return ao;
    });
  };

  getActiveObjectStaleState = (activeObject: IActiveObject) => {
    const ao = this.activeObjects.find((ao) => {
      return ao.type === activeObject.type && ao.value === activeObject.value;
    });
    return ao?.staleState;
  };

  setActiveObjectUrlParams = (
    activeObject: IActiveObject,
    urlState?: object
  ) => {
    this.activeObjects = this.activeObjects.map((ao) => {
      if (ao.type === activeObject.type && ao.value === activeObject.value) {
        return {
          ...ao,
          urlState: urlState,
        };
      }
      return ao;
    });
    this.updateRouter();
  };

  getActiveObjectUrlParams = (activeObject: IActiveObject) => {
    const ao = this.activeObjects.find((ao) => {
      return ao.type === activeObject.type && ao.value === activeObject.value;
    });
    return ao?.urlState;
  };

  getActiveObjectUIState = (activeObject: IActiveObject) => {
    const ao = this.activeObjects.find((ao) => {
      return ao.type === activeObject.type && ao.value === activeObject.value;
    });
    return ao?.uiState;
  };

  setActiveObjectUIState = (
    activeObject: IActiveObject,
    uiState?: IActiveObjectUIState
  ) => {
    this.activeObjects = this.activeObjects.map((ao) => {
      if (ao.type === activeObject.type && ao.value === activeObject.value) {
        return {
          ...ao,
          uiState: uiState,
        };
      }
      return ao;
    });
  };
}

export interface WorkbenchUIStoreProps {
  workbenchUIStore: WorkbenchUIStore;
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new WorkbenchUIStore();
