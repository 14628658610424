import { Input, Radio, Select, Skeleton, Space, Typography } from "antd";
import _ from "lodash";
import { inject, observer } from "mobx-react";
import React from "react";
import { compose } from "../../../../../components/compose/WlyCompose";
import { ReportIcon } from "../../../../../components/report/ReportIcon";
import type { AsyncData } from "../../../../../helpers/typescriptHelpers";
import { IOrgFeatureType } from "../../../../../interfaces/org";
import GraphQLService from "../../../../../services/graphql/GraphQLService";
import type { WorkspaceDatatoreProps } from "../../../../../store/dataStores/workspace/workspaceDatastore";
import type { InjectedOrgProps } from "../../../../orgs/WithOrg";
import WithOrg from "../../../../orgs/WithOrg";
import type { ContentSelectorType } from "./domain";
import { isContentSelectorValid } from "./domain";

interface ICustomizeHomePageSelectorProps {
  value: ContentSelectorType;
  onChange: (v: ContentSelectorType) => void;
}

type Props = ICustomizeHomePageSelectorProps &
  WorkspaceDatatoreProps &
  InjectedOrgProps;

function CustomizeHomePageSelector(props: Props) {
  const { value: typeFromProps, org, onChange, orgFeatures } = props;

  const [value, setValue] = React.useState(typeFromProps);
  const [availableObjects, setAvailableObjects] = React.useState<
    AsyncData<Array<{ id: string; name: string }>>
  >({ status: "initial" });
  const [availableReports, setAvailableReports] = React.useState<
    AsyncData<
      Array<{ id: string; name: string; type: "DASHBOARD" | "QUESTION" }>
    >
  >({ status: "initial" });

  const debouncedChanged = _.debounce(onChange, 500);

  React.useEffect(() => {
    if (isContentSelectorValid(value)) {
      debouncedChanged(value);
    }
  }, [value]);

  async function fetchObject() {
    try {
      setAvailableObjects({ status: "loading" });
      const data = await GraphQLService<{
        allObjects: Array<{ id: string; name: string }>;
      }>(
        `
      query allObjects($orgId: ID!) {
        allObjects(where:{ isDeleted_not: true, org: { id: $orgId } }) {
          id
          name
          table {
            cubeName
          }
        }
      }
      `,
        {
          orgId: org.id,
        }
      );
      setAvailableObjects({ status: "success", data: data.allObjects });
    } catch (err) {
      setAvailableObjects({ status: "error", error: err });
    }
  }

  async function fetchReport() {
    try {
      setAvailableReports({ status: "loading" });
      const data = await GraphQLService<{
        allReports: Array<{ id: string; name: string; type: string }>;
      }>(
        `
      query allReports($orgId: ID!) {
        allReports(where:{ deleted_not: true, org: { id: $orgId } }) {
          id
          name
          type
        }
      }
      `,
        {
          orgId: org.id,
        }
      );
      setAvailableReports({ status: "success", data: data.allReports as any });
    } catch (err) {
      setAvailableReports({ status: "error", error: err });
    }
  }

  React.useEffect(() => {
    if (value.type === "record") {
      fetchObject();
    } else if (value.type === "report") {
      fetchReport();
    }
  }, [value.type]);

  const renderLoadingSelect = () => {
    if (
      availableObjects.status === "initial" ||
      availableObjects.status === "loading"
    ) {
      return (
        <Skeleton.Button block active shape="round" style={{ width: "100%" }} />
      );
    }
    if (availableObjects.status === "error") {
      return <Typography.Text type="danger">Error fetching...</Typography.Text>;
    }
    return (
      <Select
        onChange={(e) => setValue((v) => ({ ...v, objectId: e }))}
        value={(value as any).objectId}
        style={{ width: "100%" }}
      >
        {availableObjects.data.map((a) => {
          return (
            <Select.Option key={a.id} value={a.id}>
              {a.name}
            </Select.Option>
          );
        })}
      </Select>
    );
  };

  const renderLoadingReportSelect = () => {
    if (
      availableReports.status === "initial" ||
      availableReports.status === "loading"
    ) {
      return (
        <Skeleton.Button block active shape="round" style={{ width: "100%" }} />
      );
    }
    if (availableReports.status === "error") {
      return <Typography.Text type="danger">Error fetching...</Typography.Text>;
    }
    return (
      <Select
        onChange={(e) => setValue((v) => ({ ...v, reportId: e }))}
        value={(value as any).reportId}
        style={{ width: "100%" }}
      >
        {availableReports.data.map((a) => {
          return (
            <Select.Option key={a.id} value={a.id}>
              <ReportIcon type={a.type} /> {a.name}
            </Select.Option>
          );
        })}
      </Select>
    );
  };

  const renderRecordConf = () => {
    if (value.type === "record") {
      return (
        <Space direction="vertical" style={{ display: "flex" }}>
          <Typography.Text strong>Object</Typography.Text>
          {renderLoadingSelect()}
          {value.objectId && (
            <>
              <Typography.Text strong>Record</Typography.Text>
              <Input
                onChange={(e) =>
                  setValue((v) => ({ ...v, recordId: e.target.value }))
                }
                value={value.recordId}
              />
            </>
          )}
        </Space>
      );
    } else if (value.type === "report") {
      return (
        <Space direction="vertical" style={{ display: "flex" }}>
          <Typography.Text strong>Report</Typography.Text>
          {renderLoadingReportSelect()}
        </Space>
      );
    }
    return null;
  };

  return (
    <Space direction="vertical" size={"large"} style={{ display: "flex" }}>
      <Space direction="vertical" style={{ display: "flex" }}>
        <Typography.Text strong>Content</Typography.Text>
        <Radio.Group
          onChange={(e) => setValue({ type: e.target.value } as any)}
          value={value.type}
        >
          <Space direction="vertical">
            <Radio value={"default"}>Recently Viewed</Radio>
            {orgFeatures.includes(IOrgFeatureType.OBJECTS) && (
              <Radio value={"record"}>Object Record</Radio>
            )}
            <Radio value={"report"}>Report</Radio>
          </Space>
        </Radio.Group>
      </Space>
      {renderRecordConf()}
    </Space>
  );
}

export default compose<Props, ICustomizeHomePageSelectorProps>(
  inject("workspaceDatastore"),
  observer,
  WithOrg
)(CustomizeHomePageSelector);
