import { gql } from "@apollo/client";
import {
  useGQLQuery,
  type QueryOptions,
} from "../../../../../../components/hooks/query/useGQLQuery";
import type { IFileUploadRun } from "../../../../../../interfaces/fileUpload";

type JQueryData = {
  allFileUploadJobRuns: IFileUploadRun[];
};

type JQueryVariables = {
  fileUploadId: string;
};

const FileUploadJobExecutionsDetailsQuery = gql`
  query getFileUploadInvokations($fileUploadId: ID!) {
    allFileUploadJobRuns(
      where: { fileUploadJob: { id: $fileUploadId } }
      sortBy: [createdAt_DESC]
      first: 24
    ) {
      id
      syncStatus
      filePath
      transformJobExecution {
        id
        identifier
        objectType
        objectId
        status
        errorText
        errorDebugText
        errorType
        createdAt
        updatedAt
      }
      uploadJobExecution {
        id
        identifier
        objectType
        objectId
        status
        errorText
        errorDebugText
        errorType
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      createdBy {
        logo
        firstName
        lastName
        gravatar
        avatarColor
      }
    }
  }
`;

export const useGetFileUploadJobExecutionsDetails = (
  options: QueryOptions<JQueryData, JQueryVariables>
) => {
  return useGQLQuery<JQueryData, JQueryVariables>(
    FileUploadJobExecutionsDetailsQuery,
    options
  );
};
