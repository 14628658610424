import { Button } from "antd";
import { useState } from "react";
import type { DiffFieldType } from "../../../components/modals/DiffModal";
import { DiffModal } from "../../../components/modals/DiffModal";

interface IHistoryValueRendererProps {
  children?: React.ReactNode;
  previousValue: string | undefined;
  newValue: string | undefined;
  fieldType: DiffFieldType;
}

export function HistoryValueRenderer({
  children,
  previousValue,
  newValue,
  fieldType,
}: IHistoryValueRendererProps) {
  const [visible, setVisible] = useState<boolean>(false);

  return (
    <>
      <Button
        onClick={() => setVisible(true)}
        ghost
        size="small"
        type="primary"
      >
        {children}
      </Button>
      <DiffModal
        previousValue={previousValue}
        newValue={newValue}
        fieldType={fieldType}
        visible={visible}
        onCancel={() => setVisible(false)}
        onOk={() => setVisible(false)}
      />
    </>
  );
}
