import type { IBaseCatalogItemMeta, IBaseCatalogItemOption } from "./catalog";
import type { IChartType } from "./dashboards";
import type { IDestination } from "./destinations";
import type {
  IJobExecution,
  JobSyncPeriod,
  JobSyncStatus,
} from "./jobExecutions";
import type { Table } from "./model";
import type { IModelFolder } from "./modelFolder";
import type { IOrg } from "./org";
import type { IRelationshipType } from "./table";
import type { FieldTableInfo, Transformation } from "./transformations";
import type { IUser } from "./user";
import type { IView } from "./view";

export const TARGET_DATABASE_FORM_FIELD_NAME = "targetDatabase";
export const TARGET_SCHEMA_FORM_FIELD_NAME = "targetSchema";

export interface ISourceMeta extends IBaseCatalogItemMeta {
  id: string;
  slug: string;
  name: string;
  auth: boolean;
  buttonType: string;
  executor: ISourceMetaExecutor;
  defaultTargetSchema: string;
  shoreSourceName: string;
  oAuthConfig: IAuthConfig;
  publicInfo: ISourceMetaDataPublicInfo;
  chartModels: IChartModel[];
  options: ISourceOption[];
  createdBy: IUser;
  org: IOrg;
  sources: ISource[];
  overrideUrl?: string;
}

export type ISourceMetaExecutor = "FIVETRAN" | "SINGER" | "SHORE" | "WAREHOUSE";

interface IAuthConfig {
  clientId: string;
  scope: string;
  redirectUri: string;
  authenticationUrl: string;
  authorizationUrl: string;
}

interface IChartModel {
  id: string;
  name: string;
  type: IChartType;
  queryModel: IQueryModel;
  sourceMeta: ISourceMeta;
}

export interface IQueryModel {
  id: string;
  type: "BRIZO" | "MASHUP";
  queryText: string;
}

interface ISourceMetaDataPublicInfo {
  id: string;
  name: string;
  slug: string;
  tagline: string;
  label: ISourceMetaDataPublicInfoLabel;
  description: string;
  documentationPagePath?: string;
  secondaryDocumentationPagePath?: string;
  logo: string;
  website: string;
  sourceMeta?: ISourceMeta;
  createdBy: IUser;
  org: IOrg;
}

export type ISourceMetaDataPublicInfoLabel =
  | "beta"
  | "live"
  | "deprecated"
  | "thirdparty"
  | "technical";

export type ISupportedTypes =
  | "float"
  | "int"
  | "string"
  | "password"
  | "boolean"
  | "date"
  | "select"
  // those types come from the action hub 😅
  | "textarea"
  | "oauth_link_google"
  | "oauth_link";

export interface ISourceValue {
  id: string;
  value: string;
  option: ISourceOption;
  createdBy: IUser;
  org: IOrg;
  source: ISource;
}

export interface ISourceOption extends IBaseCatalogItemOption {
  required: boolean;
  id: string;
  key: string;
  label: string;
  slug: string;
  type: ISupportedTypes;
  options?: string | Array<{ label: string; value: string }>;
  editable: boolean;
  hidden: boolean;
  encrypt: boolean;
  description: string;
  defaultValue?: string;
  sourceMeta: ISourceMeta;
  createdBy: IUser;
  org: IOrg;
}

export enum SLAPeriodEnum {
  "one_hour" = "one_hour",
  "two_hours" = "two_hours",
  "three_hours" = "three_hours",
  "six_hours" = "six_hours",
  "eight_hours" = "eight_hours",
  "twelve_hours" = "twelve_hours",
  "twenty_four_hours" = "twenty_four_hours",
  "forty_eight_hours" = "forty_eight_hours",
}

export interface ISource {
  name: string;
  slug: string;
  status: SourceStatus;
  syncStatus: JobSyncStatus;
  lastJobExecution: IJobExecution;
  syncPeriod: JobSyncPeriod;
  shouldCheckSla: boolean;
  slaPeriod?: SLAPeriodEnum;
  managedBy: "WHALY" | "DBT_CLOUD";
  error?: "unknown" | "unauthorized" | null;
  values: ISourceValue[];
  sourceMeta: ISourceMeta;
  datasets: IDataset[];
  _datasetsMeta?: {
    count: number;
  };
  createdBy: IUser;
  org: IOrg;
  id: string;
  tables: Table[];
  lastSyncDate?: string;
  nextSyncDate?: string;
  catalogFileURI?: string;
  stateFileURI?: string;
  targetDatabase?: string;
  targetSchema?: string;
  isEnabled: boolean;
  isDeleted?: boolean;
  warehouse: IDestination;
}

export interface IDataset {
  id: string;
  slug: string;
  name: string;
  description: string;
  type: IDatasetType;
  folder?: IModelFolder;
  isModel?: boolean;
  head?: string;
  sql: string;
  baseQuery: Transformation[];
  rawQuery: string;
  primaryKey: string;
  runResults: string; // This contains stringified JSON
  hideFromInterface: boolean;
  /**
   * Schema:
   * {
   * "type": "every" | "column" | "sql" | "whaly_synced",
   * "value": "2 minute" // When type=every
   * "value": "SELECT ..." // When type=sql
   * "value": "updated_at" // When type=column
   * "value": undefined // When type=whaly_synced
   * }
   */
  cacheStrategy?: string;
  managedBy: "WHALY" | "DBT_CLOUD";
  dbtFileName: string;
  dbtRenderedSql: string;
  dependsOn: Array<{
    child: {
      id: string;
    };
  }>;
  isChildOf: Array<{
    parent: {
      id: string;
    };
  }>;
  columnTests?: string;
  warehouseTableId: string;
  warehouseSchemaId: string;
  warehouseDatabaseId: string;
  isPersistedAs: IsPersistedAsType;
  warehouseViewTableId: string;
  warehouseViewSchemaId: string;
  warehouseViewDatabaseId: string;
  warehouseFields: IWarehouseField[];
  warehouseSize: IWarehouseSize;
  outgoingRelationships: IDatasetRelationship[];
  incomingRelationships: IDatasetRelationship[];
  query: IQueryModel;
  views: IView[];
  source: ISource;
  updatedAt?: string;
}

export type IParsedColumnTest =
  | IParsedColumnTestBase
  | IParsedColumnTestAcceptedValues;

export interface IParsedColumnTestBase {
  id: string;
  column: string;
  type: IColumnTestType;
  config: IColumnTestConfig;
}

export interface IParsedColumnTestAcceptedValues extends IParsedColumnTestBase {
  type: "accepted_values";
  values: string[];
  quote: boolean;
}

export type IColumnTestSeverity = "error" | "warn";

// check doc here https://docs.getdbt.com/reference/data-test-configs
export interface IColumnTestConfig {
  limit?: number;
  severity?: IColumnTestSeverity;
}

export type IColumnTestType = "not_null" | "unique" | "accepted_values";

export type IsPersistedAsType = "ephemeral" | "view" | "table";

export type IDatasetType = "TABLE" | "SQL" | "WAREHOUSE" | "FLOW";

export interface IDatasetRelationship {
  id: string;
  type: IRelationshipType;
  from: string;
  to: string;
  left: IDataset;
  right: IDataset;
  editable: boolean;
}

type IWarehouseField = FieldTableInfo;

interface IWarehouseSize {
  rowCount?: string;
  sizeBytes?: string;
}

export interface IParsedWarehouseSize {
  rowCount?: number;
  sizeBytes?: number;
}

export type SourceStatus = "discovery" | "initial" | "running" | "error";
