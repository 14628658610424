import { Flex, Popover, Space, Tag, Typography } from "antd";
import type { TooltipPlacement } from "antd/lib/tooltip";
import type { ReactNode } from "react";
import { Fragment } from "react";
import { useCss } from "react-use";
import dbt from "../../../../assets/dbt.webp";
import whaly from "../../../../assets/icon.svg";
import type { IDataset } from "../../../../interfaces/sources";
import type { TableTabItem } from "../../../spreadsheet/domain";
import type { FetchedDestination } from "../domain";

export const toDisplayErrors = (tabData: TableTabItem): string[] => {
  let errors: string[] = [];

  const runResultErrors = tabData.runResults.filter(
    (r) => r.status !== "success"
  );

  if (
    runResultErrors.find(
      (err) =>
        err.type === "configuration" && err.operationName === "primary_key"
    )
  ) {
    errors.push("Missing primary key");
  }

  if (tabData.hasUpstreamError) {
    errors.push("Upstream error");
  }

  const numberOfFailedTest = runResultErrors.filter(
    (err) => err.type === "test"
  );
  if (numberOfFailedTest.length > 0) {
    errors.push(`${numberOfFailedTest.length} failed tests`);
  }

  const freshnessError = runResultErrors.find(
    (err) => err.type === "freshness"
  );
  if (freshnessError) {
    errors.push("Freshness error");
  }

  const materializationError = runResultErrors.find(
    (err) => err.type === "materialization"
  );
  if (materializationError) {
    errors.push("Materialization error");
  }
  return errors;
};

type DatasetPopoverProps = {
  tabData?: TableTabItem;
  destination: FetchedDestination;
  placement: TooltipPlacement;
  isDragging?: boolean;
  children?: ReactNode;
};
export const DatasetPopover = ({
  tabData,
  destination,
  placement,
  isDragging,
  children,
}: DatasetPopoverProps) => {
  if (isDragging || !tabData?.dataset) {
    return <>{children}</>;
  }

  const showLogo = destination.isDbtCloudSyncEnabled;
  const isMaterialized = ["view", "table"].includes(
    tabData.dataset.isPersistedAs
  );
  const errors = toDisplayErrors(tabData).map((err, i) => {
    return (
      <Tag color="error" key={i}>
        {err}
      </Tag>
    );
  });

  return (
    <Popover
      content={
        <div style={{ maxWidth: 250 }}>
          <div>
            {tabData.dataset.description ?? (
              <Typography.Text italic>Empty description</Typography.Text>
            )}
          </div>

          {errors && errors.length > 0 && (
            <Space style={{ paddingTop: 8 }} size={[0, 8]} wrap>
              {errors.map((tag, i) => {
                return <Fragment key={i}>{tag}</Fragment>;
              })}
            </Space>
          )}
        </div>
      }
      title={
        <Flex vertical>
          <Flex align="center" style={{ maxWidth: 350 }}>
            {showLogo ? <Logo managedBy={tabData.dataset.managedBy} /> : null}{" "}
            <Typography.Text ellipsis strong>
              {tabData.dataset.name}
            </Typography.Text>
          </Flex>

          <Flex gap="2px" wrap="wrap">
            {isMaterialized && <Tag color="purple">Materialized</Tag>}
          </Flex>
        </Flex>
      }
      trigger="hover"
      placement={placement}
      style={{ maxWidth: 250 }}
    >
      {children}
    </Popover>
  );
};

const Logo = ({ managedBy }: { managedBy: IDataset["managedBy"] }) => {
  const cs = useCss({
    height: 22,
    display: "flex",
    alignItems: "inherit",
    paddingRight: 6,
  });

  switch (managedBy) {
    case "WHALY":
      return (
        <div className={cs}>
          <img src={whaly} alt="whaly" style={{ width: 15, height: 15 }} />
        </div>
      );
    case "DBT_CLOUD":
      return (
        <div className={cs}>
          <img src={dbt} alt="dbt" style={{ width: 15, height: 15 }} />
        </div>
      );
  }
};
