// @ts-strict-ignore
import { gql } from "@apollo/client";
import type { DeepPartial } from "@apollo/client/utilities";
import { Modal } from "antd";
import _ from "lodash";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import type { InjectedAntUtilsProps } from "../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../components/ant-utils/withAntUtils";
import { compose } from "../../../components/compose/WlyCompose";
import type {
  IExploration,
  IExplorationSection,
} from "../../../interfaces/explorations";
import type { IFileUpload } from "../../../interfaces/fileUpload";
import type { IJobExecution } from "../../../interfaces/jobExecutions";
import type { IModelFolder } from "../../../interfaces/modelFolder";
import type { IObject, IObjectLayout } from "../../../interfaces/object";
import type { IRadar } from "../../../interfaces/radar";
import type { IDataset, ISource } from "../../../interfaces/sources";
import type { ITable } from "../../../interfaces/table";
import type { IWorksheet } from "../../../interfaces/worksheet";
import { routeDescriptor } from "../../../routes/routes";
import type { IObjectUpdatePayload } from "../../../services/ExplorationMutationService";
import { mutateObjectFromCode } from "../../../services/ExplorationMutationService";
import GraphQLService from "../../../services/graphql/GraphQLService";
import { EXPLORATION_SECTION_FRAGMENT } from "../../../store/dataStores/workspace/WorkspaceDatastoreDomain";
import { getBrowserTimezone } from "../../../utils/cubejsUtils";
import {
  DATASET_FRAGMENT,
  EXPLORATION_ITEM_FRAGMENT,
} from "../../exploration/single/domain";
import type { InjectedOrgProps } from "../../orgs/WithOrg";
import WithOrg, { getCurrentWarehouse } from "../../orgs/WithOrg";
import WorkbenchWrapper from "../workbench/WorkbenchWrapper";
import type {
  FetchedDestination,
  UpdateDestination,
} from "../workbench/domain";
import { TableType, generateFakeId } from "../workbench/exploration/domain";
import type {
  IOnDeleteConnector,
  IOnUpdateConnector,
} from "../workbench/selector/connectors/ConnectorsTable";
import {
  createFileUploader,
  updateFileUploader,
  type CreateFileUploaderJobFunction,
  type UpdateFileUploaderJobFunction,
} from "../workbench/selector/file-uploader/domain";
import type {
  CreateObjectFunction,
  UpdateObjectFunction,
} from "../workbench/selector/object/domain";
import type {
  CreateRadarFunction,
  UpdateRadarFunction,
} from "../workbench/selector/radar/domain";
import type { IObjectLayoutFragment, IWorkbenchData } from "./domain";
import {
  MODEL_FOLDER_FRAGMENT,
  OBJECT_FRAGMENT,
  OBJECT_LAYOUT_FRAGMENT,
  RADAR_LIST_FRAGMENT,
  storeValueFromCache,
} from "./domain";

interface IWorkbenchPageProps {
  saving: (b: boolean) => any;
  datasets: IDataset[];
  sources: ISource[];
  connectors: ISource[];
  destinationJobExecutions: IJobExecution[];
  destination?: FetchedDestination;
  worksheets: IWorksheet[];
  explorations: IExploration[];
  explorationSections: IExplorationSection[];
  allObjectLayouts: IObjectLayoutFragment[];
  modelFolders: IModelFolder[];
  objects: IObject[];
  radars: IRadar[];
  fileUploadJobs: IFileUpload[];
  height?: number;
  cancelJobExecutionRun: (exec: IJobExecution) => Promise<void>;
  onRefreshJobExecutions: () => Promise<void>;
  renderRoute?: (params: object, query?: object | undefined) => string;
  onDestinationUpdate: UpdateDestination;
}

interface IState {
  datasets: IDataset[];
  sources: ISource[];
  explorations: IExploration[];
  explorationSections: IExplorationSection[];
  modelFolders: IModelFolder[];
  worksheets: IWorksheet[];
  errorOnWarehouse: boolean;
  connectors: ISource[];
  objects: IObject[];
  allObjectLayouts: IObjectLayoutFragment[];
  radars: IRadar[];
  fileUploadJobs: IFileUpload[];
}

type Props = InjectedOrgProps &
  IWorkbenchPageProps &
  InjectedAntUtilsProps &
  RouteComponentProps<{ warehouseSlug?: string }>;

class WorkbenchPage extends React.Component<Props, IState> {
  refreshInterval?: number;
  constructor(props: Props) {
    super(props);
    this.refreshInterval = window.setInterval(this.refreshData, 2000);
    const currentWarehouse = getCurrentWarehouse(
      props.org,
      props.match.params.warehouseSlug
    );
    this.state = {
      datasets: props.datasets,
      sources: props.sources,
      explorations: props.explorations,
      explorationSections: props.explorationSections,
      modelFolders: props.modelFolders,
      worksheets: props.worksheets,
      objects: props.objects,
      errorOnWarehouse: !currentWarehouse?.isBiEnabled,
      connectors: props.connectors,
      allObjectLayouts: props.allObjectLayouts,
      radars: props.radars,
      fileUploadJobs: props.fileUploadJobs,
    };
  }

  componentWillUnmount() {
    window.clearInterval(this.refreshInterval);
    this.setState({});
  }

  refreshData = () => {
    const {
      destination,
      destinationJobExecutions,
      onRefreshJobExecutions,
      org,
      match,
    } = this.props;
    const currentWarehouse = getCurrentWarehouse(
      org,
      match.params.warehouseSlug
    );
    if (
      currentWarehouse?.isDataLoadingEnabled ||
      destination?.dbtCloudSyncStatus === "scheduled" ||
      destination?.dbtCloudSyncStatus === "syncing" ||
      destination?.persistenceEngineSyncStatus === "scheduled" ||
      destination?.persistenceEngineSyncStatus === "syncing" ||
      destinationJobExecutions[0]?.status === "RUNNING"
    ) {
      onRefreshJobExecutions();
    }
  };

  componentDidUpdate(prevProps: Props, prevState: IState) {
    const currentWarehouse = getCurrentWarehouse(
      this.props.org,
      this.props.match.params.warehouseSlug
    );
    const prevCurrentWarehouse = getCurrentWarehouse(
      prevProps.org,
      prevProps.match.params.warehouseSlug
    );
    if (!_.isEqual(this.props.datasets, prevProps.datasets)) {
      this.setState({
        datasets: this.props.datasets,
      });
    }
    if (!_.isEqual(this.props.sources, prevProps.sources)) {
      this.setState({
        sources: this.props.sources,
      });
    }
    if (!_.isEqual(this.props.explorations, prevProps.explorations)) {
      this.setState({
        explorations: this.props.explorations,
      });
    }
    if (
      !_.isEqual(this.props.explorationSections, prevProps.explorationSections)
    ) {
      this.setState({
        explorationSections: this.props.explorationSections,
      });
    }

    if (
      !_.isEqual(
        currentWarehouse?.isBiEnabled,
        prevCurrentWarehouse?.isBiEnabled
      )
    ) {
      this.setState({
        errorOnWarehouse: !currentWarehouse?.isBiEnabled,
      });
    }
    if (!_.isEqual(this.props.worksheets, prevProps.worksheets)) {
      this.setState({
        worksheets: this.props.worksheets,
      });
    }
    if (!_.isEqual(this.props.objects, prevProps.objects)) {
      this.setState({
        objects: this.props.objects,
      });
    }
    if (!_.isEqual(this.props.connectors, prevProps.connectors)) {
      this.setState({
        connectors: this.props.connectors,
      });
    }
    if (!_.isEqual(this.props.allObjectLayouts, prevProps.allObjectLayouts)) {
      this.setState({
        allObjectLayouts: this.props.allObjectLayouts,
      });
    }
    if (!_.isEqual(this.props.radars, prevProps.radars)) {
      this.setState({
        radars: this.props.radars,
      });
    }
    if (!_.isEqual(this.props.fileUploadJobs, prevProps.fileUploadJobs)) {
      this.setState({
        fileUploadJobs: this.props.fileUploadJobs,
      });
    }
    if (
      !_.isEqual(this.state, prevState) ||
      this.props.destination !== prevProps.destination
    ) {
      if (this.props.destination?.id) {
        this.cacheData(
          this.props.user.id,
          this.props.org.id,
          this.props.destination?.id,
          this.state
        );
      }
    }
  }

  private cacheData = (
    userId: string,
    orgId: string,
    warehouseId: string,
    state: IState
  ) => {
    const data: IWorkbenchData = {
      Destination: this.props.destination,
      allDatasets: state.datasets,
      allExplorationSections: state.explorationSections,
      allExplorations: state.explorations,
      allModelFolders: state.modelFolders,
      allObjects: state.objects,
      allSources: state.sources,
      allWorksheets: state.worksheets,
      connectors: state.connectors,
      destinationJobExecutions: this.props.destinationJobExecutions,
      allObjectLayouts: this.props.allObjectLayouts,
      allRadars: state.radars,
      allFileUploadJobs: state.fileUploadJobs,
    };
    storeValueFromCache(userId, orgId, warehouseId, data);
  };

  public onRefreshDataset = (ids?: string[]): Promise<void> => {
    const currentWarehouse = getCurrentWarehouse(
      this.props.org,
      this.props.match.params.warehouseSlug
    );
    return GraphQLService(
      `
        ${DATASET_FRAGMENT}

        query allDatasets($datasetIds: [ID]!, $orgId: ID!, $destinationId: ID!) {
          allDatasets(
            where: {
              org: { id: $orgId }
              id_in: $datasetIds
              deleted_not: true
              warehouse: { id: $destinationId }
            }
          ) {
            ...DatasetQuery
          }
          allSources(
            where: {
              AND: [
                {
                  org: { id: $orgId }
                  isDeleted_not: true
                  hideFromInterface: false
                  warehouse: { id: $destinationId }
                }
              ]
            }
          ) {
            id
            managedBy
            name
            status
            sourceMeta {
              executor
              publicInfo {
                logo
              }
            }
          }
        }
      `,
      {
        datasetIds: ids ? ids : this.state.datasets.map((t) => t.id),
        orgId: this.props.org.id,
        destinationId: currentWarehouse?.id,
      }
    ).then((r) => {
      const previousDatasetData = this.state.datasets;
      const updatedDatasetData = r.allDatasets as IDataset[];

      let newDatasetData: IDataset[] = [];

      // we update all datasets if no id were provided
      if (!ids || ids.length === 0) {
        newDatasetData = updatedDatasetData;
      } else {
        newDatasetData = previousDatasetData;
        // we update datasets that were updated
        newDatasetData = [
          ...newDatasetData.map((nd) => {
            const updateIndex = updatedDatasetData.findIndex(
              (ud) => ud.id === nd.id
            );
            if (updateIndex > -1) {
              return updatedDatasetData[updateIndex];
            } else {
              return {
                ...nd,
              };
            }
          }),
        ];
        // we add new datasets
        updatedDatasetData.forEach((ud) => {
          if (newDatasetData.findIndex((nd) => nd.id === ud.id) === -1) {
            newDatasetData = [...newDatasetData, ud];
          }
        });
        // we delete removed datasets;
        ids.forEach((id) => {
          const index = updatedDatasetData.findIndex((ud) => ud.id === id);
          if (index > -1) return;
          newDatasetData = [...newDatasetData.filter((nd) => nd.id !== id)];
        });
      }

      const sourcesData = r.allSources as ISource[];
      return new Promise((resolve, reject) => {
        this.setState(
          {
            datasets: newDatasetData,
            sources: sourcesData,
          },
          () => {
            return resolve();
          }
        );
      });
    });
  };

  public onRefreshQuery = (): Promise<void> => {
    return GraphQLService(
      `
    ${DATASET_FRAGMENT}

    query allDatasets($datasetIds: [ID]!) {
      allDatasets(where: { id_in: $datasetIds}) {
        ...DatasetQuery
      }
    }
    `,
      {
        datasetIds: this.state.datasets.map((t) => t.id),
      }
    ).then((r) => {
      const datasetData = r.allDatasets as IDataset[];
      return new Promise((resolve, reject) => {
        this.setState(
          {
            datasets: datasetData,
          },
          () => {
            return resolve();
          }
        );
      });
    });
  };

  public onRefreshSections = async (ids: string[]) => {
    await GraphQLService(
      `
      ${EXPLORATION_SECTION_FRAGMENT}

    query allExplorationSections($sectionIds: [ID]!, $orgId: ID!) {
      allExplorationSections(sortBy: name_ASC, where: { org: { id: $orgId }, id_in: $sectionIds, deleted_not: true }) {
        ...Section
      }
    }
    `,
      {
        sectionIds: ids ? ids : this.state.explorationSections.map((t) => t.id),
        orgId: this.props.org.id,
      }
    ).then((r) => {
      const previousSectionData = this.state.explorationSections;
      const updatedSectionData =
        r.allExplorationSections as IExplorationSection[];

      let newExplorationSectionData: IExplorationSection[] = [];

      // we update all datasets if no id were provided
      if (!ids || ids.length === 0) {
        newExplorationSectionData = updatedSectionData;
      } else {
        newExplorationSectionData = previousSectionData;
        // we update datasets that were updated
        newExplorationSectionData = [
          ...newExplorationSectionData.map((nd) => {
            const updateIndex = updatedSectionData.findIndex(
              (ud) => ud.id === nd.id
            );
            if (updateIndex > -1) {
              return updatedSectionData[updateIndex];
            } else {
              return {
                ...nd,
              };
            }
          }),
        ];
        // we add new datasets
        updatedSectionData.forEach((ud) => {
          if (
            newExplorationSectionData.findIndex((nd) => nd.id === ud.id) === -1
          ) {
            newExplorationSectionData = [...newExplorationSectionData, ud];
          }
        });
        // we delete removed datasets;
        ids.forEach((id) => {
          const index = updatedSectionData.findIndex((ud) => ud.id === id);
          if (index > -1) return;
          newExplorationSectionData = [
            ...newExplorationSectionData.filter((nd) => nd.id !== id),
          ];
        });
      }

      return new Promise<void>((resolve, reject) => {
        this.setState(
          {
            explorationSections: newExplorationSectionData,
          },
          () => {
            return resolve();
          }
        );
      });
    });
  };

  public onRefreshExplorations = async (ids: string[]) => {
    const currentWarehouse = getCurrentWarehouse(
      this.props.org,
      this.props.match.params.warehouseSlug
    );
    await GraphQLService(
      `
      ${EXPLORATION_ITEM_FRAGMENT}

      query allExplorations($explorationIds: [ID]!, $orgId: ID!, $destinationId: ID!) {
        allExplorations(
          sortBy: name_ASC,
          where: {
            org: { id: $orgId },
            id_in: $explorationIds,
            warehouse: { id: $destinationId }
            deleted_not: true
          }) {
          ...Exploration
        }
      }
    `,
      {
        explorationIds: ids ? ids : this.state.explorations.map((t) => t.id),
        orgId: this.props.org.id,
        destinationId: currentWarehouse?.id,
      }
    ).then((r) => {
      const previousExplorationData = this.state.explorations;
      const updatedSectionData = r.allExplorations as IExploration[];

      let newExplorationData: IExploration[] = [];

      // we update all datasets if no id were provided
      if (!ids || ids.length === 0) {
        newExplorationData = updatedSectionData;
      } else {
        newExplorationData = previousExplorationData;
        // we update datasets that were updated
        newExplorationData = [
          ...newExplorationData.map((nd) => {
            const updateIndex = updatedSectionData.findIndex(
              (ud) => ud.id === nd.id
            );
            if (updateIndex > -1) {
              return updatedSectionData[updateIndex];
            } else {
              return {
                ...nd,
              };
            }
          }),
        ];
        // we add new datasets
        updatedSectionData.forEach((ud) => {
          if (newExplorationData.findIndex((nd) => nd.id === ud.id) === -1) {
            newExplorationData = [...newExplorationData, ud];
          }
        });
        // we delete removed datasets;
        ids.forEach((id) => {
          const index = updatedSectionData.findIndex((ud) => ud.id === id);
          if (index > -1) return;
          newExplorationData = [
            ...newExplorationData.filter((nd) => nd.id !== id),
          ];
        });
      }

      return new Promise<void>((resolve, reject) => {
        this.setState(
          {
            explorations: newExplorationData,
          },
          () => {
            return resolve();
          }
        );
      });
    });
  };

  public onRefreshModelFolders = async () => {
    try {
      const currentWarehouse = getCurrentWarehouse(
        this.props.org,
        this.props.match.params.warehouseSlug
      );
      const query = await GraphQLService<{ allModelFolders: IModelFolder[] }>(
        `
          ${MODEL_FOLDER_FRAGMENT}
          query refreshModelFolders($orgId: ID!, $destinationId: ID!) {
            allModelFolders(
              sortBy: name_ASC
              where: { org: { id: $orgId }, warehouse: { id: $destinationId }, isDeleted_not: true }
            ) {
              ...ModelFolderQuery
            }
          }
        `,
        {
          orgId: this.props.org.id,
          destinationId: currentWarehouse?.id,
        }
      );
      this.setState({ modelFolders: query.allModelFolders });
    } catch (error) {
      console.error(error);
    }
  };

  public createWorksheet = () => {
    const { user } = this.props;
    const newId = generateFakeId();
    this.setState({
      worksheets: [
        {
          id: newId,
          query: "",
          createdAt: new Date().toISOString(),
          createdBy: user,
        } as IWorksheet,
        ...this.state.worksheets,
      ],
    });
    return newId;
  };

  public updateWorksheet = (id: string, data: Partial<IWorksheet>) => {
    this.setState({
      worksheets: this.state.worksheets
        .map((w) => {
          if (w.id === id) {
            return {
              ...w,
              ...data,
            };
          }
          return w;
        })
        .filter((w) => !w.deleted),
    });
  };

  public createTempObjectLayout = (object: IObjectLayoutFragment) => {
    this.setState({
      allObjectLayouts: [object, ...this.state.allObjectLayouts],
    });
    return object.id;
  };

  public onUpdateObjectLayout = async (
    objectLayoutId: string,
    data: DeepPartial<IObjectLayout>
  ): Promise<void> => {
    const newData = await GraphQLService<{
      updateObjectLayout: IObjectLayoutFragment;
    }>(
      `
      ${OBJECT_LAYOUT_FRAGMENT}

      mutation updateObjectLayout($id: ID!, $data: ObjectLayoutUpdateInput) {
        updateObjectLayout(id: $id, data: $data) {
          ...ObjectLayoutFragment
       }   
     }
    `,
      {
        id: objectLayoutId,
        data,
      }
    );

    this.setState((v) => ({
      allObjectLayouts: v.allObjectLayouts
        .filter((a) => {
          if (
            data.isDeleted === true &&
            a.id === newData.updateObjectLayout.id
          ) {
            return false;
          } else {
            return true;
          }
        })
        .map((m) => {
          if (m.id === newData.updateObjectLayout.id) {
            return newData.updateObjectLayout;
          }
          return m;
        }),
    }));
  };

  public onWorksheetRefresh = async (swap?: {
    fakeId: string;
    newId: string;
  }) => {
    if (swap) {
      this.setState({
        worksheets: this.state.worksheets.map((w) => {
          if (w.id === swap.fakeId) {
            return {
              ...w,
              id: swap.newId,
            };
          }
          return w;
        }),
      });
    }
  };

  public createObject: CreateObjectFunction = async (options) => {
    const { org } = this.props;
    try {
      const objectUpdatePayload: IObjectUpdatePayload = {
        name: options.name,
        icon: options.icon,
        color: options.color,
        conf: {
          canBeListed: false,
          name: options.name,
          icon: options.icon,
          color: options.color,
          properties: options.properties.map((p) => {
            return {
              tempId: generateFakeId(),
              columnDomain: p.columnDomain,
              columnName: p.columnName,
              label: p.label,
            };
          }),
        },
        table: {
          name: options.name,
          dimensions: [],
          drills: options.primaryKey,
          metrics: options.metrics.map((m) => {
            const { condition, format, drills, ...rest } = m;
            return {
              tempId: generateFakeId(),
              ...rest,
            };
          }),
          primaryKey: options.primaryKey,
          semanticGroups: [],
          tableType: TableType.REGULAR,
          tempId: generateFakeId(),
          view: options.viewId,
        },
      };

      const d = await mutateObjectFromCode(
        org.id,
        options.modelId,
        objectUpdatePayload
      );

      const data = await GraphQLService<{ Object: IObject }>(
        `
          ${OBJECT_FRAGMENT}

          query getObject($id: ID!) {
            Object(where: { id: $id }) {
              ...ObjectQuery
            }
          }
        `,
        {
          id: d!.objectId,
        }
      );

      this.setState({
        objects: [
          {
            ...data.Object,
            id: data.Object.id,
            org: this.props.org,
            name: options.name,
            isDeleted: false,
          },
          ...this.state.objects,
        ],
      });

      return data.Object.id;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  public updateRadar: UpdateRadarFunction = async (id, data) => {
    try {
      let gqlData: Record<string, any> = data;
      if (data.model?.id) {
        gqlData = {
          ...gqlData,
          model: { connect: { id: data.model.id } },
        };
      }
      if (data.object?.id) {
        gqlData = {
          ...gqlData,
          object: { connect: { id: data.object.id } },
        };
      }
      if (data.emailLayout?.id) {
        gqlData = {
          ...gqlData,
          emailLayout: { connect: { id: data.emailLayout.id } },
        };
      }
      // we are updating the radar itself
      const request = await GraphQLService<{ updateRadar: IRadar }>(
        gql`
          ${RADAR_LIST_FRAGMENT}
          mutation updateRadar($id: ID!, $data: RadarUpdateInput!) {
            updateRadar(id: $id, data: $data) {
              ...RadarListFragment
            }
          }
        `,
        {
          id: id,
          data: gqlData,
        }
      );
      this.setState({
        radars: this.state.radars
          .map((radar) => {
            if (radar.id === id) {
              return {
                ...radar,
                ...request.updateRadar,
              };
            }
            return radar;
          })
          .filter((radar) => !radar.isDeleted),
      });
    } catch (error) {
      console.error(error);
    }
  };

  public createRadar: CreateRadarFunction = async (options) => {
    const { org, destination } = this.props;
    const currentWarehouse = getCurrentWarehouse(
      this.props.org,
      this.props.match.params.warehouseSlug
    );
    try {
      // first we create the radar
      // then the schedule
      if (!currentWarehouse) {
        throw new Error("Warehouse must be defined");
      }
      const data = await GraphQLService<{ createRadar: IRadar }>(
        gql`
          ${RADAR_LIST_FRAGMENT}

          mutation createRadar($data: RadarCreateInput) {
            createRadar(data: $data) {
              ...RadarListFragment
              slug
            }
          }
        `,
        {
          data: {
            name: options.name,
            recordIdKey: options.recordIdKey,
            warehouseSchemaId: destination?.signalEngineDefaultTargetSchema,
            warehouseDatabaseId: destination?.signalEngineDefaultTargetDatabase,
            object: {
              connect: {
                id: options.object.id,
              },
            },
            model: {
              connect: {
                id: options.model.id,
              },
            },
            warehouse: {
              connect: {
                id: currentWarehouse.id,
              },
            },
            schedule: {
              create: {
                name: options.name,
                type: "radar",
                status: "idle",
                period: "never",
                timezone: getBrowserTimezone(),
                org: {
                  connect: {
                    id: org.id,
                  },
                },
              },
            },
            org: {
              connect: {
                id: org.id,
              },
            },
          },
        }
      );

      await GraphQLService<{ updateRadar: IRadar }>(
        gql`
          ${RADAR_LIST_FRAGMENT}
          mutation updateRadar($id: ID!, $data: RadarUpdateInput!) {
            updateRadar(id: $id, data: $data) {
              ...RadarListFragment
            }
          }
        `,
        {
          id: data.createRadar.id,
          data: {
            warehouseTableId: data.createRadar.slug,
          },
        }
      );

      this.setState({ radars: [...this.state.radars, data.createRadar] });

      return data.createRadar.id;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  public createFileUploadJob: CreateFileUploaderJobFunction = async (
    opts,
    orgId
  ) => {
    const id = await createFileUploader(opts, orgId);
    this.setState((prevState) => ({
      fileUploadJobs: [...prevState.fileUploadJobs, { ...opts, id: id } as any],
    }));
    return id;
  };

  public updateFileUploadJob: UpdateFileUploaderJobFunction = async (
    id,
    data
  ) => {
    await updateFileUploader(id, data);
    if (data.isDeleted) {
      this.setState((prevState) => ({
        fileUploadJobs: prevState.fileUploadJobs.filter((fj) => fj.id !== id),
      }));
    } else if (data.name) {
      this.setState((prevState) => ({
        fileUploadJobs: prevState.fileUploadJobs.map((fj) => {
          if (fj.id === id) {
            return { ...fj, name: data.name } as IFileUpload;
          }
          return fj;
        }),
      }));
    }
  };

  public updateObject: UpdateObjectFunction = async (id, data) => {
    try {
      if (data.model?.id && data.table?.id && data.table?.view?.id) {
        // we are updating the table and model ref
        // first we update the table
        await GraphQLService<{ updateTable: ITable }>(
          `
            mutation updateTable($id: ID!, $data: TableUpdateInput!) {
              updateTable(id: $id, data: $data) {
                id
              }
            }
          `,
          {
            id: data.table?.id,
            data: {
              view: {
                connect: {
                  id: data.table?.view?.id,
                },
              },
            },
          }
        );
        // we are updating the object itself
        const request = await GraphQLService<{ updateObject: IObject }>(
          `
                    ${OBJECT_FRAGMENT}
                    mutation updateObject($id: ID!, $data: ObjectUpdateInput!) {
                      updateObject(id: $id, data: $data) {
                        ...ObjectQuery
                      }
                    }
                  `,
          {
            id: id,
            data: {
              model: {
                connect: {
                  id: data?.model?.id,
                },
              },
            },
          }
        );
        this.setState({
          objects: this.state.objects
            .map((object) => {
              if (object.id === id) {
                return {
                  ...object,
                  ...request.updateObject,
                };
              }
              return object;
            })
            .filter((object) => !object.isDeleted),
        });
      } else {
        // we are updating the object itself
        const request = await GraphQLService<{ updateObject: IObject }>(
          `
            ${OBJECT_FRAGMENT}
            mutation updateObject($id: ID!, $data: ObjectUpdateInput!) {
              updateObject(id: $id, data: $data) {
                ...ObjectQuery
              }
            }
          `,
          {
            id: id,
            data: data,
          }
        );
        this.setState({
          objects: this.state.objects
            .map((object) => {
              if (object.id === id) {
                return {
                  ...object,
                  ...request.updateObject,
                };
              }
              return object;
            })
            .filter((object) => !object.isDeleted),
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  public onRefreshModelFolderTree = () => {
    const currentWarehouse = getCurrentWarehouse(
      this.props.org,
      this.props.match.params.warehouseSlug
    );
    return GraphQLService(
      `
        ${MODEL_FOLDER_FRAGMENT}
        query onRefreshModelFolderTree($orgId: ID!, $destinationId: ID!) {
          allDatasets(
            where: {
              org: { id: $orgId }
              deleted_not: true
              warehouse: { id: $destinationId }
            }
          ) {
            id
            updatedAt
            folder {
              id
            }
          }
          allModelFolders(
            sortBy: name_ASC
            where: { org: { id: $orgId }, warehouse: { id: $destinationId }, isDeleted_not: true }
          ) {
            ...ModelFolderQuery
          }
        }
      `,
      {
        orgId: this.props.org.id,
        destinationId: currentWarehouse?.id,
      }
    ).then((query) => {
      const updatedDatasetData = query.allDatasets as IDataset[];
      this.setState((prevState) => ({
        datasets: prevState.datasets.map((d) => {
          const newDatasetData = updatedDatasetData.find(
            (ud) => ud.id === d.id
          );
          return {
            ...d,
            updatedAt: newDatasetData?.updatedAt,
            folder: newDatasetData?.folder,
          };
        }),
        modelFolders: query.allModelFolders,
      }));
    });
  };

  public onDeleteConnector: IOnDeleteConnector = async (connectorId) => {
    try {
      await GraphQLService<{ updateSource: ISource }>(
        `
          mutation deleteSource($id: ID!) {
            updateSource(id: $id, data: { isDeleted: true }) {
              id
            }
          }
        `,
        {
          id: connectorId,
        }
      );
      this.setState((prevState) => ({
        connectors: prevState.connectors.filter(
          (connector) => connector.id !== connectorId
        ),
        sources: prevState.sources.filter(
          (source) => source.id !== connectorId
        ),
      }));
    } catch (error) {
      console.error(error);
    }
  };

  public onUpdateConnector: IOnUpdateConnector = async (connectorId, data) => {
    try {
      await GraphQLService<{ updateSource: ISource }>(
        `
          mutation updateSourceName($id: ID!, $data: SourceUpdateInput) {
            updateSource(id: $id, data: $data) {
              id
            }
          }
        `,
        {
          id: connectorId,
          data: data,
        }
      );
      this.setState((prevState) => ({
        connectors: prevState.connectors.map((connector) => {
          if (connector.id === connectorId) {
            return { ...connector, ...data };
          } else {
            return connector;
          }
        }),
        sources: prevState.sources.map((source) => {
          if (source.id === connectorId) {
            return { ...source, ...data };
          } else {
            return source;
          }
        }),
      }));
    } catch (error) {
      console.error(error);
    }
  };

  public render() {
    const { org, match } = this.props;
    const currentWarehouse = getCurrentWarehouse(
      org,
      match.params.warehouseSlug
    );

    return (
      <>
        <WorkbenchWrapper
          onDeleteConnector={this.onDeleteConnector}
          onUpdateConnector={this.onUpdateConnector}
          onRefreshModelFolderTree={this.onRefreshModelFolderTree}
          onRefreshModelFolders={this.onRefreshModelFolders}
          onRefreshDatasets={this.onRefreshDataset}
          onRefreshQueries={this.onRefreshQuery}
          onRefreshSections={this.onRefreshSections}
          onRefreshExplorations={this.onRefreshExplorations}
          saving={this.props.saving}
          destinationJobExecutions={this.props.destinationJobExecutions}
          modelFolders={this.state.modelFolders}
          destination={this.props.destination}
          cancelJobExecutionRun={this.props.cancelJobExecutionRun}
          onRefreshJobExecutions={this.props.onRefreshJobExecutions}
          onDestinationUpdate={this.props.onDestinationUpdate}
          sources={this.state.sources.filter(
            (s) =>
              !["authentication", "discovery"].includes(s.status) ||
              ["FIVETRAN", "WAREHOUSE"].includes(s.sourceMeta.executor)
          )}
          connectors={this.state.connectors}
          datasets={this.state.datasets}
          explorations={this.state.explorations}
          explorationSections={this.state.explorationSections}
          routeRender={
            this.props.renderRoute
              ? this.props.renderRoute
              : routeDescriptor.workbench.renderRoute
          }
          height={this.props.height}
          worksheets={this.state.worksheets}
          radars={this.state.radars}
          onUpdateRadar={this.updateRadar}
          onCreateRadar={this.createRadar}
          onWorksheetRefresh={this.onWorksheetRefresh}
          createWorksheet={this.createWorksheet}
          updateWorksheet={this.updateWorksheet}
          objects={this.state.objects}
          updateObject={this.updateObject}
          createObject={this.createObject}
          allObjectLayouts={this.state.allObjectLayouts}
          createObjectLayout={this.createTempObjectLayout}
          onUpdateObjectLayout={this.onUpdateObjectLayout}
          fileUploadJobs={this.state.fileUploadJobs}
          onUpdateFileUploader={this.updateFileUploadJob}
          onCreateFileUploader={this.createFileUploadJob}
        />
        <Modal
          title="Finish setting up your warehouse"
          open={this.state.errorOnWarehouse}
          okText={"Reconnect"}
          cancelText={"I will do it later"}
          onOk={() =>
            this.props.history.push(
              routeDescriptor.warehouseConnectionStep2.renderRoute({
                organizationSlug: org.slug,
                itemSlug: currentWarehouse?.destinationMeta.slug,
                itemId: currentWarehouse?.id,
              })
            )
          }
          onCancel={() => this.setState({ errorOnWarehouse: false })}
        >
          <p>
            Your connection appear to not be working. Finish setting it up to
            get up & running.
          </p>
        </Modal>
      </>
    );
  }
}

export default compose<Props, IWorkbenchPageProps>(
  WithOrg,
  withAntUtils,
  withRouter
)(WorkbenchPage);
