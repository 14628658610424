import type { ReactNode } from "react";
import { Children } from "react";
import SanitizeHTML from "sanitize-html";

export const ALLOWED_TAGS = [
  "h1",
  "h2",
  "h3",
  "h4",
  "h5",
  "h6",
  "blockquote",
  "p",
  "ul",
  "ol",
  "li",
  "b",
  "i",
  "strong",
  "italic",
  "u",
  "em",
  "strike",
  "code",
  "hr",
  "br",
  "div",
  "table",
  "thead",
  "caption",
  "tbody",
  "tr",
  "th",
  "td",
  "pre",
  "span",
];

type SafeTextProps = {
  children: ReactNode;
  allowedTags?: string[];
};

export const SafeText = ({
  children,
  allowedTags = ALLOWED_TAGS,
}: SafeTextProps) => {
  const unsafe = Children.toArray(children)
    .filter((child) => typeof child === "string")
    .join("");

  const safe = SanitizeHTML(unsafe, {
    allowedTags,
  });

  return <span dangerouslySetInnerHTML={{ __html: safe }} />;
};
