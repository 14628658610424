import { useLagoonQuery } from "../../../../../components/hooks/query/useLagoonQuery";
import type { IOrg } from "../../../../../interfaces/org";

export const useNullsCount = (org: IOrg, objectId: string, field: string) => {
  const measureKey = `View_${objectId}._wly_count`;
  const dimensionKey = `View_${objectId}.${field}`;
  const { data, loading, error } = useLagoonQuery(
    {
      measures: [measureKey],
      filters: [{ and: [{ member: dimensionKey, operator: "notSet" }] }],
    },
    { org, objectType: "VIEW", objectId }
  );

  return {
    nullsCount: data ? (data[0][measureKey] as number) : undefined,
    loading,
    error,
  };
};
