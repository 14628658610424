import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import { compose } from "../../../components/compose/WlyCompose";
import Error from "../../../components/layout/feedback/error";
import Loading from "../../../components/layout/feedback/loading";
import type { ICatalogValue } from "../../../interfaces/catalog";
import type { ISourceMeta } from "../../../interfaces/sources";
import GraphQl from "../../graphql/graphql";
import type {
  AdditionalFieldsGenerator,
  ItemValueFromInputGenerator,
  Step1CreationPromise,
} from "../connection/steps/step1/ItemConnectionStep1";
import ItemConnectionWrapper from "./steps/ItemConnectionWrapper";
import type {
  InputValidationPromise,
  Step2CreationPromise,
} from "./steps/step2/ItemConnectionStep2";

interface IItemConnectionPageProps {
  allItemsMetaQuery: string;
  remainingItemValuesQuery: string;
  step1CreationPromise: Step1CreationPromise;
  step2CreationPromise: Step2CreationPromise;
  inputValidationPromise?: InputValidationPromise;
  overrideGenerateItemValueFromInput?: ItemValueFromInputGenerator;
  additionalFormFieldsGenerator?: AdditionalFieldsGenerator;
  redirectUri: string;
  submitButtonLabel?: string;
  catalogValueTransformer?: (i: ICatalogValue[]) => Array<ICatalogValue>;
  getDocumentPagePath?: (itemMeta: ISourceMeta) => string | undefined;
}

interface GQLQueryResult {
  allItemMetas: ISourceMeta[];
}

type Props = IItemConnectionPageProps &
  RouteComponentProps<{ itemSlug: string }>;

class ItemConnectionPage extends React.Component<Props> {
  public render() {
    const {
      match: {
        params: { itemSlug },
      },
      allItemsMetaQuery,
      remainingItemValuesQuery,
      step1CreationPromise,
      step2CreationPromise,
      inputValidationPromise,
      overrideGenerateItemValueFromInput,
      additionalFormFieldsGenerator,
      redirectUri,
      submitButtonLabel,
      catalogValueTransformer,
      getDocumentPagePath,
    } = this.props;

    return (
      <GraphQl<GQLQueryResult>
        query={allItemsMetaQuery}
        variables={{ slug: itemSlug }}
      >
        {(gql) => {
          if (gql.status === "initial" || gql.status === "loading") {
            return <Loading />;
          }
          if (gql.status === "error" || gql.data.allItemMetas.length !== 1) {
            return <Error />;
          }
          return (
            <ItemConnectionWrapper
              step1CreationPromise={step1CreationPromise}
              step2CreationPromise={step2CreationPromise}
              itemMeta={gql.data.allItemMetas[0]}
              remainingItemValuesQuery={remainingItemValuesQuery}
              inputValidationPromise={inputValidationPromise}
              overrideGenerateItemValueFromInput={
                overrideGenerateItemValueFromInput
              }
              additionalFormFieldsGenerator={additionalFormFieldsGenerator}
              redirectUri={redirectUri}
              submitButtonLabel={submitButtonLabel}
              catalogValueTransformer={catalogValueTransformer}
              getDocumentPagePath={
                getDocumentPagePath
                  ? getDocumentPagePath
                  : (i) => i.publicInfo.documentationPagePath
              }
            />
          );
        }}
      </GraphQl>
    );
  }
}

export default compose<Props, IItemConnectionPageProps>(withRouter)(
  ItemConnectionPage
);
