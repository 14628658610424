import { CaretRightFilled, CloseOutlined } from "@ant-design/icons";
import { Button, List, Typography } from "antd";
import { type RouteComponentProps, withRouter } from "react-router";
import { compose } from "../../../components/compose/WlyCompose";
import type { IFileUpload } from "../../../interfaces/fileUpload";
import { routeDescriptor } from "../../../routes/routes";
import { BORDER_COLOR } from "../../v2-demo/container/layout/domain";

export interface IFileUploaderListSelectionProps {
  onClose: () => void;
  fileUploadJobs: IFileUpload[];
}

type Props = IFileUploaderListSelectionProps & RouteComponentProps;

function FileUploaderListSelection(props: Props) {
  const {
    onClose,
    fileUploadJobs,
    history,
    match: { params },
  } = props;
  return (
    <div>
      <div
        style={{ borderBottom: `1px solid ${BORDER_COLOR}` }}
        className="file-uploader-title"
      >
        <div className="file-uploader-title-content">
          <Typography.Title
            id={"file-uploader-title"}
            style={{ marginBottom: 0 }}
            level={5}
          >
            Upload a file
          </Typography.Title>
        </div>
        <div className="file-uploader-title-action">
          <Button
            onClick={onClose}
            icon={<CloseOutlined />}
            type="text"
            shape="circle"
          />
        </div>
      </div>
      <div
        style={{
          maxHeight: "Calc(100% - 56px)",
          overflowX: "hidden",
          overflowY: "auto",
        }}
      >
        <List<IFileUpload>
          size="large"
          dataSource={fileUploadJobs}
          renderItem={(item) => (
            <List.Item
              onClick={() =>
                history.push(
                  routeDescriptor.fileUploads.renderRoute({
                    ...params,
                    fileUploadSlug: item.slug,
                  })
                )
              }
            >
              <div className="file-uploader-list-item">
                <div style={{ flex: 1 }}>
                  <div>
                    <Typography.Text strong>{item.name}</Typography.Text>
                  </div>
                  {/* <div>
                    <Typography.Text type="secondary">
                      {item.description}
                    </Typography.Text>
                  </div> */}
                </div>
                <div style={{ flex: `0 24px` }}>
                  <CaretRightFilled />
                </div>
              </div>
            </List.Item>
          )}
        />
      </div>
    </div>
  );
}

export default compose<Props, IFileUploaderListSelectionProps>(withRouter)(
  FileUploaderListSelection
);
