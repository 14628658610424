import { gql } from "@apollo/client";

export const OBJECT_FRAGMENT = gql`
  fragment ObjectFragment on Object {
    id
    slug
    color
    icon
    canBeListed
    name
    canBeManagedByCurrentUser
    defaultView {
      id
      slug
      isDeleted
    }
    properties(where: { isDeleted_not: true }) {
      id
      label
      cubeName
      description
      columnName
      propertyType
      columnDomain
      formatter
      formatterConfig
      userPropertyMapping
      sortingAndFilteringColumn
      hierarchyPath
      foreignKey {
        id
        name
        slug
        cubeName
        table {
          cubeName
        }
      }
    }
    registeredActions(where: { isDeleted_not: true }) {
      id
      name
      displayName
      icon
      type
      config
    }
    table {
      id
      name
      cubeName
      metrics(where: { deleted_not: true, hidden_not: true }) {
        id
        slug
        cubeName
        name
        overrideName
        description
        prefix
        suffix
        format
        overrideFormatting
        hierarchyPath
      }
    }
  }
`;

export const OBJECT_LAYOUT_FRAGMENT = gql`
  fragment ObjectLayoutFragment on ObjectLayout {
    id
    header
    config
    name
    type
    rows(where: { isDeleted_not: true }) {
      id
      name
      description
      displayFilters
      position
      config
    }
    cols(where: { isDeleted_not: true }) {
      id
      name
      position
      config
      row {
        id
      }
    }
    widgets(where: { isDeleted_not: true }) {
      id
      name
      displayFilters
      position
      type
      config
      parentColumn {
        id
      }
    }
  }
`;

export const OBJECT_VIEW_FRAGMENT = gql`
  fragment ObjectViewFragment on ObjectView {
    id
    name
    slug
    viewType
    type
    config
    isDeleted
    isPersonal
  }
`;

export const OBJECT_PRESET_FILTER_FRAGMENT = gql`
  fragment ObjectPresetFilterFragment on ObjectPresetFilter {
    id
    name
    value
    order
  }
`;

export const OBJECT_QUERY_BUILDER_SECTION_FRAGMENT = gql`
  fragment ObjectQueryBuilderSectionFragment on ObjectQueryBuilderSection {
    id
    name
    type
    order
    items(where: { isDeleted_not: true }, sortBy: order_DESC) {
      id
      label
      order
      property
      additionalFilters
    }
  }
`;
