import { Button, Space, Table, Typography } from "antd";
import * as React from "react";
import { ChartDefinition } from "../../../../components/chart/domain";
import { compose } from "../../../../components/compose/WlyCompose";
import EmojiRenderer from "../../../../components/form/emoji-picker/EmojiRenderer";
import Feednack from "../../../../components/layout/feedback/feedback";
import Loading from "../../../../components/layout/feedback/loading";
import { ReportIcon } from "../../../../components/report/ReportIcon";
import { handleGQLErrors } from "../../../../helpers/gqlHelpers";
import type { AsyncData } from "../../../../helpers/typescriptHelpers";
import type { ITile } from "../../../../interfaces/reports";
import { routeDescriptor } from "../../../../routes/routes";
import GraphQLService from "../../../../services/graphql/GraphQLService";
import type { InjectedOrgProps } from "../../../orgs/WithOrg";
import WithOrg from "../../../orgs/WithOrg";

interface IExplorationUsageProps {
  explorationId: string;
}

interface IState {
  data: AsyncData<ITile[]>;
}

type Props = IExplorationUsageProps & InjectedOrgProps;
class ExplorationUsage extends React.Component<Props, IState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      data: {
        status: "initial",
      },
    };
  }

  componentDidMount() {
    this.fetchCharts(this.props.explorationId);
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.explorationId !== prevProps.explorationId) {
      this.fetchCharts(this.props.explorationId);
    }
  }

  fetchCharts = (explorationId: string) => {
    this.setState({ data: { status: "loading" } });
    return GraphQLService(
      `
    query getAssociatedCharts($explorationId: ID!) {
      allTiles(where: { 
        exploration: {
          id: $explorationId
        }, 
        type: "chart", 
        report: {
          deleted_not: true, 
          folder: {
            id_not: null,
            deleted_not: true
          }
        }, 
        deleted_not: true 
      }) {
        id
        name
    		type
    		chartType
        report {
          slug
          name
          type
        }
      }
    }
    `,
      {
        explorationId,
      }
    )
      .then((r) => {
        this.setState({
          data: { status: "success", data: r.allTiles as ITile[] },
        });
      })
      .catch(handleGQLErrors());
  };

  renderInner = () => {
    const { data } = this.state;

    if (data.status === "initial" || data.status === "loading") {
      return (
        <div style={{ height: "100%" }}>
          <Loading />
        </div>
      );
    }
    if (data.status === "error") {
      return <div style={{ height: "100%" }}>{data.error.message}</div>;
    }

    if (data.data.length === 0) {
      return <Feednack>This exploration is not used in any charts</Feednack>;
    }

    return (
      <Table
        dataSource={data.data}
        size="small"
        pagination={false}
        scroll={{
          y: 470,
        }}
        rowKey={(record) => record.id}
        columns={[
          {
            title: "Tile",
            render: (text, _) => (
              <Space size={"large"}>
                {React.cloneElement(ChartDefinition[_.chartType].icon, {
                  style: { fontSize: 28 },
                })}

                {_.name}
              </Space>
            ),
            sorter: (a, b) =>
              a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
          },
          {
            title: "Report",
            render: (text, _) => {
              if (!_.report) {
                return (
                  <Space size="small">
                    <EmojiRenderer emoji=":closed_lock_with_key:" size={18} />
                    <i style={{ paddingLeft: 4 }}>Private report</i>
                  </Space>
                );
              } else {
                return (
                  <Space size="small">
                    <ReportIcon type={_.report.type} />{" "}
                    <Typography.Text>{_.report.name}</Typography.Text>
                  </Space>
                );
              }
            },
            sorter: (a, b) =>
              a.report.name
                .toLowerCase()
                .localeCompare(b.report.name.toLowerCase()),
          },
          {
            title: "Actions",
            width: 100,
            render: (text, _) => {
              return (
                <Button
                  type="link"
                  disabled={!_.report?.slug}
                  onClick={() => {
                    window
                      .open(
                        routeDescriptor.report.renderRoute({
                          organizationSlug: this.props.org.slug,
                          reportSlug: _.report?.slug,
                        }),
                        "_blank"
                      )
                      .focus();
                  }}
                >
                  Open
                </Button>
              );
            },
          },
        ]}
      />
    );
  };

  public render() {
    return this.renderInner();
  }
}

export default compose<Props, IExplorationUsageProps>(WithOrg)(
  ExplorationUsage
);
