import {
  FieldBinaryOutlined,
  LineChartOutlined,
  PieChartOutlined,
} from "@ant-design/icons";
import type {
  BinaryFilter,
  Filter,
  TimeDimensionGranularity,
  UnaryFilter,
} from "@cubejs-client/core";
import React from "react";
import type { ChartType } from "../../../components/chart/domain";
import type { IWlyConditionalFormatterFormOptions } from "../../../components/conditional-formatting/WlyConditionalFormatterForm";
import type { IComparisonPeriod } from "../../../components/measures/comparison-selector/ComparisonSelector";
import type { MeasureItemSortValue } from "../../../components/measures/measure-item/MeasureItem";
import type { ChartOption } from "../../chart-options/ChartOptions";
import type { IWlyDatePickerInputValue } from "../../reports/view/filters/date-filter/WlyDatePicker";

export interface ILagoonQuery {
  selectedMeasures: string[];
  selectedDimensions: string[];
  selectedTime?: string;
  dateRange: IWlyDatePickerInputValue;
  comparison?: IComparisonPeriod;
  selectedGranularity?: TimeDimensionGranularity;
  filterOperator: FilterOperator;
  filters: (UnaryFilter | BinaryFilter)[];
  metricFilterOperator?: FilterOperator;
  metricFilters?: (UnaryFilter | BinaryFilter)[];
  analysisType: IAnalysisType;
  orderBy?: Array<[string, MeasureItemSortValue]>;
  pivotDimensions?: string[];
  limit?: number;
  showOther?: boolean;
  showOtherDimensionLimit?: number;
  chartOptions?: ChartOption;
  chartType?: ChartType;
  forecast?: IForecastConfig;
  timezone?: string;
  extra?: ILagoonQueryExtra;
}

export type ILagoonQueryExtraKeys = "pin-map-chart-label" | "pin-map-color";

export type ILagoonQueryExtra = {
  [key in ILagoonQueryExtraKeys]?: { dimensions?: string[]; metrics?: [] };
};

export type FilterOperator = "or" | "and";

export type GetCurrentQuery = (
  isStale: boolean,
  analysisType: IAnalysisType,
  chartType: ChartType,
  measures: string[],
  dimensions: string[],
  filters: Filter[],
  filterOperator: FilterOperator,
  dateRange: IWlyDatePickerInputValue,
  orderBy: Array<[string, MeasureItemSortValue]>,
  limit: number,
  showOther?: boolean,
  showOtherDimensionLimit?: number,
  comparison?: IComparisonPeriod,
  timeDimension?: string,
  selectedGranularity?: TimeDimensionGranularity,
  pivotConfig?: string[],
  chartOptions?: ChartOption,
  forecast?: IForecastConfig,
  extra?: ILagoonQueryExtra,
  metricFilters?: (UnaryFilter | BinaryFilter)[],
  metricFilterOperator?: FilterOperator
) => void;

export interface IForecastConfig {
  enabled: boolean;
}
export interface ExtractCustomizationSeries {
  series: Array<{ key: string; label: string }>;
  customColor:
    | boolean
    | "palette"
    | "color"
    | "palette::continue"
    | "palette::discrete";
  customLabel: boolean;
  customType: Array<
    "line" | "area" | "scatter" | "bar" | "areapline" | "spline"
  >;
  conditionalFormatting?: IWlyConditionalFormatterFormOptions;
  showVisualization?: boolean;
}

export interface ExtractCustomizationDimension {
  dimensions: Array<{
    key: string;
    label: string;
    labelEditable: boolean;
    momentFormatEditable: boolean;
  }>;
}

export type IAnalysisType = "METRIC" | "CATEGORIES" | "TIME";

export const AnalysisDefiniton: Array<{
  key: IAnalysisType;
  label: string;
  icon: React.ReactNode;
  description: string;
  allowedMetrics: boolean | number;
  allowedDimensions: boolean | number;
  allowedGranularity: boolean;
  availableCharts: ChartType[];
  disabledMessage: string;
  allowSortMetrics: boolean;
  allowSortDimensions: boolean;
}> = [
  {
    key: "METRIC",
    label: "Metric",
    icon: <FieldBinaryOutlined />,
    description: "Compute single value metrics. Ideal for KPI building.",
    allowedMetrics: 4,
    allowedDimensions: false,
    allowedGranularity: false,
    availableCharts: ["kpi", "gauge"],
    disabledMessage: "You need at least one metric to use this chart type.",
    allowSortMetrics: false,
    allowSortDimensions: false,
  },
  {
    key: "CATEGORIES",
    label: "Categories",
    icon: <PieChartOutlined />,
    description: "See the breakdown of your metrics by dimensions.",
    allowedMetrics: true,
    allowedDimensions: true,
    allowedGranularity: false,
    availableCharts: [
      "bar",
      "bar-horizontal",
      "scatter",
      "pie",
      "sunburst",
      "table",
      "heatmap",
      "retention",
      "treemap",
      "waterfall",
      "funnel",
      "map",
      "interractive-map",
      "interractive-pin-map",
    ],
    disabledMessage:
      "You need at least one metric and one dimension to use this chart type.",
    allowSortMetrics: true,
    allowSortDimensions: true,
  },
  {
    key: "TIME",
    label: "Timeserie",
    icon: <LineChartOutlined />,
    description: "Investigate your KPIs in time.",
    allowedMetrics: true,
    allowedDimensions: true,
    allowedGranularity: true,
    availableCharts: [
      "line",
      "stacked-area",
      "stacked-barchart-timeserie",
      "bar",
      "table",
      "calendar",
      "activity",
      "waterfall-timeserie",
    ],
    disabledMessage:
      "You need at least one metric, one dimension and one time dimension to use this chart type.",
    allowSortMetrics: false,
    allowSortDimensions: true,
  },
];

export const EXPLORATION_ITEM_FRAGMENT = `
fragment Exploration on Exploration {
  id
  name
  slug
  section {
    id
  }
}
`;

const RELATIONSHIP_FRAGMENT = `
fragment RelationshipQuery on TableRelationship {
  id
  apiName
  from
  to
  type
  left {
    name
    id
    view {
      id
      dataset {
        name
        id
      }
    }
  }
  right {
    name
    id
    view {
      id
      dataset {
        name
        id
      }
    }
  }
}
`;

const DATASET_RELATIONSHIP_FRAGMENT = `
fragment DatasetRelationshipQuery on DatasetRelationship {
  id
  from
  to
  type
  left {
    id
  }
  right {
    id
  }
  editable
}
`;

export const TABLE_WITHOUT_BRIZO_FRAGMENT = `
  ${RELATIONSHIP_FRAGMENT}
  fragment TableCoreQuery on Table {
    id
    apiName
    slug
    cubeName
    name
    drills
    dimensions(where: { deleted_not: true }, sortBy: [columnName_ASC]) {
      id
      apiName
      name
      description
      type
      cubeName
      columnName
      columnDomain
      latitude
      longitude
      overrideName
      hidden
      customOrderingConfArray
      semanticGroups(where: { isDeleted: false } ) {
        id
      }
    }
    primaryKey
    metrics(where: { deleted_not: true }, sortBy: [overrideName_ASC]) {
      id
      apiName
      name
      description
      cubeName
      expression
      columnName
      filters
      overrideName
      overrideFormatting
      hierarchyPath
      format
      prefix
      suffix
      hidden
      drills
      semanticGroups(where: { isDeleted: false } ) {
        id
      }
    }
    semanticGroups(where: { isDeleted_not: true }, sortBy: [name_ASC]) {
      id
      name
    }
    view {
      id
      cubeName
      name
      slug
      drills
      query {
        id
        queryText
      }
      dataset {
        id
        slug
        name
        isPersistedAs
        isModel
        warehouseTableId
        warehouseSchemaId
        cacheStrategy
        query {
          id
        }
        warehouseSize {
          sizeBytes
          rowCount
        }
        outgoingRelationships(where: { deleted_not: true, right: { deleted_not: true } }) {
          id
          from
          to
          type
        }
        incomingRelationships(where: { deleted_not: true, left: { deleted_not: true } }) {
          id
          from
          to
          type
        }
      }
    }
    blendedDatasets {
      id
    }
    outgoingRelationships(where: { deleted_not: true, right: { deleted_not: true } }) {
      ...RelationshipQuery
    }
    incomingRelationships(where: { deleted_not: true, left: { deleted_not: true } }) {
      ...RelationshipQuery
    }
    description
  }
`;

export const TABLE_FRAGMENT = `
${TABLE_WITHOUT_BRIZO_FRAGMENT}

fragment TableQuery on Table {
  ...TableCoreQuery
  rawQuery
}
`;

export const DATASET_FRAGMENT = `
  ${DATASET_RELATIONSHIP_FRAGMENT}

  fragment DatasetQuery on Dataset {
    id
    slug
    name
    description
    primaryKey
    type
    head
    sql
    rawQuery
    isModel
    folder {
      id
    }
    managedBy
    columnsMetadata
    warehouseDatabaseId
    warehouseTableId
    warehouseSchemaId
    isPersistedAs
    warehouseViewDatabaseId
    warehouseViewTableId
    warehouseViewSchemaId
    warehouseSize {
      rowCount
      sizeBytes
    }
    columnTests
    cacheStrategy
    dbtFileName
    updatedAt
    runResults
    hideFromInterface
    outgoingRelationships(where: { deleted_not: true, right: { deleted_not: true } }) {
      ...DatasetRelationshipQuery
    }
    incomingRelationships(where: { deleted_not: true, left: { deleted_not: true } }) {
      ...DatasetRelationshipQuery
    }
    dependsOn(where: { deleted_not: true }) {
      child {
        id
      }
    }
    isChildOf(where: { deleted_not: true }) {
      parent {
        id
      }
    }
    views(sortBy: name_ASC, where: { deleted_not: true }) {
      id
      cubeName
      name
      slug
      drills
      default
      table(where: { AND: [ {deleted_not: true }, {exploration: {deleted_not: true}} ]}) {
        exploration {
          id
          name
          slug
        }
      }
      query {
        id
        queryText
      }
      updatedAt
      updatedBy {
        firstName
        lastName
        gravatar
        avatarColor
      }
    }
    query {
      id
      queryText
    }
    source {
      id
      name
      managedBy
      sourceMeta{
        executor
        publicInfo {
          logo
        }
      }
    }
  }
`;

export const EXPLORATION_FRAGMENT = `
${TABLE_FRAGMENT}

fragment ExplorationQuery on Exploration {
  id
  apiName
  name
  slug
  description
  rowLevelFilters
  owner {
    id
    firstName
    lastName
    email
    gravatar
    avatarColor
  }
  version {
    value
  }
  updatedAt
  updatedBy {
    firstName
    lastName
    gravatar
    avatarColor
  }
  section {
    id
  }
  warehouse {
    id
    slug
  }
  tables(sortBy: name_ASC, where: {deleted_not: true}) {
    ...TableQuery
  }
}
`;

export const GET_EXPLORATION_QUERY = `
  ${EXPLORATION_FRAGMENT}

  query getCurrentExplorationOnEditor ($orgId: ID!, $explorationSlug: String!) {
        allExplorations(sortBy: name_ASC, where: {AND:[{org: {id: $orgId}}, {slug: $explorationSlug}, { deleted_not: true }]}) {
          ...ExplorationQuery
        }
      }

`;

export const UPDATE_QUERY = `
mutation updateExploration ($explorationId: ID!, $explorationUpdateInput: ExplorationUpdateInput!) {
  updateExploration(id: $explorationId, data: $explorationUpdateInput) {
    id
    apiName
    slug
    name
    hasUpstreamErrors
    description
    owner {
      id
      firstName
      lastName
      email
      gravatar
      avatarColor
    }
  }
}
`;

export const CHECK_TILE_EXPLORATION_USAGE = `
  query checkIfMeasureIsInUse($explorationId: ID!, $orgId: ID!) {
    _allTilesMeta(
      where: {
        org: { id: $orgId }
        report: {
          deleted_not: true
          folder: { id_not: null, deleted_not: true }
        }
        deleted_not: true
        exploration: { id: $explorationId }
      }
    ) {
      count
    }
  }
`;
