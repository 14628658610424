import {
  CloseOutlined,
  LayoutOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { Button, Flex, Space, Typography } from "antd";
import { sortBy } from "lodash";
import { inject, observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import { compose } from "../../../../../components/compose/WlyCompose";
import { WlyDynamicIcon } from "../../../../../components/icons/dynamic-renderer/WlyDynamicIconRenderer";
import { IconHolder } from "../../../../../components/icons/holder/IconHolder";
import type { IFileUpload } from "../../../../../interfaces/fileUpload";
import type { IReportFolder } from "../../../../../interfaces/folder";
import type { IObject } from "../../../../../interfaces/object";
import { routeDescriptor } from "../../../../../routes/routes";
import type { WorkspaceUIStoreProps } from "../../../../../store/workspaceUIStore";
import type { InjectedOrgProps } from "../../../../orgs/WithOrg";
import WithOrg from "../../../../orgs/WithOrg";
import FolderMenu from "../../folders/menu/FolderMenu";

interface IWorkspaceMenuProps {
  isDraggingReport: boolean;
  allReportFolders: IReportFolder[];
  allObjects?: IObject[];
  personalFolder?: IReportFolder;
  allFileUploadJobs: IFileUpload[];
}

type Props = IWorkspaceMenuProps &
  RouteComponentProps &
  WorkspaceUIStoreProps &
  InjectedOrgProps;

const WorkspaceMenu = ({
  history,
  match: { params },
  workspaceUIStore,
  isDraggingReport,
  allReportFolders,
  allObjects,
  personalFolder,
  allFileUploadJobs,
}: Props) => {
  const {
    drawerType,
    drawerTitleActionsDomNode,
    setSelectedFolder,
    setDrawerType,
  } = workspaceUIStore;

  const [additionalObjects, setAdditionalObjects] = useState<Array<IObject>>(
    []
  );

  useEffect(() => {
    if ("objectSlug" in params && params.objectSlug && allObjects) {
      const current = allObjects.find(({ slug }) => slug === params.objectSlug);

      if (current && !current.canBeListed) {
        setAdditionalObjects((v) =>
          [...v, current].filter((v, i, s) => s.indexOf(v) === i)
        );
      }
    }
  }, [allObjects, params]);

  const renderObjects = () => {
    if (allObjects) {
      const renderItems = (): {
        key: string;
        onClick: () => void;
        selected?: boolean;
        label: React.ReactNode;
      }[] => {
        const allListedObjects = sortBy(allObjects, "position")
          .filter(({ canBeListed }) => canBeListed)
          .map(({ slug, icon, color, name, defaultView }) => {
            return {
              key: slug,
              onClick: () => {
                setSelectedFolder();
                setDrawerType(undefined);
                history.push(
                  routeDescriptor["object"].renderRoute(
                    {
                      ...params,
                      objectSlug: slug,
                    },
                    {
                      view: !defaultView?.isDeleted
                        ? defaultView?.slug
                        : undefined,
                    }
                  )
                );
              },
              selected: "objectSlug" in params && slug === params.objectSlug,
              label: (
                <ListedObjectLabel icon={icon} color={color} name={name} />
              ),
            };
          });

        const ao = sortBy(additionalObjects, "position")
          .filter(({ id }) => additionalObjects.map((i) => i.id).includes(id))
          .map(({ slug, icon, color, name, defaultView }) => {
            return {
              key: slug,
              onClick: () => {
                setSelectedFolder();
                setDrawerType(undefined);
                history.push(
                  routeDescriptor["object"].renderRoute(
                    {
                      ...params,
                      objectSlug: slug,
                    },
                    {
                      view: !defaultView?.isDeleted
                        ? defaultView?.slug
                        : undefined,
                    }
                  )
                );
              },
              selected: "objectSlug" in params && slug === params.objectSlug,
              label: (
                <ListedObjectLabel
                  icon={icon}
                  color={color}
                  name={name}
                  onClick={() =>
                    setAdditionalObjects((v) =>
                      v.filter((a) => a.slug !== slug)
                    )
                  }
                />
              ),
            };
          });

        return [...allListedObjects, ...ao];
      };

      if (allObjects.length === 0) {
        return (
          <div>
            <Typography.Text type="secondary">
              No objects available
            </Typography.Text>
          </div>
        );
      }

      return (
        <Flex gap="small" vertical>
          {renderItems().map(({ key, selected, label, onClick }) => (
            <Button
              key={key}
              onClick={onClick}
              type="text"
              style={{
                padding: "4px 8px",
                cursor: "pointer",
                backgroundColor: selected ? "#f0f0f0" : undefined,
              }}
            >
              {label}
            </Button>
          ))}
        </Flex>
      );
    }
  };

  return (
    <div
      style={{
        overflowY: "auto",
        maxHeight: "100%",
      }}
    >
      <Space direction="vertical" style={{ width: "100%" }}>
        {/* {orgFeatures.includes(IOrgFeatureType.RCA_DEMO_DATA) && (
          <ClosableContent
            name={"Analyses"}
            items={[
              {
                slug: "missing-references",
                name: "Analyse de la PDL",
              },
            ].map((p) => {
              return {
                key: p.slug,
                selected: p.slug === (params as any).analysisSlug,
                onClick: () => {
                  setSelectedFolder();
                  history.push(
                    routeDescriptor["rootCause"].renderRoute({
                      ...params,
                    })
                  );
                },
                label: (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        flex: 1,
                      }}
                    >
                      <Typography.Text>{p.name}</Typography.Text>
                    </div>
                  </div>
                ),
              };
            })}
            button={{
              name: "Browse Analyses",
            }}
          />
        )} */}
        {drawerType === "lists" &&
          drawerTitleActionsDomNode &&
          createPortal(
            <Button
              style={{
                alignSelf: "flex-end",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={() =>
                history.push(
                  routeDescriptor["object-list"].renderRoute({
                    ...params,
                  })
                )
              }
              type="text"
              icon={<SettingOutlined />}
              shape="circle"
            />,
            drawerTitleActionsDomNode
          )}
        {drawerType === "lists" && renderObjects()}
        {drawerType === "dashboards" && (
          <FolderMenu
            isDragging={isDraggingReport}
            folders={allReportFolders}
            personalFolder={personalFolder}
          />
        )}
      </Space>
    </div>
  );
};

export default compose<Props, IWorkspaceMenuProps>(
  withRouter,
  WithOrg,
  inject("workspaceUIStore"),
  observer
)(WorkspaceMenu);

type ListedObjectLabelProps = {
  icon?: string;
  color?: string;
  name: string;
  onClick?: () => void;
};
const ListedObjectLabel = ({
  icon,
  color,
  name,
  onClick,
}: ListedObjectLabelProps) => {
  return (
    <Flex align="center">
      <Flex gap="small" align="center" style={{ minWidth: 0, flex: 1 }}>
        <IconHolder
          icon={
            icon ? (
              <WlyDynamicIcon name={icon} fallback={<LayoutOutlined />} />
            ) : (
              <LayoutOutlined />
            )
          }
          size={20}
          background={color ?? "#F1BD6C"}
          color={"#F9F8F8"}
        />
        <Typography.Text ellipsis italic={!!onClick}>
          {name}
        </Typography.Text>
      </Flex>

      {onClick && (
        <Button
          type="text"
          size="small"
          icon={<CloseOutlined style={{ fontSize: "0.9em" }} />}
          shape="circle"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onClick();
          }}
        />
      )}
    </Flex>
  );
};
