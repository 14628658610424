import { inject, observer } from "mobx-react";
import { useEffect } from "react";
import { compose } from "../../../../../../components/compose/WlyCompose";
import Feednack from "../../../../../../components/layout/feedback/feedback";
import Loading from "../../../../../../components/layout/feedback/loading";
import type { IDestination } from "../../../../../../interfaces/destinations";
import type { WorkbenchUIStoreProps } from "../../../../../../store/workbenchUIStore";
import * as Toolbar from "../../../../../spreadsheet/toolbar/Toolbar";
import type {
  IActiveObject,
  IActiveObjectObjectUrlState,
} from "../../../domain";
import type { UpdateFileUploaderJobFunction } from "../../../selector/file-uploader/domain";
import { useGetFileUploaderDetails } from "../api/useGetFileUploaderDetails";
import "./FileUploaderViewer.scss";
import { FileUploaderOverviewViewer } from "./overview/FileUploaderOverviewViewer";
import FileUploaderSettingsViewer from "./settings/FileUploaderSettingsViewer";
// import { useGetRadarDetails } from "./api/useGetRadarDetails";
// import RadarSettings from "./tabs/settings/RadarSettings";

type IFileUploaderViewerProps = {
  currentWarehouse: IDestination;
  activeObject: IActiveObject;
  updateKey?: string;
  onUpdateFileUploader: UpdateFileUploaderJobFunction;
};

type Props = IFileUploaderViewerProps & WorkbenchUIStoreProps;

enum Tabs {
  SETTINGS,
  OVERVIEW,
}

function FileUploaderViewer({
  currentWarehouse,
  activeObject,
  onUpdateFileUploader,
  updateKey,
  workbenchUIStore: { getActiveObjectUrlParams, setActiveObjectUrlParams },
}: Props) {
  const { data, loading, error, refetch } = useGetFileUploaderDetails({
    variables: { id: activeObject.value },
  });

  useEffect(() => {
    refetch();
  }, [updateKey]);

  const getSelectedTab = () => {
    switch (
      (getActiveObjectUrlParams(activeObject) as IActiveObjectObjectUrlState)
        ?.tab
    ) {
      case "settings":
        return Tabs.SETTINGS;
      case "overview":
        return Tabs.OVERVIEW;
      default:
        return Tabs.OVERVIEW;
    }
  };

  const selectedTab = getSelectedTab();

  const renderInner = () => {
    const fileUploadJob = data?.FileUploadJob;
    if (loading) {
      return (
        <Feednack>
          <Loading />
        </Feednack>
      );
    }
    if (error) {
      return <Feednack>{error.message}</Feednack>;
    }
    if (!fileUploadJob) {
      return <Feednack>File upload job not found</Feednack>;
    }
    if (selectedTab === Tabs.SETTINGS) {
      return (
        <FileUploaderSettingsViewer
          onUpdateFileUploader={onUpdateFileUploader}
          fileUploadJob={fileUploadJob}
          afterSave={() => refetch()}
          activeObject={activeObject}
        />
      );
    }
    if (selectedTab === Tabs.OVERVIEW) {
      return <FileUploaderOverviewViewer fileUploadJob={fileUploadJob} />;
    }
    return (
      <div className="workbench-content">
        <div className="file-uploader-viewer">
          <Feednack>File Upload Job not found</Feednack>
        </div>
      </div>
    );
  };

  return (
    <div className="workbench-content">
      <div className="workbench-spreadsheet">
        <div className="workbench-content-inner">
          <Toolbar.Toolbar style={{ borderTop: "none" }}>
            <Toolbar.Item
              color="violet"
              active={selectedTab === Tabs.OVERVIEW}
              onClick={() => {
                setActiveObjectUrlParams(activeObject, {
                  ...activeObject.urlState,
                  tab: "overview",
                });
              }}
            >
              Overview
            </Toolbar.Item>
            <Toolbar.Item
              color="yellow"
              active={selectedTab === Tabs.SETTINGS}
              onClick={() => {
                setActiveObjectUrlParams(activeObject, {
                  ...activeObject.urlState,
                  tab: "settings",
                });
              }}
            >
              Settings
            </Toolbar.Item>
          </Toolbar.Toolbar>
          <div className="workbench-content">
            <div className="radar-viewer">{renderInner()}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default compose<Props, IFileUploaderViewerProps>(
  inject("workbenchUIStore"),
  observer
)(FileUploaderViewer);
