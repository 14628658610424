import { LoadingOutlined } from "@ant-design/icons";
import { useMemo, type CSSProperties } from "react";
import { useCss } from "react-use";

type Size = "small" | "medium" | "large";

export const LoadingOverlay = ({
  size,
  style,
}: {
  size?: Size;
  style?: CSSProperties;
}) => {
  const containerCs = useCss({
    position: "absolute",
    top: "2px",
    left: "2px",
    width: "100%",
    height: "100%",
    backdropFilter: "blur(2px)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    ...style,
  });

  const iconFs = useMemo(
    () =>
      ({
        small: "24px",
        medium: "48px",
        large: "64px",
      }[size ?? "medium"]),
    [size]
  );

  return (
    <div className={containerCs}>
      <LoadingOutlined style={{ fontSize: iconFs }} />
    </div>
  );
};
