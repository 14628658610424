import cuid from "cuid";
import * as React from "react";
import { compose } from "../../../../components/compose/WlyCompose";
import Error from "../../../../components/layout/feedback/error";
import Loading from "../../../../components/layout/feedback/loading";
import { type IObjectStorage } from "../../../../interfaces/objectStorage";
import GraphQl from "../../../graphql/graphql";
import type { InjectedOrgProps } from "../../../orgs/WithOrg";
import WithOrg from "../../../orgs/WithOrg";
import SettingsWrapper from "../../SettingsWrapper";
import ObjectStorageList from "./ObjectStorageList";

// import "./Warehouse.scss";
// import WarehouseDetail from "./WarehouseDetail

interface ObjectStoragePageProps {}

const gql = `
query getConnectedObjectStorages(
    $orgId: ID!
  ) {
    allObjectStorages(where: { org: { id: $orgId }, isDeleted_not: true }) {
      id
      name
      objectStorageMeta {
        id
        slug
        storageType
        publicInfo {
          name
          description
          logo
          website
        }
        name
        options(where: { hidden: false }) {
          id
          slug
          key
          label
          editable
          type
          options
          encrypt
          hidden
        }
      }
      values(where: { option: { hidden: false } }) {
        id
        value
        option {
          id
          slug
          key
          label
          editable
          type
          options
          encrypt
          description
          hidden
        }
      }
      slug
      org {
        id
      }
    }
  }
`;

interface IReturnValue {
  allObjectStorages: IObjectStorage[];
}

type Props = ObjectStoragePageProps & InjectedOrgProps;

class ObjectStoragePage extends React.Component<Props, { s: string }> {
  constructor(props: Props) {
    super(props);
    this.state = {
      s: cuid(),
    };
  }

  public render() {
    const { org } = this.props;
    return (
      <SettingsWrapper>
        <GraphQl<IReturnValue>
          query={gql}
          variables={{ orgId: org.id, s: this.state.s }}
        >
          {(gql) => {
            if (gql.status === "loading" || gql.status === "initial") {
              return <Loading />;
            }
            if (gql.status === "error") {
              return <Error />;
            }

            return (
              <ObjectStorageList
                objectStorages={gql.data.allObjectStorages}
                refetch={() => this.setState({ s: cuid() })}
              />
            );
          }}
        </GraphQl>
      </SettingsWrapper>
    );
  }
}

export default compose<Props, ObjectStoragePageProps>(WithOrg)(
  ObjectStoragePage
);
