import type { DocumentNode } from "graphql";
import { useEffect, useMemo, useState } from "react";
import type { AsyncData } from "../../../helpers/typescriptHelpers";
import GraphQLService from "../../../services/graphql/GraphQLService";

export type QueryOptions<TData, TVariables> = {
  variables: TVariables;
  enabled?: boolean;
};

export function useGQLQuery<TData = any, TVariables = Record<string, any>>(
  query: string | DocumentNode,
  options: QueryOptions<TData, TVariables>
) {
  const [enabled, setEnabled] = useState<boolean>(
    typeof options.enabled === "boolean" ? options.enabled : true
  );
  const [data, setData] = useState<AsyncData<TData>>({
    status: "initial",
  });

  const fetch = useMemo(
    () =>
      async (
        query: string | DocumentNode,
        variables: TVariables,
        setData: (value: AsyncData<TData>) => void
      ) => {
        try {
          setData({ status: "loading" });
          const response = await GraphQLService<TData>(query, variables);
          setData({ status: "success", data: response });
        } catch (err) {
          console.error(err);
          setData({
            status: "error",
            error: err instanceof Error ? err : new Error(err),
          });
        }
      },
    []
  );

  useEffect(() => {
    if (enabled) {
      fetch(query, options.variables, setData);
    }
  }, [enabled]);

  useEffect(() => {
    if (!enabled && options.enabled) {
      setEnabled(true);
    }
  }, [options.enabled, enabled]);

  const fetchMore = useMemo(
    () => async (variables: TVariables) => {
      fetch(query, variables, setData);
    },
    []
  );

  const refetch = useMemo(
    () => async () => {
      fetch(query, options.variables, setData);
    },
    []
  );

  return {
    /** An object containing the result of your GraphQL query after it completes.
     * This value might be undefined if a query results in one or more errors */
    data: data.status === "success" ? data.data : undefined,
    /** If true, the query is has never been sent yet */
    pending: data.status === "initial",
    /** If true, the query is still in flight and results have not yet been returned. */
    loading: data.status === "loading",
    /** If the query produces an error, this object contains the associated error. Otherwise, this value is undefined. */
    error: data.status === "error" ? data.error : undefined,
    /** A function that helps you fetch the next set of results for a paginated list field. */
    fetchMore,
    /** Allow to refetch the results with the same variables as the initial query */
    refetch,
  };
}
