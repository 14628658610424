import { FileFilled } from "@ant-design/icons";
import type { ReportType } from "../../interfaces/reports";
import { WlyDashboardIcon } from "./icons/DashboardIcon";
import { WlyQuestionIcon } from "./icons/QuestionIcon";

export const ReportIcon = ({ type }: { type: ReportType | undefined }) => {
  switch (type) {
    case "DASHBOARD":
      return <WlyDashboardIcon style={{ color: "#BE98C6" }} />;
    case "QUESTION":
      return <WlyQuestionIcon style={{ color: "#69A2B0" }} />;
    default:
      return <FileFilled />;
  }
};
