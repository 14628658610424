import { inject, observer } from "mobx-react";
import * as React from "react";
import { CardContext } from "../../../../components/cards/Card";
import { compose } from "../../../../components/compose/WlyCompose";
import Feednack from "../../../../components/layout/feedback/feedback";
import Loading from "../../../../components/layout/feedback/loading";
import type { AsyncData } from "../../../../helpers/typescriptHelpers";
import type { IExploration } from "../../../../interfaces/explorations";
import type { IReport } from "../../../../interfaces/reports";
import GraphQLService from "../../../../services/graphql/GraphQLService";
import type { WorkspaceUIStoreProps } from "../../../../store/workspaceUIStore";
import workspaceUIStore from "../../../../store/workspaceUIStore";
import type { InjectedOrgProps } from "../../../orgs/WithOrg";
import WithOrg from "../../../orgs/WithOrg";
import { getFilterDefaultValue } from "../../../reports/domain";
import ReportContent from "../../../reports/view/ReportContent";
import type { ActionType } from "../../../reports/view/domain";
import {
  INITIAL_GQL_QUERY,
  INITIAL_GQL_QUERY_ID,
  fetchUsedExplorationInReport,
} from "../../../reports/view/domain";
import { BORDER_COLOR } from "../layout/domain";

interface IReportV2WrapperProps {
  reportPayload:
    | { type: "id"; value: string }
    | { type: "slug"; value: string };
  isEmbedded: boolean;
  isBeingPushed: boolean;
  isSharingLink: boolean;
  isPublic: boolean;
  isDisplayedInWorkspace: boolean;
  hideLayout: boolean;
  editing: boolean;
  actionType?: ActionType;
  embededWorkbench?: boolean;
  dontHighlightFolder?: boolean;
  children?: React.ReactNode;
}

type Props = IReportV2WrapperProps & InjectedOrgProps & WorkspaceUIStoreProps;

function ReportV2Wrapper(props: React.PropsWithChildren<Props>) {
  const {
    userAttributes,
    reportPayload,
    org,
    isEmbedded,
    isBeingPushed,
    isSharingLink,
    isPublic,
    isDisplayedInWorkspace,
    hideLayout,
    editing,
    actionType,
    embededWorkbench,
    dontHighlightFolder,
    workspaceUIStore: { setRightHeaderVisible },
  } = props;

  const [data, setData] = React.useState<
    AsyncData<{
      report: IReport;
      explorations: IExploration[];
    }>
  >({ status: "initial" });

  React.useEffect(() => {
    const fetchData = (payload, orgId: string) => {
      setData({ status: "initial" });

      const buildPayload = () => {
        if (payload.type === "slug") {
          return {
            query: INITIAL_GQL_QUERY,
            variables: {
              slug: payload.value,
              orgId,
            },
          };
        } else if (reportPayload.type === "id") {
          return {
            query: INITIAL_GQL_QUERY_ID,
            variables: {
              id: reportPayload.value,
              orgId,
            },
          };
        }
      };

      return GraphQLService<{
        allReports: IReport[];
      }>(buildPayload().query, buildPayload().variables)
        .then((r) => {
          if (r.allReports.length !== 1) {
            throw new Error("NOT_FOUND");
          }
          return r.allReports[0];
        })
        .then((r) => {
          if (!dontHighlightFolder) {
            workspaceUIStore.setSelectedFolder(r.folder.id);
          }

          return fetchUsedExplorationInReport(r, orgId).then((e) => {
            setData({
              status: "success",
              data: {
                report: [r].map((r) => {
                  return {
                    ...r,
                    filters: r.filters.map((f) => {
                      return {
                        ...f,
                        defaultValue: getFilterDefaultValue(f, userAttributes),
                        rawDefaultValue: f.defaultValue as any,
                      };
                    }),
                  };
                })[0],
                explorations: e,
              },
            });
          });
        })
        .catch((err) => {
          console.error(err);
          setData({
            status: "error",
            error: err,
          });
        });
    };
    fetchData(reportPayload, org.id);
  }, [reportPayload.value, reportPayload.value, org.id]);

  React.useEffect(() => {
    if (data.status === "success") {
      setRightHeaderVisible(
        !(data.data.report.type === "DASHBOARD" && editing)
      );
    }
  }, [editing, data, setRightHeaderVisible]);

  const renderContent = () => {
    switch (data.status) {
      case "initial":
      case "loading":
        return (
          <div style={{ height: "100%" }}>
            <Feednack>
              <Loading />
            </Feednack>
          </div>
        );
      case "error":
        if (data.error.message === "NOT_FOUND") {
          return (
            <div style={{ height: "100%" }}>
              <Feednack>This report doesn't exists</Feednack>
            </div>
          );
        } else {
          return (
            <div style={{ height: "100%" }}>
              <Feednack>{data.error.message}</Feednack>
            </div>
          );
        }
      case "success":
        const isQuestion = data.data.report.type === "QUESTION";

        return (
          <CardContext.Provider value={{ color: BORDER_COLOR }}>
            <div
              className={`${editing || isQuestion ? "report-view" : ""}`}
              style={{
                padding:
                  editing || isQuestion ? undefined : `0px 24px 24px 24px`,
              }}
            >
              <ReportContent
                isEmbedded={isEmbedded}
                isBeingPushed={isBeingPushed}
                saving={() => ({})}
                isSharingLink={isSharingLink}
                isPublic={isPublic}
                isDisplayedInWorkspace={isDisplayedInWorkspace}
                isSaving={false}
                report={data.data.report}
                explorations={data.data.explorations}
                editing={editing}
                actionType={actionType}
                hideLayout={hideLayout}
                embededWorkbench={embededWorkbench}
              />
            </div>
          </CardContext.Provider>
        );
    }
  };

  return renderContent();
}

export default compose<Props, IReportV2WrapperProps>(
  inject("workspaceUIStore"),
  observer,
  WithOrg,
  React.memo
)(ReportV2Wrapper);
