import { Avatar } from "antd";
import { useState } from "react";
import defaultLogo from "../../../../assets/logo/logo_square_dark.svg";

interface Props {
  className?: string;
  logo?: string;
}

export const OrgLogoV2 = ({ className, logo }: Props) => {
  const [error, setError] = useState<boolean>(false);

  return (
    <div
      className={className}
      style={{ width: 32, height: 32, position: "relative" }}
    >
      <Avatar
        src={error ? defaultLogo : logo || defaultLogo}
        shape="square"
        onError={() => {
          setError(true);
          return false;
        }}
        style={{
          position: "absolute",
          borderColor: "rgb(216, 216, 216)",
          background: "white",
        }}
      />
    </div>
  );
};
