import { Form, Input, Typography } from "antd";
import { useForm } from "antd/es/form/Form";
import React from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import type { InjectedAntUtilsProps } from "../../../../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../../../../components/ant-utils/withAntUtils";
import { compose } from "../../../../../../components/compose/WlyCompose";
import { SimpleFormModal } from "../../../../../../components/form/modal/SimpleFormModal";
import type { InjectedOrgProps } from "../../../../../orgs/WithOrg";
import WithOrg from "../../../../../orgs/WithOrg";
import type { CreateFileUploaderJobFunction } from "../../../selector/file-uploader/domain";

interface IFileUploaderCreateUpdateModalProps {
  open: boolean;
  onClose: () => void;
  onCreate: CreateFileUploaderJobFunction;
}

interface FormValues {
  name: string;
}

type Props = IFileUploaderCreateUpdateModalProps &
  InjectedAntUtilsProps &
  InjectedOrgProps &
  RouteComponentProps<{ warehouseSlug: string }>;

function FileUploaderCreateUpdateModal(props: Props) {
  const { open, onClose, onCreate, org } = props;

  const [form] = useForm<FormValues>();

  const [saving, setSaving] = React.useState<boolean>(false);

  return (
    <SimpleFormModal
      isSaving={saving}
      form={form}
      open={open}
      onCancel={onClose}
      modalProps={{ title: "Create a file upload job" }}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={async (values) => {
          try {
            setSaving(true);
            await onCreate(values, org.id);
          } catch (error) {
            console.error(error);
          } finally {
            setSaving(false);
            onClose();
          }
        }}
      >
        <div style={{ paddingBottom: 12 }}>
          <Typography.Text type="secondary">
            File uploaders allow your users to manually upload files using the
            Whaly interface.
          </Typography.Text>
        </div>
        <Form.Item label={"Name"} name={["name"]} rules={[{ required: true }]}>
          <Input />
        </Form.Item>
      </Form>
    </SimpleFormModal>
  );
}

export default compose<Props, IFileUploaderCreateUpdateModalProps>(
  WithOrg,
  withRouter,
  withAntUtils
)(FileUploaderCreateUpdateModal);
