import ApiService from "./ApiService";

export const getJSONFile = <T>(fileUri: string): Promise<T> => {
  return ApiService.getRequest(
    `/v1/data?uri=${fileUri}`,
    {},
    {},
    { authenticated: true, fileServiceUrl: true }
  );
};

export const getBlobFile = (fileUri: string): Promise<Blob> => {
  return ApiService.getRequest(
    `/v1/data?uri=${fileUri}`,
    {},
    {},
    { authenticated: true, fileServiceUrl: true, asBlob: true }
  );
};

export const updateFile = (fileUri: string, body: FormData): Promise<any> => {
  return ApiService.putRequest(
    `/v1/data?uri=${fileUri}`,
    body,
    {},
    {},
    { authenticated: true, fileServiceUrl: true }
  );
};

export const testObjectStorage = (
  objectStorageId: string,
  body: any
): Promise<any> => {
  return ApiService.postRequest(
    `/v1/object-storage/${objectStorageId}/test`,
    body,
    {},
    {},
    { authenticated: true, fileServiceUrl: true }
  );
};

export const uploadFileJobTrigger = (
  fileUploaderId: string,
  body: FormData
): Promise<any> => {
  return ApiService.postRequest(
    `/v1/file-upload-jobs/${fileUploaderId}/upload`,
    body,
    {},
    {},
    { authenticated: true, fileServiceUrl: true }
  );
};

export const getFileUploadDownloadLink = (
  fileUploaderId: string,
  filePath: string
): Promise<{
  status: "ok";
  data: {
    token: string;
  };
}> => {
  return ApiService.postRequest(
    `/v1/file-upload-jobs/${fileUploaderId}/download`,
    {
      filePath,
    },
    {},
    {},
    { authenticated: true, fileServiceUrl: true }
  );
};
