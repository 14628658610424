import { WarningTwoTone } from "@ant-design/icons";
import { Button, Flex, Modal, Typography } from "antd";
import { useCallback, useState } from "react";
import { UPDATE_QUERY } from "../../containers/reports/view/domain";
import type { IReport } from "../../interfaces/reports";
import graphQlService from "../../services/graphql/GraphQLService";
import { orange } from "../../utils/colorPalette";
import {
  withAntUtils,
  type InjectedAntUtilsProps,
} from "../ant-utils/withAntUtils";
import { compose } from "../compose/WlyCompose";
import { TimezoneSelect } from "../timezone-select/TimezoneSelect";

type ReportTimezoneEditorProps = {
  report: IReport;
  visible: boolean;
  onCancel: () => any;
  onSave: (v: IReport) => any;
};

type Props = ReportTimezoneEditorProps & InjectedAntUtilsProps;

const Component = ({ report, visible, onCancel, onSave, antUtils }: Props) => {
  const [loading, setLoading] = useState(false);
  const [timezoneSelected, setTimezoneSelected] = useState<string>(
    report.timezoneOverride ?? "user-based"
  );

  const handleSave = useCallback(async () => {
    setLoading(true);

    try {
      if (report.id) {
        const resp = await graphQlService(UPDATE_QUERY, {
          id: report.id,
          data: { timezoneOverride: timezoneSelected },
        });

        antUtils.message.success("Timezone saved");
        onSave(resp.updateReport);
      }
    } catch (error) {
      antUtils.message.error("Error while editing timezone of report");
    }

    setLoading(false);
    onCancel();
  }, [antUtils.message, onCancel, onSave, report.id, timezoneSelected]);

  return (
    <Modal
      title="Edit timezone"
      centered
      open={visible}
      footer={
        <Flex gap="small" justify="flex-end">
          <Button onClick={onCancel}>Cancel</Button>
          <Button
            disabled={timezoneSelected === report.timezoneOverride}
            type="primary"
            loading={loading}
            onClick={handleSave}
          >
            Save
          </Button>
        </Flex>
      }
      closable={false}
      maskClosable={true}
      onCancel={onCancel}
    >
      <Flex vertical gap="middle">
        <Typography.Text>
          Time zones affect time-based aggregations (e.g., day, month, year) and
          time filtering, as results vary depending on the selected time zone.
        </Typography.Text>
        <Flex vertical>
          <TimezoneSelect
            customOptions={[
              { value: "user-based", label: "Based on viewer timezone" },
            ]}
            value={timezoneSelected}
            onChange={(value) => setTimezoneSelected(value)}
            style={{ width: "100%" }}
          />

          {timezoneSelected === "user-based" && (
            <Typography.Text type="secondary">
              <WarningTwoTone twoToneColor={orange[6]} /> Users in different
              time zones may see different results in the charts.
            </Typography.Text>
          )}
        </Flex>
      </Flex>
    </Modal>
  );
};

export const ReportTimezoneEditor = compose<Props, ReportTimezoneEditorProps>(
  withAntUtils
)(Component);
