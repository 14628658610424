import { Button, Flex, Form, Modal } from "antd";
import { useCallback, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import type { InjectedOrgProps } from "../../../containers/orgs/WithOrg";
import WithOrg from "../../../containers/orgs/WithOrg";
import { compose } from "../../compose/WlyCompose";
import Delayed from "../../delayed/delayed";

interface IDescriptionEditorProps {
  allowEdit?: boolean;
  initialData?: initialData;
  visible: boolean;
  onSave: (initialData: initialData) => Promise<any>;
  onCancel: () => void;
}

interface initialData {
  description: string;
}

type Props = IDescriptionEditorProps & InjectedOrgProps;

const DescriptionEditor = ({
  allowEdit,
  visible,
  initialData,
  onSave,
  onCancel,
  user,
  org,
}: Props) => {
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
  const [form] = Form.useForm();

  const htmlStringContainText = (htmlString: string) => {
    return htmlString.replace(/<[^>]+>/g, "").trim().length > 0;
  };

  const handleSave = useCallback(() => {
    setConfirmLoading(true);

    return form
      .validateFields()
      .then((values) =>
        htmlStringContainText(form.getFieldValue("description"))
          ? onSave({ ...initialData, ...values })
          : onSave({ ...initialData, description: "" })
      )
      .catch(() => setConfirmLoading(false))
      .finally(() => setConfirmLoading(false));
  }, [form, initialData, onSave]);

  return (
    <Modal
      title="Description"
      centered
      open={visible}
      footer={
        <Flex gap="small" justify="flex-end">
          <Button onClick={onCancel}>Cancel</Button>
          <Button
            disabled={allowEdit === false}
            type="primary"
            loading={confirmLoading}
            onClick={handleSave}
          >
            Save
          </Button>
        </Flex>
      }
      confirmLoading={confirmLoading}
      destroyOnClose={true}
      closable={false}
      maskClosable={true}
      onCancel={onCancel}
    >
      <Delayed delay={100}>
        <Form initialValues={initialData} layout="vertical" form={form}>
          <Form.Item name={["description"]}>
            <ReactQuill
              formats={[
                "header",
                "bold",
                "italic",
                "underline",
                "strike",
                "blockquote",
                "list",
                "bullet",
                "indent",
                "link",
                "image",
                "align",
              ]}
              theme="snow"
              modules={{
                toolbar: [
                  [{ header: [1, 2, 3, false] }],
                  ["bold", "italic", "underline"],
                  [
                    { align: "" },
                    { align: "center" },
                    { align: "right" },
                    { align: "justify" },
                  ],
                  [
                    { list: "ordered" },
                    { list: "bullet" },
                    { indent: "-1" },
                    { indent: "+1" },
                  ],
                  ["link"],
                ],
              }}
            />
          </Form.Item>
        </Form>
      </Delayed>
    </Modal>
  );
};

export default compose<Props, IDescriptionEditorProps>(WithOrg)(
  DescriptionEditor
);
