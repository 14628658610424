import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  CloseOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { Button, Card, Empty, Space, Tag, Typography } from "antd";
import moment from "moment";
import React from "react";
import { type RouteComponentProps, withRouter } from "react-router";
import { usePrevious } from "react-use";
import { compose } from "../../../components/compose/WlyCompose";
import Feednack from "../../../components/layout/feedback/feedback";
import Loading from "../../../components/layout/feedback/loading";
import UserAvatar from "../../../components/user/avatar/UserAvatar";
import {
  FileUploadRunSyncStatus,
  type IFileUpload,
} from "../../../interfaces/fileUpload";
import { routeDescriptor } from "../../../routes/routes";
import { uploadFileJobTrigger } from "../../../services/FileService";
import { BORDER_COLOR } from "../../v2-demo/container/layout/domain";
import { useGetFileUploadJobExecutionsDetails } from "../../workbench/workbench/viewer/file-uploader/api/useGetFileUploaderJobExecutionDetails";
import { CustomFileDownloader } from "./CustomFileDownloader";
import CustomFileUploader from "./CustomFileUploader";

export interface IFileUploaderDetailProps {
  onClose: () => void;
  currentFileUpload?: IFileUpload;
}

type Props = IFileUploaderDetailProps &
  RouteComponentProps<{ fileUploadSlug: string }>;

function FileUploaderDetail(props: Props) {
  const {
    history,
    currentFileUpload,
    match: { params },
    onClose,
  } = props;

  const [hasFetched, setHasFetched] = React.useState<boolean>(false);
  const { data, loading, pending, error, refetch } =
    useGetFileUploadJobExecutionsDetails({
      enabled: !!currentFileUpload?.id,
      variables: { fileUploadId: currentFileUpload?.id! },
    });
  const prevData = usePrevious(data);

  const lastSyncStatus = data?.allFileUploadJobRuns?.[0]?.syncStatus;
  console.log(lastSyncStatus);

  React.useEffect(() => {
    if (
      lastSyncStatus === FileUploadRunSyncStatus.queued ||
      lastSyncStatus === FileUploadRunSyncStatus.syncing
    ) {
      setTimeout(() => {
        refetch();
      }, 3000);
    }
  }, [lastSyncStatus]);

  React.useEffect(() => {
    if (!prevData && data && !hasFetched) {
      setHasFetched(true);
    }
  }, [data]);

  const renderTitle = () => {
    if (currentFileUpload) {
      return <span>{currentFileUpload.name}</span>;
    }
    return <Typography.Text type="danger">Not Found</Typography.Text>;
  };

  const renderInner = () => {
    if (!currentFileUpload) {
      return (
        <Feednack>
          <div>
            You don't seem to have access to this resource, please check with
            your admin.
          </div>
        </Feednack>
      );
    }
    if (!hasFetched && (pending || loading)) {
      return (
        <Feednack>
          <Loading />
        </Feednack>
      );
    }
    if (error) {
      return (
        <Feednack>
          <div>{error?.message}</div>
        </Feednack>
      );
    }
    return (
      <Space style={{ width: "100%" }} direction="vertical">
        {currentFileUpload.description ? (
          <div>
            <Typography.Text type="secondary">
              {currentFileUpload.description}
            </Typography.Text>
          </div>
        ) : undefined}
        <div style={{ height: 200 }}>
          <CustomFileUploader
            disabled={
              lastSyncStatus && lastSyncStatus !== FileUploadRunSyncStatus.idle
            }
            onUpload={async (fileName, formData) => {
              await uploadFileJobTrigger(currentFileUpload.id, formData);
              await refetch();
            }}
          />
        </div>
        <div>
          <div>
            <Typography.Text strong>History</Typography.Text>
          </div>
        </div>
        {data?.allFileUploadJobRuns?.length === 0 ? <Empty /> : undefined}
        {data?.allFileUploadJobRuns.map((r) => {
          const renderStatus = () => {
            if (
              r.syncStatus === FileUploadRunSyncStatus.queued ||
              r.syncStatus === FileUploadRunSyncStatus.syncing
            ) {
              return (
                <Tag color="processing">
                  <SyncOutlined spin /> Running
                </Tag>
              );
            }
            if (
              !r.transformJobExecution?.status &&
              !r.uploadJobExecution?.status
            ) {
              return (
                <Tag color="error">
                  <CloseCircleOutlined /> Failed
                </Tag>
              );
            }
            if (
              r.transformJobExecution.status === "ERROR" ||
              r.transformJobExecution.status === "FAILED" ||
              r.uploadJobExecution.status === "ERROR" ||
              r.uploadJobExecution.status === "FAILED"
            ) {
              return (
                <Tag color="error">
                  <CloseCircleOutlined /> Failed
                </Tag>
              );
            }

            return (
              <Tag color="success">
                <CheckCircleOutlined /> Success
              </Tag>
            );
          };
          return (
            <div key={r.id}>
              <Card size="small">
                <div style={{ display: "flex", gap: 6, alignItems: "center" }}>
                  <div style={{ flex: 1 }}>
                    <div style={{ paddingBottom: 6 }}>
                      <div>{renderStatus()}</div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div style={{ flex: 1 }}>
                        <Typography.Text type={"secondary"}>
                          {moment(r.createdAt).fromNow()}
                        </Typography.Text>
                        {" - "}
                        <CustomFileDownloader
                          fileUploaderId={currentFileUpload.id}
                          filePath={r.filePath}
                          size="small"
                          type="text"
                        >
                          Download file
                        </CustomFileDownloader>
                      </div>
                    </div>
                  </div>
                  <div style={{ flex: `0 auto` }}>
                    <UserAvatar tooltip size="small" user={r.createdBy} />
                  </div>
                </div>
              </Card>
            </div>
          );
        })}
      </Space>
    );
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <div
        style={{ borderBottom: `1px solid ${BORDER_COLOR}`, flex: `0 56px` }}
        className="file-uploader-title"
      >
        <div className="file-uploader-title-content">
          <Typography.Title
            id={"file-uploader-title"}
            style={{ marginBottom: 0 }}
            level={5}
          >
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                const { fileUploadSlug, ...rest } = params;
                history.push(
                  routeDescriptor.fileUploads.renderRoute({ ...rest })
                );
              }}
            >
              All uploaders
            </span>
            {" > "}
            <span>{renderTitle()}</span>
          </Typography.Title>
        </div>
        <div className="file-uploader-title-action">
          <Space>
            <div className="file-uploader-title-action-portal" />
            <Button
              onClick={onClose}
              icon={<CloseOutlined />}
              type="text"
              shape="circle"
            />
          </Space>
        </div>
      </div>
      <div
        style={{
          overflow: "auto",
          padding: 16,
          flex: 1,
        }}
      >
        {renderInner()}
      </div>
    </div>
  );
}

export default compose<Props, IFileUploaderDetailProps>(withRouter)(
  FileUploaderDetail
);
