import { MoreOutlined } from "@ant-design/icons";
import { Button, Dropdown, List, Typography } from "antd";
import { inject, observer } from "mobx-react";
import * as React from "react";
import {
  type InjectedAntUtilsProps,
  withAntUtils,
} from "../../../../../components/ant-utils/withAntUtils";
import { compose } from "../../../../../components/compose/WlyCompose";
import type { IDestination } from "../../../../../interfaces/destinations";
import type { IFileUpload } from "../../../../../interfaces/fileUpload";
import type { WorkbenchUIStoreProps } from "../../../../../store/workbenchUIStore";
import type { IActiveObject } from "../../domain";
import "./FileUploaderTable.scss";
import type { UpdateFileUploaderJobFunction } from "./domain";

interface IFileUploaderTableProps {
  fileUploaders: IFileUpload[];
  activeObject?: IActiveObject;
  onActiveObjectChange: (activeObject: IActiveObject) => void;
  currentWarehouse: IDestination;
  onUpdateFileUploader: UpdateFileUploaderJobFunction;
}

type Props = IFileUploaderTableProps &
  WorkbenchUIStoreProps &
  InjectedAntUtilsProps;

class FileUploaderTable extends React.Component<Props> {
  public render() {
    const {
      fileUploaders,
      activeObject,
      onActiveObjectChange,
      antUtils,
      workbenchUIStore,
      onUpdateFileUploader
    } = this.props;

    return (
      <List<IFileUpload>
        itemLayout="horizontal"
        dataSource={fileUploaders}
        className="file-uploader-content-list"
        renderItem={(item) => {
          return (
            <List.Item
              className={`file-uploader-content-list-item ${
                activeObject && activeObject.value === item.id ? "active" : ""
              }`}
              onClick={() =>
                onActiveObjectChange({
                  type: "uploader",
                  value: item.id,
                })
              }
            >
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  padding: "0px 8px",
                  gap: 8,
                  alignItems: "center",
                }}
              >
                <div style={{ flex: 1, overflow: "hidden" }}>
                  <Typography.Text ellipsis>{item.name}</Typography.Text>
                  <br />
                </div>
                <div style={{ flex: 0 }}>
                  <Dropdown
                    trigger={["click"]}
                    menu={{
                      items: [
                        {
                          label: "Rename",
                          key: "rename",
                          onClick: (e) => {
                            e.domEvent.stopPropagation();
                            this.setState({ renameOpen: item });
                          },
                        },
                        {
                          label: "Delete",
                          key: "delete",
                          onClick: (e) => {
                            e.domEvent.stopPropagation();
                            return antUtils.modal.confirm({
                              title: "Do you want to proceed?",
                              content:
                                "You are about to delete this file uploader, this cannot be undone. Do you want to proceed ?",
                              okButtonProps: {
                                danger: true,
                              },
                              onCancel: () => undefined,
                              onOk: async () => {
                                try {
                                  await onUpdateFileUploader(item.id, {
                                    isDeleted: true
                                  });

                                  workbenchUIStore.removeActiveObject({
                                    type: "uploader",
                                    value: item.id,
                                  });
                                  antUtils.message.success(
                                    "File uploader successfully deleted"
                                  );
                                } catch (error) {
                                  console.error(error);
                                  antUtils.message.error(
                                    "Error while deleting file uploader, try again later"
                                  );
                                }
                              },
                            });
                          },
                          danger: true,
                        },
                      ],
                    }}
                    placement={"bottomRight"}
                  >
                    <Button
                      size="small"
                      type="text"
                      shape="circle"
                      style={{
                        marginTop: 4,
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <MoreOutlined />
                    </Button>
                  </Dropdown>
                </div>
              </div>
            </List.Item>
          );
        }}
      />
    );
  }
}

export default compose<Props, IFileUploaderTableProps>(withAntUtils)(
  inject("workbenchUIStore")(observer(FileUploaderTable))
);
