import { Button, Col, Dropdown, Row, Space, Typography } from "antd";
import React from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import { compose } from "../../../../components/compose/WlyCompose";
import Aligner from "../../../../components/layout/aligner/Aligner";
import CardTable from "../../../../components/table/CardTable";
import { IUserRoleType } from "../../../../interfaces/user";
import { routeDescriptor } from "../../../../routes/routes";
import { CatalogModal } from "../../../catalog/CatalogModal";
import type { InjectedOrgProps } from "../../../orgs/WithOrg";
import WithOrg from "../../../orgs/WithOrg";
import { hasRoleAccessBoolean } from "../../../user-settings/HasRoleAccess";

import {
  type InjectedAntUtilsProps,
  withAntUtils,
} from "../../../../components/ant-utils/withAntUtils";
import { type IObjectStorage } from "../../../../interfaces/objectStorage";
import graphQlService from "../../../../services/graphql/GraphQLService";
import "./ObjectStorageList.scss";

interface IObjectStorageListProps {
  objectStorages: IObjectStorage[];
  refetch: () => void;
}

type Props = IObjectStorageListProps &
  InjectedOrgProps &
  RouteComponentProps<{}> &
  InjectedAntUtilsProps;

function ObjectStorageList(props: Props) {
  const {
    user,
    role,
    org,
    objectStorages,
    history,
    match: { params },
    antUtils,
    refetch,
  } = props;

  const [catalogOpen, setCatalogOpen] = React.useState<boolean>(false);

  const generateColumns = () => {
    return [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        render: (v, s: IObjectStorage) => {
          return (
            <div className="object-storage-list-item">
              <div className="object-storage-list-item-image">
                <img
                  src={s.objectStorageMeta.publicInfo.logo}
                  alt="warehouse logo"
                />
              </div>
              <div className="object-storage-list-item-description">
                <div className="object-storage-list-item-description-name">
                  <Typography.Text ellipsis strong>
                    {s.name}
                  </Typography.Text>
                </div>
                <div className="object-storage-list-item-description-type">
                  <Typography.Text ellipsis>
                    {s.objectStorageMeta.publicInfo.name}
                  </Typography.Text>
                </div>
              </div>
            </div>
          );
        },
      },
      ...(role.role === IUserRoleType.ADMIN_BUILDER
        ? [
            {
              title: "Actions",
              key: "Actions",
              align: "right" as const,
              render: (v, record: IObjectStorage) => {
                return (
                  <Space>
                    <Dropdown.Button
                      size="small"
                      disabled={!user.isAdmin}
                      trigger={["click"]}
                      menu={{
                        items: [
                          {
                            label: "Delete",
                            key: "delete",
                            danger: true,
                            onClick: () => {
                              antUtils.modal.confirm({
                                content: `You are about to delete "${record.name}". This cannot be undone, do you wish to continue?`,
                                onOk: async () => {
                                  try {
                                    await graphQlService(
                                      `
                                     mutation deleteObjectStorage($itemId: ID!) {
                                        updateObjectStorage(id: $itemId, data: {isDeleted: true}) {id}
                                      }  
                                    `,
                                      {
                                        itemId: record.id,
                                      }
                                    );
                                    refetch();
                                  } catch (err) {
                                    antUtils.notifications.error({
                                      duration: 3,
                                      message:
                                        "There was an error deleting your object storage. Please try again",
                                    });
                                  }
                                },
                              });
                            },
                          },
                        ],
                      }}
                      onClick={() =>
                        history.push(
                          routeDescriptor[
                            "objectStorageConnectionStep2"
                          ].renderRoute({
                            organizationSlug: org.slug,
                            itemSlug: record.objectStorageMeta.slug,
                            itemId: record.id,
                          })
                        )
                      }
                    >
                      Edit
                    </Dropdown.Button>
                  </Space>
                );
              },
            },
          ]
        : []),
    ];
  };

  return (
    <Aligner>
      <Row justify="center" className="object-storage-list">
        <Col xs={24} sm={24} md={20} lg={20} xl={20}>
          <CardTable<IObjectStorage>
            cardTitle={<div className="title">Object Storage</div>}
            actionButtons={
              hasRoleAccessBoolean(
                IUserRoleType.ADMIN_BUILDER,
                user,
                org.id
              ) ? (
                <Space>
                  <Button onClick={() => setCatalogOpen(true)} type="primary">
                    Add an object storage
                  </Button>
                </Space>
              ) : undefined
            }
            rowKey="id"
            dataSource={objectStorages}
            columns={generateColumns()}
            pagination={{
              size: "small",
              style: { display: "none" },
              defaultPageSize: 1000,
            }}
          />
        </Col>
      </Row>
      <CatalogModal
        open={catalogOpen}
        onClose={() => setCatalogOpen(false)}
        type="object-storage"
      />
    </Aligner>
  );
}
export default compose<Props, IObjectStorageListProps>(
  WithOrg,
  withRouter,
  withAntUtils
)(ObjectStorageList);
