import {
  CodeOutlined,
  DiffOutlined,
  DoubleLeftOutlined,
  DoubleRightOutlined,
  HistoryOutlined,
  MoreOutlined,
  PlayCircleOutlined,
  PlusCircleOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import { Badge, Button, Dropdown, Flex, Tooltip, Typography } from "antd";
import { useState } from "react";
import "../../../../../../../node_modules/react-resizable/css/styles.css";
import type { InjectedAntUtilsProps } from "../../../../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../../../../components/ant-utils/withAntUtils";
import { compose } from "../../../../../../components/compose/WlyCompose";
import { DiffModal } from "../../../../../../components/modals/DiffModal";
import type { IDestination } from "../../../../../../interfaces/destinations";
import type { IDataset } from "../../../../../../interfaces/sources";
import type { SchemaResult } from "../../../../../../interfaces/transformations";
import { isMacintosh } from "../../../../../../utils/isMacinthosh";
import DbtQueryModal from "../../../../../spreadsheet/dbtQueryModal/dbtQueryModal";
import type { IRelatedObject } from "../../../../../spreadsheet/history/HistoryDrawer";
import HistoryDrawer from "../../../../../spreadsheet/history/HistoryDrawer";
import * as Toolbar from "../../../../../spreadsheet/toolbar/Toolbar";
import { ModelMigrationModal } from "../../../migration/MigrationModal";
import CreateModelModal from "../../../viewer/worksheet/Modals/CreateModelModal";
import PreSaveModelModal from "../common/PreSaveModelModal";
import PrimaryKeysModal from "../common/PrimaryKeysModal";
import "./SQLConfiguration.scss";

interface ISQLToolbarProps {
  datasets: IDataset[];
  currentDataset?: IDataset;
  activeTableKey?: string;
  activeViewKey?: string;
  highlightRunQuery: boolean;
  isMigrationModalOpen: boolean;
  onMigrationDone: (
    toDatasetId: string,
    datasetIds: string[],
    explorationIds: string[]
  ) => Promise<void>;
  setMigrationModalOpen: (open?: boolean) => void;
  onFormatQuery: () => void;
  onRunQuery: () => void;
  schema?: SchemaResult;
  staleQuery?: string;
  resetStaleQuery: (query: string) => void;
  updateModel?: (primaryKeys: string[]) => Promise<void>;
  onCreateDataset?: (
    name: string,
    primaryKeys: string[],
    shouldDelete?: boolean
  ) => Promise<void>;
  canUpdateModel?: boolean;
  onDelete?: () => Promise<any>;
  onToggleDataExplorer: () => void;
  isDataExplorerOpen: boolean;
  currentWarehouse: IDestination;
}

type Props = ISQLToolbarProps & InjectedAntUtilsProps;

function SQLToolbar(props: Props) {
  const {
    datasets,
    currentDataset,
    activeTableKey,
    activeViewKey,
    isMigrationModalOpen,
    highlightRunQuery,
    setMigrationModalOpen,
    onMigrationDone,
    onFormatQuery,
    onRunQuery,
    updateModel,
    canUpdateModel,
    schema,
    staleQuery,
    resetStaleQuery,
    antUtils,
    onCreateDataset,
    onDelete,
    onToggleDataExplorer,
    isDataExplorerOpen,
    currentWarehouse,
  } = props;

  const schemaDef = schema ? schema : {};

  const [preSaveModalVisible, setPreSaveModalVisible] = useState(false);
  const [primaryKeysModalVisible, setPrimaryKeysModalVisible] = useState(false);
  const [primaryKeysModalSaving, setPrimaryKeysModalSaving] = useState(false);
  const [createModelModalVisible, setCreateModelModalVisible] = useState(false);
  const [dbtQueryModalVisible, setDbtQueryModalVisible] = useState(false);
  const [historyDrawerVisible, setHistoryDrawerVisible] = useState(false);
  const [diffModalVisible, setDiffModalVisible] = useState(false);

  const moreMenuItems = [
    {
      key: "format",
      label: (
        <Tooltip
          title={isMacintosh() ? "(⌘ + shift + F)" : "(Ctrl + shift + F)"}
          placement="left"
        >
          <Typography.Text>Format query</Typography.Text>
        </Tooltip>
      ),
      onClick: onFormatQuery,
    },
    ...(currentDataset
      ? [
          {
            key: "migrate",
            label: "Migrate",
            onClick: () => setMigrationModalOpen(true),
          },
        ]
      : []),
    ...(currentDataset
      ? [
          {
            key: "history",
            icon: <HistoryOutlined />,
            label: "Show history",
            onClick: () => setHistoryDrawerVisible(true),
          },
        ]
      : []),
    ...(currentDataset
      ? [
          {
            key: "dbt-query",
            icon: <CodeOutlined />,
            label: "Show dbt query",
            onClick: () => setDbtQueryModalVisible(true),
          },
        ]
      : []),
    ...(onDelete
      ? [
          {
            key: "delete",
            label: "Delete",
            danger: true,
            onClick: () =>
              antUtils.modal.confirm({
                title: "Do you want to proceed?",
                content:
                  "This action cannot be undone, are you sure you want to proceed ?",
                onOk: onDelete,
                onCancel: () => undefined,
              }),
          },
        ]
      : []),
  ];

  const relatedObjects: IRelatedObject[] = [
    ...(activeTableKey
      ? [
          {
            recordId: activeTableKey,
            tableName: "Dataset" as "Dataset",
          },
        ]
      : []),
    ...(activeViewKey
      ? [
          {
            recordId: activeViewKey,
            tableName: "View" as "View",
          },
        ]
      : []),
  ];

  return (
    <div style={{ height: 40 }}>
      <Toolbar.Toolbar align="right" style={{ borderTop: "none" }}>
        <Toolbar.ViewName>
          <Button
            shape="circle"
            size="small"
            icon={
              isDataExplorerOpen ? (
                <DoubleLeftOutlined />
              ) : (
                <DoubleRightOutlined />
              )
            }
            onClick={onToggleDataExplorer}
          />
        </Toolbar.ViewName>
        <Toolbar.ViewName>SQL Query</Toolbar.ViewName>

        {currentDataset && (
          <ModelMigrationModal
            visible={isMigrationModalOpen}
            onClose={() => setMigrationModalOpen(false)}
            datasets={datasets}
            dataset={currentDataset}
            onMigrationDone={onMigrationDone}
            currentWarehouse={currentWarehouse}
          />
        )}

        <div style={{ flex: 1 }} />

        {currentDataset?.managedBy !== "DBT_CLOUD" ? (
          <Flex gap="middle" align="center" style={{ paddingInline: 12 }}>
            <Flex gap="small">
              <Tooltip
                title={`Run query ${
                  isMacintosh() ? "(⌘ + enter)" : "(Ctrl + enter)"
                }`}
              >
                <Button
                  type="text"
                  size="small"
                  icon={<PlayCircleOutlined />}
                  onClick={onRunQuery}
                >
                  <Badge offset={[5, -5]} dot={highlightRunQuery}>
                    Run query
                  </Badge>
                </Button>
              </Tooltip>

              {updateModel && currentDataset && (
                <Button
                  onClick={() => setPreSaveModalVisible(true)}
                  disabled={!canUpdateModel}
                  type="text"
                  size="small"
                  icon={<SaveOutlined />}
                >
                  Save
                </Button>
              )}

              {onCreateDataset && (
                <Button
                  onClick={() => setCreateModelModalVisible(true)}
                  disabled={!canUpdateModel}
                  type="text"
                  size="small"
                  icon={<PlusCircleOutlined />}
                >
                  Save as model
                </Button>
              )}

              {currentDataset && (
                <Button
                  onClick={() => setDiffModalVisible(true)}
                  disabled={currentDataset.sql === staleQuery}
                  type="text"
                  size="small"
                  icon={<DiffOutlined />}
                >
                  Show diff
                </Button>
              )}
            </Flex>

            <Dropdown trigger={["click"]} menu={{ items: moreMenuItems }}>
              <Button type="text" size="small" icon={<MoreOutlined />} />
            </Dropdown>

            {onCreateDataset && (
              <CreateModelModal
                visible={createModelModalVisible}
                onClose={() => setCreateModelModalVisible(false)}
                getSchema={async () => schemaDef}
                onCreate={onCreateDataset}
              />
            )}

            {currentDataset && (
              <PreSaveModelModal
                datasetId={currentDataset.id}
                currentWarehouse={currentWarehouse}
                visible={preSaveModalVisible}
                getNewSchema={async () => {
                  return schemaDef;
                }}
                onCancel={() => setPreSaveModalVisible(false)}
                onContinue={() => {
                  setPreSaveModalVisible(false);
                  setPrimaryKeysModalVisible(true);
                }}
              />
            )}

            {currentDataset && (
              <DbtQueryModal
                datasetId={currentDataset.id}
                isOpen={dbtQueryModalVisible}
                setIsOpen={(open) => setDbtQueryModalVisible(open)}
              />
            )}

            {currentDataset && (
              <HistoryDrawer
                onClose={() => setHistoryDrawerVisible(false)}
                visible={historyDrawerVisible}
                relatedObjects={relatedObjects}
              />
            )}

            {currentDataset && (
              <PrimaryKeysModal
                getInitialKeys={() => currentDataset.primaryKey.split(",")}
                getSchema={async () => {
                  return schemaDef;
                }}
                visible={primaryKeysModalVisible}
                saving={primaryKeysModalSaving}
                onCancel={() => setPrimaryKeysModalVisible(false)}
                onSave={async (values) => {
                  setPrimaryKeysModalSaving(true);
                  try {
                    await updateModel?.(values.keys);
                  } catch (error) {
                    antUtils.message.error("Error saving your query");
                  } finally {
                    setPrimaryKeysModalSaving(false);
                    setPrimaryKeysModalVisible(false);
                  }
                }}
              />
            )}

            <DiffModal
              previousValue={currentDataset?.sql}
              newValue={staleQuery}
              fieldType="sql"
              visible={diffModalVisible}
              onCancel={() => setDiffModalVisible(false)}
              onOk={() => setDiffModalVisible(false)}
              additionalButtons={
                <Button
                  disabled={!currentDataset?.sql}
                  type="default"
                  onClick={() =>
                    currentDataset?.sql && resetStaleQuery(currentDataset.sql)
                  }
                >
                  Revert with previous value
                </Button>
              }
            />
          </Flex>
        ) : null}
      </Toolbar.Toolbar>
    </div>
  );
}

export default compose<Props, ISQLToolbarProps>(withAntUtils)(SQLToolbar);
