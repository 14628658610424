import { Drawer } from "antd";
import { inject, observer } from "mobx-react";
import React from "react";
import { useBeforeunload } from "react-beforeunload";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import { compose } from "../../../../components/compose/WlyCompose";
import type { AsyncData } from "../../../../helpers/typescriptHelpers";
import type { IDestination } from "../../../../interfaces/destinations";
import type { IExploration } from "../../../../interfaces/explorations";
import type { IDataset, ISource } from "../../../../interfaces/sources";
import type { Transformation } from "../../../../interfaces/transformations";
import type { WorkbenchUIStoreProps } from "../../../../store/workbenchUIStore";
import ExplorationContent from "../../../exploration/single/ExplorationContent";
import type { InjectedOrgProps } from "../../../orgs/WithOrg";
import WithOrg from "../../../orgs/WithOrg";
import type { ISpreadsheetLoading } from "../../../spreadsheet/SpreadsheetCore";
import type { TableTabItem } from "../../../spreadsheet/domain";
import Dataset from "../dataset/Dataset";
import type {
  FetchedDestination,
  IActiveObject,
  IDatasetLineageGraph,
  IDatasetUpdate,
  ModifyQuery,
  TabData,
  TableData,
} from "../domain";
import type { DatasetSavedData } from "../selector/tables/models/AddModelModal";

interface IModelProps {
  left: IModelItemProps;
  datasets: IDataset[];
  tables: TableTabItem[];
  activeModelKey: string;
  sources: Array<ISource>;
  routeRender: (params: object, query?: object) => string;
  onShowFlowMigration: () => void;
  onFullscreenChange?: (isFullscreen: boolean) => void;
  onCreateModel: (dataset: DatasetSavedData) => Promise<any>;
  activeObject: IActiveObject;
  onRefreshDatasets: (id?: string[]) => Promise<void>;
  destination: FetchedDestination;
  onRefreshExplorations: (id?: string[]) => Promise<void>;
  currentWarehouse: IDestination;
}

interface IModelItemProps {
  tabData: TabData;
  onCreateRelationship?: (createPayload: any) => Promise<void>;
  onUpdateRelationship?: (id: string, updatePayload: any) => Promise<void>;
  onDeleteRelationship?: (id: string) => Promise<void>;
  onUpdateDataset?: IDatasetUpdate;
  onDeleteDataset?: () => Promise<void>;
  onRenameView?: (viewId: string, name: string) => Promise<void>;
  onDeleteView?: (viewId: string) => Promise<any>;
  onCreateView?: (name: string) => Promise<void>;
  mashupData: TableData;
  onRefresh?: (activeKey: string, activeView: string) => void;
  scollToColumnIndex?: number;
  onDrillUpdate?: (viewId: string, drills: string[]) => Promise<any>;
  spreadsheetLoading: ISpreadsheetLoading;
  onModifyTransformation?: ModifyQuery;
  activeTableKey: string;
  activeViewKey: string;
  spreadsheetErrorMessage?: string;
  datasetQueryCursor: string;
  viewQueryCursor: AsyncData<string>;
  transformations: AsyncData<Transformation[]>;
  onActiveViewKeyChange?: (key: string) => void;
  datasetLineageGraph: IDatasetLineageGraph;
}

type Props = RouteComponentProps<{
  dependencySlug?: string;
  tableSlug: string;
}> &
  IModelProps &
  WorkbenchUIStoreProps &
  InjectedOrgProps;

const Model = (props: Props) => {
  const {
    left,
    datasets,
    tables,
    activeModelKey,
    routeRender,
    sources,
    onFullscreenChange,
    activeObject,
    destination,
    onRefreshDatasets,
    onRefreshExplorations,
    user,
    currentWarehouse,
  } = props;

  const [showExploration, setShowExploration] = React.useState<
    IExploration | undefined
  >();

  const query =
    left.tabData.dataset.type === "SQL"
      ? left.tabData.sql
      : JSON.stringify(
          left.tabData.currentStack.filter(
            (a) => a.domain === "datasetResolver"
          )
        );

  const storedStaleState = props.workbenchUIStore.getActiveObjectStaleState({
    type: "dataset",
    value: activeModelKey,
  }) as { query: string; head?: string } | undefined;

  const staleQuery =
    storedStaleState?.query != null
      ? storedStaleState.query?.toString()
      : query;

  const staleHead =
    storedStaleState && storedStaleState.head
      ? storedStaleState.head.toString()
      : left.tabData.head;

  useBeforeunload((event) => {
    if (staleQuery !== query) {
      event.preventDefault();
    }
    if (staleHead !== left.tabData.head) {
      event.preventDefault();
    }
  });

  const currentdataset = datasets.find((d) => d.id === activeModelKey);
  if (!currentdataset) {
    return <div>no dataset selected...</div>;
  }

  const isSql = !!currentdataset.sql;
  const isFlow = currentdataset.type === "FLOW";

  const dependenciesDataset: IDataset[] = [];

  if (isSql) {
    datasets.forEach((d) => {
      const legacyInc = `\$\{TABLES["${d.source?.name}"]["${d.name}"]\}`;
      const inc = `\$\{DATASETS["${d.id}"]\}`;
      const raw = `\$\{RAW["${d.id}"]\}`;
      if (
        staleQuery?.includes(legacyInc) ||
        staleQuery?.includes(inc) ||
        staleQuery?.includes(raw)
      ) {
        dependenciesDataset.push(d);
      }
    });
  }

  if (isFlow) {
    try {
      const parsedStaleQuery = JSON.parse(staleQuery) as Transformation[];
      parsedStaleQuery.forEach((query) => {
        const operation = query.operation;
        if (operation.type === "Table.FromWarehouseTable") {
          const foundDataset = datasets.find((d) => {
            return (
              d.warehouseDatabaseId === operation.args.databaseName &&
              d.warehouseSchemaId === operation.args.schemaName &&
              d.warehouseTableId === operation.args.tableName
            );
          });
          if (foundDataset) {
            dependenciesDataset.push(foundDataset);
          }
        }
        if (operation.type === "Table.FromWhalyDataset") {
          const foundDataset = datasets.find((d) => {
            return d.id === operation.args.datasetId;
          });
          if (foundDataset) {
            dependenciesDataset.push(foundDataset);
          }
        }
      });
    } catch (err) {
      console.error(err);
    }
  }

  const single = (
    <>
      <Dataset
        datasetLineageGraph={left.datasetLineageGraph}
        tabData={left.tabData}
        datasets={datasets}
        currentWarehouse={currentWarehouse}
        onCreateRelationship={left.onCreateRelationship}
        onUpdateRelationship={left.onUpdateRelationship}
        onDeleteRelationship={left.onDeleteRelationship}
        onDeleteDataset={left.onDeleteDataset}
        routeRender={routeRender}
        onRefreshDatasets={onRefreshDatasets}
        onExploreClick={
          !user.isAdmin
            ? undefined
            : (datasetId, viewId) => {
                const foundDataset = left.tabData.dataset;
                const foundView = left.tabData.views.find(
                  (v) => v.id === viewId
                );
                if (foundView && left.mashupData.schema) {
                  const exploration: IExploration = {
                    id: foundView.id,
                    name: foundView.name,
                    version: {
                      id: "",
                    },
                    tables: [
                      {
                        id: foundView.id,
                        name: foundView.name,
                        cubeName: foundView.cubeName,
                        primaryKey: foundDataset.primaryKey,
                        incomingRelationships: [],
                        outgoingRelationships: [],
                        view: {
                          ...foundView,
                          dataset: {
                            ...foundDataset,
                          },
                        },
                        metrics: [
                          {
                            id: "count",
                            cubeName: "_wly_count",
                            name: "Record count",
                            columnName: "",
                            expression: "COUNT",
                          },
                        ],
                        dimensions: Object.keys(left.mashupData.schema).map(
                          (k) => {
                            return {
                              id: k,
                              cubeName: k,
                              columnDomain: left.mashupData.schema[k].domain,
                              name: k,
                              columnName: k,
                              type: "standard",
                            };
                          }
                        ),
                      },
                    ],
                  } as IExploration;
                  setShowExploration(exploration);
                }
              }
        }
        onUpdateDataset={
          left.onUpdateDataset
            ? (d: any) => {
                return left.onUpdateDataset(d).then(() => {
                  props.workbenchUIStore.setActiveObjectStale(
                    activeObject,
                    undefined,
                    false
                  );
                });
              }
            : undefined
        }
        scollToColumnIndex={left.scollToColumnIndex}
        onRefresh={left.onRefresh}
        mashupData={left.mashupData}
        tables={tables}
        onModifyTransformation={left.onModifyTransformation}
        activeTableKey={left.activeTableKey}
        activeViewKey={left.activeViewKey}
        spreadsheetLoading={left.spreadsheetLoading}
        spreadsheetErrorMessage={left.spreadsheetErrorMessage}
        transformations={left.transformations}
        datasetQueryCursor={left.datasetQueryCursor}
        viewQueryCursor={left.viewQueryCursor}
        onRefreshExplorations={onRefreshExplorations}
        setStaleQuery={(v) => {
          if (v !== staleQuery) {
            props.workbenchUIStore.setActiveObjectStale(
              activeObject,
              { ...storedStaleState, query: v },
              true
            );
          }
        }}
        staleQuery={staleQuery}
        staleHead={staleHead}
        setStaleHead={(h) => {
          if (h !== staleHead) {
            props.workbenchUIStore.setActiveObjectStale(
              activeObject,
              { ...storedStaleState, head: h },
              true
            );
          }
        }}
        sources={sources}
        isStale={activeObject.stale}
        onDrillUpdate={left.onDrillUpdate}
        onShowFlowMigration={
          left.tabData.isModel ? undefined : props.onShowFlowMigration
        }
        showFullscreen={true}
        onFullscreenChange={(isFullscreen) => {
          if (onFullscreenChange) {
            onFullscreenChange(isFullscreen);
          }
        }}
        onCreateModel={props.onCreateModel}
        onRenameView={left.onRenameView}
        onDeleteView={left.onDeleteView}
        onCreateView={left.onCreateView}
        runResults={left.tabData.runResults}
        destination={destination}
      />
      <Drawer
        open={!!showExploration}
        placement="bottom"
        height={"calc(100% - 60px)"}
        title={`Explore`}
        onClose={() => setShowExploration(undefined)}
        styles={{
          body: { padding: 0 },
          header: { paddingLeft: 4, paddingRight: 4 },
        }}
      >
        {showExploration && (
          <div className="exploration-wrapper">
            <ExplorationContent
              isEmbeded={true}
              exploration={showExploration}
              overrideObjectType="VIEW"
            />
          </div>
        )}
      </Drawer>
    </>
  );

  return single;
};

export default compose<Props, IModelProps>(
  withRouter,
  WithOrg,
  inject("workbenchUIStore"),
  observer
)(Model);
