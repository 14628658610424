// Based on npm package @ant-design/colors
// TODO : Use the NPM package instead when Ant has fixed major issues with it (https://github.com/ant-design/ant-design-colors/issues/39)

export const red = [
  "#fff1f0",
  "#ffccc7",
  "#ffa39e",
  "#ff7875",
  "#ff4d4f",
  "#f5222d",
  "#cf1322",
  "#a8071a",
  "#820014",
  "#5c0011",
];
export const volcano = [
  "#fff2e8",
  "#ffd8bf",
  "#ffbb96",
  "#ff9c6e",
  "#ff7a45",
  "#fa541c",
  "#d4380d",
  "#ad2102",
  "#871400",
  "#610b00",
];
export const orange = [
  "#fff7e6",
  "#ffe7ba",
  "#ffd591",
  "#ffc069",
  "#ffa940",
  "#fa8c16",
  "#d46b08",
  "#ad4e00",
  "#873800",
  "#612500",
];
export const gold = [
  "#fffbe6",
  "#fff1b8",
  "#ffe58f",
  "#ffd666",
  "#ffc53d",
  "#faad14",
  "#d48806",
  "#ad6800",
  "#874d00",
  "#613400",
];
export const yellow = [
  "#feffe6",
  "#ffffb8",
  "#fffb8f",
  "#fff566",
  "#ffec3d",
  "#fadb14",
  "#d4b106",
  "#ad8b00",
  "#876800",
  "#614700",
];
export const lime = [
  "#fcffe6",
  "#f4ffb8",
  "#eaff8f",
  "#d3f261",
  "#bae637",
  "#a0d911",
  "#7cb305",
  "#5b8c00",
  "#3f6600",
  "#254000",
];
export const green = [
  "#f6ffed",
  "#d9f7be",
  "#b7eb8f",
  "#95de64",
  "#73d13d",
  "#52c41a",
  "#389e0d",
  "#237804",
  "#135200",
  "#092b00",
];
export const cyan = [
  "#e6fffb",
  "#b5f5ec",
  "#87e8de",
  "#5cdbd3",
  "#36cfc9",
  "#13c2c2",
  "#08979c",
  "#006d75",
  "#00474f",
  "#002329",
];
export const blue = [
  "#e6f4ff",
  "#bae0ff",
  "#91caff",
  "#69b1ff",
  "#4096ff",
  "#1677ff",
  "#0958d9",
  "#003eb3",
  "#002c8c",
  "#001d66",
];
export const geekblue = [
  "#f0f5ff",
  "#d6e4ff",
  "#adc6ff",
  "#85a5ff",
  "#597ef7",
  "#2f54eb",
  "#1d39c4",
  "#10239e",
  "#061178",
  "#030852",
];
export const purple = [
  "#f9f0ff",
  "#efdbff",
  "#d3adf7",
  "#b37feb",
  "#9254de",
  "#722ed1",
  "#531dab",
  "#391085",
  "#22075e",
  "#120338",
];
export const magenta = [
  "#fff0f6",
  "#ffd6e7",
  "#ffadd2",
  "#ff85c0",
  "#f759ab",
  "#eb2f96",
  "#c41d7f",
  "#9e1068",
  "#780650",
  "#520339",
];
export const grey = [
  "#ffffff",
  "#fafafa",
  "#f5f5f5",
  "#f0f0f0",
  "#d9d9d9",
  "#bfbfbf",
  "#8c8c8c",
  "#595959",
  "#434343",
  "#262626",
  "#1f1f1f",
  "#141414",
  "#000000",
];
