import { action, makeObservable, observable, runInAction } from "mobx";
import type { IObject } from "../interfaces/object";

type ShareObjectModalOpenParams = IObject | undefined;
type DrawerType = "lists" | "dashboards" | undefined;

class WorkspaceUIStore {
  drawerType: DrawerType = undefined;
  drawerTitleActionsDomNode: HTMLDivElement | undefined = undefined;
  drawerLabel: string = "";
  shareObjectModalOpen: ShareObjectModalOpenParams;
  siderSize: number = 240;
  selectedFolderId: string | undefined;
  hideRightHeader: boolean = false;
  globalSearchOpen: boolean = false;

  constructor() {
    makeObservable(this, {
      drawerType: observable,
      drawerTitleActionsDomNode: observable,
      drawerLabel: observable,
      shareObjectModalOpen: observable,
      globalSearchOpen: observable,
      siderSize: observable,
      selectedFolderId: observable,
      hideRightHeader: observable,
      setDrawerType: action,
      setDrawerTitleActionsDomNode: action,
      setDrawerLabel: action,
      setRightHeaderVisible: action,
      setSiderSize: action,
      setSelectedFolder: action,
      setGlobalSearchOpen: action,
      setShareObjectModalOpen: action,
    });
  }

  setRightHeaderVisible = (visible: boolean) => {
    this.hideRightHeader = !visible;
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 50);
  };

  setDrawerType = (drawerType: DrawerType) => {
    this.drawerType = drawerType;
    this.setDrawerLabel(drawerType);
  };

  setDrawerTitleActionsDomNode = (domNode: HTMLDivElement) => {
    this.drawerTitleActionsDomNode = domNode;
  };

  setDrawerLabel = (drawerType: DrawerType) => {
    switch (drawerType) {
      case "lists":
        this.drawerLabel = "Lists";
        break;
      case "dashboards":
        this.drawerLabel = "Dashboards";
        break;
    }
  };

  setGlobalSearchOpen = (open: boolean) => {
    this.globalSearchOpen = open;
  };

  setSiderSize = (siderSize: number) => {
    this.siderSize = siderSize;
  };

  setSelectedFolder = (folderId?: string) => {
    this.selectedFolderId = folderId;
  };

  setShareObjectModalOpen = (params: ShareObjectModalOpenParams) => {
    runInAction(() => {
      this.shareObjectModalOpen = params;
    });
  };
}

export interface WorkspaceUIStoreProps {
  workspaceUIStore: WorkspaceUIStore;
}

export default new WorkspaceUIStore();
