import {
  FieldBinaryOutlined,
  FieldNumberOutlined,
  FieldStringOutlined,
  FieldTimeOutlined,
} from "@ant-design/icons";
import { Tag } from "antd";
import { useCss } from "react-use";
import type { DataType } from "../../../../interfaces/transformations";

export const DomainTag = ({ domain }: { domain: DataType }) => {
  const tagClassname = useCss({
    padding: "0 4px",
    margin: "0px",
    fontSize: "12px",
    lineHeight: "16px",
  });

  switch (domain) {
    case "TIME":
      return (
        <Tag className={tagClassname} color="rgb(241, 189, 108)">
          <FieldTimeOutlined />
        </Tag>
      );
    case "NUMERIC":
      return (
        <Tag color="rgb(131, 201, 169)" className={tagClassname}>
          <FieldNumberOutlined />
        </Tag>
      );
    case "STRING":
      return (
        <Tag color="rgb(166, 159, 243)" className={tagClassname}>
          <FieldStringOutlined />
        </Tag>
      );
    case "BOOLEAN":
      return (
        <Tag color="rgb(242, 111, 178)" className={tagClassname}>
          <FieldBinaryOutlined />
        </Tag>
      );
  }
};
