import type { FlexProps } from "antd";
import { Button, Flex } from "antd";
import type { ReactNode } from "react";

import classNames from "classnames";
import "./TabMenu.scss";

interface TabMenuItem<T> {
  key: T;
  content?: ReactNode;
  icon?: ReactNode;
  disabled?: boolean;
}

interface TabMenuProps<T> {
  items: Array<TabMenuItem<T>>;
  activeKey: T;
  onChange: (key: T) => void;
  gap?: string;
  align?: FlexProps["align"];
}
export const TabMenu = <T extends string>({
  items,
  activeKey,
  onChange,
  gap,
  align,
}: TabMenuProps<T>) => {
  return (
    <Flex gap={gap ?? "small"} align={align ?? "center"}>
      {items.map(({ key, content, icon, disabled }) => {
        return (
          <Button
            key={key}
            size="small"
            type="text"
            onClick={() => onChange(key)}
            icon={icon}
            disabled={disabled}
            className={classNames(
              "tab-menu-item",
              activeKey === key && "active"
            )}
          >
            {content}
          </Button>
        );
      })}
    </Flex>
  );
};
