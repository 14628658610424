import { Space } from "antd";
import * as React from "react";
import type {
  AvailableDimension,
  AvailableMetric,
} from "../../../../../../../components/measures/filter-item/FilterItem";
import type { DraggableMeasure } from "../../../../../../../components/measures/measure-item/DraggableMeasureItem";
import { DraggableMeasureItem } from "../../../../../../../components/measures/measure-item/DraggableMeasureItem";
import type {
  MeasureDropZone,
  MeasureDropZoneAccept,
} from "../../../../../../../components/measures/measure-picker/DroppableMeasureZone";
import { DroppableMeasureZone } from "../../../../../../../components/measures/measure-picker/DroppableMeasureZone";
import MeasurePicker from "../../../../../../../components/measures/measure-picker/MeasurePicker";

interface IPropertyGroupsSelectorProps {
  zone: MeasureDropZone;
  accept: MeasureDropZoneAccept;
  measures?: Array<AvailableMetric | AvailableDimension>;
  max?: number;
  value?: Array<{ id: string; agg?: string }>;
  showTimeAgg?: boolean;
  onChange?: (value: Array<{ id: string; agg?: string }>) => any;
}

const PropertyGroupsSelector: React.FunctionComponent<
  IPropertyGroupsSelectorProps
> = (props) => {
  const { onChange, zone, accept, max } = props;
  const measures = props.measures ? props.measures : [];
  const value = props.value ? props.value : [];

  const disabled = typeof max === "number" && value.length >= max;

  const availableMeasures = measures.filter(
    (measure) => !value.map((v) => v.id).includes(measure.key)
  );

  const isMeasureAvailable = (key: string): boolean => {
    return !!measures.find((m) => m.key === key);
  };

  const onMeasureDelete = (key: string) => {
    if (!onChange) return;
    const newValue = [...value.filter((v) => v.id !== key)];
    onChange(newValue);
  };

  const onMeasureAdd = (key: string, position?: number) => {
    if (!isMeasureAvailable(key) || !onChange) return;
    const newValue = [...value];
    let dropIndex = newValue.length;
    if (position != null) dropIndex = position;
    newValue.splice(dropIndex, 0, { id: key });
    onChange(newValue);
  };

  const onMeasureDrop = (key: string, position: number, agg?: string) => {
    // we prevent adding new measures if we've already reached max
    if (!value.find((v) => v.id === key)) {
      if (disabled) return;
    }
    if (typeof position !== "number" || !onChange) return;
    const newValue = [...value.filter((v) => v.id !== key)];
    let dropIndex = newValue.length;
    if (position != null) dropIndex = position;
    newValue.splice(dropIndex, 0, { id: key, agg: agg });
    onChange(newValue);
  };

  const onTimeAggChange = (timeAgg: string, key: string) => {
    // we prevent adding new measures if we've already reached max
    if (!value.find((v) => v.id === key)) {
      if (disabled) return;
    }
    const position = value.findIndex((v) => v.id === key);
    if (typeof position !== "number" || !onChange) return;
    const newValue = [...value.filter((v) => v.id !== key)];
    let dropIndex = newValue.length;
    if (position != null) dropIndex = position;
    newValue.splice(dropIndex, 0, { id: key, agg: timeAgg });
    onChange(newValue);
  };

  return (
    <DroppableMeasureZone
      zone={zone}
      accept={accept}
      availableMeasures={disabled ? [] : availableMeasures}
      onAddMeasure={(i) => onMeasureAdd(i)}
    >
      <Space direction="vertical" style={{ width: "100%" }} size={4}>
        {value.map((sm, i) => {
          const measure = measures.find((measure) => {
            return measure.key === sm.id;
          });
          const onDelete = () => {
            onMeasureDelete(sm.id);
          };
          const onDrop = (item: DraggableMeasure, position: number) => {
            onMeasureDrop(item.id, position, item.agg);
          };
          return (
            <DraggableMeasureItem
              key={sm.id}
              id={sm.id}
              zone={zone}
              index={i}
              onDrop={onDrop}
              onRemove={onDelete}
              type={"property"}
              isDeleted={!!!measure}
              description={measure?.description}
              name={measure?.label || ""}
              onDelete={onDelete}
              timeAgg={sm.agg}
              onTimeAgg={
                props.showTimeAgg && measure && (measure as any)?.allowTimeAgg
                  ? (agg) => onTimeAggChange(agg, measure.key)
                  : undefined
              }
            />
          );
        })}
        <MeasurePicker
          availableMeasures={availableMeasures}
          selectedMeasureKeys={value.map((v) => v.id)}
          onMeasureChange={(i) => onMeasureAdd(i)}
          disabled={disabled}
          block
        >
          Add
        </MeasurePicker>
      </Space>
    </DroppableMeasureZone>
  );
};

export default PropertyGroupsSelector;
