import * as React from "react";
import { useMount } from "react-use";
import { track } from "../../services/AnalyticsService";
import "./SettingsWrapper.scss";

type Props = {
  children?: React.ReactNode;
};

const SettingsWrapper = ({ children }: Props) => {
  useMount(() => track("Org Settings Viewed"));

  return <>{children}</>;
};

export default SettingsWrapper;
