import {
  Button,
  Card,
  Checkbox,
  Empty,
  Form,
  List,
  Modal,
  Select,
  Switch,
  Typography,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import { compose } from "../../../components/compose/WlyCompose";
import FormActions from "../../../components/form/actions/FormActions";
import { GroupRenderer } from "../../../components/group/GroupRenderer";
import GroupPicker from "../../../components/group/picker/GroupPicker";
import { NotificationSubscriptionModal } from "../../../components/notification-subscription/NotificationSubscriptionModal";
import type { IUserGroup } from "../../../interfaces/group";
import type { INotificationWorkflow } from "../../../interfaces/notification";
import { IOrgFeatureType } from "../../../interfaces/org";
import type { ITopic } from "../../../interfaces/topic";
import type { IUserRole, IUserRoleWithGroups } from "../../../interfaces/user";
import { IUserRoleType, UserLocale } from "../../../interfaces/user";
import type { InjectedOrgProps } from "../../orgs/WithOrg";
import WithOrg, { compileUserAttributes } from "../../orgs/WithOrg";
import { CustomizeHomePageFormItem } from "../../v2-demo/container/home/customize/CustomizeHomePageFormItem";
import { UserAttributeRenderer } from "../user-attributes/UserAttributeRenderer";
import {
  isAttributeValid,
  parseUserAttributeMeta,
} from "../user-attributes/domain";

interface IEditUserFormProps {
  onDrawerClose: () => void;
  onSubmit: (
    updatedUserRole: IUserRole,
    groupIdsToBeAdded: string[],
    groupsToBeRemoved: IUserGroup[],
    attributesToUpdate: Array<any>,
    attributesToCreate: Array<any>,
    subscriptionsToCreate: Array<{
      topicId: string;
      workflowId: string;
    }>,
    subscriptionsToDelete: Array<string>
  ) => Promise<void>;
  initialData?: IUserRoleWithGroups;
  topics: ITopic[];
  workflows: INotificationWorkflow[];
}

interface FormData {
  role: IUserRoleType;
  attributes: { [key: string]: string[] | undefined };
  locale: UserLocale;
  v2HomeConfig?: string;
  hasFileUploadPermission: boolean;
}

type Props = IEditUserFormProps & InjectedOrgProps;

function EditUserForm(props: Props) {
  const {
    onDrawerClose,
    onSubmit,
    initialData,
    topics,
    workflows,
    org,
    orgFeatures,
  } = props;

  const [form] = useForm<FormData>();
  const [submittting, setSubmitting] = useState<boolean>(false);
  const [addNotificationModalOpened, setAddNotificationModalOpened] =
    useState<boolean>(false);
  const [addGroupModalOpened, setAddGroupModalOpened] =
    useState<boolean>(false);
  const [groupIdsToBeAdded, setGroupIdsToBeAdded] = useState<string[]>([]);
  const [groupsToBeRemoved, setGroupsToBeRemoved] = useState<IUserGroup[]>([]);
  const [subs, setSubs] = useState<{ topicId: string; workflowId: string }[]>(
    (initialData?.user?.topicSubscriptions ?? [])
      .map((cts) => ({
        topicId: cts.topic?.id,
        workflowId: cts.workflow?.id,
      }))
      .filter(
        (item): item is { topicId: string; workflowId: string } =>
          !!item.topicId && !!item.workflowId
      )
  );

  const displaySubscriptions = subs.flatMap((s) => {
    const topic = topics.find((t) => t.id === s.topicId);
    const workflow = workflows.find((t) => t.id === s.workflowId);
    if (!topic || !workflow) return [];
    return [{ topicName: topic.name, workflowName: workflow.name }];
  });

  useEffect(() => {
    form.validateFields();
  }, []);

  if (!initialData) {
    return <></>;
  }

  const availableGroups = org.groups.filter((orgGroup) => {
    if (orgGroup.isSystemGroup) {
      return false;
    } else {
      return true;
    }
  });

  const groupsThatCanStillBeAdded = availableGroups.filter((orgGroup) => {
    const groupIdsUserIsAlreadyIn = initialData.user.groups.map(
      (userGroup) => userGroup.group.id
    );
    if (groupIdsUserIsAlreadyIn.includes(orgGroup.id)) {
      return false;
    }
    if (groupIdsToBeAdded.includes(orgGroup.id)) {
      return false;
    }
    return true;
  });

  const currentGroups = initialData?.user?.groups.map((userGroup) => {
    return {
      type: "existing",
      ...userGroup.group,
    };
  });

  const toBeAddedUserGroups = availableGroups
    .filter((group) => {
      if (groupIdsToBeAdded.includes(group.id)) {
        return true;
      } else {
        return false;
      }
    })
    .map((group) => {
      return {
        type: "new",
        ...group,
      };
    });

  const groupsToDisplay = currentGroups
    .concat(toBeAddedUserGroups)
    .filter((group) => {
      if (
        groupsToBeRemoved
          .map((userGroupToBeRemoved) => userGroupToBeRemoved.group.id)
          .includes(group.id)
      ) {
        return false;
      } else {
        return true;
      }
    });

  const initialAttributes = compileUserAttributes(
    initialData.user,
    org.userAttributeMetas
  );

  const rationalizedAttributes = Object.keys(initialAttributes).reduce<{
    [key: string]: string[] | undefined;
  }>((acc, v) => {
    return {
      ...acc,
      [v]: initialAttributes[v].value,
    };
  }, {});

  const formattedInitialData: FormData = {
    role: initialData.role,
    attributes: rationalizedAttributes,
    locale: initialData.user.locale,
    v2HomeConfig: initialData.v2HomeConfig,
    hasFileUploadPermission: initialData.hasFileUploadPermission,
  };

  return (
    <Form<FormData>
      layout="vertical"
      initialValues={{ ...formattedInitialData }}
      form={form}
      onFinish={(v) => {
        setSubmitting(true);

        const attributesToUpdate = [];
        const attributesToCreate = [];

        const { attributes: currentAttribute } = v;

        Object.keys(initialAttributes).forEach((key) => {
          if (currentAttribute[key]) {
            // we have found the key and need to check if the value has changed
            const currentValue = JSON.stringify(currentAttribute[key]);
            const prevValue = JSON.stringify(initialAttributes[key].value);
            if (currentValue !== prevValue) {
              // the value has changed and needs to be persisted
              if (initialAttributes[key].id) {
                // it needs to be updated
                attributesToUpdate.push({
                  id: initialAttributes[key].id,
                  data: {
                    value: currentValue,
                  },
                });
              } else {
                // it needs to be created
                attributesToCreate.push({
                  data: {
                    value: currentValue,
                    user: {
                      connect: {
                        id: initialData.user.id,
                      },
                    },
                    userAttributeMeta: {
                      connect: {
                        id: initialAttributes[key].meta.id,
                      },
                    },
                    org: {
                      connect: {
                        id: org.id,
                      },
                    },
                  },
                });
              }
            }
          } else {
            // we have not found the key and need to delete it;
            if (initialAttributes[key] && initialAttributes[key].id) {
              attributesToUpdate.push({
                id: initialAttributes[key].id,
                data: {
                  deleted: true,
                },
              });
            }
          }
        });

        const initialSubsMapped: Array<{
          topicId: string;
          workflowId: string;
        }> = (initialData?.user?.topicSubscriptions ?? [])
          .map((cts) => ({
            topicId: cts.topic?.id,
            workflowId: cts.workflow?.id,
          }))
          .filter(
            (item): item is { topicId: string; workflowId: string } =>
              !!item.topicId && !!item.workflowId
          );

        const subscriptionsToCreate: Array<{
          topicId: string;
          workflowId: string;
        }> = subs.filter(
          (s) =>
            !initialSubsMapped.find(
              (ism) =>
                ism.topicId === s.topicId && ism.workflowId === s.workflowId
            )
        );

        const subscriptionsToDelete: Array<string> = initialSubsMapped
          .filter(
            (sm) =>
              !subs.find(
                (ism) =>
                  ism.topicId === sm.topicId && ism.workflowId === sm.workflowId
              )
          )
          .flatMap((sm) => {
            const match = (initialData?.user?.topicSubscriptions ?? []).find(
              (ts) =>
                ts.topic?.id === sm.topicId && ts.workflow?.id === sm.workflowId
            )?.id;
            return match ? [match] : [];
          });

        return onSubmit(
          {
            ...initialData,
            ...v,
            user: {
              ...initialData.user,
              locale: v.locale,
            },
          },
          groupIdsToBeAdded,
          groupsToBeRemoved,
          attributesToUpdate,
          attributesToCreate,
          subscriptionsToCreate,
          subscriptionsToDelete
        )
          .then(() => {
            setSubmitting(false);
            setGroupIdsToBeAdded([]);
            setGroupsToBeRemoved([]);

            onDrawerClose();
          })
          .catch((err) => {
            console.error(err);
            setSubmitting(false);
            setGroupIdsToBeAdded([]);
            setGroupsToBeRemoved([]);
          });
      }}
    >
      <div style={{ padding: 24 }}>
        <Form.Item label="Role" name={["role"]}>
          <Select>
            <Select.Option value={IUserRoleType.ADMIN_BUILDER}>
              Admin Builder
            </Select.Option>
            <Select.Option value={IUserRoleType.BUILDER}>Builder</Select.Option>
            <Select.Option value={IUserRoleType.EDITOR}>Editor</Select.Option>
            <Select.Option value={IUserRoleType.VIEWER}>Viewer</Select.Option>
            <Select.Option value={IUserRoleType.REPORT_DOWNLOADER}>
              Report Downloader
            </Select.Option>
            <Select.Option value={IUserRoleType.REPORT_VIEWER}>
              Report Viewer
            </Select.Option>
          </Select>
        </Form.Item>

        <Form.Item label="Locale" name={["locale"]}>
          <Select>
            <Select.Option value={UserLocale.fr_FR}>French</Select.Option>
            <Select.Option value={UserLocale.en_US}>English</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item label={"Home"}>
          <Card>
            <Form.Item name={["v2HomeConfig"]} noStyle>
              <CustomizeHomePageFormItem hide={["color"]} />
            </Form.Item>
          </Card>
        </Form.Item>

        <Form.Item
          hidden={!orgFeatures.includes(IOrgFeatureType.FILE_UPLOADS_API_NAME)}
          name={["hasFileUploadPermission"]}
          valuePropName="checked"
        >
          <Checkbox>Has upload file rights</Checkbox>
        </Form.Item>

        <div style={{ paddingBottom: 24 }}>
          <div style={{ display: "flex", alignItems: "baseline" }}>
            <div>
              <Typography.Title level={5}>Groups</Typography.Title>
            </div>
            <Button
              style={{ marginLeft: "auto" }}
              type={"text"}
              onClick={() => {
                setAddGroupModalOpened(true);
              }}
            >
              Add
            </Button>
          </div>
          <List
            bordered
            dataSource={groupsToDisplay}
            renderItem={(group) => (
              <List.Item>
                <GroupRenderer group={group} />
                {!group.isSystemGroup ? (
                  <>
                    {group.type === "existing" ? (
                      <Button
                        type="link"
                        onClick={() => {
                          const deletedUserGroup = initialData.user.groups.find(
                            (userGroup) => {
                              return userGroup.group.id === group.id;
                            }
                          );
                          setGroupsToBeRemoved(
                            groupsToBeRemoved.concat([deletedUserGroup])
                          );
                        }}
                      >
                        Remove
                      </Button>
                    ) : undefined}
                    {group.type === "new" ? (
                      <Button
                        type="link"
                        onClick={() => {
                          setGroupIdsToBeAdded(
                            groupIdsToBeAdded.filter((groupId) => {
                              return groupId !== group.id;
                            })
                          );
                        }}
                      >
                        Remove
                      </Button>
                    ) : undefined}
                  </>
                ) : undefined}
              </List.Item>
            )}
          />
        </div>

        {orgFeatures.includes(IOrgFeatureType.OBJECTS) && (
          <div style={{ paddingBottom: 24 }}>
            <div style={{ display: "flex", alignItems: "baseline" }}>
              <div>
                <Typography.Title level={5}>Notifications</Typography.Title>
              </div>
              <Button
                style={{ marginLeft: "auto" }}
                type={"text"}
                onClick={() => {
                  setAddNotificationModalOpened(true);
                }}
              >
                Manage
              </Button>
            </div>
            <List
              bordered
              locale={{
                emptyText: (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description="No subscriptions"
                  />
                ),
              }}
              dataSource={displaySubscriptions}
              renderItem={(topicSubscription) => (
                <List.Item>
                  <List.Item.Meta
                    title={
                      <Typography.Text strong>
                        {topicSubscription.topicName}
                      </Typography.Text>
                    }
                    description={topicSubscription.workflowName}
                  />
                </List.Item>
              )}
            />
          </div>
        )}

        <div>
          {org.userAttributeMetas.length > 0 && (
            <div style={{ marginBottom: 12 }}>
              <Typography.Title level={5}>Attributes</Typography.Title>
            </div>
          )}
          {org.userAttributeMetas.map((t, i) => {
            const parsed = parseUserAttributeMeta(t);

            return (
              <Form.Item
                key={i}
                rules={[
                  {
                    validator: (_, v) => {
                      const isValid = isAttributeValid(v, parsed);
                      if (!isValid) {
                        return Promise.reject("Value is not valid");
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
                name={["attributes", t.technicalName]}
                label={t.label}
              >
                <UserAttributeRenderer
                  type={parsed.type}
                  multi={!!parsed.allowMultipleValues}
                  options={parsed.options}
                />
              </Form.Item>
            );
          })}
        </div>
        <NotificationSubscriptionModal
          initialValues={subs}
          topics={topics}
          workflows={workflows}
          open={addNotificationModalOpened}
          onCancel={() => setAddNotificationModalOpened(false)}
          onOk={(v) => {
            setSubs(v);
            setAddNotificationModalOpened(false);
          }}
        />
        <Modal
          open={addGroupModalOpened}
          title={"Add a Group"}
          onCancel={() => setAddGroupModalOpened(false)}
          maskClosable={false}
          footer={null}
        >
          <GroupPicker
            ctaText="Add"
            onClose={() => setAddGroupModalOpened(false)}
            availableGroups={groupsThatCanStillBeAdded}
            onGroupSelection={async (groupId) => {
              setGroupIdsToBeAdded(groupIdsToBeAdded.concat([groupId]));
            }}
          />
        </Modal>
      </div>
      <FormActions
        isSubmitting={submittting}
        onCancel={onDrawerClose}
        submitText="Save"
      />
    </Form>
  );
}

export default compose<Props, IEditUserFormProps>(WithOrg)(EditUserForm);
