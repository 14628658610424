import { action, makeObservable, observable } from "mobx";
import { refreshPortalJwt, refreshToken } from "../auth/auth";
import type {
  JwtTokenResource,
  RefreshTokenResource,
} from "../interfaces/auth";

const SAFETY_NET = 10;

class AuthStore {
  authentication: RefreshTokenResource | JwtTokenResource | undefined;
  loading: boolean;
  refreshing: boolean;
  timeout: boolean;

  constructor() {
    makeObservable(this, {
      authentication: observable,
      loading: observable,
      refreshing: observable,
      timeout: observable,
      login: action,
      saveToken: action,
      refreshSession: action,
      logout: action,
    });

    this.loading = false;
    this.refreshing = false;
    this.timeout = false;
  }

  setAuthentication = (token: RefreshTokenResource | JwtTokenResource) => {
    window.authentication = token;
    this.authentication = token;
    // StorageService.setItem(token);
  };

  getExpirationDate = (expiresIn: number) => {
    return Date.now() + expiresIn * 1000 - SAFETY_NET * 1000;
  };

  generateTimeout = (delay: number) => {
    if (!this.timeout) {
      this.timeout = true;

      setTimeout(() => {
        this.refreshSession();
        this.timeout = false;
      }, (delay - SAFETY_NET) * 1000);
    }
  };

  saveToken = (token: RefreshTokenResource | JwtTokenResource) => {
    const expires_at = this.getExpirationDate(token.expires_in);

    this.setAuthentication({ ...token, expires_at });
    this.generateTimeout(token.expires_in);
  };

  login = (code: string) => {
    this.loading = true;

    return refreshToken({ code, grant_type: "authorization_code" })
      .then(
        action((token) => {
          this.saveToken(token);
          return token;
        })
      )
      .finally(() => (this.loading = false));
  };

  refreshSession = () => {
    if (this.authentication && !this.refreshing) {
      const { token_type } = this.authentication;
      this.refreshing = true;

      if (token_type === "bearer") {
        const { refresh_token } = this.authentication;

        return refreshToken({ refresh_token, grant_type: "refresh_token" })
          .then(action((token) => this.saveToken(token)))
          .catch((err) => console.error(err))
          .finally(() => (this.refreshing = false));
      } else if (token_type === "portal-jwt") {
        const { access_token } = this.authentication;

        return refreshPortalJwt(access_token)
          .then(
            action(({ token: access_token, expire_in }) => {
              this.saveToken({
                token_type: "portal-jwt",
                expires_in: expire_in ? parseFloat(expire_in) : 2500,
                expires_at: 0,
                access_token,
              });
            })
          )
          .catch((err) => console.error(err))
          .finally(() => (this.refreshing = false));
      }
    }

    return Promise.resolve();
  };

  logout = () => {
    this.authentication = undefined;
    window.authentication = undefined;
  };
}

export interface AuthStoreProps {
  authStore: AuthStore;
}

export default new AuthStore();
